
.admin-system-edit-coupon-dialog .ant-btn:hover,
.admin-system-edit-coupon-dialog .ant-btn:focus,
.admin-system-edit-coupon-dialog .ant-btn:active {
  color: #4a8a5d;
  border-color: #4a8a5d;
}
.admin-system-edit-coupon-dialog .ant-btn-primary {
  background: #4a8a5d;
  border: #4a8a5d;
  color: white;
}
.admin-system-edit-coupon-dialog .ant-btn-primary:hover,
.admin-system-edit-coupon-dialog .ant-btn-primary:focus,
.admin-system-edit-coupon-dialog .ant-btn-primary:active {
  background: #5b8c00;
  border-color: #5b8c00;
  color: white;
}
