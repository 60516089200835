
.address-modal
  &__form
    padding-left: 100px
    padding-right: 100px
    font-size: 20px

  &__field-margin-bottom
    margin-bottom: 16px !important

  &__save-button
    width: 147px
    height: 46px
    margin-top: 19px
    margin-bottom: 43px

  .is-danger-select
    border: 1px solid red
