
.tag-container
  padding: 0
  .ant-button
    display: flex
    align-self: center
.tag-item
  display: inline-block
  margin-bottom: 5px
  margin-right: 5px
.choosed
  background-color: #4A8A5D !important
  color: #fff !important
  outline: none
// .icon-components
//   font-size: 16px
