
.message-table__main {
  .ant-table-bordered .ant-table-thead > tr > th,
  .ant-table-bordered .ant-table-tbody > tr > td {
    border-right: none;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    font-family: "Proxima Nova";
    padding: 4px 6px;
    vertical-align: middle;
    color: #262626;
  }
  .ant-table-tbody > tr > td.ant-table-selection-column .ant-checkbox-wrapper {
    margin-bottom: 4px;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    height: 0;
  }
  .ant-table-thead > tr > th:last-child,
  .ant-table-tbody > tr > td:last-child {
    border-right: 1px solid #e8e8e8;
  }
  .ant-pagination-item-active {
    border-color: #4a8a5d;
  }
  .ant-pagination-item-active a {
    color: #4a8a5d;
  }
  &__icon {
    margin-right: 8px;
    display: none;
    cursor: pointer;
  }
  &__clear-search {
    background: #4a8a5d !important;
    border-radius: 2px !important;
    border-color: #4a8a5d !important;
    color: white !important;
    float: right;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
  }
}
.message-table__tooltip .ant-tooltip-inner {
  background: #d9d9d9;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #262626;
}
.message-table__tooltip.ant-tooltip-placement-bottomLeft {
  padding-top: 0px;
  padding-left: 32px;
}
.message-table__tooltip .ant-tooltip-arrow::before {
  background-color: #d9d9d9;
  display: none;
}
.message-table__main .ant-table-row:hover {
  background: #e2eeec;
}
.message-table__main
  .ant-table-tbody
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td {
  background: #e2eeec;
}
.message-table__main
  .ant-table-tbody
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td
  .message-table__main__icon,
.message-table__main .ant-table-tbody > tr.ant-table-row-selected:hover .message-table__main__icon {
  display: inline-block !important;
}
.message-table__main .ant-table-tbody > tr.ant-table-row-selected td,
.message-table__main .ant-table-tbody > tr.ant-table-row-selected:hover td {
  background: #e2eeec;
}
.message-table__main .ant-table-tbody > tr > td.ant-table-selection-column > span {
  visibility: hidden;
}
.message-table__main
  .ant-table-tbody
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td.ant-table-selection-column
  > span,
.message-table__main
  .ant-table-tbody
  > tr.ant-table-row-selected
  td.ant-table-selection-column
  > span {
  visibility: visible;
}
.message-table__main .ant-table-thead > tr > th.ant-table-selection-column .ant-checkbox-wrapper {
  visibility: hidden;
}
.message-table__main .ant-table.ant-table-bordered .ant-table-footer {
  border: none;
  border-top: 1px solid #e8e8e8;
}
.message-table__main .ant-table-footer {
  height: 50px;
  padding: 10px 0px;
  background: inherit;
}
