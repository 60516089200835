
button.button.forgot-password__button.is-fullwidth.is-black
  background-color: #FF6132 !important
  border-radius: 27.5px
  font-family: "ProximaNova-Regular"

.forgot-password__input
    font-family: "ProximaNova-Regular"

.form-style
   margin-top: 50px
   min-height: 70vh !important
.forgot-password
  &__container
    display: flex
    justify-content: center
    align-items: center
  &__box
    width: 360px
    border: 1px solid #E5E5E5
    border-radius: 2px
    height: 335px
    padding: 25px

  &__title
    color: #000000
    font-size: 16px
    letter-spacing: 0.44px
    line-height: 24px
    margin-bottom: 16px

  &__subtitle
    color: #8D8D8D
    font-size: 14px
    letter-spacing: 0.25px
    line-height: 24px

  &__input
    height: 40px
    border: 1px solid #E5E5E5
    border-radius: 2px
    margin: 15px 0px

  &__button
    height: 46px
    width: 312px
    border-radius: 2px

  &__divider
    border-bottom: 1px solid #e5e5e5
    margin: 24px 0px 25px 0px

  &__divider--success
    border-bottom: 1px solid #e5e5e5
    margin: 140px 0px 25px 0px

  &__links
    display: flex
    justify-content: center
    align-items: center

  &__link-title
    color: #000000
    font-size: 14px
    letter-spacing: 0.25px
    line-height: 24px

  &__link-login
    color: #008489
    font-size: 14px
    letter-spacing: 0.25px
    line-height: 17px

.forgot-password__link-login
    color: #12433D
    font-family: "ProximaNova-Regular"

.height-for-sticky-footer
  height: calc(93vh - 72px)
