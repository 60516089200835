
.admin-system {
  &__left-menu {
    float: left;
    width: 100%;
    margin-right: 25px;
    background: #ffffff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    &__menu {
      border-top: 1px solid #d9d9d9;
    }
  }
}
