
.upload-video-dialog__main {
}
.error-text-style {
  font-size: 10px;
  width: 100%;
  height: 20px;
  color: #ff6132;
  display: flex;
  align-items: center;
  justify-content: center;
}
.error-text-icon {
  width: 20px;
  height: 20px;
  line-height: 20px;
  color: #ff6132;
  margin-right: 24px;
}
.dialog-main {
  margin: 0 auto;
  text-align: center;
  padding: 125px 183px;
}
.dialog-video-title {
  font-size: 16px;
  font-family: "Proxima Nova", "Roboto", sans-serif;
  color: #000000;
  margin: 14px 0 22px 0;
  font-weight: bold;
}
.dialog-video-btn {
  //width: 103px;
  height: 40px;
  padding: 0 16px;
  line-height: 37px;
  color: #4a8a5d;
  font-size: 16px;
  margin: 0 auto;
  background-color: #e2eeec;
  border: 1px solid #4a8a5d;
  margin-bottom: 28px;
  cursor: pointer;
}
.dialog-video-content-format {
  width: 480px;
  font-size: 10px;
  text-align: left;
  font-family: "Proxima Nova", "Roboto", sans-serif;
  span {
    margin-right: 18px;
    display: inline-block;
    width: 95px;
    text-align: right;
  }
}
.ant-modal-content {
  background-color: #fafafa;
}
.upload-icon-style {
  //font-size: 60px;
  color: #4a8a5d;
}
.ant-progress-circle .ant-progress-text .anticon {
  width: 121px;
  height: 121px;
  border-radius: 50%;
  font-size: 55px;
  line-height: 124px;
  background-color: #e2eeec;
}
.ant-upload-list-item-error,
.ant-upload-list-item-error .anticon-paper-clip,
.ant-upload-list-item-error .ant-upload-list-item-name {
  display: none;
}
.ant-btn:hover,
.ant-btn:focus {
  color: #4a8a5d;
  background-color: #e2eeec;
  border: 1px solid #4a8a5d;
}
