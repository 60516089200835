
.brand-training-details__main {
  min-height: 520px;
  width: 952px;
  margin: 0 auto;

  .training-page-title {
    width: 100%;
    height: 94px;
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .training-logo {
    font-size: 48px;
    font-weight: bold;
    color: #000000;
    font-family: "Playfair Display";
  }

  .title-button {
    width: 205px;
    text-align: right;
    //display: flex;
    //justify-content: space-between;
    .btn-style {
      width: 88px;
      height: 30px;
      font-family: "Proxima Nova";
      //line-height: 20px;
      //padding: 5px 16px;
      font-size: 14px;
      border: 1px solid #4a8a5d;
      color: #4a8a5d;
      background: #e2eeec;
    }

    .btn-style:nth-child(2) {
      margin-left: 16px;
    }

    .btn-style:hover {
      color: white;
      background: #4a8a5d;
    }
    .public-btn {
      color: white;
      background-color: #12433d;
    }

    .can-not-click {
      width: 88px;
      height: 30px;
      font-family: "Proxima Nova";
      font-size: 14px;
      border: 1px solid #bfbfbf;
      color: #bfbfbf;
      background: #fafafa;
      margin-left: 16px;
    }

    .can-not-click:hover {
      //color: white;
      //background: #4a8a5d;
    }
  }
  .save-draft {
    margin-top: 16px;
    padding: 0 2px;
    width: 88px;
    height: 30px;
    font-family: "Proxima Nova";
    font-size: 14px;
    border: 1px solid #595959;
    color: #595959;
    background: #fef2e2;
  }

  .step-title {
    width: 488px;
    font-size: 13px;
    line-height: 15.83px;
    color: #000000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .step-title-one {
      width: 102px;
      height: 64px;
    }
    .step-title-two {
      width: 123px;
      height: 64px;
    }
    .step-title-three {
      width: 175px;
      height: 64px;
    }
    p {
      margin-bottom: 0;
    }
  }

  .step-line {
    width: 100%;
    height: 4px;
    border-radius: 1px;
    background: #e2eeec;
    margin-top: 8px;
  }

  .training-details-content {
    width: 100%;
    padding: 0 0 0 22px;
    margin-top: 20px;
    //height: 22px;
    .company-details {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
    .content-left {
      width: 171px;
      height: 171px;
      line-height: 171px;
      //border: 1px solid #bfbfbf;
      position: relative;
      img {
        width: 100%;
        //height: 100%;
      }
      .demo-icon-tip {
        position: absolute;
        left: -34px;
        bottom: 90px;
        z-index: 3;
        i {
          font-size: 12px;
          color: #bfbfbf;
        }
      }
    }
    .content-right {
      width: 694px;
      position: relative;
      .title-name {
        color: #000000;
        font-size: 24px;
        font-family: "Proxima Nova", "Roboto", sans-serif;
        font-weight: bold;
        line-height: 25px;
        margin-bottom: 12px;
        position: relative;
        .demo-icon-tip {
          position: absolute;
          left: 85px;
          bottom: 4px;
          z-index: 3;
        }
      }
      .about-content-main {
        position: relative;
        .demo-icon-tip {
          position: absolute;
          right: -25px;
          bottom: 0;
          z-index: 3;
        }
      }
      .content-instructions {
        height: 64px;
        font-size: 13px;
        line-height: 16px;
        font-family: "Proxima Nova", "Roboto", sans-serif;
        margin-bottom: 12px;
        color: #000000;
        position: relative;
        word-break: break-word;
        overflow-y: auto;
        //word-wrap: break-word;
        //word-break: normal;
        //overflow: hidden;
        //text-overflow: ellipsis;
        //display: -webkit-box;
        //-webkit-box-orient: vertical;
        //-webkit-line-clamp: 4;
      }
      .other-content {
        display: flex;
        justify-content: flex-start;
      }
      .curations,
      .brand-value {
        width: 325px;
        font-size: 16px;
        font-family: "Proxima Nova", "Roboto", sans-serif;
        font-weight: 400;
        span {
          display: inline-block;
          margin-right: 10px;
          margin-bottom: 10px;
          font-size: 12px;
        }
      }
      .name-curation-value {
        //margin-bottom: 8px;
        color: #000000;
      }
      .blue-tag {
        padding: 3px 7px;
        color: #1d4ba1;
        border: 1px solid #1d4ba1;
      }
      .green-tag {
        padding: 3px 7px;
        color: #4a8a5d;
        border: 1px solid #4a8a5d;
      }
      .right-top-text-button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .right-text-right {
          width: 570px;
        }
        .right-button-left {
          width: 171px;
          margin-left: 30px;
          position: relative;
          .upload-video-style {
            width: 170px;
            border: 1px solid #4a8a5d;
            color: #4a8a5d;
            background-color: #e2eeec;
            text-align: center;
            margin-top: 15px;
          }
          .upload-success {
            width: 170px;
            border: 1px solid #4a8a5d;
            color: #ffffff;
            background-color: #4a8a5d;
            text-align: center;
            margin-top: 15px;
          }
        }
      }
    }
  }
  .product-list {
    width: 100%;
    margin-top: 25px;
  }
  .demo-icon-tip {
    i {
      font-size: 12px;
      color: #bfbfbf;
    }
  }
  .ant-btn-primary {
    background-color: #12433d;
  }
  .ant-modal-confirm .ant-modal-confirm-btns {
    text-align: center;
    float: none;
  }
  .dialog-close-btn {
    width: 88px;
    height: 33px;
    line-height: 33px;
    border-radius: 2px;
    font-size: 14px;
    margin-top: 27px;
    background-color: #12433d;
    color: white;
    text-align: center;
    margin-left: 108px;
    cursor: pointer;
  }
  .upload-optional-style {
    font-size: 8px;
    color: #262626;
    position: absolute;
    left: 124px;
    bottom: -18px;
    z-index: 3;
  }
  .upload-icon-tip {
    padding-right: 2px;
    position: absolute;
    right: -17px;
    bottom: -4px;
    z-index: 4;
    i {
      font-size: 12px;
      color: #bfbfbf;
      margin-top: 20px;
    }
  }
  .video-style {
    width: 196px;
    height: 110px;
    margin-top: 16px;
    margin-left: -13px;
    text-align: center;
    border: 1px solid #262626;
    cursor: pointer;
    position: relative;
    .video-img {
      //width: 100%;
      height: 100%;
    }
    .public-start {
      position: absolute;
      top: 37px;
      right: 80px;
      border-radius: 50%;
    }
    .background-style {
      width: 43px;
      height: 43px;
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 50%;
      top: 34px;
      right: 83px;
    }
  }
  .product-list {
    width: 100%;
    margin-top: 25px;
  }
}
.ant-modal-confirm-title {
  text-align: center;
}
.info-dialog-main {
  text-align: center;
  background-color: yellow;
}
.dialog-info-before-submit {
  width: 300px;
  text-align: center;
  color: #12433d;
  font-size: 13px;
  margin-left: 64px;
  margin-top: 23px;
  font-family: "Proxima Nova", "Roboto", sans-serif;
}
.dialog-info-title {
  text-align: center;
  font-size: 16px;
  margin-bottom: 23px;
}
