
.payment {
  .payment-header {
    font-family: Proxima;

    div:first-child {
      color: #1a1818;
      font-size: 25px;
    }

    div:nth-child(2) {
      font-size: 20px;
      color: #4a4a4a;
      //   margin-bottom: 34px;
    }

    // div:nth-child(3) {
    //   color: #363636;
    //   font-size: 1rem;
    //   font-weight: 700;
    // }
  }

  .payment-section {
    font-family: Niveau Grotesk;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 15px;
  }

  .promo-top {
    display: flex;
    flex-direction: row;
    font-family: Niveau Grotesk;
    font-size: 14px;
    color: #000;

    .promo-code {
      font-weight: 700;
    }
  }

  .promo-item {
    margin-bottom: 40px !important;
  }

  .apply-coupon-code-check {
    position: absolute;
    right: 23px;
    top: 2px;
    font-size: 20px;
    color: #f15a42;
    cursor: pointer;
  }

  .fx-spacer {
    flex: 1 1 auto;
  }

  .ant-form-item {
    margin-bottom: 22px;

    &.center {
      display: flex;
      justify-content: center;
    }

    .ant-form-explain {
      position: absolute;
      bottom: -20px;
      left: 24px;
    }

    input {
      border: 1px solid #f15a42;
      padding: 6px 24px 10px 24px;
      border-radius: 50px;
      font-size: 20px;
      color: #000;
      height: fit-content;
    }

    .ant-checkbox {
      border: 1px solid #f15a42;
    }

    button {
      color: white;
      background-color: #f15a42;
      font-weight: bold;
      padding: 17px 35px;
      border-radius: 100px;
      height: fit-content;
      font-size: 1.25rem;
      line-height: 16px;

      &.is-text {
        background-color: transparent;
        border-color: transparent;
        color: #4a4a4a;

        span {
          text-decoration: underline;
        }
      }

      &.is-text:hover {
        background-color: #f5f5f5;
        color: #363636;
      }
    }
  }

  .subscription {
    padding: 17px;
    font-family: Mr Eaves Mod OT;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    text-transform: uppercase;
    background-color: rgba(241, 90, 66, 0.1);
    border-radius: 100px;
    color: #000;
    margin-bottom: 20px;

    span:last-child {
      font-weight: 400;
      margin-left: 16px;
    }
  }

  .promo-code-status-msg {
    position: absolute;
    top: 40px;
    left: 0;

    .error {
      color: #f5222d;
    }

    .valid-code {
      color: #60ceb3;
    }
  }
}
