
.brands-layout {
  display: flex;
  flex-direction: column;
  .filter-list-layout {
    display: flex;
    flex-direction: row;
    .filter {
      flex-basis: 20rem;
      flex-grow: 1;
    }

    .list {
      flex-basis: 0;
      flex-grow: 999;
    }
  }
}
