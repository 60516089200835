
.admin-retailer-setttings {
  padding-top: 58px;
  width: 800px;
  font-family: Proxima;
  &__title {
    color: #000000;
    font-size: 18px;
    letter-spacing: 0.32px;
    line-height: 18px;
    padding-bottom: 16px;
  }
  &__line {
    color: #4a4a4a;
    font-size: 1em;
    line-height: 1.5;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
  &__first-row {
    border-top: 1px solid #ddd;
  }
  &__main-block {
    width: 100%;
    display: flex;
    padding: 10px 0px 8px 35px;
    border-bottom: 1px solid #ddd;
    min-height: 50px;
  }
  &__left-block {
    padding: 0px !important;
    display: flex;
    align-items: center;
  }
  &__right-block {
    padding: 0px !important;
    display: flex;
    align-items: center;
  }
  &__left-text {
    font-size: 14px;
    font-weight: 600;
    color: #333;
    margin-bottom: 0 !important;
    text-align: left;
  }
  &__text {
    margin-bottom: 0px !important;
    text-align: left;
    font-size: 14px;
    font-weight: 300;
    color: #333;
  }
  &__action-col {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  &__btn {
    background-color: #000 !important;
    border-color: transparent;
    color: #fff !important;
    min-width: 124px;
    height: 30px;
    font-size: 14px;
    font-weight: 600;
    border-width: 1px;
    cursor: pointer;
    -webkit-box-pack: center;
    justify-content: center;
    -ms-flex-pack: center;
    padding-bottom: calc(0.375em - 1px);
    padding-left: 0.75em;
    padding-right: 0.75em;
    padding-top: calc(0.375em - 1px);
    text-align: center;
    white-space: nowrap;
    line-height: 1.5;
    position: relative;
    vertical-align: top;
  }
}
