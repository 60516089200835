
.admin-system-promocode .ant-pagination-item-active {
  border-color: #4a8a5d;
}
.admin-system-promocode .ant-pagination-item-active a {
  color: #4a8a5d;
}
.admin-system-promocode-history .ant-btn:hover,
.admin-system-promocode-history .ant-btn:focus,
.admin-system-promocode-history .ant-btn:active {
  color: #4a8a5d;
  border-color: #4a8a5d;
}
