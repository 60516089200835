
.public-read-training__main {
  min-height: 520px;
  width: 952px;
  margin: 0 auto;

  .training-page-title {
    width: 100%;
    height: 94px;
    line-height: 94px;
    font-size: 20px;
    text-align: center;
    color: #1a1818;
    font-family: "Proxima Nova", "Roboto", sans-serif;
    margin-top: 24px;
    //display: flex;
    //justify-content: space-between;
    //align-items: center;
    //img {
    //  width: 200px;
    //  height: 64px;
    //}
  }

  .training-logo {
    font-size: 48px;
    font-weight: bold;
    color: #000000;
    font-family: "Playfair Display";
  }
  .title-name {
    color: #000000;
    font-size: 24px;
    font-family: "Proxima Nova", "Roboto", sans-serif;
    font-weight: bold;
    //line-height: 94px;
    //margin-left: 49px;
    margin-top: -10px;
    margin-bottom: 8px;
  }
  .training-details-content {
    width: 100%;
    padding: 0 18px 0 22px;
    margin-top: 20px;
    //height: 22px;
    .company-details {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
    .brand-info-img-video {
      width: 360px;
      display: flex;
      justify-content: space-between;
      //align-items: center;
    }
    .img-video-list {
      width: 35px;
      height: 222px;
      .item-img-info {
        width: 35px;
        height: 35px;
        border: 0.5px solid #d9d9d9;
        border-radius: 2px;
        margin-bottom: 10px;
        img {
          width: 100%;
        }
      }
    }
    .content-left {
      width: 300px;
      height: 222px;
      line-height: 215px;
      text-align: center;
      border: 0.5px solid #d9d9d9;
      border-radius: 2px;
      position: relative;
      //border: 1px solid #bfbfbf;
      img {
        //width: 100%;
        height: 100%;
      }
      .public-start-button {
        width: 50px;
        //height: 30px;
        height: auto;
        position: absolute;
        top: 80px;
        right: 120px;
        border-radius: 50%;
      }
    }
    .content-right {
      width: 500px;
      position: relative;
      .content-instructions {
        height: 108px;
        font-size: 12px;
        line-height: 16px;
        font-family: "Proxima Nova", "Roboto", sans-serif;
        margin-bottom: 12px;
        color: #262626;
        position: relative;
        word-break: break-word;
        overflow-y: auto;
      }
      .other-content {
        //display: flex;
        //justify-content: flex-start;
      }
      .curations,
      .brand-value {
        width: 100%;
        font-size: 16px;
        font-family: "Proxima Nova", "Roboto", sans-serif;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: start;
        span {
          display: inline-block;
          margin-right: 10px;
          margin-bottom: 10px;
          font-size: 12px;
        }
      }
      .name-curation-value {
        font-size: 12px;
        font-family: "Proxima Nova", "Roboto", sans-serif;
        margin-bottom: 8px;
        margin-right: 30px;
        color: #262626;
      }
      .blue-tag {
        padding: 5px 8px;
        color: #ffffff;
        border: 1px solid #f27c59;
        background: #f27c59;
        border-radius: 100px;
        font-size: 10px;
      }
      .green-tag {
        padding: 5px 8px;
        color: #ffffff;
        border: 1px solid #91d395;
        background: #91d395;
        border-radius: 100px;
        font-size: 10px;
      }
      .right-top-text-button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .right-text-right {
          width: 570px;
        }
        .right-button-left {
          width: 171px;
          margin-left: 30px;
          .upload-video-style {
            width: 170px;
            border: 1px solid #4a8a5d;
            color: #4a8a5d;
            background-color: #e2eeec;
            text-align: center;
          }
          .upload-success {
            width: 170px;
            border: 1px solid #4a8a5d;
            color: #ffffff;
            background-color: #4a8a5d;
            text-align: center;
          }
        }
      }
    }
  }
  .video-style {
    width: 35px;
    height: 35px;
    margin-top: 6px;
    text-align: center;
    //border: 1px solid #262626;
    cursor: pointer;
    position: relative;
    .video-img {
      //width: 100%;
      height: 100%;
    }
    .public-start {
      width: 15px !important;
      position: absolute;
      top: 4px;
      right: 10px;
      border-radius: 50%;
    }
    .background-style {
      width: 10px;
      height: 10px;
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 50%;
      top: 10px;
      right: 10px;
    }
  }
  .product-list {
    width: 100%;
    margin-top: 25px;
  }
  .right-img-and-video-list {
    position: relative;
    .replace-video-button-style {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 10;
    }
  }
}
