
.order-list .ant-table-thead > tr > th .ant-table-column-sorter {
  display: inline-flex;
  justify-content: center;
  vertical-align: initial;
}
table {
  a {
    color: #333;
  }
  .old-order-icon {
    position: absolute;
    left: -4px;
    top: 19px;
    color: #1c7430;
  }
}
