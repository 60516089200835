
.guest-sign-up__main-content {
  .input,
  .textarea,
  .input:hover,
  .textarea:hover,
  .input:focus,
  .textarea:focus {
    border: 1px solid #f15a42;
    box-sizing: border-box;
    border-radius: 50px;
    padding: 16px 24px;

    font-size: 20px;
    line-height: 22px;
    color: #000000;

    box-shadow: none;
    height: fit-content;
  }

  .b-checkbox.checkbox {
    display: flex;
  }

  .b-checkbox.checkbox input[type="checkbox"] {
    width: 16px;
    height: 16px;
    margin-right: 12px;
    background: #f15a42;
    border: 1px solid #f15a42;
    box-sizing: border-box;
    border-radius: 0px;
  }

  .b-checkbox.checkbox input[type="checkbox"]:before {
    border: 1px solid #f15a42;
    box-sizing: border-box;
    content: "";
    position: relative;
    display: block;
    width: 16px;
    height: 16px;
    //background: #F15A42;
  }

  .b-checkbox.checkbox input[type="checkbox"]:after {
    z-index: 1;
  }

  .b-checkbox.checkbox span.control-label {
    flex: 1;
  }

  .field:not(:last-child) {
    margin-bottom: 20px;
  }

  .form--title {
    font-size: 25px;
    line-height: 29px;
    letter-spacing: 0.02em;
    color: #1a1818;
    margin-bottom: 30px;
    margin-top: 0;
    font-family: "Niveau Grotesk";
  }

  .form--footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
  }
  .control.has-icons-right .icon.is-right {
    top: 8px;
    cursor: pointer;
  }
  .password-input-style {
    position: relative;
  }
  .eyeIcon-style {
    top: 28px !important;
    cursor: pointer;
    position: absolute;
    z-index: 9999;
    right: 32px !important;
  }
}
