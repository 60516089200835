
.estimated-payment {
  &__title {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #262626;
  }
  &__content {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    padding: 6px 65px;
    color: #262626;
    &__title {
      color: #8c8c8c;
    }
  }
  &__divider {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #d9d9d9;
  }
  &__btn {
    box-sizing: border-box;
    border-radius: 2px !important;
    align-items: center;
    padding: 5px 16px !important;
    color: white !important;
  }
}
.download-estimated-payment__main .ant-modal-root .ant-modal .ant-modal-content .ant-modal-header {
  padding: 12px 24px !important;
}
.download-estimated-payment__main .ant-modal-close-x {
  width: 48px;
  height: 48px;
  line-height: 48px;
  font-size: 12px;
}
