
.retailer-training__main {
  width: 1200px;
  min-height: 660px;
  margin: 0 auto;
  .retailer-training-breadcrumb {
    margin-top: 50px;
  }
  .retailer-training-tab-content {
    margin-top: 20px;
  }
}
