
.span-left
  margin-left: 5px

.brand-new-product-base-form
  width: 600px

.brand-new-product
  &__label
    font-size: 14px
    font-weight: 500

  &__msrp-input
    width: 260px

  &__size-select
    width: 90px

  &__size-input
    width: 205px

  &__select, &__input
    width: 295px

    &--fullwidth
      width: 620px

  &__sample-checkbox
    margin-top: 10px
    margin-bottom: 10px
    position: relative

  &__categories-container
    font-size: 14px
    border: 1px solid #DDDDDD
    min-height: 220px
    max-height: 220px
    overflow-y: auto
    padding: 0px 2px 2px 2px

  &__category-select
    padding: 10px 20px 5px 10px
    color: #AFAFAF

  &__category-element
    padding: 7px 20px 7px 20px
    cursor: pointer

  &__category-element--selected
    background-color: #F5F6F8

  &__category-optional
    color: #DADADA
    font-size: 12px
    margin-right: 80px

  &__sample-checkbox-icon
    position: absolute
    left: 0
    cursor: pointer

  &__sample-checkbox-icon--checked
    position: absolute
    right: 3px
    top: 3px
    width: 18px
    height: 18px

  &__sample-checkbox-text
    margin-left: 25px

  &__popup-label
    font-size: 14px
    margin-right: 10px

  &__popup-control
    padding-top: 4px

  &__ingredients-margin
    margin-bottom: 10px
    display: inline-block !important
    width: 100% !important
  &__ingredients-delete
    margin-left: 10px
    cursor: pointer
  &__ingredients-load-more
    margin: 0 auto
    text-align: center
    padding-top: 6px
    padding-bottom: 6px
  &__ingredients-tag
    padding: 1px 4px !important
    padding-right: 2px !important
    background-color: #E2EEEC !important
    border-radius: 2px !important
    max-width: 170px !important
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    color: #262626 !important
    display: inline-block
    margin-left: 2px !important
    margin-right: 0px !important
    margin-bottom: 8px !important
  &__ingredients-tag-text
    font-size: 14px
    line-height: 17px
    height: 17px
    text-overflow: ellipsis
    white-space: nowrap
    overflow: hidden
    display: inline-block
    max-width: 86%
    vertical-align: middle
    margin-bottom: 2px
  &__ingredients-close
    text-align: right !important
    margin-right: 14px
  &__ingredients-tag-remove
    width: 22px
    line-height: 22px
    cursor: pointer
    text-align: center
    font-size: 10px
    border-radius: 2px
  &__ingredients-option
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 17px
    color: #262626
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis
    align-items: center
    display: block
    width: 100%
  &__ingredients-option:hover
    background: #E2EEEC !important
  &__ingredients-total
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 10px
    line-height: 12px
    color: #8C8C8C
  &__ingredients-load-more-text
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 17px
    color: #12433D
    margin-right: 9px
  &__ingredients-load-more:hover
    background-color: #E2EEEC !important
  &__ingredients-add-action
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 17px
    text-decoration-line: underline
    color: #262626
    cursor: pointer
  &__ingredients-search-clear
    font-size: 10px
    z-index: 99
    position: absolute
    top: 10px
    right: 36px
  .key-benefits-style
    margin-bottom: 10px
.productValueMultiSelect .multiselect__option--highlight
  background: #E2EEEC !important
  color: #262626 !important
.productValueMultiSelect .multiselect__tag
  background: #E2EEEC !important
  border-radius: 2px !important
  color: #262626 !important
  margin-right: 2px !important
.productValueMultiSelect .multiselect__tag:hover
  background: #E2EEEC !important
  border-radius: 2px !important
  color: #262626 !important
.brand-new-product__ingredients-margin .multiselect__option--highlight
  background: #E2EEEC !important
  color: #262626 !important
.brand-new-product__ingredients-margin .multiselect__content
  width: 100%
.brand-new-product__tooltip .ant-tooltip-arrow::before
  background-color: #8C8C8C !important
.brand-new-product__tooltip .ant-tooltip-inner
  background-color: #8C8C8C !important
  border-radius: 0px !important
  font-family: Proxima Nova
  font-style: normal
  font-weight: normal
  font-size: 12px !important
  line-height: 15px
  min-height: 17px !important
  padding: 4px !important
.multiselect__input::-ms-clear
  display: inline
#ingredientsDescTooltip .base-form-popup .base-form-popuptext
  width: 400px
  padding: 2px
