
.section.thank-you {
  position: relative;
  overflow: hidden;
  padding-top: 87px;
  padding-bottom: 64px;
}
.container {
  width: 100%;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
}
.title-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 47px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.thankyou-h1 {
  margin-top: 10%;
  margin-bottom: 36px;
  text-align: center;
}
h1 {
  margin-top: 0px;
  margin-bottom: 30px;
  font-family: niveau-grotesk, sans-serif;
  color: #1a1818;
  font-size: 60px;
  line-height: 1;
  font-weight: 300;
  letter-spacing: -0.03em;
}
.h1-span {
  display: inline-block;
  padding-right: 6px;
  padding-left: 6px;
  background-color: rgba(241, 90, 66, 0.1);
  color: #f15a42;
}
.div-block-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40%;
  margin-bottom: 10%;
}
.email {
  width: auto;
  font-size: 30px;
  font-weight: 300;
  text-align: center;
  letter-spacing: 0em;
  text-transform: none;
}
a {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.02em;
  text-decoration: none;
  text-transform: uppercase;
}
.contact-circle {
  position: absolute;
  top: 259px;
  right: -177px;
  width: 259px;
  height: 259px;
  border-radius: 50%;
  background-color: #fac92c;
}
@media screen and (max-width: 991px) {
  .container {
    width: 100%;
  }
  .contact-pattern {
    left: -115px;
  }
  .section.thank-you {
    padding-top: 57px;
  }
  .contact-circle {
    right: -186px;
  }
  .contact-pattern._2 {
    right: -90px;
  }
  .thankyou-h1 {
    margin-bottom: 26px;
  }
}
.contact-pattern {
  position: absolute;
  left: -59px;
  top: 245px;
}
img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}
.contact-pattern._2 {
  left: auto;
  top: 316px;
  right: -52px;
  z-index: 2;
}
