
.vs-progress--background
  z-index: 0!important
.uploadProgressBar
  margin-bottom: 15px!important
.productUploadBox
  width: 400px!important
  .con-vs-card
    padding: 8px 16px 8px 16px
    .vs-card--content
      margin-bottom: 0px
  .headerTxt
    color: #333333
    font-size: 16px
    font-weight: bold
    letter-spacing: 0
    line-height: 24px
    margin-bottom: 8px
  .bodyTxt
    color: #212B36
    font-size: 14px
    letter-spacing: 0
    line-height: 20px
    margin-bottom: 8px
  .upgrade-button
      background-color: #000
      border-color: transparent
      color: white
      min-width: 124px
      height: 30px
      font-size: 14px
      font-weight: 600

.brand-product
 max-width: 1024px
 margin-left: 80px
.brand-new-product__add-button, .brand-new-product__add-button--disabled
  font-size: 14px
  font-weight: 300
  letter-spacing: 0.16px
  display: flex
  justify-content: flex-end
  align-items: flex-end
  //flex-direction: column
  padding-bottom: 8px
  color: #161616
  .addProductBtn
    text-decoration: underline
    align-self: flex-end
  a
    color: #161616
    cursor: pointer
.brand-new-product__add-button--disabled
  cursor: not-allowed
  color: #9E9E9E
  align-items: flex-end
  text-decoration: underline
.catalog-content
  padding: 0 20px 0 30px
.brand-catalog
  color: #000000
  min-height: 1200px

  &__banner
    height: 280px
    margin-bottom: -35px
    background-color: #ffffff
    img
      width: 100%
      height: 100%
      display: none

  &__header
      font-size: 18px
      font-weight: 500
      letter-spacing: 0.25px
      color: #000000
      //padding-left: 15px
      border-bottom: 1px solid #D8D8D8
      padding: 0 20px 0 30px


  &__logo
    display: flex
    justify-content: center
    margin-inline-end: 10px
    margin-left: 30px
    height: 140px
    width: 140px
    border: 1px solid #E5E5E5
    background-color: #ffffff

  &__country
    font-size: 14px

  &__icons
    margin-top: 50px

  &__name
    font-size: 18px
    font-weight: bold
    letter-spacing: 0.25px
    padding-top: 50px

  &__description
    font-size: 14px
    font-weight: 300
    letter-spacing: 0.25px

  &__website
    padding-top: 30px
    text-decoration: underline

    a
      color: #161616

  &__categories
    padding-top: 60px
    padding-bottom: 10px

    &--header
      font-size: 14px
      font-weight: 600

    &--back-to
      cursor: pointer
      font-size: 14px
      font-weight: 300
      padding-top: 10px

  &__filter-header
    font-size: 14px
    font-weight: 600
    text-transform: uppercase

  &__filter
    cursor: pointer
    margin: 10px 48px 0px 0px

  &__product
    max-width: 250px
     margin: 0 auto

  &__products
    padding-top: 30px

  &__not-found
    font-size: 12px
    font-weight: 600
    padding-top: 25px
    padding-left: 10px

  &__no-products
    font-size: 16px
    font-weight: 600
    text-align: center
    padding-top: 25px
