
.completed-orders-filter
  &__items
    display: none
    overflow: hidden
    margin-top: 10px

  &__items--visible
    display: block

  &__icon
    position: absolute
    left: 0
    margin-right: 20px

  &__header-body
    position: relative
    display: block
    cursor: pointer
    padding: 16px 0px 16px 0px
    border-top: 1px solid #EBEFF2
    color: #292929
    font-size: 12px
    font-weight: 300
    line-height: 18px

  &__header--icon
    float: right
    padding-right: 10px

  &__header--reset
    float: right
    color: #B3B3B3

  &__radio-input
    width: 100%

  &__radio-label
    font-size: 14px
    color: black

  .container
    position: relative
    padding-left: 25px
    margin-top: 10px
    cursor: pointer
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none

    input
      position: absolute
      opacity: 0
      cursor: pointer

  .checkmark-box
    position: absolute
    top: 3px
    left: 0
    height: 14px
    width: 14px
    background: #FFFFFF
    border-radius: 2px
    border: 1px solid #E5E5E5

  .checkmark-radio
    position: absolute
    top: 3px
    left: 0
    height: 14px
    width: 14px
    background: #FFFFFF
    border-radius: 50%
    border: 1px solid #E5E5E5

  .container input:checked ~ .checkmark-radio, .checkmark-box
    background-color: #FFFFFF

  .checkmark-radio:after
    content: ""
    position: absolute
    display: none

  .checkmark-box:after
    content: ""
    position: absolute
    display: none

  .container input:checked ~ .checkmark-radio:after
    display: block

  .container input:checked ~ .checkmark-box:after
    display: block

  .container .checkmark-radio:after
    top: 3px
    left: 3px
    width: 6px
    height: 6px
    border-radius: 50%
    background: #000000

  .container .checkmark-box:after
    top: 3px
    left: 3px
    width: 6px
    height: 6px
    border-radius: 2px
    background: #000000
