
.brand-user-profile-page
  min-height: 80vh !important
  width: 953px
  margin: 0px auto !important
  padding: 0px
  align-content: center
  font-family: "Proxima Nova"
.header-content
  margin-bottom: 32px
.content-text-info
  display: flex
  margin-bottom: 100px
.top-margin
  margin-top: 20px
.right-item-top-margin
  margin-top: 26px
.left-right-item-padding
  padding-right: 16px !important
.upload-pdf-btn
  line-height: 1.499
  text-align: center
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%)
  height: 32px
  padding: 4px 15px
  font-size: 14px
  border-radius: 4px
  color: rgba(0, 0, 0, 0.65)
  background-color: #fff
  border: 1px solid #d9d9d9

//For edit modal
.remaining-characters
  font-size: 12px
  justify-content: flex-end
  display: flex

.pending-brand
 min-height: 80vh !important
.error-message
    color: #BF0711
    font-size: 18px
.brand-profile
  padding-bottom: 100px
  color: #000000
  font-family: "Proxima Nova"
  .save-button
    height: 36px
    width: 83px
    font-size: 12px
    font-weight: bold
    background-color: #323232

  .modal-card-body
    overflow-y: auto
    overflow-x: hidden


  &__edit-modal
    background-color: #ffffff
    width: 1038px

    &-error-message
      color: #BF0711
      font-size: 18px

    &-form-messages
      display: flex
      align-items: center

    &-form-buttons
      display: flow-root

    &--container
      min-height: 1149px

    &--form
      padding: 0px 80px 130px 80px

    &--form_section
      font-weight: 500
      font-size: 16px
      padding-top: 20px
      letter-spacing: 0.23px
      border-top: 1px solid #E5E5E5

    &--form_section-label
      margin-bottom: 20px

    .form-section-field
      padding-bottom: 25px

    &--form_terms
      position: sticky
      top: 59px

    &--form_label
      color: #000000
      font-size: 14px
      font-weight: 500
      letter-spacing: 0.2px
      padding-bottom: 5px
      padding-right: 5px

    &--form_textarea
      height: 175px

    &--form_link
      float: right
      text-decoration: underline
      color: #000000

    &--form_address
      padding-bottom: 10px

    &--form_save-button
      padding-top: 30px

    &--header
      font-size: 18px
      font-weight: 500
      letter-spacing: 0.25px
      color: #000000
      padding-left: 15px

    &--header_close-icon
      cursor: pointer

    &--menu
      padding-left: 35px
      padding-top: 30px
      background-color: #F4F4F4
      font-size: 18px

      li
        margin-top: 30px

      a
        color: #000000

  &__banner
    height: 280px
    margin-bottom: -35px
    background-color: #ffffff

  &__logo
    height: 140px
    width: 140px
    border: 1px solid #E5E5E5
    background-color: #ffffff

    img
      max-height: 140px
      max-width: 140px

  &__about
    padding: 55px 100px 0px 100px

  &__gallery-container
    padding-top: 55px

  &__gallery
    max-width: 920px
    margin: 0 auto

  &__overview
    margin-right: 40px
    font-size: 14px

  &__details-value
    font-size: 16px

  &__details
    border: 1px solid rgba(151,151,151,0.23)
    padding: 25px
    margin-bottom: 25px

    &--container
      margin-top: 30px

    &--title
      font-size: 18px
      letter-spacing: 0.25px
      padding-bottom: 20px
      padding-right: 5px

      &_text
        font-weight: 400

    &--subtitle
       font-size: 14px
       font-weight: bold

       &_link
         text-decoration: underline

  &__logo
    display: flex
    justify-content: center
    margin-inline-end: 10px
    margin-left: 30px

  &__name
    font-size: 18px
    font-weight: bold
    letter-spacing: 0.25px
    padding-top: 50px

  &__description
    font-size: 14px
    font-weight: 300
    letter-spacing: 0.25px

  &__website
    padding-top: 30px
    text-decoration: underline

    a
      color: #161616

  &__edit
    color: #161616
    font-size: 14px
    letter-spacing: 0.16px
    text-decoration: underline
    border-bottom: 1px solid #D8D8D8
    display: flex
    justify-content: flex-end

  &__edit--link
    cursor: pointer

  &__country
    font-size: 14px

  &__icons
    margin-top: 50px

.brand-profile__edit-modal-sticky-menu
  position: sticky
  top: 60px

.is-selected
  font-weight: bold
  color: #000
.enable-line-break

  white-space: pre-wrap

.disable-save
  pointer-events: none

.brand-profile__edit-modal--form_photos-verview .ant-upload-list-text .ant-upload-list-item-card-actions
  position:absolute
.brand-profile__edit-modal--form_photos-verview .ant-upload-list-text .ant-upload-list-item-name,
.brand-profile__edit-modal--form_photos-verview .ant-upload-list-picture .ant-upload-list-item-name
  padding-left:22px
.brand-profile__edit-modal--form_photos-verview .ant-upload-list-item-name
  width: 90%
.brand-profile__edit-modal--form_photos-verview .ant-upload-list-item-card-actions
  position: absolute !important
