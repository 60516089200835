
.edit-card
  &__row-margin
    margin-bottom: 10px
  &__row-action
    margin-top: 10px
  &__content-right
    direction: rtl
  &__btn
    min-width: 80px
    margin-left: 10px
    float: right
  &__dialog-title
    font-family: Proxima Nova
    font-style: normal
    font-weight: bold
    font-size: 14px
    line-height: 20px
    margin-bottom: 10px
  &__other-title
    font-family: Proxima Nova
    font-style: normal
    font-weight: bold
    font-size: 12px
    line-height: 20px
    display: flex
    margin-right: 10px
    margin-bottom: 4px !important
    color: #000
  &__other-text
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 12px
    line-height: 20px
    display: flex
    margin-right: 10px
    margin-bottom: 4px !important
    color: #000
  &__input
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 20px
    color: #000
    max-width: 100px
    direction: ltr !important
    text-align: center !important

