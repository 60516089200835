
@import '~@/assets/styles/shared/ProximaNova-Regular.css'

.guest-header, .fixed-footer
  display: block
button.button.btn_background
  background-color: #FF6132 !important
  border-radius: 27.5px
  color: white
  padding: 16px 120px
  margin-top: 24px
  font-family: "ProximaNova-Regular"
.sign-in-title-content
  font-size: 65px
  //width: 400px
  //text-align: center
  color: #1A1818
  font-family: "Niveau Grotesk"
  font-weight: 300
.eyeIcon
  top: 30px !important
  cursor: pointer
  position : absolute
  right: 0 !important
.welcome-back
  padding: 25px
  width: 360px
  border: 1px solid #E5E5E5

  &__title
    color: #000000
    font-family: "Proxima"
    font-size: 16px
    letter-spacing: 0.44px

  &__paragraph
    color: #8D8D8D
    font-family: "Proxima"
    font-size: 14px
    letter-spacing: 0.25px
    line-height: 24px
    margin-top: 24px

  &__divider
    background: #E5E5E5
    height: 1px
    margin-top: 52px
    margin-bottom: 24px

  &__footer
    font-family: "Proxima"
    color: #000000
    font-size: 14px
    letter-spacing: 0.25px

  &__resend-email
    color: #008489
    font-family: "Proxima"
    font-size: 14px
    letter-spacing: 0.25px
    line-height: 17px
    text-align: right
    display: block

.login
  &__box
    width: 360px
    min-height: 384px
    //border: 1px solid #E5E5E5
    border-radius: 2px
    display: flex
    justify-content: center
    align-items: center
    @media only screen and (max-width: 600px)
      width: auto

  &__container
    //display: flex
    //justify-content: center
    //align-items: center

  &__input
    &-email
      margin: 85px 24px 0px 24px
      width: 312px
      height: 40px

    &-password
      margin: 16px 24px 18px 24px
      width: 312px
      height: 40px

  &__question
    font-size: 20px
    font-weight: 600
    letter-spacing: 0.25px
    line-height: 17px
    color: #008489
    display: flex
    flex-direction: row-reverse
    margin-right: 24px

  &__button
    margin-top: 25px
    margin-left: 150px
    width: 312px
    height: 46px
    text-align: center

  &__border
    border-bottom: 1px solid #e5e5e5
    margin: 0px 24px 25px 24px

  &__register
    display: flex
    justify-content: center
    padding-top: 15px
    padding-left: 24px
    padding-right: 24px

    &-question
      font-size: 20px
      //font-weight: 600
      letter-spacing: 0.25px
      line-height: 21px
      margin-bottom: 23px

    &-sign-up
      font-size: 14px
      font-weight: 600
      letter-spacing: 0.25px
      color: #008489
      margin-bottom: 23px

  &__input-error
    margin: 6px 24px 0px 24px

.login__question
    color: #12433D
    font-family: "Mr Eaves Mod OT"
    font-weight: 500

.login__input-email
    font-family: 'ProximaNova-Regular'

.control.has-icons-right .input
    font-family: 'ProximaNova-Regular'

.login__register-question
    color: #212529

.login__register-sign-up
    cursor: pointer
    color: #F15A42
    margin-left: 8px

.height-for-sticky-footer
  font-family: "Mr Eaves Mod OT"
  height: calc(70vh)
  text-align: center
  .input, .textarea,
  .input:hover, .textarea:hover,
  .input:focus, .textarea:focus
    width: 560px
    border: 1px solid #F15A42
    box-sizing: border-box
    border-radius: 50px
    padding: 16px 24px

    font-size: 20px
    line-height: 22px
    color: #000000

    box-shadow: none
    height: fit-content
