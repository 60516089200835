
.admin-product-search {
  &__left-title {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 22px;
    color: #333333;
    float: left;
  }
  &__right-view {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 40px;
    color: #595959;
  }
  &__see-all {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    text-decoration-line: underline;
    color: #000000;
    margin-top: 21px;
    text-align: center;
    cursor: pointer;
  }
}

.admin-product-search__dropdown-view .ant-select-selection {
  background: rgba(74, 138, 93, 0.11);
  border: 1px dashed rgba(74, 138, 93, 0.46);
  box-sizing: border-box;
  border-radius: 2px;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #4a8a5d;
  width: 200px;
  margin-left: 10px;
  // margin-right: 10px;
}
