
.admin-training-retailer {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  &__click-oper {
    cursor: pointer;
    color: #4a8a5d;
  }
}
