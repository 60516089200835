
.guest-layout {
  min-height: 100vh !important;
  &__router-view {
    margin-top: 31px !important;

    @media screen and (max-width: 800px) {
      margin-top: 80px !important;
    }
  }
}
