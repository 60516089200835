
.brand-training-product-table__main {
  .training-page-title {
    width: 100%;
    height: 94px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .training-logo {
    font-size: 48px;
    font-weight: bold;
    color: #000000;
    font-family: "Playfair Display";
  }
  .title-button {
    width: 415px;
    text-align: right;
    //display: flex;
    //justify-content: space-between;
    .btn-style {
      width: 186px;
      height: 30px;
      font-family: "Proxima Nova";
      //line-height: 20px;
      //padding: 5px 16px;
      font-size: 14px;
      border: 2px solid #e19387;
      color: #ffffff;
      background: #e19387;
    }
    .btn-style:nth-child(2) {
      margin: 0 26px;
      width: 140px;
    }
    .btn-style:hover {
      color: white;
      background: #f27c59;
    }
  }
  .no-data {
    font-size: 16px;
    font-family: "Proxima Nova";
    color: #bfbfbf;
    height: 30px;
    width: 100%;
    margin: 30px 0;
    text-align: center;
    span {
      display: inline-block;
      border: 1px solid #bfbfbf;
      width: 106px;
      height: 30px;
      line-height: 30px;
      border-radius: 3px;
    }
  }
  .no-table-data {
    width: 100%;
    height: 270px;
    text-align: center;
    color: #bfbfbf;
    font-size: 16px;
    padding-top: 80px;
    position: relative;
    left: 350px;
  }
  .training-page-table {
    //width: 952px;
    //position: relative;
    .training-table-style {
      overflow: visible;
      width: 100%;
      color: #262626;
      //border: #e8e8e8 solid 1px;
      border-collapse: collapse;
    }
    .training-table-style tr {
      width: 100%;
      position: relative;
    }
    .training-table-style th {
      border-top: #e8e8e8 solid 1px;
      border-bottom: #e8e8e8 solid 1px;
      padding: 16px 8px;
      //background-color: #fafafa;
      font-size: 14px;
      font-weight: normal;
    }
    .training-table-style td {
      height: 56px;
      border-bottom: #e8e8e8 solid 1px;
      padding: 17px 8px;
      background-color: #ffffff;
      color: #595959;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .training-table-style tr,
    .training-table-style td.move:hover {
      //cursor: pointer;
    }
    .training-table-style td.click-pro-action:hover {
      cursor: pointer;
    }
    .name-tab-style {
      position: relative;
      span {
        position: absolute;
        right: 16px;
        top: 15px;
      }
    }
    .data-tab-style {
      //position: relative;
    }
  }
  .ant-pagination-item-active {
    border-color: #4a8a5d;
  }
  .ant-switch-checked {
    background-color: #9fd19a;
  }
  .editable-row-operations {
    color: #4a8a5d;
  }
  .icon-sort-style {
    color: #8c8c8c;
  }
}
