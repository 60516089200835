
.message-search-panel .ant-collapse-borderless > .ant-collapse-item {
  border: none;
}
.message-search-panel .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0px;
}
.message-search-panel
  .ant-collapse
  > .ant-collapse-item.ant-collapse-no-arrow
  > .ant-collapse-header {
  padding-left: 0px;
  display: block;
}
.message-search-panel .ant-collapse-borderless {
  background-color: #fff;
}
.message-search-panel
  .ant-collapse-borderless
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box {
  padding-top: 0px;
}
.message-search-panel .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px;
}
.message-search-panel__search-input .ant-input {
  height: 24px;
  border-radius: 30px;
  border: none;
}
.message-search-panel__search-input .ant-input:hover {
  border: none;
}
.message-search-panel__search-input.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border: none;
}
.message-search-panel__search-input.ant-input-affix-wrapper {
  border: none;
}
.message-search-panel__date-input .ant-input {
  border-radius: 0px;
}
.message-search-panel__date-picker .ant-calendar-selected-day .ant-calendar-date {
  background: #e2eeec;
}
.message-search-panel__date-picker .ant-calendar-today .ant-calendar-date {
  color: #4a8a5d;
  font-weight: bold;
  border-color: #4a8a5d;
}
.message-search-panel__date-picker .ant-calendar-date {
  color: #595959;
}
