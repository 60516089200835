
.promo-code {
    display: flex;
    flex-direction: column;

    .title {
        font-size: 14px;
        font-weight: bold;
        color: rgb(26, 24, 24);
        font-family: "Niveau Grotesk";
    }

    .remove-code {
        text-transform: uppercase;
        cursor: pointer;

        color: #4a4a4a;

        font-size: 16px;

        &:hover {
            text-decoration: underline;
        }
    }

    .col-spacer {
        flex: 1 1 auto;
    }

    .col2 {
        display: flex;
        flex-direction: row;
    }

    input {
        border: 1px solid #f15a42;
        padding: 6px 24px 10px 24px;
        border-radius: 50px;
        font-size: 20px;
        color: #000;
        height: fit-content;
    }

    .apply-coupon-code-check {
        font-size: 13px;
        color: #f15a42;
        cursor: pointer;
        font-family: Niveau Grotesk;
        position: absolute;
        top: 15px;
        right: 15px;
        z-index: 5;

        i {
            font-size: 12px;
            margin-left: 15px
        }
    }

    .promo-item {
        position: relative;
    }

    .promo-code-status-msg {
        margin-top: 20px;

        .error {
            color: #f5222d;
        }

        .valid-code {
            color: #60ceb3;
        }



    }
}
