
.datepicker
  float: left

  &__icon
    cursor: pointer
    position: absolute
    z-index: 10
    right: 15px
    top: 7px

  &__box
    min-height: 300px
    width: 300px
    border-right: 1px solid #CBCBCB
    border-left: 1px solid #CBCBCB
    border-bottom: 1px solid #CBCBCB
    z-index: 1
    padding-top: 10px

  &__month-name
    padding: 5px
    font-size: 14px
    color: #161616

  &__arrow
    cursor: pointer

  &__day
    height: 35px
    padding-top: 10px

  &__element
    font-size: 14px
    color: #161616
    cursor: pointer
    width: 35px
    height: 35px

  &__element_disabled
    color: #ffffff
    pointer-events: none

  &__element_selected
    background-color: #9A8C8D
    color: white
    border-radius: 50%
  &__element_invalid
    background-color: #DCDCDC
    color: white
    border-radius: 50%
    pointer-events: none

  &__weeks
    margin-top: -10px
