
.admin-retailer-search {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  padding: 10px;
  &__download-btn {
    background: #4a8a5d !important;
    border: 1px solid #4a8a5d !important;
    box-sizing: border-box;
    border-radius: 2px !important;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #fff !important;
    margin: 42px 0px 10px 0px;
  }
}
.admin-console-brand-retailer-product-table
  .ant-table-bordered
  > .ant-table-content
  .ant-table-thead
  > tr
  > th {
  padding: 7px 12px;
  color: #595959;
  background: #fafafa;
}
.admin-console-brand-retailer-product-table .ant-table-bordered .ant-table-tbody > tr > td {
  padding: 7px 12px;
  color: #595959;
}
.admin-console-brand-retailer-product-table .ant-table-bordered .ant-table-tbody > tr > td,
.admin-console-brand-retailer-product-table .ant-table-bordered .ant-table-thead > tr > th {
  border-right: none;
}
.admin-console-brand-retailer-product-table .ant-table-bordered .ant-table-thead > tr > th i {
  display: none;
  position: absolute;
  top: 2px;
  margin-left: 8px;
  // right: 0px;
}
.admin-console-brand-retailer-product-table .ant-table-bordered .ant-table-thead > tr > th:hover i {
  display: inline-block;
}
.admin-console-brand-retailer-product-table
  .ant-table-bordered
  .ant-table-tbody
  > tr
  > td:last-child,
.admin-console-brand-retailer-product-table
  .ant-table-bordered
  .ant-table-thead
  > tr
  > th:last-child {
  border-right: 1px solid #e8e8e8;
}
.admin-console-brand-retailer-product-table .ant-table-thead > tr:first-child > th:first-child {
  border-top-left-radius: 0px;
}
.admin-console-brand-retailer-product-table .ant-table-thead > tr:first-child > th:last-child {
  border-top-right-radius: 0px;
}
.admin-console-brand-link-click:hover {
  text-decoration: underline;
  cursor: pointer;
}
