
.edit-products-modal__main {
  .modal-main-edit-product {
  }
}
.edit-product__title-text {
  font-size: 24px;
  color: #000000;
  font-weight: 400;
  font-family: "Proxima Nova", "Roboto", sans-serif;
}
.button-apply-box {
  text-align: right;
}
.edit-product__apply-button_modal {
  //width: 73px;
  padding: 5px 16px;
  text-align: center;
  color: white;
  border-radius: 2px;
  cursor: pointer;
}
