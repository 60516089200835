
.admin-message-choose-product .ant-modal-header {
  background: #e2eeec;
  padding: 13px 20px;
  border-bottom: 1px solid #4a8a5d;
}
.admin-message-choose-product__title .ant-input {
  height: 28px;
  border-radius: 30px;
  border: none;
}
.admin-message-choose-product__title .ant-input:hover {
  border: none;
}
.admin-message-choose-product__title.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border: none;
}
.admin-message-choose-product__title.ant-input-affix-wrapper {
  border: none;
}
.admin-message-choose-product__title .anticon {
  color: #4a8a5d;
}
.admin-message-choose-product .ant-btn:hover {
  color: #fff;
  background: #4a8a5d;
  border-radius: 2px;
  border-color: #4a8a5d;
}
.admin-message-choose-product .ant-modal-close-x {
  color: #595959;
}
