
.bf-payment {
    .payment-header {
        font-family: Proxima;

        div:first-child {
            color: #1a1818;
            font-size: 25px;
        }

        div:nth-child(2) {
            font-size: 20px;
            color: #4a4a4a;
            //   margin-bottom: 34px;
        }

        // div:nth-child(3) {
        //   color: #363636;
        //   font-size: 1rem;
        //   font-weight: 700;
        // }
    }

    .payment-section {
        font-family: Niveau Grotesk;
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 15px;
    }

    .promo-top {
        display: flex;
        flex-direction: row;
        font-family: Niveau Grotesk;
        font-size: 14px;
        color: #000;

        .promo-code {
            font-weight: 700;
        }
    }

    .promo-item {
        margin-bottom: 40px !important;
    }

    .apply-coupon-code-check {
        position: absolute;
        right: 23px;
        top: 2px;
        font-size: 20px;
        color: #f15a42;
        cursor: pointer;
    }

    .fx-spacer {
        flex: 1 1 auto;
    }

    .ant-form-item {
        margin-bottom: 22px;

        &.center {
            display: flex;
            justify-content: center;
        }

        .ant-form-explain {
            position: absolute;
            bottom: -20px;
            left: 24px;
        }

        input {

            padding: 6px 24px 10px 24px;
            border-radius: 50px;
            font-size: 20px;
            color: #000;
            height: fit-content;
        }

        .ant-checkbox {
            border: 1px solid #f15a42;
        }
    }


}
