
.print-order-modal__main {
  padding: 0px;
}

.modal-main-print-order {
  p {
    margin-bottom: 0;
  }

  .print-order-title {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    p {
      color: #000000;
      font-size: 10px;
      transform: scale(0.83, 0.83);
      font-family: "Proxima Nova", "Roboto", sans-serif;
    }
    .modal-title-right {
      font-size: 14px;
      color: #000000;
      margin-top: 17px;
      text-align: right;
      font-family: "Proxima Nova", "Roboto", sans-serif;
    }
  }
  .print-gray-txt {
    font-family: Proxima Nova Alt;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #8c8c8c;
  }
  .print-black-txt {
    font-family: Proxima Nova Alt;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #000;
    margin-bottom: 5px;
  }
  .table-foot-txt {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #000000;
    padding-top: 63px;
    padding-left: 35px;
  }
  .modal-print-order-table {
    margin-top: 45px;
  }

  .table-tr-color-style {
    background-color: #e2eeec;
    font-family: "Proxima Nova", "Roboto", sans-serif;
    font-size: 10px;

    .print-order-table-td-style {
      color: #000000;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 12px;
    }
  }

  .print-orders__table-cell {
    font-size: 10px;
    border-bottom: 1px solid #e0e0e0;
  }
  .bottom-content {
    padding-left: 11px;
    padding-right: 0px;
  }
  .bottom-note {
    width: 100%;
    min-height: 123px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
  }
  .bottom-invoice-note {
    width: 260px;
    //display: inline-block;
    min-height: 123px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
    //position: absolute;
    margin-left: 44px;
  }
  .date-and-term-style {
    display: flex;
    justify-content: space-between;
  }
  #print-textarea-text,
  .table-notes-print,
  #print-textarea-notes-tabThr,
  #print-textarea-notes-other {
    display: none;
  }
  .print-count-and-input-content {
    width: 100%;
    padding: 0 11px;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
  }
  @media print {
    #textarea-notes,
    .table-notes,
    #textarea-notes-tabThr,
    #textarea-notes-other {
      display: none;
    }
    #print-textarea-text,
    .table-notes-print,
    #print-textarea-notes-tabThr,
    #print-textarea-notes-other {
      display: block;
      overflow: visible;
      font-family: "Proxima Nova", "Roboto", sans-serif;
      //white-space: pre;
      white-space: pre-wrap;
    }
  }
}
