
.beauty-fluent-step-three-billing__main {
  margin-bottom: 48px;
  p {
    margin-bottom: 0;
  }
  .billing-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
    font-family: "Niveau Grotesk";
  }
  .billing-card-content {
    .plan-card {
      width: 100%;
      height: 96px;
      padding: 20px;
      margin-bottom: 16px;
      border: 1px solid rgba(26, 24, 24, 0.2);
      border-radius: 6px;
      color: #1a1818;
      cursor: pointer;
    }
    .plan-card-click {
      border-color: #f15a42;
    }
    .year-plan-card {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .save-up-style {
    display: inline-block;
    //width: 142px;
    //height: 24px;
    text-align: center;
    padding: 3px 10px;
    margin-top: 10px;
    margin-left: 20px;
    border: 1px solid #f15a42;
    border-radius: 30px;
    color: #f15a42;
    font-family: "Proxima Nova", "Roboto", sans-serif;
    font-size: 14px;
    font-weight: bold;
  }
  .no-save-up-style {
    color: #1a1818;
    border: 1px solid #1a1818;
  }
  .save-price-style {
    display: inline-block;
    width: 134px;
    height: 32px;
    line-height: 32px;
    border-radius: 3px;
    background-color: rgba(250, 201, 44, 0.3);
    color: #f15a42;
    margin-right: 10px;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    font-family: "Proxima Nova", "Roboto", sans-serif;
  }
  .price-style {
    //width: 300px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: #f15a42 !important;
  }
  .ant-radio-checked::after {
    border: 1px solid #f15a42;
  }
  .ant-radio-inner::after {
    background-color: #f15a42 !important;
  }
}
