
.cancel-subscription-dialog-new__main {
  .cancel-subscription-new {
    text-align: center;
    padding: 48px 0 0 0;
    position: relative;
    p {
      font-size: 18px;
      font-weight: 400;
      color: #000000;
      font-family: "Mr Eaves Mod OT";
    }
    .dots_pattern {
      position: absolute;
      top: 0;
      left: 0;
    }
    .ellipse {
      position: absolute;
      bottom: -84px;
      right: -24px;
    }
  }
  .continue-cancel-style {
    color: #000000;
    cursor: pointer;
  }
  .close-dialog-style {
    padding: 17px 35px;
    color: white;
    background: #f15a42;
    font-size: 14px !important;
    font-weight: bold;
    border-radius: 100px;
    margin-left: 30px;
    cursor: pointer;
  }
  .cancel-dialog-btn-style {
    width: 100%;
    margin-top: 50px;
  }
  .footer-card-extra {
    margin-top: 60px;
  }
  .footer-card-extra-content {
    font-size: 14px !important;
    a {
      color: #f15a42;
      margin-left: 10px;
    }
  }
}
