
.admin-training-user {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  &__click-oper {
    cursor: pointer;
    color: #4a8a5d;
  }
}
.admin-training-user__main {
  .ant-checkbox-wrapper::after {
    contain: none;
    display: none;
  }
}
