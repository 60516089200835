
.terms-changes-message
  background-color: white
  width: 540px
  border: 1px solid #EBEBED
  font-size: 14px
  padding: 17px 41px
  display: flex
  box-shadow: 0 -10px 10px -5px rgba(63,63,68,0.05), 0 1px 2px -1px rgba(63,63,68,0.15)

  .terms-changes-message__message
    max-height: 200px
    overflow: auto
  .terms-changes-message__icon-block
    margin-right: 16px
    .succss-icon-block
      width: 40px
      height: 40px
      background-color: #D1E2DD
      border-radius: 100%
      display: flex
      align-items: center
      justify-content: center
      svg
        width: 22px
        fill: #fff

  .terms-changes-message__right-block
    .terms-changes-message__card-header
      margin-bottom: 3px
    .terms-changes-message__actions
      margin-top: 6px
      margin-bottom: 10px
      .terms-changes-message__link
        text-decoration: underline

  &__card-header
    font-weight: bold

  &__message
    font-weight: 300
    color: #212B36

  &__link
    font-weight: 300
    color: #212B36
    margin-right: 16px

.admin-page
  height: 125px
