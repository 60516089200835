
.contact-us-page__main {
  max-width: 1400px;
  //min-height: 600px;
  margin: 0 auto;
  .contact-us-content {
    //height: 200px;
    //padding-top: 20px;
  }
  .left-main {
    //height: 200px;
    text-align: center;
    //background-color: #00e7eb;
    .left-main-content {
      .left-content-title {
        font-size: 48px;
        color: #12433d;
        margin-bottom: 26px;
        font-family: 'Playfair Display Bold';
      }
      .content-email-content {
        font-size: 14px;
        margin-bottom: 34px;
      }
      //img {
      //  width: ;
      //}
    }
  }
  .right-main-success {
    padding-top: 270px;
    text-align: center;
    color: #000000;
    font-family: "Proxima Nova", "Roboto", sans-serif;
    p:nth-child(1) {
      font-size: 28px;
      margin-bottom: 4px;
    }
    p:nth-child(2) {
      font-size: 16px;
    }
    p:nth-child(3) {
      font-size: 16px;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .right-main {
    //height: 100px;
    width: 540px;
    text-align: center;
    margin-top: 40px;
    //background-color: #FF6132;
  }
  .form-title-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    .label-style {
      width: 260px;
      text-align: left;
      font-size: 15px;
      margin-bottom: 7px;
      color: #000000;
    }
  }
  .form-submit-style {
    //padding: 12px 34px;
    text-align: center;
    height: 40px;
    font-size: 16px;
    margin-top: 50px;
    span {
      padding: 12px 34px;
      border-radius: 27px;
      background-color: #FF6132;
      border-color: #FF6132;
      color: white;
      cursor: pointer;
    }
  }
}
.ant-form-item {
  margin-bottom: 0;
  padding-bottom: 15px !important;
  font-family: "ProximaNova-Regular", sans-serif;
}
.ant-row .ant-form-item {
  width: 260px;
}
.ant-form label {
  font-size: 16px;
  font-family: "ProximaNova-Regular", "Roboto", sans-serif;
}
