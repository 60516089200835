
.training-brand-retailer-menu-main {
  width: 100%;
  border-bottom: 1px solid #d9d9d9;
  .brand-menu-item-style {
    display: flex;
    justify-content: start;
    margin-top: 30px;
  }
  .tab-title-text {
    display: inline-block;
    width: 137px;
    height: 30px;
    background: #3a77a9;
    color: white;
    text-align: center;
    line-height: 30px;
    margin-right: 5px;
    border-radius: 7px 7px 0 0;
    cursor: pointer;
  }
  .tab-title-text-no-active {
    background: #d9d9d9;
    color: white;
  }
  .tab-title-text:hover {
    background: rgba(58, 119, 169, 0.7);
    color: white;
  }
}
