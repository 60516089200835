
.landing-terms-transaction__main {
  .transaction-style {
    font-size: 28px;
    color: #000000;
    font-weight: 600;
    font-family: "Proxima Nova", "Roboto", sans-serif;
  }
  .landing-terms-content {
    .check-standard-style {
      margin-bottom: 80px;
      span {
        margin-right: 20px;
      }
    }
  }
  .title-second-style {
    font-size: 24px;
    color: #000000;
    font-family: "Proxima Nova", "Roboto", sans-serif;
  }
  .title-edit-button {
    //display: inline-block;
    //width: 60px;
    //height: 24px;
    text-align: center;
    //background-color: #f5f5f5;
    //border: 1px solid #595959;
    cursor: pointer;
  }
  .table-terms-standard {
    min-height: 46px;
    line-height: 46px;
    margin-bottom: 0;
    border: 1px solid #595959;
    border-left: none;
    border-right: none;
    .table-terms-standard-title {
      display: inline-block;
      width: 280px;
    }
    p {
      width: 300px;
      text-align: right;
      margin-bottom: 0;
    }
  }
  .other-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .spin-style-admin {
    text-align: center;
    margin: 40px 0;
  }
}
