
.choose-card-list
  &__row-margin
    margin-bottom: 10px
  &__bottom-margin
    margin-bottom: 17px
  &__other-text
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 12px
    line-height: 20px
    margin: 0px !important
  &__red-text
    color: #FF6132
  &__green
    color: #4a8a5d
.ant-radio-inner::after
  background-color: #4a8a5d !important
  margin-top: 0px
  margin-left: 0px
.ant-radio-checked .ant-radio-inner
  border-color: #4a8a5d !important
