
.select-payment-pay
    width: 900px
    margin: 0 auto
    align-content: center
    padding: 60px 60px !important
    &__card-content
        width: 487px
        border: 1px solid #D9D9D9
        padding: 24px 24px 28px 30px
        margin-left: 11px
    &__title
        font-family: Proxima Nova
        font-style: normal
        font-weight: bold
        font-size: 18px
        line-height: 24px
        display: flex
        align-items: center
        color: #262626
        margin-bottom: 17px
    &__operation
        font-weight: normal
        font-size: 18px
        line-height: 24px
        display: flex
        align-items: center
        color: #4A8A5D
        margin-bottom: 0px
        width: fit-content
        width: -moz-fit-content
        cursor: pointer
    &__operation-border
        border-bottom: 1px solid #4a8a5d
        margin-bottom: 17px
        margin-top: 0px
        margin-right: 9px
    &__operation-unable
        color: #595959
    &__border
        width: 435px
        border-bottom: 0.5px solid #E8E8E8
        height: 1px
        margin-bottom: 17px !important
        margin-top: 13px !important
    &__info
        font-family: Proxima Nova
        font-style: normal
        font-weight: normal
        font-size: 12px
        line-height: 22px
        color: #595959
    &__button-row
        align-items: center
        margin-top: 26px
        padding: 0px 25%
    &__button
        font-family: Proxima Nova !important
        font-style: normal !important
        font-weight: normal !important
        font-size: 16px !important
        line-height: 24px !important
        border-radius: 2px !important
.pay-failed-dialog
  &__failed-title
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 24px
    line-height: 24px
    color: #262626
    text-align: center
    margin-bottom: 10px !important
  &__content
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    width: 375px
    font-size: 14px
    line-height: 20px
    display: flex
    align-items: center
    text-align: center
    color: #595959
    margin: 0 auto
  &__btn
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal !important
    font-size: 14px
    line-height: 20px
    align-items: center
    text-align: center
    color: #4A8A5D !important
    border: 1px solid #4A8A5D !important
    box-sizing: border-box
    border-radius: 2px !important
    padding: 2px 14px !important

.ant-result
  padding: 48px 16px !important
.ant-result-extra
  margin-top: 13px !important
.ant-col-4
    align-content: end
    img
        margin-right: 0px
#doPurchase
    border-color: #4A8A5D
    color: #4A8A5D
#doPurchase:disabled
    background-color: rgba(0,0,0,0) !important
    color: #BFBFBF !important
    border-color: #BFBFBF !important
    border-radius: 2px !important
