$column-gap: 3px
$black: #000
$turquoise: hsl(162, 23%, 85%)
$red-light: hsl(5, 19%, 87%)
$grey-light: hsl(0, 0%, 59%)
$tag-radius: 2px
$tag-background-color: #DCDCDC
$green: #60ceb3
$link: #B3B3B3
$table-cell-border: 0
$table-cell-padding: 16px
$tabs-link-active-color: #292929
$tabs-link-active-border-bottom-color: #292929
$breadcrumb-item-hover-color: #292929
$breadcrumb-item-active-color: #292929
$breadcrumb-item-color:  #9B9B9B 
$modal-card-head-background-color: #FFFFFF
$modal-card-body-padding: 0
$modal-card-head-border-bottom: none
$gap: 60px

$input-shadow: none
$input-radius: 2
$input-height: 40px
$radius: 0

$dropdown-content-radius: 0px

$input-placeholder-color: #B3B3B3
$input-arrow: #979797

::placeholder
  font-size: 14px
  font-weight: 500
  text-transform: none !important

.select
  &:not(.is-multiple):not(.is-loading)
    &::after
      border-radius: 0 !important
      border-width: 1px !important
      height: 0.8em !important
 
      margin-top: -0.6em !important
      width: 0.8em !important
