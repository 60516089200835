
.admin-message-compose .ant-btn:hover,
.admin-message-compose .ant-btn:focus,
.admin-message-compose .ant-btn:active {
  background: #e2eeec;
  border: 1px solid #4a8a5d;
  color: #4a8a5d;
}
.admin-compose-message__category.ant-select-dropdown {
  z-index: 1050;
  padding-bottom: 20px;
  padding-left: 10px;
}
