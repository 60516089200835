
.product-edit-card__main {
  width: 100%;
  min-height: 494px;
  //padding-bottom: 25px;
  .line {
    width: 950px;
    height: 2px;
    margin-bottom: 25px;
    background: #595959;
  }
  .edit-card-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .edit-card-left {
      width: 206px;
      .left-img {
        width: 100%;
        min-height: 264px;
        margin-bottom: 16px;
        //background: rgba(191, 191, 191, 0.2);
        position: relative;
        display: flex;
        justify-content: space-around;
        align-items: center;
        img {
          width: 90%;
          height: 90%;
        }
        .demo-icon-tip {
          position: absolute;
          left: -15px;
          top: -5px;
          z-index: 3;
        }
      }
      .left-content {
        text-align: center;
        color: #000000;
        font-size: 14px;
        position: relative;
        font-family: "ProximaNova-Regular", "Proxima Nova", "Roboto", sans-serif;
        p {
          margin-bottom: 0;
        }
        .left-content-one {
          position: relative;
          .demo-icon-tip {
            position: absolute;
            left: 15px;
            top: 0;
            z-index: 3;
          }
        }
        .demo-icon-tip-one {
          position: absolute;
          left: -15px;
          top: 0;
          z-index: 3;
          i {
            font-size: 12px;
            color: #bfbfbf;
          }
        }
        .content-title-p {
          height: 44px;
          //width: 80%;
          //margin: 0 auto;
          text-align: center;
          word-wrap: break-word;
          word-break: normal;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }
    }
    .upload-content {
      width: 100%;
      position: relative;
      //text-align: right;
      margin: 16px 0;
      display: flex;
      justify-content: flex-start;
    }
    .video-style {
      width: 196px;
      height: 110px;
      margin-top: 16px;
      text-align: center;
      border: 1px solid #262626;
      cursor: pointer;
      position: relative;
      .video-img {
        //width: 100%;
        height: 100%;
      }
      .public-start {
        position: absolute;
        top: 37px;
        right: 80px;
        border-radius: 50%;
      }
      .background-style {
        width: 43px;
        height: 43px;
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 50%;
        top: 34px;
        right: 83px;
      }
    }

    .upload-video-style {
      width: 192px;
      border: 1px solid #4a8a5d;
      color: #4a8a5d;
      background-color: #e2eeec;
      text-align: center;
    }
    .upload-success {
      width: 192px;
      border: 1px solid #4a8a5d;
      color: #ffffff;
      background-color: #4a8a5d;
      text-align: center;
    }
    .upload-icon-tip {
      padding-right: 2px;
      i {
        font-size: 12px;
        color: #bfbfbf;
        margin-top: 20px;
      }
    }
    .edit-card-right__main {
      width: 716px;
      margin-bottom: 20px;
    }
    .edit-card-center {
      //width: 342px;
      .center-key-benefits {
        //height: 221px;
        //margin-bottom: 30px;
        font-family: "Proxima Nova", "Roboto", sans-serif;
        .key-title {
          font-size: 16px;
          margin-bottom: 8px;
          color: #000000;
          i {
            font-size: 12px;
            color: #bfbfbf;
          }
        }
        ul {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          li {
            display: flex;
            justify-content: flex-start;
            width: 228px;
            height: 80px;
            line-height: 16px;
            //list-style-type: disc;
            margin-bottom: 16px;
            padding-left: 8px;
            word-wrap: break-word;
            word-break: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 5;
            position: relative;
            i {
              display: inline-block;
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background-color: #000000;
              margin-right: 4px;
              margin-bottom: 35px;
              position: absolute;
              top: 4px;
              left: 0;
            }
            span {
              display: inline-block;
              width: 200px;
            }
          }
        }
      }
    }
    .edit-card-right {
      //width: 342px;
    }
  }
  .textarea-bottom {
    display: flex;
    justify-content: space-between;
  }
  .center-demo-textarea {
    width: 350px;
    font-family: "Proxima Nova", "Roboto", sans-serif;
    position: relative;
    .demo-icon-tip {
      position: absolute;
      left: 0;
      bottom: 2px;
      z-index: 3;
      i {
        font-size: 12px;
        color: #bfbfbf;
      }
    }
    .demo-number-tip {
      font-size: 8px;
      color: #262626;
      position: relative;
      left: 310px;
      bottom: 4px;
      z-index: 3;
      transform: scale(0.85);
    }
    .tip-content-style {
      width: 323px;
      height: 85px;
      font-size: 13px;
      color: #262626;
      font-family: "Proxima Nova", "Roboto", sans-serif;
    }
  }
  .demo-tip-style {
    font-size: 16px;
    margin-bottom: 4px;
    color: #000000;
  }
  .textarea-style {
    height: 109px;
  }
  .demo-icon-tip {
    i {
      font-size: 12px;
      color: #bfbfbf;
    }
  }
  .demo-tip-content {
    width: 350px;
    position: relative;
  }
  .demo-icon-tip {
    position: relative;
    left: 0;
    bottom: 2px;
    z-index: 3;
    i {
      font-size: 12px;
      color: #bfbfbf;
    }
  }
  .demo-icon-tip {
    position: relative;
    left: 0;
    bottom: 2px;
    z-index: 3;
    i {
      font-size: 12px;
      color: #bfbfbf;
    }
  }
  .demo-number-tip-style {
    font-size: 8px;
    color: #262626;
    position: absolute;
    left: 308px;
    bottom: 4px;
    z-index: 3;
  }

  .demo-optional-style {
    font-size: 8px;
    color: #262626;
    position: absolute;
    left: 250px;
    bottom: 4px;
    z-index: 3;
  }
  .upload-optional-style {
    font-size: 8px;
    color: #262626;
    position: absolute;
    left: 158px;
    bottom: -14px;
    z-index: 3;
  }
}
