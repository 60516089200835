
@import '~cropperjs/dist/cropper.css'
.cropper-crop-box, .cropper-view-box
  border-radius: var(--border-radius)

.cropper-view-box
  outline: 0

.cropper-face, .cropper-line, .cropper-point
  background-color: transparent

.cropper-modal
  background-color: rgba(0,0,0,0.59)
