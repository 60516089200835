
.order-summary
    font-family: Proxima Nova
    font-style: normal
    &__bg
        width: 282px
        border: 1px solid #D9D9D9
        box-sizing: border-box
        padding: 24px 10px 10px 16px
    &__title
        font-weight: bold
        font-size: 18px
        line-height: 24px
        color: #262626
        padding: 0px !important
        margin-bottom: 2px !important
    &__row
        align-content: center
        margin-bottom: 10px !important
    &__row-big
        margin-top: 10px !important
    &__row-col
        width: 221px
        align-content: center
        margin-bottom: 6px !important
    &__light-gray
        font-weight: normal
        font-size: 12px
        line-height: 22px
        color: #8C8C8C
        margin-bottom: 6px !important
    &__dark-gray
        font-weight: normal
        font-size: 12px
        line-height: 22px
        color: #595959
    &__border
        width: 221px
        border-bottom: 0.5px solid #E8E8E8
        height: 1px
        margin-bottom: 17px !important
    &__button
        font-family: Proxima Nova !important
        font-style: normal !important
        font-weight: normal !important
        font-size: 16px !important
        line-height: 24px !important
        border-radius: 2px !important
        border-color: #4A8A5D !important
        color: #4A8A5D !important
img
    margin-right: 7px
.ant-col-6
    text-align: right
