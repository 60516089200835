
.admin-message-edit-filter .ant-modal-close-x {
  width: 44px;
  height: 44px;
  line-height: 44px;
  font-size: 14px;
}
.admin-message-edit-filter .ant-modal-title {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #000;
}
.admin-message-edit-filter .ant-modal-header {
  padding: 14px 24px 7px 26px;
}
.admin-message-edit-filter__save-btn.ant-btn:active,
.admin-message-edit-filter__save-btn.ant-btn.active,
.admin-message-edit-filter__save-btn.ant-btn:hover,
.admin-message-edit-filter__save-btn.ant-btn:focus {
  color: #fff;
  border: none;
  background-color: #4a8a5d;
}
//.admin-message-edit-filter__custom-area .ant-select-selection--single {
//  height: 36px;
//}
//.admin-message-edit-filter__custom-area .ant-select-selection {
//  border: 1px solid #4A8A5D;
//  box-sizing: border-box;
//  border-radius: 2px;
//}
