
.admin-training-search {
  &__text {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.04em;
    color: #000000;
  }
  &__input-outline {
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 400;
    font-size: 12px !important;
    letter-spacing: 0.04em;
    line-height: 20px;
  }
}
