
// .admin-message-brand-create-new-modal__outer {
//   padding-top: 40px;
//   padding-bottom: 47px;
//   background-color: #fff;
// }
.order_info_dialog .order_info_layer {
  height: 52px;
  padding: 10px 0px;
}
.order_info_content {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  width: 339px;
  margin-left: 16px;
  float: left;
}
.admin-message-brand-create-new-modal__choose-model-layer
  .admin-message-brand-create-new-modal__toTarget.ant-select {
  line-height: 28px;
  position: absolute;
  top: 0px;
}
.admin-message-brand-create-new-modal__choose-model-layer
  .admin-message-brand-to-error.admin-message-brand-create-new-modal__toTarget.ant-select
  .ant-select-selection-selected-value {
  color: #ff4d4f;
}
.admin-message-brand-create-new-modal__choose-model-layer
  .ant-select-selection--single {
  height: 30px;
}
.admin-message-brand-create-new-modal__choose-model-layer
  .ant-select-selection {
  background: rgba(74, 138, 93, 0.11);
  border: 1px dashed rgba(74, 138, 93, 0.46);
  box-sizing: border-box;
  border-radius: 2px;
}
.admin-message-brand-create-new-modal__choose-model-layer
  .ant-select-selection-selected-value {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 30px;
  color: #4a8a5d;
}
.admin-message-brand-create-new-modal__choose-model-layer .ant-select-arrow {
  color: #4a8a5d;
}
.admin-message-brand-create-new-modal__choose-model-layer
  .ant-select-selection:hover,
.admin-message-brand-create-new-modal__choose-model-layer
  .ant-select-selection:focus,
.admin-message-brand-create-new-modal__choose-model-layer
  .ant-select-selection:active,
.admin-message-brand-create-new-modal__choose-model-layer
  .ant-select-open
  .ant-select-selection,
.admin-message-brand-create-new-modal__choose-model-layer
  .ant-select-focused
  .ant-select-selection {
  border-color: #4a8a5d;
  box-shadow: none;
}
.admin-message-brand-create-new-modal__choose-model-layer .ant-select {
  line-height: 30px;
  position: absolute;
  top: 6px;
}
.admin-message-brand-create-new-modal__select-dropdown
  .ant-select-dropdown-menu-item-active,
.admin-message-brand-create-new-modal__select-dropdown
  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled),
.admin-message-brand-create-new-modal__select-dropdown
  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  color: #262626;
  background-color: rgba(74, 138, 93, 0.15);
}
.admin-message-brand-create-new-modal .ant-btn:hover,
.admin-message-brand-create-new-modal .ant-btn:active,
.admin-message-brand-create-new-modal .ant-btn:focus,
.admin-message-brand-create-new-modal .ant-btn.active {
  color: #fff;
  background-color: #4a8a5d;
  border: none;
}
.admin-message-brand-create-new-modal__choose-model-layer .multiselect {
  min-height: 30px;
  height: 30px;
}
.admin-message-brand-create-new-modal__choose-model-layer .multiselect__select {
  display: none;
}
.admin-message-brand-create-new-modal__choose-model-layer .multiselect__tags {
  min-height: 30px;
  padding: 8px;
}
.admin-message-brand-create-new-modal__choose-model-layer .multiselect__input {
  height: 15px;
  line-height: 15px;
}
.admin-message-brand-create-new-modal__choose-model-layer
  .multiselect__placeholder {
  color: #adadad;
  display: inline-block;
  margin-bottom: 0px;
  padding-top: 0px;
  position: absolute;
  top: 4px;
}
