
@font-face {
  font-family: "Playfair Display";
  src: url("../../assets/fonts/PlayfairDisplay-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../../assets/fonts/Proxima-Nova-Light.otf") format("truetype");
}
.brand-list-block {
  text-align: center;
  padding: 92px 20px 50px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  p {
    color: #6c8784;
    font-size: 18px;
    font-family: "ProximaNova-Regular", "ProximaNova", "Roboto", sans-serif;
    line-height: 52px;
    margin-bottom: 10px;
  }
  .swiper-container {
    max-width: 1120px;
    margin: 0 auto;
    overflow: hidden;
    .swiper-slide {
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      -ms-backface-visibility: hidden;
      backface-visibility: hidden;
      -webkit-perspective: 1000;
      -moz-perspective: 1000;
      -ms-perspective: 1000;
      perspective: 1000;
      width: 100%;
      width: auto;
      animation: danmu 60s linear infinite;
      color: #fff;
      white-space: nowrap;
      flex-shrink: 0;
    }
    .swiper-slide div {
      display: inline-block;
      // transform: translateX(100%);
      transform: translateX(0);
    }
    .swiper-slide a {
      margin: 0 20px;
      vertical-align: middle;
      display: inline-block;
      img {
        width: 100%;
        vertical-align: text-bottom;
      }
    }
    @keyframes danmu {
      0% {
        transform: translateX(0);
      }
      100% {
        // transform: translateX(-100%);
        transform: translateX(-70%);
      }
    }
  }
}

.about-list-block {
  text-align: center;
  margin-top: 205px;
  margin-bottom: 159px;
  p {
    color: #12433d;
    font-size: 48px;
    font-family: "Playfair Display Bold";
    margin-bottom: 93px;
  }
  .swiper-container {
    max-width: 1120px;
    margin: 0 auto;
    overflow: hidden;
    .swiper-slide {
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      -ms-backface-visibility: hidden;
      backface-visibility: hidden;
      -webkit-perspective: 1000;
      -moz-perspective: 1000;
      -ms-perspective: 1000;
      perspective: 1000;
      width: 100%;
      width: auto;
      animation: danmu 60s linear infinite;
      color: #fff;
      white-space: nowrap;
      flex-shrink: 0;
    }
    .swiper-slide div {
      display: inline-block;
      // transform: translateX(100%);
      transform: translateX(0);
    }
    .swiper-slide a {
      margin: 0 20px;
      vertical-align: middle;
      display: inline-block;
      img {
        width: 100%;
        vertical-align: text-bottom;
      }
    }
    @keyframes danmu {
      0% {
        transform: translateX(0);
      }
      100% {
        // transform: translateX(-100%);
        transform: translateX(-70%);
      }
    }
  }
}
