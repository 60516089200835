
.create-new-location-dialog__main {
  p {
    margin-bottom: 0;
  }
  .modal-title-location {
    width: 100%;
    text-align: center;
  }
  .form-location-dialog {
    padding-left: 55px;
    text-align: center;
  }
  .ant-form-item {
    text-align: center;
  }
  .location-active-style {
    color: #1a1818;
    padding-right: 114px;
    text-align: right;
    margin-bottom: 10px;
  }
  .location-save-style {
    width: 160px;
    background-color: #4a8a5d;
    color: white;
  }
  .attach-csv-style {
    background-color: #4a8a5d;
  }
  .upload-csv-file {
    padding-right: 114px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    color: #e1644c;
    text-decoration: underline;
    cursor: pointer;
  }
  .ant-switch {
    background: #f27c59;
    background-image: none !important;
  }
  .ant-switch-checked {
    background: #a0d29b;
  }
}
