

.add-member__question
  display: flex
  justify-content: space-between

.add-member-style
  margin-top: -107px

.signup-label
  font-size: 14px

.signup-button
  display: flex
  margin-top: 20px
  width: 100%
  height: 46px
  width: 312px
  border-radius: 2px

.signup-member__input
  height: 40px
  border: 1px solid #E5E5E5
  border-radius: 2px
  margin-top: 4px
  margin-bottom: 23px

.add-member
  &__container
    display: flex
    justify-content: center
    align-items: center

  &__box
    width: 360px
    padding: 25px
    height: 31px
    width: 360px
    border-radius: 2px
    background-color: rgba(255,255,255,0.5)
    color: #212B36
    font-size: 14px
  &__form
    width: 350px
    margin: 0 auto

  &__signup-box
    padding: 25px
    padding-top: 58px
    height: 510px
    width: 360px
    border: 1px solid #E5E5E5
    border-radius: 6px
    background-color: rgba(255, 255, 255, 0.5)
    color: #212B36
    font-size: 14px

  &__title
    color: #000000
    font-size: 16px
    letter-spacing: 0.44px
    line-height: 24px
    margin-bottom: 16px
    text-align: center

  &__subtitle
    color: #8D8D8D
    font-size: 14px
    letter-spacing: 0.25px
    line-height: 24px
    padding-bottom: 22px
    text-align: center

  &__divider
    border-bottom: 1px solid #e5e5e5
    margin: 120px 0px 25px 0px

  &__verified-divider
    border-bottom: 1px solid #e5e5e5
    margin: 165px 0px 25px 0px

  &__links
    display: flex
    justify-content: space-between

  &__link-title
    color: #000000
    font-size: 14px
    letter-spacing: 0.25px
    line-height: 24px

  &__link-resend
    color: #008489
    font-size: 14px
    letter-spacing: 0.25px
    line-height: 17px
    cursor: pointer

.height-for-sticky-footer
  height: calc(101vh - 72px)
