
.add-new-retailer-purchase__main {
  p {
    margin-bottom: 0;
  }
  .modal-title-location {
    width: 100%;
    text-align: center;
  }
  .payment-add-retailer-amount {
    padding: 20px;
    font-size: 18px;
    font-family: "Proxima Nova", "Roboto", sans-serif;
  }
  .payment-add-retailer-purchase {
    text-align: center;
  }
}
