
.brand-training-retailer-add-Step2-dialog__main {
  p {
    margin-bottom: 0;
  }
  .step-content-text {
    padding-bottom: 20px;
  }
  .modal-title-location {
    width: 100%;
    text-align: center;
  }
  .terms-condition-style {
    padding: 20px 0;
  }
  .button-step-style {
    text-align: right;
    .cancel-button-style {
      width: 160px;
      color: white;
      background-color: rgba(242, 124, 89, 1);
    }
    .next-button-style {
      width: 160px;
      color: white;
      background-color: rgba(145, 211, 149, 1);
    }
  }
  .terms-condition-title-style {
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .terms-title {
      width: 60%;
      font-weight: bold;
      text-align: right;
    }
    span:hover {
      text-decoration: underline;
      color: #4a8a5d;
    }
  }
  .terms-condition-style {
    width: 100%;
    height: 200px;
    overflow: auto;
    border: 1px solid #e2dfdf;
  }
  .agree-terms-condition {
    text-align: right;
    // padding: 15px 0;
  }
  .nav-link {
    color: rgba(0,0,0,0.6);
    text-decoration: underline;
  }
}
@media print {
  .terms-condition-style {
    width: 100%;
    height: auto;
  }
}
