
.brand-settings
 &__title
    color: #000000
    font-size: 20px
    letter-spacing: 0.32px
    line-height: 18px
    margin-bottom: 30px

.memberss__wrap
  .members-details-table
    border: 1px solid #ddd

    .members-details-table-header
      padding: 11px 0px
      border-bottom: 1px solid #ddd
      display: flex

      .members__title
        margin-top: 6px
        padding-left: 18px
        margin-bottom: 0px !important
      .invite_member
        justify-content: flex-end
        display: flex
        padding-right: 20px
        .button-send-invitations
          background-color: black
          color: white
          font-size: 14px
          width: 125px
          height: 31px
          line-height: 31px
          text-align: center
          border-radius: 0
    .members-details-table-body
      .table-header
        padding: 11px 35px
        display: flex
        border-bottom: 1px solid #ddd
        .membersList
          flex: 1!important
          &__table-cell

        .brand-settings__account-information_text
          color: #888888

      .table-body
        padding: 6px 35px
        border-bottom: 1px solid #ddd
        display: block !important
        align-items: center
        .membersList
          flex: 1!important

        .user-name__block
          display: flex
          align-items: center

          .user-name-status
            width: 31px
            height: 31px
            background-color: #e3d7d7
            border: 1px solid #979797
            color: #535050
            padding: 2px
            border-radius: 100%
            font-size: 16px
            font-weight: 300
            margin-right: 5px
            /*display: flex*/
            align-items: center
            justify-content: center

        .brand-settings__account-information_name
          color: #333
          font-size: 14px
          white-space: normal
          word-break: break-all

    .members-details-table-footer
      .banner-status
        width: 100%
        margin-top: 0px
        margin-left: 0px
        border: none
        display: flex
        flex-direction: column
        justify-content: center

        .title-block
          .title-boxbanners
            margin-top: 0px
            .title-style
              font-weight: bold
          .title-boxbanners + .title-boxbanners
            margin-top: 25px

.button-block
  margin-top: 25px
  text-align: center
.box-button
  min-width: 124px
  width: auto
  min-height: 27px
  font-size: 14px
.member-upgrade-title
  font-family: Proxima
  margin-top: 30px
  margin-left: 30px
  line-height: 2px
  font-weight: 700
  font-size: 16px
  letter-spacing: 0
  color: #000000
  margin-bottom: 0px !important
.member-upgrade-subtitle
  font-family: Proxima
  margin-top: 25px
  margin-left: 30px
  line-height: 2px
  font-size: 16px
  letter-spacing: 0
  color: #000000
  margin-bottom: 0px !important
.member-upgrade-button
  background-color: #000
  color: #fff
  font-size: 14px
  margin-bottom: 30px
.member-upgrade-button:hover
  color: #fff
