
.admin-console-add-cascade-header {
  &__add-new-btn {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    background: #4a8a5d;
    color: #fff;
    border: 1px solid #4a8a5d !important;
    border-radius: 2px !important;
    margin-right: 12px;
  }
  &__btn {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    background: #4a8a5d;
    color: #fff;
    padding: 0px 8px !important;
    border: 1px solid #4a8a5d !important;
    border-radius: 2px !important;
    margin-right: 12px;
    height: auto !important;
  }
  &__drop-btn {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #fff;
    background: #4a8a5d;
    padding: 0px 20px !important;
    border: 1px solid #4a8a5d !important;
    border-radius: 2px !important;
    margin-right: 12px;
    height: auto !important;
  }
  &__btn-icon {
    vertical-align: middle;
    line-height: normal;
    margin-bottom: 4px;
  }
  &__add-new-input {
    background: #ffffff;
    border: 1px solid #4a8a5d;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 6px 8px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    margin-top: 2px;
    width: 100px;
    height: 32px;
  }
}
.admin-console-add-cascade-header__btn:hover,
.admin-console-add-cascade-header__btn:focus,
.admin-console-add-cascade-header__btn:active,
.admin-console-add-cascade-header__btn.active,
.admin-console-add-cascade-header__drop-btn:hover,
.admin-console-add-cascade-header__drop-btn:focus,
.admin-console-add-cascade-header__drop-btn:active,
.admin-console-add-cascade-header__drop-btn.active {
  background-color: #e2eeec;
}
.admin-console-add-cascade-header__btn:hover,
.admin-console-add-cascade-header__btn:focus,
.admin-console-add-cascade-header__drop-btn:hover,
.admin-console-add-cascade-header__drop-btn:focus {
  color: #4a8a5d;
}
