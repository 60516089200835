
.retailer-orders
  //padding-top: 50px
  //padding-bottom: 80px
  padding: 50px 122px 80px 122px
  .progress-download-tips
    width: 400px
    height: 100px
    padding: 20px 25px
    text-align: center
    position: fixed
    top: 60px
    left: 35%
    background-color: white
    p
      margin: 0 auto

  &__no-background
    background: none !important

  &__no-orders
    color: #9E9E9E
    white-space: pre-wrap

  //&__filter
  //  padding-right: 71px
  //  min-height: 90vh
  .order-title-button
    display: flex
    justify-content: space-between
  &__table-name
    color: #FEC206
    font-size: 14px
    width: 250px
    display: flex
    justify-content: space-between
    align-items: center
    .search-brand-order
      padding: 5px 16px
      border: 1px solid #FEC206
      border-radius: 2px
      background-color: #FEF2E2
      cursor: pointer
      .search-icon-style
        margin-left: 5px
    .clear-brand-order
      text-decoration: underline
      cursor: pointer

  .order-processing-tabs
    margin-bottom: 3px
    margin-top: 100px
    span
      padding: 4px 18px
      border: 1px solid white
      cursor: pointer
    .tab-active
      border: 1px solid gray
      border-bottom: none
  &__table-head
    color: #9E9E9E !important
    font-size: 14px
    font-weight: 500
    border-bottom: 1px solid #E0E0E0 !important

  &__table-cell
    color: #333333 !important
    font-size: 14px
    font-weight: 500
    border-bottom: 1px solid #E0E0E0 !important
    margin-top: 10px
    position: relative
    a
      color: #333333
  .tacking-number-style
    display: inline-block
    width: 200px
    text-overflow: ellipsis
    overflow: hidden
    white-space: nowrap

  &__filter-column-name
    color: #292929
    font-size: 16px
    font-weight: 300
    line-height: 22px
    margin-bottom: 15px

  &__table-header
    border-top: 1px solid #E0E0E0

  &__sort-options
    margin-top: -15px
  &__table-row
    cursor: pointer

  &__orders-count
    font-size: 12px
    padding-bottom: 10px
  .select-text-right
    float: right
    margin-top: 90px
  .csv-style
    width: 150px
    height: 30px
    line-height: 30px
    text-align: center
    border: 1px solid #FEC206
    background-color: #FEF2E2
    color: #FEC206
    cursor: pointer
    position: relative
    .csv-select
      position: absolute
      top: 30px
      left: -1px
      border: 1px solid #D9D9D9
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15)
      .download-csv-select-one
        display: block
        color: #000000
        width: 150px
        cursor: pointer

      .download-csv-select-one:hover
        background-color: #FEF2E2
      .download-csv-select-two
        display: block
        color: #000000
        width: 150px
        cursor: pointer

      .download-csv-select-two:hover
        background-color: #FEF2E2
.spin-style-retailer
  text-align: center
  margin: 40px 0
  //display: flex
  //justify-content: center
  //align-items: center
  .ant-spin
    color: #FEC206
  .ant-spin-dot-item
    background-color: #FEC206 !important
.old-order-table-retailer
  position: relative
  .old-order-icon
    position: absolute
    left: -4px
    top: 19px
    color: #1c7430
.retailer-pagination-style
  text-align: right
