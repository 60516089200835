
.admin-training-retailer-brand-product {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  &__hero-product-position {
    padding: 3px 8px;
    color: #ffffff;
    font-size: 12px;
    background-color: #f3ca50;
    border-radius: 20px;
  }
}
