
.brand-setting-subscription {
  &__title {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: #000000;
  }
  &__left-text {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
  }
  &__right-text {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
  }
  &__btn-txt {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 24px;
    border-radius: 4px;
  }
}
