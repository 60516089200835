
.brand-retailers-main-box
  width: 1082px
  margin: 0 auto
.brand-new-retailers
  width: 100%
  height: 188px
  //padding: 0 10%
  text-align: center
  .title
    font-size: 18px
    height: 40px
    line-height: 40px
    font-weight: 700
    color: #12433D
    font-family: DM Sans
  .retailers-box
    display: flex
    justify-content: space-between
    align-items: center
    margin-top: 55px
    .box-one:nth-child(2)
      width: 146px
      height: 38px
    .box-one
      img:hover
        cursor: pointer
