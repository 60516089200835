
.beauty-fluent-step-three-subscription__main {
  font-family: "DM Sans";
  max-width: 479px;
  font-size: 16px;
  p {
    margin-bottom: 12px;
  }
  .subscription-plan-style {
    background-color: rgba(207, 229, 204, 0.2);
    padding: 42px 41px 42px 47px;
    margin-bottom: 35px;
    border-radius: 10px;
  }
  .flex-style {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .terms-condition-title-style {
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .terms-title {
      width: 60%;
      font-weight: bold;
      text-align: right;
    }
    span:hover {
      //text-decoration: underline;
      color: #4a8a5d;
    }
  }
  .terms-condition-style {
    padding: 20px 0;
    width: 100%;
    height: 270px;
    overflow: auto;
    border: 1px solid #e2dfdf;
  }
  .agree-terms-condition {
    text-align: left;
    padding: 15px 0;
  }
  .click-retailer-plan {
    background-color: #f15a42;
    color: #ffffff;
  }
  .promo-code-apply {
    font-size: 13px;
    color: #f15a42;
    cursor: pointer;
    font-family: "Niveau Grotesk";
    position: absolute;
    top: 17px;
    right: 15px;
    z-index: 5;
  }
  .remove-code {
    cursor: pointer;
  }
  .remove-code:hover {
    text-decoration: underline;
  }
  .ant-input,
  .ant-input-affix-wrapper,
  .ant-input-password,
  .ant-input-affix-wrapper .ant-input {
    height: 55px;
    border-radius: 100px;
    border-color: #f15a42;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #f27c59 !important;
    border-color: #f27c59 !important;
  }
  .ant-checkbox-inner {
    border-radius: 50%;
  }
  .ant-checkbox-inner::after {
    background-color: #f27c59 !important;
  }
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-item-active {
  background-color: #f15a42 !important;
  color: #ffffff;
}
.nav-link {
  color: rgba(0,0,0,0.6);
  text-decoration: underline;
}
