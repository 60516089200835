
.retailer-profile
  color: #000000
  padding: 0 40px
  font-family: SF Pro Display
  font-style: normal
  font-weight: normal
  &__card-shadowed
    -webkit-box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%)
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%)
    border-radius: 2px
    padding: 20px 20px 38px 13px
  .container
    position: relative
    width: auto !important
    padding-left: 25px
    margin-top: 10px
    cursor: pointer
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none
  &__border
    max-width: 201px !important
    border-bottom: 1px solid #E8E8E8
    margin-bottom: 1px
  &__title
    font-family: Proxima Nova
    font-size: 24px !important
    font-weight: normal !important
    line-height: 24px !important
    color: #262626 !important
    margin-bottom: 13px

  &__content
    color: #8C8C8C
    width: 120px
    white-space: pre-wrap

  &__details
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    line-height: 22px
    font-size: 14px

    &--container
      margin-top: 15px
      margin-left: 3px
    &--title
      font-size: 18px
      letter-spacing: 0.25px

      &_text
        margin-top: 20px
        font-weight: 300

    &--subtitle
      color: #262626

      &_link
        text-decoration: underline

    &--edit-icon
      float: right
      cursor: pointer

    &--info-container
      padding-top: 25px
      font-size: 14px
      letter-spacing: 0.13px
