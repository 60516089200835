
.search_index {
  &_title {
    font-family: Playfair Display;
    font-weight: bold;
    font-size: 48px;
    color: #262626;
  }

  &_filter {
    margin: 32px 0 25px;
  }

  &_content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .loading_wrap {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .ant-empty {
    margin: 0 auto;
  }

  .filter_btn {
    margin-bottom: 18px !important;
  }
}
