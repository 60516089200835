
.landing_search__main {
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing_search {
  padding-top: 73px;
  padding-bottom: 70px;
  box-sizing: border-box;

  &_filter {
    float: left;
    margin-top: 17px;
    width: 340px !important;
    background: #ffffff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    margin-right: 25px;
    margin-bottom: 150px;

    &_search {
      height: 60px;
      padding: 0 18px;
      display: flex;
      border-bottom: 1px solid #d9d9d9;
      align-items: center;

      .icon {
        margin-right: 5px;
        cursor: pointer;
      }

      .inp {
        flex: 1;

        .ant-input {
          border: none;
        }
      }
    }

    &_loca {
      padding: 12px 18px 0;
      cursor: pointer;

      &_title {
        font-family: Proxima Nova;
        font-size: 16px;
        color: #262626;
        margin-bottom: 13px;
      }
    }

    &_list {
      border-top: 1px solid #d9d9d9;
    }
  }

  &_content {
    float: left !important;
    width: 508px;
  }

  .location-style {
    font-size: 20px;
    margin-right: 20px;
  }
}
