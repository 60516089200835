
.new-card-list
  &__card-border
    border: 1px solid #ddd
    margin-bottom: 10px
    padding: 16px 10px 16px 16px
    webkit-box-shadow: 0 2px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)
    box-shadow: 0 2px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)
  &__row-margin
    margin-bottom: 10px
  &__other-title
    font-family: Proxima Nova
    font-style: normal
    font-weight: bold
    font-size: 12px
    line-height: 20px
    display: flex
    margin-right: 10px
    margin-bottom: 4px !important
    color: #000
  &__other-text
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 12px
    line-height: 20px
    display: flex
    margin-right: 10px
    margin-bottom: 4px !important
    color: #000
  &__content-right
    direction: rtl
  &__btn
    min-width: 80px
    margin-left: 10px
    float: right
  &__set-default
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 20px
    color: #4a8a5d
  &__dialog-title
    font-family: Proxima Nova
    font-style: normal
    font-weight: bold
    font-size: 14px
    line-height: 20px
    margin-bottom: 10px
  &__dialog-desc
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 20px
    margin-bottom: 10px
.new-card-list__btn.ant-btn
  border-radius: 2px !important
.ant-btn:hover
  border-color: inherit
  color: inherit
#unDefaultBtn
  font-family: Proxima Nova
  font-style: normal
  font-weight: normal
  font-size: 14px
  line-height: 20px
  color: #4a8a5d
  padding: 1px 8px
  background: #F6FFED
  border: 1px solid #4A8A5D
  box-sizing: border-box
  border-radius: 2px
  text-align: center
