
.clean-pre-check-list
  width: 952px
  &__title
    font-family: Playfair Display
    font-style: normal
    font-weight: bold
    font-size: 48px
    line-height: 64px
    display: flex
    align-items: center
    color: #000000
    margin-bottom: 10px !important
  &__sub-title
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 20px
    line-height: 24px
    color: #000000
    margin-bottom: 16px !important
  &__type-area
    width: 293px
    height: 88px
    background-color: #FAFAFA
    display: flex
    border-radius: 2px
  &__type-area-left
    width: 220px
    float: left
    padding: 8px 15px 8px 8px
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 17px
    text-align: left
  &__type-area-title
    color: #262626
    margin-bottom: 4px !important
  &__type-area-desc
    color: #595959
    margin-bottom: 0px !important
  &__type-right_100
    width: 73px
    display: flex
    background-color: #E2EEEC
    img
      width: 42px
      height: 42px
      margin: auto
  &__type-right_partial
    width: 73px
    display: flex
    background-color: #FEF2E2
    img
      margin: auto
      width: 42px
      height: 42px
  &__type-right_no
    width: 73px
    display: flex
    background-color: #E8E8E8
    img
      margin: auto
      width: 42px
      height: 42px
  &__panel
    height: 52px
  &__panel-right
    width: 52px
    height: 52px
    position: absolute
    right: 0px
    display: flex
    img
      margin: auto
      width: 30px
      height: 30px
  &__panel-right-selected
    border: 1px solid #fec206
  &__panel-arrow-border
    border-radius: 2px
    border: 1px solid #1D4BA1
  &__panel-right-100
    background-color: #E2EEEC
  &__panel-right-partial
    background-color: #FEF2E2
  &__panel-right-no
    background-color: #E8E8E8
  &__panel-left
    width: 52px
    height: 52px
    background-color: #F5F5F5
  &__panel-img
    width: 52px
    height: 52px
    margin-right: 0px !important
    border-radius: 50%
  &__panel-title
    float: left
    margin-left: 28px
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 16px
    line-height: 52px
    letter-spacing: 0.2px
    color: #262626
    max-width: 509px
    overflow: hidden
    height: 52px
    white-space: nowrap
    text-overflow: ellipsis
  &__panel-subtitle
    float: left
    font-family: Proxima Nova
    font-weight: normal
    font-size: 16px
    line-height: 52px
    height: 52px
    color: #262626
    position: absolute
    left: 634px
  &__table-head
    margin-top: 8px
    margin-right: 34px !important
    margin-left: 34px !important
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 16px !important
    color: #262626
  &__tr-hover
    background-color: #f5f5f5 !important
  &__tr-no-hover
    background-color: #ffffff
  &__table-icon
    padding-left: 1px !important
    color: #BFBFBF !important
    margin-right: 22px
    cursor: pointer
  &__table-content
    margin: 0px 36px
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 16px
    line-height: 31px
    color: #262626
    max-height: 582px
    overflow-y: scroll
  &__table-product-mark
    width: 20px
    height: 20px
    background: transparent
    display: inline-block
  &__table-title-left
    float: left
    //width: 582px
    padding-left: 33px !important
    //text-overflow: ellipsis
  &__table-content-name
    margin-right: 12px
    //width: 582px
    padding-left: 33px !important
    //text-overflow: ellipsis
  &__tooltip-icon
    margin: 8px 3px 8px 11px
    border-radius: 50%
    background-color: white
  &__tooltip-text
    color: white
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 12px
    line-height: 15px
    margin: 0px 4px
  &__page-row
    margin-top: 10px
    float: right
  &__error-border
    border-color: #ff6132 !important
  &__modal-spin-div
    padding-top: 45px
    text-align: center
    line-height: 300px
    height: 400px
    width: 100%

.clp_p100 >.ant-collapse-header:hover
  background-color: #FAFAFA
.clp_p100 >.ant-collapse-header:hover .clean-pre-check-list__panel-right
  border: 1px solid #12433d
.clp_p100 >.ant-collapse-header:hover .clean-pre-check-list__panel-title
  text-decoration: underline
.clp_p100.ant-collapse-item-active .clean-pre-check-list__panel-right
  border: 1px solid #12433d
.clp_p100.ant-collapse-item-active >.ant-collapse-header:hover .ant-collapse-arrow
  color: #4a8a5d !important
.clp_p50 >.ant-collapse-header:hover
  background-color: #FAFAFA
.clp_p50 >.ant-collapse-header:hover .clean-pre-check-list__panel-right
  border: 1px solid #fec206
.clp_p50.ant-collapse-item-active .clean-pre-check-list__panel-right
  border: 1px solid #fec206
.clp_p50 >.ant-collapse-header:hover .clean-pre-check-list__panel-title
  text-decoration: underline
.clp_p50.ant-collapse-item-active >.ant-collapse-header:hover .ant-collapse-arrow
  color: #fec206 !important
.clp_l50 >.ant-collapse-header:hover
  background-color: #FAFAFA
.clp_l50 >.ant-collapse-header:hover .clean-pre-check-list__panel-right
  border: 1px solid #595959
.clp_l50.ant-collapse-item-active .clean-pre-check-list__panel-right
  border: 1px solid #595959
.clp_l50 >.ant-collapse-header:hover .clean-pre-check-list__panel-title
  text-decoration: underline
.clp_l50.ant-collapse-item-active >.ant-collapse-header:hover .ant-collapse-arrow
  color: #ffffff !important
#cleanPreCheckCollapse >.ant-collapse-borderless
  background-color: white !important
#cleanPreCheckCollapse >.ant-collapse-borderless > .ant-collapse-item
  border: 2px solid white !important
#cleanPreCheckCollapse >.ant-collapse >.ant-collapse-item .ant-collapse-header
  padding: 0px !important
  display: block
#cleanPreCheckCollapse >.ant-collapse >.ant-collapse-item-active.clp_p100 .ant-collapse-header
  background-color: #E2EEEC !important
#cleanPreCheckCollapse >.ant-collapse >.ant-collapse-item-active.clp_p50 .ant-collapse-header
  background-color: #FEF2E2 !important
#cleanPreCheckCollapse >.ant-collapse >.ant-collapse-item-active.clp_l50 .ant-collapse-header
  background-color: #E8E8E8 !important
#cleanPreCheckCollapse >.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow
  right: 328px !important
.clean-pre-check-list__table-head .ant-table-small
  border: none !important
.clean-pre-check-list__table-head .ant-table.ant-table-small
  font-size: 16px !important
  color: #262626 !important
.clean-pre-check-list__table-head .ant-table-small > .ant-table-content .ant-table-header > table > .ant-table-thead > tr > th
  border: none !important
  padding: 0px 0px !important
.clean-pre-check-list__table-head .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th
  border: none !important
  padding: 0px 0px !important
.clean-pre-check-list__table-head .ant-table-thead
  height: 31px
  background-color: #F5F5F5
  font-family: Proxima Nova
  font-size: 16px !important
  line-height: 31px
  color: #262626
.clean-pre-check-list__table-head .ant-table-small > .ant-table-content .ant-table-body > table > .ant-table-tbody > tr > td
  padding: 0px 0px !important
  font-family: Proxima Nova
  height: 31px
  line-height: 31px
  font-size: 16px !important
  font-weight: normal
  font-style: normal
  border: none !important
.clean-pre-check-list__table-head .ant-table-small > .ant-table-content .ant-table-body > table > .ant-table-tbody > tr.ant-table-row.ant-table-row-level-0:hover
  background: #F5F5F5 !important
.clean-pre-check-list__table-head .ant-table.ant-table-small .ant-table-footer
  padding: 0px 0px !important
  height: 30px
  line-height: 30px
  background: #F5F5F5
  border-radius: 2px
  text-align: center
  color: #5E6871 !important
.clean-pre-check-list__table-head .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr
  //width: calc(100% - 6px)
  border: none !important
.clean-pre-check-list__table-head .ant-table-small .ant-table-content .ant-table-scroll .ant-table-body::-webkit-scrollbar
  width: 6px !important
.clean-pre-check-list__table-head .ant-table-small .ant-table-content .ant-table-scroll .ant-table-body::-webkit-scrollbar-thumb
    background-color: #d9d9d9
    border-radius: 4px
.clean-pre-check-list__table-head .ant-table-small .ant-table-content .ant-table-scroll .ant-table-body::-webkit-scrollbar-track
  background-color: #f5f5f5
.clp_p100 .clean-pre-check-list__table-head .ant-table.ant-table-small .ant-table-footer:hover
  background-color: #E2EEEC
.clp_p50 .clean-pre-check-list__table-head .ant-table.ant-table-small .ant-table-footer:hover
  background-color: #FEF2E2
.clp_l50 .clean-pre-check-list__table-head .ant-table.ant-table-small .ant-table-footer:hover
  background-color: #E8E8E8
.clean-pre-check-list__page-row .ant-pagination-item-active
  background: #1D4BA1 !important
  border-color: #1D4BA1 !important
  border-radius: 2px !important
.clean-pre-check-list__page-row .ant-pagination-item.ant-pagination-item-active a
  color: white !important
.clean-pre-check-list__page-row .ant-pagination-item a
  font-family: Proxima Nova
  font-style: normal
  font-weight: normal
  font-size: 14px !important
  color: #262626 !important
.clean-pre-check-list__page-row .ant-pagination.mini .ant-pagination-options
  margin-left: 50px !important
.clean-pre-check-list__page-row .ant-pagination.mini .ant-pagination-options >.ant-pagination-options-quick-jumper
  font-family: Proxima Nova
  font-style: normal
  font-weight: normal
  font-size: 14px !important
  color: #000000 !important
.clean-pre-check-list__page-row .ant-pagination.mini .ant-pagination-options-quick-jumper input
  height: 18px
  padding: 1px 2px
  width: 35px
  font-family: Proxima Nova
  font-style: normal
  font-weight: normal
  font-size: 14px !important
  color: #262626 !important
  border-radius: 1px !important
.clean-pre-check-list__page-row .ant-pagination-options-quick-jumper input:hover
  border-color: #1B4DA1
  border-right-width: 1px !important
.clean-pre-check-list__page-row .ant-pagination-options-quick-jumper input:focus
  border-color: #1B4DA1
  border-right-width: 1px !important
  box-shadow: none !important
.clean-pre-check-list__page-row .ant-pagination.mini .ant-pagination-prev:hover,.clean-pre-check-list__page-row .ant-pagination.mini .ant-pagination-next:hover
  background-color: #E2EEEC
.clean-pre-check-list__page-row .ant-pagination.mini .ant-pagination-item:hover
  background-color: #e2eeec
.cleanPreCheckTooltip .ant-tooltip-arrow::before
  background-color: #BFBFBF !important
.cleanPreCheckTooltip .ant-tooltip-inner
  background-color: #BFBFBF !important
.clean-pre-check-list__table-content-name:hover
  text-decoration: underline
#productModal .ant-modal-body >.product-card
  padding: 0px 10px !important
#productModal .ant-modal-body .product-card__details
  padding: 16px 10px 10px 10px
#productModal .ant-modal-body .product-card__selected-image
  width: 460px !important
  height: 460px !important
#productModal .ant-modal-body .product-card__details-header
  margin-top: 10px !important
#productModal .ant-modal-body .product-card__body-info--title
  margin-top: 10px !important
#productModal .ant-modal-body .product-card__body-description
  padding-bottom: 10px !important
  padding-top: 10px !important
#productModal .ant-modal-body .product-card__body
  padding-bottom: 20px !important
#productModal .ant-modal-body .product-card__buttons
  margin-top: 0px
  margin-bottom: 0px
#productModal .ant-modal-body .product-card__data-sheet-link
  margin-top: 10px
#productModal .ant-modal-body p
  margin-bottom: 0px
.clean-pre-check-list__table-head .ant-table-scroll
  overflow: hidden
.clean-pre-check-list__table-head .ant-table-small>.ant-table-content .ant-table-header
  background-color: #f5f5f5
