
p {
  margin-bottom: 0;
}
.thank-you {
  padding: 40px 20px;
}

.heading--5 {
  font-size: 25px;
  line-height: 29px;
  letter-spacing: 0.02em;
  color: #f15a42;
  font-family: "Niveau Grotesk";
  margin-bottom: 10px;
}

p.body {
  font-size: 30px;
  line-height: 33px;
  display: flex;
  align-items: center;
  color: #000000;
  font-family: "Mr Eaves Mod OT";
}

@media screen and (max-width: 767px) {
  .thank-you {
    text-align: center;
  }

  .thank-you .heading--5 {
    margin-bottom: 20px;
  }

  .thank-you p.body {
    font-size: 25px;
    line-height: 28px;
  }
}
