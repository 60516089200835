
.logo-or-placeholder
  width: 48px
  height: 48px
  display: flex
  position: relative
  justify-content: center
  align-items: center
  overflow: hidden
  border-radius: 50%

img
  max-width: 100%
  max-height: 100%
