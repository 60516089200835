
.pay-success-dialog
  &__title
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 24px
    line-height: 24px
    color: #262626
    text-align: center
    margin-bottom: 10px !important
  &__content
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    width: 329px
    font-size: 14px
    line-height: 20px
    display: flex
    align-items: center
    text-align: center
    color: #595959
    margin: 0 auto
  &__btn
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal !important
    font-size: 16px
    line-height: 24px
    align-items: center
    text-align: center
    color: #4A8A5D !important
    border: 1px solid #4A8A5D !important
    box-sizing: border-box
    border-radius: 2px !important
    padding: 2px 14px !important
