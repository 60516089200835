
.admin-inventory {
  width: 100%;
  margin: 0 auto;
  align: center;
  text-align: center;
  align-items: center;
  padding-left: 10px;
  &__full-width {
    width: 100% !important;
  }
  &__float-left {
    float: left;
    margin-left: 9px;
  }
  &__float-right {
    float: right;
    margin-right: 15px;
    margin-top: 4px;
  }
  &__download-btn {
    background: #fafafa !important;
    border: 1px solid #1d4ba1 !important;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 5px 16px;
    width: 78px;
    height: 34px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    line-height: 24px;
    color: #1d4ba1 !important;
  }
  &__header {
    width: 960px;
    height: auto;
    margin: 0 auto !important;
    &__title {
      font-family: Playfair Display;
      font-style: normal;
      font-weight: bold;
      font-size: 48px;
      line-height: 64px;
      color: #000000;
      margin-bottom: 26px;
    }
    &__download {
      margin-top: 28px;
      margin-bottom: 33px;
    }
    &__search {
      width: 54%;
      float: left;
    }
    &__tab {
      width: 38%;
      float: right;
    }
    &__dropdown-menu {
      width: 84px;
      background-color: #192b46 !important;
      border-radius: 1px;
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff !important;
      height: 28px !important;
    }
    &__dropdown-menu-content {
      background-color: #394a64 !important;
      border-radius: 1px !important;
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: normal;
      font-size: 14px !important;
      line-height: 17px;
      color: #ffffff !important;
    }
    &__search-input {
      background-color: #dee5ef !important;
      border-radius: 1px;
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: normal;
      font-size: 14px !important;
      line-height: 17px !important;
      color: #595959 !important;
      width: 80%;
      border: none !important;
      height: 28px !important;
    }
    &__search-dropdown {
      background-color: #f6f9fd !important;
      border-radius: 2px !important;
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: normal;
      font-size: 14px !important;
      line-height: 17px;
      color: #595959 !important;
    }
    &__tab-text {
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #262626;
      padding: 5px 6px;
      margin-left: 2px;
      cursor: pointer;
    }
    &__tab-selected {
      background: #e8e8e8;
      border-radius: 2px;
    }
  }
  &__content {
    max-width: 1290px;
    margin-top: 10px;
    margin-left: auto !important;
    margin-right: auto !important;
    &__brand-table-head {
      background: #fafafa;
      border: 1px solid #e8e8e8;
      box-sizing: border-box;
      border-radius: 2px 2px 0px 0px;
    }
    &__brand-table-head-col {
      border-right: 1px solid #e8e8e8;
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: #595959;
      padding: 7px 0px;
    }
    &__brand-table-content {
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      align-items: center;
      color: #595959;
      text-align: left;
    }
    &__brand-table-title-left {
      text-align: left;
      padding-left: 9px !important;
    }
    &__product-view-action {
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: #4a8a5d;
      cursor: pointer;
    }
    &__name-value {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 98%;
      display: block;
    }
    &__brand-name-value {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 98%;
      display: block;
      text-align: left;
    }
  }
}
#brandProductInventory
  > .ant-collapse-icon-position-right
  > .ant-collapse-item
  > .ant-collapse-header {
  display: block;
  padding: 7px 0px;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #262626;
}
#brandProductInventory > .ant-collapse {
  background-color: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 0px 0px 0px 0px;
  border-bottom: none;
  border-top: none;
}
#brandProductInventory > .ant-collapse:last-child {
  border-bottom-width: 1px;
  border-radius: 0px 0px 2px 2px;
}
#brandProductInventory > .ant-collapse > .ant-collapse-item:last-child {
  border-radius: 0px 0px 0px 0px;
}
#productInventoryTable .ant-table > .ant-table-content > .ant-table-body .ant-table-thead > tr {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #262626;
}

#productInventoryTable .ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 7px 9px;
}

#productInventoryTable .ant-table.ant-table-bordered {
  border-radius: 0px;
}
#productInventoryTable .ant-table-bordered .ant-table-tbody > tr > td {
  border-right: none;
  padding: 7px;
  vertical-align: middle;
}
#productInventoryTable .ant-table-bordered .ant-table-tbody > tr > td:last-child {
  border-right: 1px solid #e8e8e8;
}
#expirationInventoryTable .ant-table.ant-table-bordered {
  border-radius: 0px;
}
#expirationInventoryTable .ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 7px 9px;
}
#expirationInventoryTable .ant-table-bordered .ant-table-tbody > tr > td {
  border-right: none;
  padding: 7px;
  vertical-align: middle;
}
#expirationInventoryTable .ant-table > .ant-table-content > .ant-table-body .ant-table-thead > tr {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #262626;
}
.admin-inventory__header__search-input input.ant-input {
  background-color: #dee5ef;
  padding-left: 10px;
}
.admin-inventory__header__search-input .ant-input {
  height: 27px;
}
.admin-inventory__header__search-input .ant-select-selection__clear {
  background-color: transparent;
  right: 21px;
}
.admin-inventory__header__search-input.ant-select-focus {
  border: none;
}
.admin-inventory__header__search-input
  .ant-select-selection--multiple
  .ant-select-selection__choice {
  padding: 2px 2px 0 2px;
  background-color: transparent;
  border: none;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #595959;
}
.admin-inventory__header__search-input
  .ant-select-selection--multiple
  .ant-select-selection__choice__remove {
  display: none;
}
.admin-inventory__content__brand-name-value:hover {
  text-decoration: underline;
  cursor: pointer;
}
.admin-inventory__content__name-value:hover {
  text-decoration: underline;
  cursor: pointer;
}
.admin-inventory__header__search-input .ant-select-selection--single {
  height: 28px;
  min-height: 28px;
  background-color: transparent;
  border: none;
}
.admin-inventory__header__search-input .ant-select-selection__placeholder {
  color: #595959 !important;
}
.admin-inventory__header__search-input .ant-select-selection__rendered {
  line-height: 27px;
}
.admin-inventory__header__search-dropdown.ant-select-dropdown.ant-select-dropdown--multiple
  .ant-select-dropdown-menu-item
  .ant-select-selected-icon {
  display: none;
}
.admin-inventory__header__search-dropdown.ant-select-dropdown.ant-select-dropdown--multiple
  .ant-select-dropdown-menu-item {
  padding-right: 10px;
}
.admin-inventory__header__search-dropdown.ant-select-dropdown.ant-select-dropdown--multiple
  .ant-select-dropdown-menu-item-selected
  .ant-select-selected-icon {
  display: none;
}
.admin-inventory__header__search-dropdown.ant-select-dropdown.ant-select-dropdown--multiple
  .ant-select-dropdown-menu-item-selected:hover
  .ant-select-selected-icon {
  display: none;
}
.admin-inventory__header__dropdown-menu .ant-select-selection--single {
  height: 27px;
}
.admin-inventory__header__dropdown-menu .ant-select-selection {
  background-color: transparent;
  border: none;
}
.admin-inventory__header__dropdown-menu
  .ant-select-selection--single
  .ant-select-selection__rendered {
  line-height: 27px;
}
.admin-inventory__header__dropdown-menu .ant-select-selection-selected-value {
  color: white;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
}
.admin-inventory__header__dropdown-menu .ant-select-arrow {
  color: white;
}
.admin-inventory__header__dropdown-menu-content .ant-select-dropdown-menu-item {
  color: white;
}
.admin-inventory__header__dropdown-menu-content
  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #192b46;
}
.admin-inventory__header__dropdown-menu-content
  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #192b46;
}
.admin-inventory__content .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ffffff !important;
  border: 1px solid #bfbfbf !important;
}
.admin-inventory__content .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #595959;
}
.admin-inventory__content__name-value:hover {
  text-decoration: underline;
  cursor: pointer;
}
/* pagination css ...*/
#expirationInventoryTable .ant-pagination-disabled .ant-pagination-item-link {
  color: #5e6871;
}
#expirationInventoryTable .ant-pagination-item-active {
  background: #8c8c8c;
  border-radius: 2px;
  border: none;
}
#expirationInventoryTable .ant-pagination-item.ant-pagination-item-active a {
  color: white;
}
#expirationInventoryTable .ant-pagination-item a {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #262626;
}
#brandProductInventory .ant-pagination-disabled .ant-pagination-item-link {
  color: #5e6871;
}
#brandProductInventory .ant-pagination-item-active {
  background: #8c8c8c;
  border-radius: 2px;
  border: none;
}
#brandProductInventory .ant-pagination-item.ant-pagination-item-active a {
  color: white;
}
#brandProductInventory .ant-pagination-item a {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #262626;
}
