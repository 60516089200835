
@font-face
  font-family: "Playfair Display Bold"
  src: url("~@/assets/fonts/PlayfairDisplay-Bold.ttf") format("truetype")

@font-face
  font-family: "Proximanova Semibold"
  src: url("~@/assets/fonts/Proxima-Nova-Semibold.otf") format("truetype")

@font-face
  font-family: "ProvimaNova-Regular"
  src: url("~@/assets/fonts/Proxima-Nova-Regular.otf") format("truetype")

.thanks-item>h1
  text-align: center
  font-size: 28px
  font-family: 'Proxima Nova'
.thanks-item>p
  text-align: center
  line-height: 28px
.form-group
  margin-bottom: 14px
.text-center
  text-align: center
.form-check
  display:-ms-flexbox
  display:flex
  -ms-flex-align:center
  align-items:center
  -ms-flex-pack:center
  justify-content:center
  width:auto
  padding-left:0

.header-brand
  position: relative
  height: 544px
  background: url('~@/assets/img/banner/Rectangle25.png') center bottom/ auto no-repeat
  .banner-text
    position: absolute
    top: 200px
    right: 10%
    color: #12433D
    .banner-title
      font-family:"Playfair Display Bold"
      font-size: 55px
      text-align: right
    @media (max-width: 768px)
      .banner-title
        text-align: center
    .banner-subtitle
      font-size: 28px
      font-family:"Proximanova Semibold"
    .banner-content
      font-size: 16px
      font-family: ProximaNova-Regular
      text-align: right
      color: rgb(0,0,0)
    .banner-link
      font-size: 18px
      color: #000000
      bottom: -130px
      position: absolute
      right: 0px
      font-family: 'ProximaNova', 'Roboto', sans-serif
      > a
        color: rgb(255, 97, 50)
        font-weight: bold
  @media (max-width:768px)
    .banner-text
      width: 100%
      text-align: center
      right: 0
      top: 100px
      padding: 30px 10%
      .banner-content
        text-align: center
      .banner-link
        position: initial

.brand-flyer
  background: #E2EEEC
  text-align: center
  padding-top: 80px
  padding-bottom: 40px
  .flyer-title
    font-family: "Playfair Display Bold"
    font-size: 48px
    line-height: 52px
    color: #12433D
  .flyer-text
    margin-top: 24px
    font-family: "ProvimaNova-Regular",sans-serif
    font-size: 18px
    line-height: 27px
    color: #000000
  .ant-btn
    margin-top: 24px
    margin-bottom: 32px
    border: 2px solid #FF6132
    background-color: transparent
    width: 268px
    color: #000
    font-size: 16px
    height: 43px
    &:hover
      background-color: #FF6132
      color: white

.ant-input
  border-radius: 0px

.brand-body
  margin-bottom: 100px

.register-body
    margin-top:60px
    display: flex
    flex-wrap: wrap
    .register-item
      flex:0 0 50%
      align-self: flex-start
      overflow: hidden
      position: relative
      .slider-content
        text-align: center
        > a
          display: inline-flex
          width: 15px
          height: 15px
          background: black
          border: 3px solid #eee
          text-decoration: none
          align-items: center
          justify-content: center
          margin: 5px 5px
          border-radius: 50%
          &:last-child
            background: #eee
      .title-font
        font-size: 28px
        font-family: 'Playfair Display Bold'
        line-height: 37px
        color: #12433D
      .register-list
        list-style-type: disc
        list-style-position: inside
        font-family: 'ProximaNova-Regular', 'Roboto', sans-serif
        color: #000000
        font-size: 16px
        line-height: 26px
        > li
          margin-bottom: 15px

      .fillup-title
        font-family: "ProvimaNova-Regular"
        font-size: 28px
        line-height: 33px
        color: #333333
        text-align: center
      .fillup-text
        font-family: "ProvimaNova-Regular"
        font-size: 16px
        line-height: 28px
        color: #333333
        text-align: center
      .ant-btn
        margin-top: 24px
        border-color: #FF6132
        background-color: #FF6132
        width: 280px
        color: #fff
        font-size: 16px
        height: 43px
      .form-body
        .ant-form-item
          font-family: 'Proxima'
          margin-bottom: 0px
        .ant-row
          justify-content: center
        .slide-button
          text-align: center

    @media (max-width:768px)
      .register-item:first-child
        flex:0 0 100%
        max-width: 100%
        text-align: center
      .register-item:last-child
        flex:0 0 100%
        max-width: 100%
        padding-top: 120px

.carousel-body
    display: -webkit-box
    overflow-x: hidden
    scroll-snap-type: x mandatory
    scroll-behavior: smooth
    > div
      scroll-snap-align: start
