
.products-pagination
  display: flex
  justify-content: center
  margin-bottom: 15px
  padding-bottom: 70px

  a:not(:last-child)
    margin-right: 25px

  a
    color: #000
    font-size: 11px
    letter-spacing: 0.14px
    line-height: 20px
    user-select: none
    height: 24px
    width: 24px
    line-height: 24px
    text-align: center

    &.selected-page
      border-radius: 50%
      background-color: #292929
      color: white
      text-decoration: underline

    &.disabled
      color: #9e9e9e

  .is-disabled
    pointer-events: none
