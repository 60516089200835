
.brand-training-retailer-add-step-two__main {
  p {
    margin-bottom: 0;
  }
  .modal-title-location {
    width: 100%;
    text-align: center;
  }
  .step-content-text {
    width: 600px;
    text-align: center;
    margin: 0 auto;
  }
  .add-retailer-content {
    width: 560px;
    margin: 80px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 20px 50px 20px;
    .item-select-style {
      width: 120px;
      height: 120px;
      line-height: 120px;
      border: 1px solid rgba(225, 100, 76, 1);
      text-align: center;
      //padding-top: 20px;
      cursor: pointer;
    }
    .item-select-style:hover {
      background-color: rgba(237, 142, 131, 1);
      color: white;
    }
    .item-select-style-active {
      background-color: rgba(237, 142, 131, 1);
      color: white;
    }
    .select-title {
      font-weight: bold;
      margin-bottom: 15px;
    }
  }
  .button-step-style {
    text-align: right;
    .cancel-button-style {
      width: 160px;
      color: white;
      background-color: rgba(242, 124, 89, 1);
    }
    .next-button-style {
      width: 160px;
      color: white;
      background-color: rgba(145, 211, 149, 1);
    }
  }
}
