
.add-ship-detail-modal__main {
  .ant-modal-body {
    padding-top: 0;
  }
  .modal-title-text {
    text-align: center;
  }
  .item-text-title {
    text-align: left;
    margin-bottom: 0;
    margin-top: 15px;
  }
  .modal-button-style {
    margin-top: 20px;
    text-align: center;
  }
  .apply-button-save {
    padding: 5px 28px;
    color: white;
    border-radius: 2px;
    cursor: pointer;
  }
}
