
.save-all-items-dialog {
  .add-panel {
    margin-bottom: 12px;
  }
  .content {
    min-height: 270px;

    .add-panel {
      .ant-input-search-button {
        border-color: #4a8a5d;
        background: #4a8a5d;
      }
    }
    .deleted-panel {
      .header {
        margin-top: 20px;
        margin-bottom: 15px;
      }
    }
  }
}
