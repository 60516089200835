
.sign-up-title {
  font-family: "Niveau Grotesk";
  font-size: 25px;
  color: #1a1818;
}

.sign-up {
  .sign-up-link {
    text-decoration: underline;
  }

  .ant-form-item {
    margin-bottom: 22px;

    &.center {
      display: flex;
      justify-content: center;
    }

    &.agreement {
      .ant-form-explain {
        bottom: -10px;
        left: 0;
      }
    }

    .ant-form-explain {
      position: absolute;
      bottom: -20px;
      left: 24px;
    }

    input {
      border: 1px solid #f15a42;
      padding: 6px 24px 10px 24px;
      border-radius: 50px;
      font-size: 20px;
      color: #000;
      height: fit-content;
    }

    .ant-checkbox {
      border: 1px solid #f15a42;
    }

    button {
      color: white;
      background-color: #f15a42;
      font-weight: bold;
      padding: 17px 35px;
      border-radius: 100px;
      height: fit-content;
      font-size: 1.25rem;
      line-height: 16px;
    }
  }
}
