
.order-status-delivery-detail__main-modal {
  p {
    margin-bottom: 0;
  }
  .modal-order-delivery-content {
  }
  .select-or-input {
    margin-bottom: 20px;
  }
  .footer-button {
    .apply-button {
      display: inline-block;
      width: 88px;
      height: 30px;
      background: #ff6132;
      color: white;
      text-align: center;
      line-height: 30px;
      cursor: pointer;
      margin-left: 100px;
    }
  }
}
