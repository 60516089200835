
.retailer-settings
  padding-left: 80px !important
  padding-bottom: 300px
  padding-top: 0px
  &__title-style
    font-weight: bold
    font-size: 14px
    color: #333333

  &__sub-menuItem-link
    margin-left: 21px
    color: #7F7F7F
    font-size: 14px
    letter-spacing: 0.25px
    line-height: 18px
    cursor: pointer
    margin-bottom: 16px

  &__title
    color: #000000
    font-size: 18px
    letter-spacing: 0.32px
    line-height: 18px
    margin-bottom: 24px

  &__input-pwd
    box-sizing: border-box
    width: 256px !important
    border: 1px solid #D9D9D9 !important
    border-radius: 2px !important
    margin-bottom: 12px !important
    padding-left: 10px !important

  &__input-right
    box-sizing: border-box
    height: 40px
    width: 320px
    border: 1px solid #E5E5E5
    border-radius: 2px

  &__form-section

  &__account-information
    margin-top: 43px
    .margin-style
      margin-right: 97px

    &_edit-column
      display: grid

    &_label
      color: #262626 !important
      display: block
      font-size: 14px !important
      font-weight: normal !important
      line-height: 20px !important
      margin-bottom: 0px !important

    &_label-right
        color: #363636
        display: block
        font-size: 14px
        font-weight: 400

    &_job-title
        margin-top: -28px

    &_text
      color: #000000
      font-size: 18px
      letter-spacing: 0.25px
      line-height: 24px

    &_text-right
      margin-bottom: 32px
      color: #000000
      font-size: 14px
      letter-spacing: 0.25px
      line-height: 18px
      margin-left: 22px
      margin-top: 22px

    &_edit-button
      color: #161616
      font-size: 16px
      font-weight: 300
      letter-spacing: 0.16px
      line-height: 18px
      text-decoration: underline
      cursor: pointer

    &_save-button
      height: 36px
      width: 83px
      color: #292929
      font-weight: 600
      font-size: 14px
      margin-top: 21px
    .is-black
      background-color: #292929

  &__link-my-account
    color: #000000
    font-size: 16px
    letter-spacing: 0.25px
    line-height: 18px
    cursor: pointer
    margin-bottom: 16px

  &__link-retailer-account
    color: #7F7F7F
    font-size: 14px
    letter-spacing: 0.25px
    line-height: 18px
    cursor: pointer
    margin-bottom: 16px
    margin-top: 26px

  &__retailer-account
    padding: 18px
    margin-left: 20px

#savePersonInfo
  color: #FFFFFF
  background: #12433D
  border-radius: 2px
  padding: 5px 16px
  font-weight: normal
  font-size: 14px
  line-height: 20px
  margin-top: 12px
  margin-bottom: 24px
#changePwd
  color: #FFFFFF
  background: #12433D
  border-radius: 2px
  padding: 5px 16px
  font-weight: normal
  font-size: 14px
  line-height: 20px
  margin-top: 12px
  margin-bottom: 24px
