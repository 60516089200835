

.no-select-wrapper
    -webkit-touch-callout: none
    -webkit-user-select: none
    -khtml-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none

    & :hover
        cursor: pointer

.box
    &__selected
        display: flex
        align-items: center
        // width: fit-content
        height: 25px
        border: 1px solid #e0e0e0
        border-radius: 3px
        background-color: #f5f5f5
        box-shadow: 1px 1px 4px 1px #f5f5f5

    &__unselected
        display: flex
        align-items: center
        width: fit-content
        height: 25px
        border: 1px dashed #e6e6e6
        border-radius: 3px
        background-color: #fcfcfc

        // & :hover
        //     border: 1px dashed #e3e3e3

.tag-text
    &__selected
        display: inline-block
        margin: 3px
        padding: 3px
        position: relative
        text-align: center
        white-space: nowrap
        font-size: 13px
        font-weight: 450
        bottom: 2px
    &__unselected
        display: inline-block
        margin: 3px
        position: relative
        padding: 3px
        text-align: center
        white-space: nowrap
        font-size: 13px
        font-weight: 375
        bottom: 2px
.tag-icon

    &__clear
        display: flex
        // width: 17px
        // height: 17px
        width: 15px
        height: 15px
        filter: invert(61%) sepia(3%) saturate(250%) hue-rotate(128deg) brightness(90%) contrast(79%)
    &__add
        display: flex
        width: 17px
        height: 17px
        filter: invert(61%) sepia(3%) saturate(250%) hue-rotate(128deg) brightness(90%) contrast(79%)
