
.landing-terms-logistics__main {
  .logistic-style {
    font-size: 28px;
    color: #000000;
    font-weight: 600;
    font-family: "Proxima Nova", "Roboto", sans-serif;
  }
  .active-button {
    float: right;
    margin-top: -33px;
    padding: 5px 16px;
    border: 1px solid #4a8a5d;
    background-color: #e2eeec;
    color: #4a8a5d;
    font-size: 14px;
    cursor: pointer;
    font-family: "Proxima Nova", "Roboto", sans-serif;
  }
  .active-button:hover {
    background-color: #4a8a5d;
    color: white;
  }
  .activated-button {
    float: right;
    margin-top: -62px;
    color: #4a8a5d;
    font-size: 14px;
  }
  .title-second-style {
    font-size: 24px;
    color: #000000;
    font-family: "Proxima Nova", "Roboto", sans-serif;
  }
  .table-terms-standard {
    height: 46px;
    line-height: 46px;
    margin-bottom: 0;
    border: 1px solid #595959;
    border-left: none;
    border-right: none;
    span {
      display: inline-block;
      width: 280px;
    }
  }
  .table-terms-standard:nth-child(2) {
    border-top: none;
  }
  .no-border {
    border: none;
    height: 25px;
  }
  .special-span-style {
    display: block;
  }
  .header-collapse-title__name {
    margin-top: 10px;
    margin-bottom: 10px;
    span {
      margin-right: 20px;
    }
  }
  .add-button-style {
    cursor: pointer;
    text-decoration: underline;
    color: #ff6132;
  }
  .check-standard-style {
    margin-bottom: 80px;
    min-height: 40px;
    span {
      //margin-right: 20px;
    }
  }
  .admin-other-terms-content {
    border: 1px solid #595959;
    border-left: none;
    border-right: none;
    padding: 12px 10px;
    .admin-other-name {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .name-left {
        .name-left-title {
          margin-right: 207px;
        }
      }
      .name-right {
        span {
          display: inline-block;
          width: 60px;
          height: 24px;
          line-height: 24px;
          text-align: center;
          color: #595959;
          background-color: #f5f5f5;
          border: 1px solid #595959;
          border-radius: 2px;
          cursor: pointer;
          margin-right: 20px;
        }
      }
    }
    .admin-other-number {
      padding: 0 20px;
      .other-number-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 13px;
        .number-name {
          width: 280px;
        }
      }
    }
    p {
      margin-bottom: 0;
    }
  }
  .admin-other-terms-content:nth-child(n + 1) {
    border-top: none;
  }
  .admin-other-terms-content:nth-child(1) {
    border-top: 1px solid #595959;
  }
}
.logistic-modal-title {
  text-align: center;
  margin-bottom: 0;
}
.logistic-text-content {
  width: 325px;
  font-size: 14px;
  font-family: "Proxima Nova", "Roboto", sans-serif;
  font-weight: 400;
  color: #000000;
  //text-align: center;
  margin-left: 30px;
}
.logistic-modal-checkInfo {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 20px;
  color: #000000;
  margin-top: 70px;
  p {
    width: 288px;
    margin-left: 14px;
  }
}
.logistic-modal-button {
  text-align: center;
  margin-top: 20px;
  span {
    padding: 5px 16px;
    font-size: 14px;
    cursor: pointer;
    font-family: "Proxima Nova", "Roboto", sans-serif;
  }
  .decline-text {
    border: 1px solid #d9d9d9;
    color: #595959;
  }
  .accept-text {
    color: white;
    border: 1px solid #4a8a5d;
    background-color: #4a8a5d;
    margin-left: 8px;
  }
}
.ant-checkbox {
  //margin-left: 185px !important;
}
.ant-checkbox-wrapper {
  font-weight: 400 !important;
  font-family: "Proxima Nova", "Roboto", sans-serif;
}
