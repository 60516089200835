
.no-border
  border: none !important
.product-card__btn-layer
  width: 100%
.product-card__btn-img
  width: 17px
  height: 17px
  margin-left: 6px
.product-card__back-btn
  background: #DEE5EF
  border-radius: 2px
  width: 88px
  height: 30px
  font-family: Proxima Nova
  font-style: normal
  font-weight: normal
  font-size: 14px
  line-height: 22px
  color: #000000
  float: right
.product-card__body
  padding-top: 50px
  padding-bottom: 100px

.product-card__button-submit, .product-card__button-submit--added
  height: 46px
  width: 237px
  font-size: 14px
  margin-top: 16px
  border-radius: 0
  background-color: #303030 !important

.product-card__button-submit--in-cart
  height: 46px
  width: 237px
  font-size: 14px
  margin-top: 16px
  border-radius: 0
  color: #8D8D8D !important
  border-color: #8D8D8D !important
  pointer-events: none

.product-card__button-submit--added
  background-color: black !important

.product-card__button-submit:hover
  background-color: black !important

.product-card__button-submit--disabled
  pointer-events: none
  background-color: white !important
  color: black !important
  border-color: #8D8D8D !important
  opacity: 0.6

.product-card__button-submit--not-allowed-cursor
  cursor: not-allowed

.product-card__details-header
  margin-top: 24px
  font-size: 18px
  letter-spacing: 0.25px
  color: #000
  font-weight: 600

.product-card__body-info--title
  letter-spacing: 0.15px
  font-size: 16px
  color: #000
  margin-top: 27px
  font-weight: 600

.product-card__body-info
  border-bottom: 1px solid #E5E5E5

.product-card__body-info:nth-child(4)
  border-bottom: none

.product-card__body-description
  padding-left: 20px
  padding-bottom: 24px
  padding-top: 16px
  color: #4A4A4A
  max-width: 1100px
  white-space: pre-wrap
  overflow-wrap: break-word

.product-card
  padding: 0px 80px 0px 80px

  &__nav
    padding: 16px 0px

  &__nav_border
    border-bottom: 1px solid #e5e5e5
    margin-bottom: 32px

  &__breadcrumb
    display: inline

    li
      display: inline
      color: #000000
      font-size: 14px
      font-weight: 300
      letter-spacing: 0.16px

  &__thumb
    margin-bottom: 15px
    width: 64px
    height: 64px
    border: 1px solid #e5e5e5
    cursor: pointer
    display: flex
    justify-content: center
    align-items: center

    video
      max-width: 100%
      max-height: 100%

    img
      max-width: 100%
      max-height: 100%

    &_active
      border: 2px solid #000000
      border-radius: 2px

  &__details
    border: 1px solid #e5e5e5
    border-radius: 4px
    max-width: 384px
    padding: 25px 23px 0px 23px
    position: relative

    .product-card-details-list
      background: #fff
      width: 268px
      //padding: 10px 20px
      position: absolute
      top: 12px
      right: 8px
      border: 1px solid #e5e5e5
      box-shadow: 0px 4px 12px rgba(0,0,0,0.06)
      display: none

      .list-item-block
        display: flex
        align-items: center
        padding: 10px 20px
        cursor: pointer

        &:hover
          background-color: #f6f6f6

        .icon-block
          min-width: 20px

        .text-block
          padding-left: 10px

          p
            color: #212B36
            font-size: 14px

    .product-card__breadcrumb-block
      display: flex
      .image-icon-block
        margin-left: auto
        cursor: pointer
        &:hover
          color: #737781

  &__details.open
    .product-card-details-list
      display: block

    &-brand
      font-size: 16px
      letter-spacing: 0.15px
      font-weight: 600
      margin-top: 10px

      a
        color: #000

    &-size
      font-size: 18px
      letter-spacing: 0.25px
      color: #000

    &-name
      font-size: 18px
      letter-spacing: 0.25px
      color: #000
      margin-top: 8px

    &-sku
      font-size: 16px
      letter-spacing: 0.4px
      line-height: 22px
      color: #767676
      margin-top: 4px

    &-msrp
      color: #B3B3B3
      font-size: 16px
      letter-spacing: 0.15px
      line-height: 22px
      margin-top: 4px

    &-margin
      color: #AAE4D4
      font-size: 20px

    &-price
      color: #292929
      font-size: 20px
      line-height: 24px
      line-height: 24px
      margin-top: 7px

      &-info
        color: black
        font-size: 14px
        letter-spacing: 0.25px
        line-height: 18px

    &-country
      font-size: 14px
      letter-spacing: 0.25px
      line-height: 18px
      font-weight: 600

    &-sample
      color: #9B9B9B
      font-size: 14px
      letter-spacing: 0.25px
      text-transform: uppercase
      margin-top: 23px

    &-upcoming
      color: #9B9B9B
      font-size: 14px
      letter-spacing: 0.25px
      margin-top: 23px

  &__buttons
    margin: 0 auto
    margin-top: 20px
    margin-bottom: 30px

  &__data-sheet-link
    text-decoration: underline
    color: black
    font-size: 14px
    margin-top: 26px
    margin-bottom: 7px
    display: inline-block

  &__selected-image
    width: 591px
    height: 591px
    display: flex
    justify-content: center
    align-items: center

    video
      max-height: 100%
      max-width: 100%

    img
      max-height: 100%
      max-width: 100%

.tag-button
  cursor: default
  margin-top: 10px
  background-color: #dbdbdb !important
  color: #494949 !important
  height: 32px

.wait
  cursor: wait
