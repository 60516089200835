
.edit-ship-or-cancel-modal__main {
  .ship-cancel-content {
    margin: 0 auto;
    width: 300px;
    margin-bottom: 100px;
    margin-top: 30px;
  }
  .ship-cancel-button-style {
    text-align: right;
    .button-style {
      padding: 5px 16px;
      color: white;
      cursor: pointer;
      border-radius: 2px;
    }
  }
}
