
.price-range-slider__scale-wrapper
  position: relative
  height: 26px

.price-range-slider__range
  margin-top: 20px
  font-size: 12px
  color: #4A4A4A

.price-range-slider

  &__header-name
    position: relative
    display: block
    cursor: pointer
    font-size: 16px
    color: #4A4A4A
    padding: 16px 0px 10px 0px

  .current-values
    color: #000000
    font-size: 12px
    font-weight: 600

  &__scale
    height: 6px
    border-radius: 2px
    width: 100%
    background: #E5E5E5
    position: absolute
    left: 0
    top: calc(50% - 3px)

  &__scale-active
    height: 6px
    border-radius: 2px
    width: 100%
    background: #000
    left: 0
    top: 0
    position: absolute

  &__min, &__max
    position: absolute
    left: 0
    width: 100%
    pointer-events: none
    background: inherit
    -webkit-appearance: none
    height: 26px

    &:focus
      outline: none

    &::-webkit-slider-thumb
      pointer-events: auto
      background: #292929
      width: 12px
      height: 12px
      border: none
      border-radius: 50%
      -webkit-appearance: none

    &::-moz-range-thumb
      pointer-events: auto
      background: #292929
      width: 12px
      height: 12px
      border: none
      border-radius: 50%

    &::-ms-thumb
      pointer-events: auto
      background: #292929
      width: 12px
      height: 12px
      border: none
      border-radius: 50%
