
.new-brand-registration {
    font-family: 'Proxima Nova', 'Roboto', sans-serif;
    margin: 0 auto;
    display: flex;
    max-width: 1440px;
    gap: 80px;
    flex-flow: row wrap;
    justify-content: center;
    padding: 20px;

    .left {
        flex: 1 1;
    }

    .right {
        flex: 0 0 479px;
    }

    .num-symbol {
        color: #F15A42;
        background-color:
            rgba(237, 142, 131, .2);
        margin-right: 18px
    }

    .title {
        text-transform: uppercase;
        font-family: "Niveau Grotesk";
        font-size: 18px;
        font-size: 18px;
        font-weight: bold;
        color: rgb(26, 24, 24);

    }

    .change-plan {
        color: #F15A42;
        font-size: 13px;
        font-weight: bold
    }

    .right {
        .bg {
            background-color: rgba(207, 229, 204, 0.2);
            border-radius: 10px;
            padding: 42px;
            margin-bottom: 35px;
            font-family: "Proxima Nova", Roboto, sans-serif;
            font-size: 16px;
        }
    }

    .ant-divider {
        background-color: #f27c59;
    }

    .col2 {
        display: flex;
        width: 100%;


    }

    .sub-total,
    .discount-amount {
        margin-bottom: 12px;
    }

    .plan-freq-group {
        display: flex;
        flex-direction: column;

        .plan-freq {
            border: 1px solid rgba(26, 24, 24, .2);
            border-radius: 6px;
            color: #1a1818;
            display: flex;
            flex-direction: row;
            padding: 20px;
            margin-bottom: 16px;

            >span {
                display: flex;
                flex-direction: row;
                flex: 1 1 auto;
            }

            .payment-amount {
                font-size: 25px;
                font-family: DMSans;

                .freq {
                    font-weight: bold;
                    font-family: "Proxima Nova", Roboto, sans-serif;
                    font-size: 16px;
                    text-transform: lowercase;
                }

                .num-payment {
                    font-family: "Proxima Nova", Roboto, sans-serif;
                    font-size: 16px;
                }
            }


            .ant-radio {
                flex: 0;
                color: #f15a42;
            }

            .left,
            .right,
            .savings {

                flex: 0;
            }

            .right {
                padding-left: 10px;
                text-align: right;
            }

            .left {

                text-align: left;
            }

            .col-spacer {
                flex: 1 1 auto;
            }
        }

        .savings {
            border-radius: 30px;
            border: 1px solid #1a1818;
            color: #1a1818;
            padding: 3px 10px;
            font-size: 14px;
            font-weight: 700;
        }

        .savings-on {
            border: 1px solid #f15a42;
            color: #f15a42;
        }

        .save-up-to {
            background-color: rgba(250, 201, 44, .3);
            border-radius: 3px;
            font-weight: 700;
            font-size: 14px;
            height: 32px;
            color: #f15a42;
            line-height: 32px;
            padding-left: 8px;
            padding-right: 8px;
        }
    }

    .col-spacer {
        flex: 1 1 auto;
    }

    .fx-c {
        display: flex;
        flex-direction: column;

    }

    .sign-up,
    .payment,
    .frequence {
        padding-bottom: 12px;
    }

    .submit-style {
        width: 480px;
        line-height: 57px;
        height: 57px;
        text-align: center;
        border-radius: 50px;
        background-color: #f15a42;
        color: #ffffff;
        margin-top: 45px;
        font-size: 18px;
        font-family: "Niveau Grotesk";
        cursor: pointer;
        margin-bottom: 29px;

        &:hover,
        &:focus {
            background-color: #f15a42;
            color: #ffffff;
        }
    }

    .cancel-style {
        width: 480px;
        text-align: center;
        font-size: 18px;
        font-family: DMSans;
        cursor: pointer;
    }

    .nav-link {
        color: rgba(0, 0, 0, .6);
        text-decoration: underline;
    }

    .has-error {
        color: #f5222d;
    }

    .ant-radio-checked .ant-radio-inner {
        border-color: #f15a42 !important;
    }

    .ant-radio-checked::after {
        border: 1px solid #f15a42;
    }

    .ant-radio-inner::after {
        background-color: #f15a42 !important;
    }

    .ant-radio-wrapper {
        margin-top: -4px;
    }

    .payment-method {
        padding-left: 50px;
        padding-bottom: 30px;
        display: flex;
    }
}
