
.console-cascade-content {
  font-family: Proxima Nova;
  font-size: 18px;
  line-height: 22px;
  font-style: normal;
  font-weight: normal;
  .ant-collapse {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    width: 100%;

    > .ant-collapse-item {
      border-bottom: none;
    }
  }

  .content {
    padding-top: 12px;
    padding-left: 26px;
    padding-bottom: 8px;
  }
  .content-border {
    border: 1px solid #4a8a5d;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding-top: 12px;
    padding-left: 26px;
    padding-bottom: 8px;
  }
  .last-level-panel {
    background: #e2eeec;
    padding: 12px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding-bottom: 10px;
  }
}
.item-btn {
  background: #fff;
  border: 1px solid #4a8a5d;
  border-radius: 2px;
  padding: 6px 8px;
  color: #4a8a5d;
  > *:not(:last-child) {
    padding-right: 4px;
  }
}
.f-col {
  display: flex;
  flex-direction: column;
}
.f-row {
  display: flex;
  flex-direction: row;
}
.single-row {
  width: 100%;
}
.f-wrap {
  flex-wrap: wrap;
}
.pad {
  .chip-panel {
    > * {
      margin-bottom: 14px;
      margin-right: 14px;
    }
  }
}
.save-all-items-dialog .ant-modal-footer .ant-btn-primary {
  border-color: #4a8a5d;
  background: #4a8a5d;
}
