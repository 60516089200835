
.guest-header
  &__container
    position: sticky
    top: 0
    z-index: 2
    padding: 0px 80px 0px 80px

    @media screen and (max-width: 767px)
      padding: 0px 40px 0px 40px

    @media screen and (max-width: 767px)
      padding: 0px 20px 0px 20px

  &__navigation
    margin-top: 15px
    margin-bottom: 15px

  &__actions
    display: flex !important
    justify-content: flex-end
    cursor: pointer
    position: relative

  &__navbar
    height: 56px
    display: flex
    flex-flow: row
    align-items: center

  &__main-icon
    height: auto
    width: 127px

  &__items
    margin-left: 80px

  &__item
    margin-right: 4px

  &__a-color
    color: #212B36

  &__sign-up-button
    color: #212B36
    border: 1px solid black
    padding: 10px 20px 10px 20px

  &__divider
    border: 0.5px solid #E5E5E5

.flexy-column
  display: flex
  justify-content: space-between

.landing-navbar
  height: 86px

.navbar-toggler
  border: none !important
  padding: .25rem .75rem

.nav-link
  font-size: 16px
  color: #4A4A4A !important
  margin-right: 30px

.justify-content-from-end
  justify-content: flex-end

.columns:not(:last-child)
  margin-bottom: 0px
