
.beauty-fluent-step-three__main {
  padding: 50px 120px 120px 120px;
  max-width: 1440px;
  margin: 0 auto;
  .step-three-com-style {
    display: flex;
    justify-content: space-between;
  }
  .step-title {
    width: 316px;
    text-align: center;
    margin: 20px auto 60px;
    display: flex;
    justify-content: space-between;
    position: relative;
    .step-line {
      height: 3px;
      width: 316px;
      background-color: #f15a42;
      position: absolute;
      top: 15px;
      left: 0;
      z-index: 1;
    }
  }
  .submit-style {
    width: 100%;
    line-height: 57px;
    height: 57px;
    text-align: center;
    border-radius: 50px;
    background-color: #f15a42;
    color: #ffffff;
    margin-top: 45px;
    font-size: 18px;
    font-family: DMSans;
    cursor: pointer;
  }
  .cancel-style {
    text-align: center;
    font-size: 18px;
    font-family: DMSans;
    cursor: pointer;
  }
  .step-icon-style {
    position: absolute;
    top: -25px;
    left: -5px;
    color: #1a1818;
    font-weight: bold;
    font-family: DMSans;
  }
  .billing-style {
    left: 130px;
  }
  .payment-style {
    left: 270px;
    color: #f27c59;
  }
}
