
.category-select-list {
  box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;
  min-height: 35px;
  text-align: left;
  color: #35495e;
  z-index: 1000;
  &__tags {
    min-height: 35px;
    display: block;
    padding: 8px 40px 8px 8px;
    border-radius: 2px;
    border: 1px solid #bfbfbf;
    background: #fff;
    font-size: 14px;
    line-height: 22px;
    color: #bfbfbf;
  }
  &__placeholder-text {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    word-wrap: break-word;
    word-break: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__active {
    border: 1px solid #4a8a5d;
  }
  &__disabled {
    border: 1px solid #bfbfbf;
  }
  &__right-arrow {
    position: absolute;
    top: 10px;
    right: 12px;
    font-size: 20px;
  }
  &__content-wrapper {
    border: 1px solid #4a8a5d;
    box-sizing: border-box;
    border-top: none;
    border-radius: 0px 0px 2px 2px;
    position: absolute;
    display: block;
    background: #fff;
    width: 100%;
    z-index: 50;
    box-sizing: border-box;
    padding: 10px;
  }
}
