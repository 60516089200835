
.beauty-fluent-step-three-payment__main {
  p {
    margin: 0;
  }
  .billing-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
    font-family: "Niveau Grotesk";
  }
  .form-item-name {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .ant-form-item {
    margin-bottom: 24px;
    .ant-input,
    .ant-input-affix-wrapper,
    .ant-input-password,
    .ant-input-affix-wrapper .ant-input {
      height: 55px;
      border-radius: 100px;
    }
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: #f15a42 !important;
  }
  .ant-radio-checked::after {
    border: 1px solid #f15a42;
  }
  .ant-radio-inner::after {
    background-color: #f15a42 !important;
  }
  .ant-radio-wrapper {
    margin-top: -4px;
  }
  .card-box-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 500px;
    height: 60px;
    padding: 10px 20px;
    border: 1px solid rgba(26, 24, 24, 0.2);
    border-radius: 10px;
    margin-bottom: 10px;
    cursor: pointer;
  }
  .card-box-content:hover {
    background-color: rgba(237, 142, 131, 0.2);
    border-color: #f15a42;
  }
  .default-card-style {
    font-size: 12px;
    color: #f15a42;
    border-radius: 5px;
    border: 1px solid #f15a42;
    text-align: center;
    padding: 3px 5px;
    margin-left: 5px;
  }
}
