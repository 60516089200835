
.type_btn {
  height: 48px;
  border-bottom: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
  padding-left: 18px;
  color: #262626;
  cursor: pointer;
  .name {
    font-family: Proxima Nova;
    font-size: 16px;
    margin-left: 16px;
  }
  &_selected {
    background-color: #4a8a5d !important;
    color: #fff !important;
  }
  .location-style {
    font-size: 20px;
    margin-right: 5px;
  }
}
