
//.header-search__main
  //position: relative
//.search-container
//  display: flex
//  align-items: center
//.search-box
//  padding: 20px
//  //border: 1px solid #d9d9d9
//  border-radius: 40px
//  margin-right: 10px
//  height: 25px
//  width: 220px
//  margin-top: 20px
.header-search-box
  margin-top: 25px
  //border-radius: 40px
  //.search-dropdown
  //    align: center
  .ant-select-selection
    border-radius: 25px
    height: 35px
  .ant-select-search--inline
    input
      //border-radius: 40px
      padding-left: 14px !important
.search-icon
  cursor: pointer
  display: inline-flex
  position: absolute
  margin-left: 7px
  margin-top: 34px
  color: #8c8c8c
  font-size: 17px
  z-index: 12
