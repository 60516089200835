
.retailer-registraion
  padding: 50px
  width: 100%

  &__buttons
    display: flex
    margin-top: 25px
    flex-direction: column
    align-items: center

.retailer-personal-info
  &__header
    display: flex
    flex-direction: column
    align-items: center
    margin-bottom: 25px
    color: #212B36

    &--title
      font-size: 28px
      color: #333333

    &--subtitle
      font-size: 16px
      color: #333333

  &__form-field
    padding-right: 16px
    padding-left: 16px

  &__field
    margin-bottom: 24px !important

  &__form
    &--label
      font-size: 14px
      color: #212B36
    &--text-area
      height: 293px

    .registraion-code-message
      font-size: 10px

  &__terms-checkbox
    position: relative
    display: flex

  &__terms-checkbox-icon
    position: absolute
    left: 0
    cursor: pointer

  &__terms-checkbox-icon--checked
    right: 3px
    top: 3px
    width: 18px
    height: 18px

  &__terms-checkbox-icon--unchecked
    width: 18px
    height: 18px

  &__terms-checkbox-text
    margin-left: 25px
    font-size: 14px
    color: #212B36

  &__radio-field
    padding-bottom: 15px

  &__radio + &__radio
    margin-left: 3em !important

  &__radio-container
    position: relative
    padding-left: 25px
    margin-top: 10px
    cursor: pointer
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none

    input
      position: absolute
      opacity: 0
      cursor: pointer

  &__radio-label
    font-size: 14px
    color: #000000

  &__radio-checkmark
    position: absolute
    top: 3px
    left: 0
    height: 14px
    width: 14px
    background: #FFFFFF
    border-radius: 50%
    border: 1px solid #E5E5E5

  &__radio-container input:checked ~ &__radio-checkmark, .checkmark-box
    background-color: #FFFFFF

  &__radio-checkmark:after
    content: ""
    position: absolute
    display: none

  &__radio-container input:checked ~ &__radio-checkmark:after
    display: block

  &__radio-container input:checked ~ .checkmark-box:after
    display: block

  &__radio-container &__radio-checkmark:after
    top: 3px
    left: 3px
    width: 6px
    height: 6px
    border-radius: 50%
    background: #000000

  &__radio-container .checkmark-box:after
    top: 3px
    left: 3px
    width: 6px
    height: 6px
    border-radius: 2px
    background: #000000
    padding-left: 20px

  &__select-placeholder
    position: relative
    padding-left: 20px
    bottom: 30px
    color: #b4b4b4
    width: 0px
    font-size: 14px

  &__dot-container
    display: flex
    margin-bottom: 12px

    &--dot-filled
      height: 8px
      width: 8px
      background-color: #000000
      margin: 0 4px
      border-radius: 50px

    &--dot-unfilled
      height: 8px
      width: 8px
      margin: 0 4px
      background-color: #DBDBDB
      border-radius: 50px

.next-button
  background-color: #FF6132
  width: 335px
  height: 55px
  border-radius: 30px
  color: #FFFFFF
  font-size: 16px

.back-button
  margin-top: 20px
  color: #161616
  font-size: 14px
  font-weight: 300
  text-decoration: underline

.percentage-icon
  color: #000000 !important

.agree-terms
  display: flex
  align-items: center
  font-size: 10px
  color: #000000
  margin-bottom: 5px

  &__icon
    margin-right: 10px
    height: 16px
    width: 16px
    cursor: pointer

  &__icon-checked
    margin-right: 10px
    height: 16px
    width: 16px
    cursor: pointer
  &__text-link
    text-decoration: underline
    cursor: pointer
    margin: 0 3px
.disabled-button
  opacity: 0.5

.terms-tooltip
  margin-left: 5px
