
.admin-clients__status
  text-transform: capitalize

.admin-clients
  padding: 10px 20px 200px 30px

  &__client-name
    font-size: 15px
    color: #333333
    white-space: normal
    word-break: break-all

  &__client-website
    font-size: 13px
    color: #333333
    max-width: 120px
    white-space: pre-wrap
    overflow-wrap: break-word

  &__checkbox
    width: 16px
    height: 16px
    border: 1px solid black

  &__logo-container
    width: 40px
    height: 40px
    border: 0.5px solid #AAAAAA
    display: flex
    justify-content: center
    align-items: center

    img
      width: 100%
      height: 100%

  &__select
    display: flex
    //justify-content: end
    justify-content: flex-end

  &__status-circle
    width: 6px
    height: 6px
    border-radius: 50%
    background-color: #545454
    margin-right: 15px

  &__filter
    padding-right: 70px

  &__table-name
    color: #000000
    font-size: 18px
    font-weight: 500
    letter-spacing: 0.25px
    padding-left: 15px

  &__table-head
    color: #9E9E9E !important
    font-size: 12px
    font-weight: 500
    border-bottom: 1px solid #E0E0E0 !important

  &__table-cell
    color: #333333 !important
    font-size: 15px
    font-size: 14px
    font-weight: 500
    border-bottom: 1px solid #E0E0E0 !important
    padding-top: 1px
    display: flex
    align-items: center
    padding-bottom: 10px
    position: relative

    &--checkbox
      justify-content: center

  &__filter-column-name
    color: #292929
    font-size: 16px
    font-weight: 300
    margin-bottom: 15px

  &__clients-amount
    display: flex
    justify-content: end
    justify-content: flex-end
    font-size: 12px

  &__border
    border-bottom: 1px solid #E0E0E0

  &__brand-info
    padding-top: 10px
    padding-bottom: 10px
    border-bottom: 1px solid #E0E0E0

  &__dropdown-circles
    width: 30px
    height: 30px
    display: flex
    justify-content: center
    align-items: center
    cursor: pointer

  &__dropdown
    position: absolute
    width: 135px
    height: 132px
    top: 2px
    right: 20px
    z-index: 100
    background-color: #ffffff
    border: 1px solid #DADADA

  &__dropdown-item
    height: 45px
    display: flex
    align-items: center
    padding-left: 10px
    cursor: pointer

  &__review-link
    color: #333333  !important

.client-name
  max-width: 140px

.location
  min-width: 125px
  white-space: normal
  word-break: break-all

.status
  min-width: 135px

.date-registered
  max-width: 130px
