
.guest_request_demo {
  font-family: niveau-grotesk, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #262626;
  padding: 54px 24px;
  .guest_request_demo_header {
    margin-bottom: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    text-align: center;
    &__title {
      margin-bottom: 20px;
      opacity: 1;
      color: #000;
      font-size: 25px;
      line-height: 1.2;
      font-weight: 400;
      letter-spacing: 0.02em;
      text-align: center;
    }
    &__desc {
      display: block;
      text-align: center;
      margin-bottom: 20px;
    }
  }
}
.text-field---rd {
  height: 55px;
  padding-left: 25px !important;
  border-style: solid;
  border-width: 1px;
  border-color: #f15a42;
  border-radius: 50px;
  color: #000;
}
.w-input,
.w-select {
  display: block;
  width: 100%;
  padding: 8px 12px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.42857143;
  background-color: #ffffff;
}
.flex-center {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.button.rd-btn {
  min-height: 48px;
  min-width: 153px;
  margin-top: 10px;
  font-size: 14px;
  text-transform: uppercase;
  display: flex;
  padding-right: 35px;
  padding-left: 35px;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  border-style: solid;
  border-width: 2px;
  border-color: #f15a42;
  border-radius: 50px;
  background-color: #f15a42;
  line-height: 1.2;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0em;
  color: #fff;
}
input.w-button {
  -webkit-appearance: button;
}
