
.brand-training-create__main {
  min-height: 520px;
  width: 952px;
  margin: 0 auto;
  .training-page-title {
    width: 100%;
    height: 94px;
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .training-logo {
    font-size: 48px;
    font-weight: bold;
    color: #000000;
    font-family: "Playfair Display";
  }
  .title-button {
    width: 205px;
    text-align: right;
    //display: flex;
    //justify-content: space-between;
    .btn-style {
      width: 88px;
      height: 30px;
      font-family: "Proxima Nova";
      //line-height: 20px;
      //padding: 5px 16px;
      font-size: 14px;
      border: 2px solid #4a8a5d;
      color: #4a8a5d;
      background: #e2eeec;
    }
    .btn-style:nth-child(2) {
      margin-left: 26px;
    }
    .btn-style:hover {
      color: white;
      background: #4a8a5d;
    }
    .can-not-click {
      width: 88px;
      height: 30px;
      font-family: "Proxima Nova";
      font-size: 14px;
      border: 2px solid #bfbfbf;
      color: #bfbfbf;
      background: #fafafa;
      margin-left: 26px;
    }
    .can-not-click:hover {
      //color: white;
      //background: #4a8a5d;
    }
  }
  .step-title {
    width: 488px;
    font-size: 13px;
    line-height: 15.83px;
    color: #000000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .step-title-one {
      width: 109px;
      height: 60px;
    }
    .step-title-two {
      width: 126px;
      height: 60px;
    }
    .step-title-three {
      width: 186px;
      height: 60px;
    }
    p {
      margin-bottom: 0;
    }
  }
  .step-line {
    width: 100%;
    height: 4px;
    border-radius: 2px;
    background: #e2eeec;
    margin-top: 8px;
    //margin-top: 4px;
  }
  .training-create-content {
    margin-top: 24px;
    display: flex;
    //justify-content: start;
    justify-content: flex-start;
    flex-wrap: wrap;
    .products-card {
      width: 220px;
      margin-right: 24px;
      margin-bottom: 24px;
    }
    .products-card:nth-child(4n) {
      margin-right: 0;
    }
  }
}
