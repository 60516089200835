
.edit-contact-information
  &__edit-form
    text-decoration: underline
    color: #161616
    font-size: 14px
    font-weight: 300
    letter-spacing: 0.16px
    line-height: 18px
    margin-left: 11px
  &__paragraph
    color: #000000
    font-size: 14px
    letter-spacing: 0.25px
    line-height: 26px
  &__title
    color: #000000
    font-size: 18px
    letter-spacing: 0.25px
    line-height: 24px
    margin-bottom: 24px
  a
    color: #161616
.old-edit-contact-information-modal
  &__header
    color: #212B36
    font-size: 20px
    border-bottom: 1px solid #E1E4E9
    padding-top: 15px
    padding-left: 20px
    padding-bottom: 15px
  &__content
    position: relative
    background-color: #FFFFFF
    width: 635px
  &__body
    padding: 10px 20px 45px 20px
    border-bottom: 1px solid #E1E4E9
  &__input-label
    padding-bottom: 5px
  &__buttons
    padding: 10px 20px
  &__close-button
    position: absolute
    top: 24px
    right: 24px
    cursor: pointer
  &__save-button
    margin-left: 10px
