
.retailers-filter-button
  width: 100%
  border-radius: 3px
  display: flex
  border-top: 1px solid #D9D9D9
  fill: black
  cursor: pointer
.icon
  position: relative
  display: inline-block
  float: left
  width: 25px
  height: 25px
  margin-left: 25px
  margin-right: 10px
  padding-top: 10px
  fill: red

.name
  text-align: left
  display: inline-block
  position: relative
  float: left
  width: 100%
  font-weight: 620
  font-size: 16px
  padding-top: 10px
  padding-bottom: 10px
  margin-left: 10px

.selected
  width: 100%
  height: auto
  background-color: #4a8a5d
  color: white
  font-weight: 500

.pleaseHide
  display: none
