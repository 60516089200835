
.retailer-training-brand-product-tab__main {
  margin-top: 24px;
  display: flex;
  //justify-content: start;
  justify-content: flex-start;
  flex-wrap: wrap;
  p {
    margin-bottom: 0;
  }
  .retailer-training-brand-title__main {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Proxima Nova", "Roboto", sans-serif;
    .view-list-or-grid {
      color: #e1644c;
      text-decoration: underline;
      span {
        cursor: pointer;
      }
    }
    .view-all-product {
      width: 67px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      border-radius: 7px;
      color: #ffffff;
      background: #e19387;
      cursor: pointer;
    }
  }
  .retailer-training-brand-product-title {
    text-align: center;
    width: 100%;
    height: 50px;
    line-height: 50px;
    color: #000000;
    font-size: 24px;
  }
  .retailer-training-content {
    width: 1200px;
    display: flex;
    flex-wrap: wrap;
  }
  .products-card {
    width: 220px;
    margin-right: 24px;
    margin-bottom: 24px;
  }
  .products-card:nth-child(4n) {
    margin-right: 0;
  }
  .training-product-card__main {
    width: 220px;
    height: 364px;
    border: 2px solid #ffffff;
    cursor: pointer;
    .prod-img {
      max-width: 220px;
      height: 264px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        //max-width: 90%;
        //max-height: 90%;
      }
    }
    .prod-content {
      text-align: center;
      font-size: 13px;
      color: #000000;
      font-family: "Proxima Nova";
      .content-title {
        //width: 80%;
        margin: 0 auto;
        text-align: left;
        height: 22px;
        font-weight: bold;
        margin-top: 10px;
        color: #000000;
        word-wrap: break-word;
        word-break: normal;
        //display: block;
        //white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        //line-height: 44px;
      }
      .content-sku {
        text-align: left;
      }
      .content-price {
        text-align: left;
      }
    }
  }
  .no-table-data {
    width: 100%;
    height: 270px;
    text-align: center;
    color: #bfbfbf;
    font-size: 16px;
    padding-top: 80px;
    position: relative;
    left: 350px;
  }
  .name-tab-style {
    position: relative;
    span {
      position: absolute;
      right: 16px;
      top: 15px;
    }
  }
  .retailer-brand-product-table-style {
    overflow: visible;
    width: 100%;
    color: #262626;
    //border: #e8e8e8 solid 1px;
    border-collapse: collapse;
    margin-top: 20px;
  }
  .retailer-brand-product-table-style tr {
    width: 100%;
    position: relative;
  }
  .retailer-brand-product-table-style th {
    border-top: #e8e8e8 solid 1px;
    border-bottom: #e8e8e8 solid 1px;
    padding: 16px;
    //background-color: #fafafa;
    font-size: 14px;
    font-weight: normal;
  }
  .retailer-brand-product-table-style td {
    height: 56px;
    border-bottom: #e8e8e8 solid 1px;
    padding: 17px 16px;
    background-color: #ffffff;
    color: #595959;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .retailer-brand-product-table-style tr,
  .retailer-brand-product-table-style td.move:hover {
    //cursor: move;
  }
  .retailer-brand-product-table-style td.click-pro-action:hover {
    cursor: pointer;
  }
  .product-hero-style {
    display: inline-block;
    width: 39px;
    height: 22px;
    line-height: 22px;
    background: #f3ca50;
    color: #ffffff;
    border-radius: 100px;
    text-align: center;
  }
  .editable-row-operations {
    color: #4a8a5d;
  }
  .icon-sort-style {
    color: #8c8c8c;
  }
}
