
.product-view-dialog-new__main {
  p {
    margin-bottom: 0;
  }
  .brand-info-trining {
    margin: 20px 0;
    border-bottom: 1px solid #d9d9d9;
  }
  .read-product-training-details {
    padding: 0 30px 30px 30px;
  }
  .item-product-training-details {
    border-bottom: 1px solid #d9d9d9;
    margin: 20px 0;
  }
  .read-product-top {
    display: flex;
    justify-content: start;
    color: #000000;
    font-size: 14px;
    font-family: "Proxima Nova", "Roboto", sans-serif;
    .product-info-left {
      width: 400px;
      margin-right: 40px;
      p {
        width: 100%;
        line-height: 22px;
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
      }
      .test-title-name {
        display: inline-block;
        width: 65px;
      }
      .product-name-info {
        width: 300px;
      }
    }
    .product-img-video-right {
      display: flex;
      justify-content: start;
      align-items: center;
      .video-add-style {
        margin-right: 35px;
        width: 200px;
        display: flex;
      }
      .img-add-style {
        width: 200px;
        display: flex;
      }
      .videos-imgs-style {
        min-width: 90px;
        display: flex;
        justify-content: start;
      }
    }
    .video-style {
      width: 75px;
      height: 69px;
      border: 1px solid #d9d9d9;
      border-radius: 7px;
      position: relative;
      margin-right: 16px;
      text-align: center;
    }
    .img-style-add {
      border-radius: 0;
    }
    .public-start {
      width: 22px;
      height: 22px;
      position: absolute;
      top: 24px;
      left: 24px;
    }
    .public-start-img {
      width: auto;
      height: 100%;
    }
  }
  .other-info-center-content {
    margin: 20px 0 0 0;
    display: flex;
    justify-content: start;
    .form-content {
      width: 400px;
      margin-right: 40px;
      color: #262626;
      font-family: "Proxima Nova", "Roboto", sans-serif;
    }
    .form-item-name {
      display: inline-block;
      width: 101px;
      text-align: left;
      margin-right: 24px;
    }
    .form-item-content {
      display: inline-block;
      width: 400px;
      min-height: 24px;
      background-color: #fafafa;
      border: 1px solid #d9d9d9;
      border-radius: 7px;
      padding: 4px 5px;
      margin-bottom: 12px;
      margin-right: 20px;
      span,
      .span-cross-seller-text {
        margin-right: 4px;
        padding: 1px 8px;
        border: 1px solid #e2eeec;
        border-radius: 2px;
        background-color: white;
      }
      .span-cross-seller-text {
        margin-bottom: 2px;
        display: block;
      }
    }
    .center-key-benefits {
      //height: 221px;
      font-family: "Proxima Nova", "Roboto", sans-serif;
      .key-title {
        font-size: 14px;
        margin-bottom: 8px;
        color: #262626;
        i {
          font-size: 12px;
          color: #bfbfbf;
        }
      }
      ul li {
        display: flex;
        justify-content: space-between;
        width: 100%;
        //height: 80px;
        //line-height: 16px;
        //list-style-type: disc;
        word-break: break-word;
        margin-bottom: 40px;
        -webkit-line-clamp: 5;
        i {
          display: inline-block;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: #000000;
          margin-right: 4px;
          margin-top: 5px;
        }
        p {
          //  display: inline-block;
          width: 381px;
          font-size: 14px;
          color: #262626;
          font-family: "Proxima Nova", "Roboto", sans-serif;
          margin-bottom: 0;
          //  color: #000000;
          //  font-family: "Proxima Nova", "Roboto", sans-serif;
        }
      }
    }
  }
  .demo-info-tips-bottom {
    .textarea-bottom {
      //width: 392px;
      display: flex;
      justify-content: start;
    }
    .center-demo-textarea {
      width: 400px;
      margin-right: 40px;
      font-family: "Proxima Nova", "Roboto", sans-serif;
      position: relative;
    }
    .tip-content-style {
      width: 392px;
      min-height: 96px;
      font-size: 14px;
      color: #262626;
      border: 1px solid #d9d9d9;
      padding: 8px 9px;
      font-family: "Proxima Nova", "Roboto", sans-serif;
      margin-bottom: 16px;
      //word-break: break-word;
      //-webkit-line-clamp: 4;
    }
    .demo-tip-style {
      font-size: 14px;
      margin-bottom: 8px;
      color: #000000;
    }
    .demo-tip-content {
      width: 400px;
      position: relative;
    }
  }
  .close-dialog {
    text-align: right;
    margin-top: 20px;
    cursor: pointer;
    p {
      display: inline-block;
      width: 160px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      color: #ffffff;
      background: #f27c59;
      border-radius: 7px;
    }
  }
}
.ant-switch-checked {
  background-color: rgba(243, 202, 80, 1);
}
