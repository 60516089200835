
.setting-terms-new__main {
  .transaction-style {
    font-size: 28px;
    color: #000000;
    font-weight: 600;
    font-family: "Proxima Nova", "Roboto", sans-serif;
  }
  .admin-orders__table-head {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
    font-family: "Proxima Nova", "Roboto", sans-serif;
    //border-bottom: 1px solid #8c8c8c;
    //cursor: pointer;
  }
  .admin-orders__table-header {
    tr {
      border-bottom: 1px solid #8c8c8c;
    }
  }
  .admin-orders__table-cell {
    color: #262626;
    font-size: 14px;
    font-weight: 400;
    //border-bottom: 1px solid #8c8c8c;
    margin-top: 10px;
    position: relative;
    a {
      color: #333333;
    }
  }
}

.spin-style-admin {
  text-align: center;
  margin: 40px 0;
}
