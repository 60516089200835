
.img-video-carousel__main {
  height: 221px;
  display: flex;
  justify-content: space-between;
  .img-and-video-list {
    width: 35px;
    height: 222px;
    .item-img-info {
      width: 35px;
      height: 35px;
      border: 0.5px solid #d9d9d9;
      border-radius: 2px;
      margin-bottom: 10px;
      position: relative;
      cursor: pointer;
      .img-asset-style {
        width: 100%;
        height: 100%;
      }
    }
  }
  .show-big-img {
    width: 340px;
    height: 261px;
    text-align: center;
    margin-right: 50px;
    img {
      height: 261px;
      width: auto;
    }
  }
  .public-start {
    width: 15px !important;
    position: absolute;
    top: 8px;
    left: 10px;
    //height: 15px;
  }
}
