
.email-preferences
  &__heading
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    color: #000000
    font-size: 18px
    letter-spacing: 0.32px
    line-height: 24px
    align-items: center

  &__sub-heading
    font-family: Proxima Nova
    font-style: normal
    color: #262626
    font-size: 24px
    font-weight: bold
    letter-spacing: 0.25px
    line-height: 38px
    margin-bottom: 10px
    align-items: center
    margin-top: 10px

  &__checkbox-margin
      margin-top: 0px !important
      padding-top: 0px !important
      margin-left: 0px !important
      margin-bottom: 6px !important
      display: block !important
      font-family: Proxima Nova
      font-style: normal
      font-weight: normal
      font-size: 16px !important
      line-height: 24px
      color: #262626 !important


  &__sub-heading-container
    display: flex
    align-items: center
    margin-bottom: 15px

  &__preferences
    display: flex
    align-items: center
    cursor: pointer

  &__icon
    margin-right: 8px
    cursor: pointer

  &__preference-name
    color: #000000
    font-size: 14px
    margin-bottom: 5px

  &__save-button
    height: 25px
    width: 90px
    margin-top: 25px

  &__icon-checked
    height: 21px
    width: 22px

  &__select-placeholder
    position: relative
    padding-left: 20px
    bottom: 29px
    color: #b4b4b4
    font-size: 14px
    width: 90px

  &__select-box
    width: 200px !important
    margin: 0px 15px

.disable-checkbox
  opacity: .5
  pointer-events: none
.theme--light.v-icon
    color: #4A8A5D !important

.max-width-content
    width: fit-content
    width: -moz-fit-content
.ant-checkbox-checked .ant-checkbox-inner
  background-color: #4a8a5d !important
  border-color: #4a8a5d !important

