
.retailer-training-brand-tab {
  p {
    margin-bottom: 0;
  }
  .retailer-training-search {
    display: flex;
    .search-style {
      display: flex;
      flex-direction: column;
    }
    .search-input {
      width: 350px;
      margin-right: 15px;
      p {
        margin-bottom: 10px;
      }
    }
    .search-resent {
      width: 400px;
      p {
        margin-bottom: 10px;
      }
    }
  }
  .retailer-brand-training-table {
    width: 1200px;
    margin-top: 30px;
    //position: relative;
    .training-table-style {
      overflow: visible;
      width: 100%;
      color: #262626;
      //border: #e8e8e8 solid 1px;
      border-collapse: collapse;
    }
    .training-table-style tr {
      width: 100%;
      position: relative;
    }
    .training-table-style th {
      border-top: #e8e8e8 solid 1px;
      border-bottom: #e8e8e8 solid 1px;
      padding: 16px;
      //background-color: #fafafa;
      font-size: 14px;
      font-weight: normal;
    }
    .training-table-style td {
      height: 56px;
      border-bottom: #e8e8e8 solid 1px;
      padding: 17px 16px;
      background-color: #ffffff;
      color: #595959;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .training-table-style tr,
    .training-table-style td.move:hover {
      //cursor: pointer;
    }
    .training-table-style td.click-pro-action:hover {
      cursor: pointer;
    }
    .name-tab-style {
      position: relative;
      span {
        position: absolute;
        right: 16px;
        top: 15px;
      }
    }
    .data-tab-style {
      //position: relative;
    }
    .link-click-style:hover {
      color: #4a8a5d;
      text-decoration: underline;
      cursor: pointer;
    }
    .editable-row-operations {
      span:hover {
        color: #4a8a5d;
        text-decoration: underline;
      }
    }
    .editable-row-operations-share {
      width: 130px;
      text-align: left;
      padding: 3px 4px;
      //background-color: #4a8a5d;
      color: #4a8a5d;
      border-radius: 5px;
    }
  }
  .no-table-data {
    width: 100%;
    height: 270px;
    text-align: center;
    color: #bfbfbf;
    font-size: 16px;
    padding-top: 80px;
    position: relative;
    left: 350px;
  }
  .ant-tag {
    margin-bottom: 3px;
  }
  .icon-sort-style {
    color: #8c8c8c;
  }
}
