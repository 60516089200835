
.brand-settings-all
  width: 1200px
  margin: 0 auto
  padding-top: 45px
  min-height: 780px !important
  &__top-title
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 18px
    line-height: 24px
    color: #262626
    margin-left: 1px
  &__row
    margin-top: 13px
.brand-settings-left
  float: left
  width: 340px !important
  margin-right: 25px
  background: #ffffff
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15)
  border-radius: 5px
  &__menu
    border-top: 1px solid #d9d9d9

.no-margin
  margin-bottom: 0px !important
.padding-small
  padding: 0px 0px !important
.brand-settings-modal
  padding: 0px 0px !important
.brand-settings-modal .ant-modal-content
  border-radius: 0px !important
.column .is-2 .brand-settings__links
  border-right: 1px solid #ddd

.brand-settings-wireinstruction
    .myAccount-title
        margin-bottom: 24px !important

.wireinstruction__form-block
    .row-section
        .column-section
            width: 320px
            label
                display: inline-block
            .email-text
                padding-left: 18px

            input
                &:focus
                    box-shadow: none
                    border: 1px solid #cfcece

            .optional
                display: inline-block
                color: #9B9B9B
                font-size: 14px
                font-weight: 300
                padding-left: 16px
            .control
                .brand-settings__account-information_save-button
                    margin-top: 24px
                    height: 30px
                    font-size: 14px
.align-center
    align-items: center
.brand-settings__links
    color: #7f7f7f
    font-size: 14px
    padding-top: 68px
    border-right: 1px solid #ddd
    min-height: 70vh !important

.brand-line
    display: flex
    flex-direction: column

.brand-not-approved
    margin-top: 24px

.override-link
    cursor: not-allowed
    color: gray
    display: none
.brand-line__main-block
    width: 100%
    display: flex
    padding: 10px 0px 8px 35px
    border-bottom: 1px solid #ddd
    min-height: 50px

.brand-settings__account-information_text
  margin-bottom: 0px !important
  text-align: left

.brand-line__left-block
    padding: 0px !important
    display: flex
    align-items: center

    .brand-settings__account-information_text
        font-size: 14px
        font-weight: 600
        color: #333

.brand-line__right-block
    padding: 0px !important
    display: flex
    align-items: center

    .brand-settings__account-information_text
        font-size: 14px
        font-weight: 300
        color: #333


.brand-settings__account-information
    width: 100%
    padding-top: 68px

    .brand-line
        :last-child
            border-bottom: none

.brand-settings__account-information
    width: 100%
    padding-top: 68px

    .brand-line
        :last-child
            border-bottom: none

.brand-settings__wrap
  height: 100%
.upgrade-button
  background-color: #000 !important
  border-color: transparent
  color: white !important
  min-width: 80px
  height: 30px
  font-size: 14px
  font-weight: 600
.brand-settings
  .box-button
    left: 30px
    background-color: #000
    border-color: transparent
    color: white
    width: 145px
    height: 22px


    .overridesLink
        padding-left: 22px
        color: #161616
        font-weight: 300
        text-decoration: underline !important
    .members
        margin-right: -310px
        border-top: 1px solid #ebeff2

  .titleText
    .textfont
      font-size: 14px

    &__form-section
        margin-top: 36px

    .myAccount-title
        margin-top: -26px
    .password-title
        margin-top: -16px

  &__title
    color: #000000
    font-size: 18px
    letter-spacing: 0.32px
    line-height: 18px
    padding-bottom: 16px
  &__first-row
    border-top: 1px solid #ddd

    &__links
        margin-right: 60px

    &__account-information

        &_edit-column
            display: grid

        &_text
            margin-bottom: 32px
            color: #000000
            font-size: 16px
            letter-spacing: 0.25px
            line-height: 18px

        &_edit-button
            color: #161616
            font-size: 16px
            font-weight: 300
            letter-spacing: 0.16px
            line-height: 18px
            text-decoration: underline
            cursor: pointer

    &__link
        color: #7f7f7f
        font-size: 16px
        letter-spacing: 0.25px
        line-height: 18px
        cursor: pointer
        margin-bottom: 16px

    &__saved-addresses-button
        height: 40px
        width: 138px
        font-size: 14px
        font-weight: 500
        letter-spacing: 0.7px
        line-height: 18px

    &__saved-addresses_boxes
        margin-right: 40px

    &__saved-addresses_box
        border: 2px solid #E5E5E5
        border-radius: 4px
        background-color: #FFFFFF
        height: 264px
        width: 264px
        padding: 25px
        &:not(:last-child)
            margin-right: 24px

        &_title
            color: #292929
            font-size: 16px
            letter-spacing: 0.25px
            line-height: 18px
            margin-bottom: 10px

        &__buttons
            margin-top: 63px

            &_remove-button
                text-align: end

    &__payment-information

        &-title
            color: #000000
            font-size: 16px
            font-weight: bold
            letter-spacing: 0.13px
            margin-bottom: 4px
            text-transform: capitalize

        &-name
            color: #000000
            font-size: 16px
            letter-spacing: 0.13px
            line-height: 22px
            margin-bottom: 24px
            text-transform: capitalize

    &__terms

        &-title
            color: #000000
            font-size: 16px
            font-weight: bold
            letter-spacing: 0.13px
            padding-bottom: 10px

        &-name
            color: #000000
            font-size: 14px
            padding-bottom: 25px
            padding-left: 10px

.brand-footer
  border-top: 1px solid #ddd
.subscribeActionCntnr
  justify-content: flex-end
.subscription__form-block
  .column-section
    width: 320px
    margin-bottom: 24px
    margin-left: 24px
    margin-top: 24px
    label
      margin-bottom: 8px
      color: #000000
      font-size: 14px
      font-weight: 400
.table-title
  font-weight: 600
  font-size: 14px
  color: #333
  line-height: 18px
  letter-spacing: 0.25px
.table-content
  font-weight: 300
  font-size: 14px
  color: #333
  line-height: 18px
  letter-spacing: 0.25px
.modal-actions
  justify-content: flex-end
.modal-content
  width: 100%
  padding: 24px
.modal-radio
  display: block !important
  height: 30px
  lineHeight: 30px !important
.row-margin-bottom
  margin-bottom: 6px
.commission-input
  height: 40px !important
.history-list
  //width: 70% !important
// .ant-input-number
//   width: 150px !important
.change-plan-title
  font-size: 14px !important
  font-weight: 400 !important
  margin-bottom: 8px !important
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected
  background-color: #4a8a5d !important
  //color: white
  border-right: none !important
.ant-menu-item-selected > a, .ant-menu-item-selected > a:hover,
.ant-menu-item-active > a, .ant-menu-item-active > a:hover
  color: white !important
.ant-menu-item-active
  background-color: rgba(74, 138, 93, 0.51) !important
.ant-menu-submenu-selected
  color: #4a8a5d !important
