
.prodt-info
   min-height: 80vh
   padding: 0 20px 0 30px
.brand-product-card__btn-layer
  width: 100%
.brand-product-card__btn-img
  width: 17px
  height: 17px
  margin-left: 6px
.brand-product-card__back-btn
  background: #DEE5EF
  border-radius: 2px
  width: 88px
  height: 30px
  font-family: Proxima Nova
  font-style: normal
  font-weight: normal
  font-size: 14px
  line-height: 22px
  color: #000000
  float: right
.brand-product-card
  .brand-style
  &__unpublished-warning-wrapper
    border-radius: 6px
    background-color: #fcf4f1
    margin-top: 25px
    margin-bottom: 10px
    padding: 5px

    img
      padding: 3px 10px

  &__unpublished-warning
    width: 228px
    color: #212B36
    font-size: 16px
    line-height: 24px

.brand-product-card__details-header
  margin-top: 24px
  font-size: 18px
  letter-spacing: 0.25px
  color: #000

.brand-product-card__body-info-title
  letter-spacing: 0.15px
  font-size: 16px
  color: #000
  margin-top: 27px

.brand-product-card__body-description::before
  display: block

.brand-product-card__body-description
  padding-left: 20px
  padding-bottom: 24px
  padding-top: 16px
  color: #4A4A4A
  max-width: 1100px
  white-space: pre-wrap
  overflow-wrap: break-word

.brand-product-card__body-info
  text-align: justify
  border-bottom: 1px solid #E5E5E5

.brand-product-card__body-info:nth-child(4)
  border-bottom: none

.brand-product-card
  padding-top: 40px
  padding-bottom: 60px

  .brand-product-card__thumb-container
    height: 64px
    width: 64px
    display: flex
    justify-content: center
    vertical-align: center
    margin-bottom: 10px

  &__thumb
    margin-bottom: 15px
    cursor: pointer
    height: 100%

    &_active
      border: 2px solid #000000
      border-radius: 2px

  &__breadcrumb
    display: inline

    li
      display: inline
      color: #000000
      font-size: 14px
      font-weight: 300
      letter-spacing: 0.16px

  &__details
    border: 1px solid #e5e5e5
    border-radius: 4px
    max-width: 384px
    padding: 25px 23px 10px 23px
    position: relative

    &-brand
      font-size: 16px
      letter-spacing: 0.15px
      color: #000
      padding-top: 10px

    &-size
      font-size: 18px
      letter-spacing: 0.25px
      color: #000

    &-name
      font-size: 18px
      letter-spacing: 0.25px
      color: #000
      margin-top: 8px

    &-sku
      font-size: 16px
      letter-spacing: 0.4px
      line-height: 22px
      color: #767676
      margin-top: 4px

    &-msrp
      color: #B3B3B3
      font-size: 16px
      letter-spacing: 0.15px
      line-height: 22px
      margin-top: 4px

    &-margin
      color: #AAE4D4
      font-size: 20px

    &-price
      color: #292929
      font-size: 20px
      line-height: 24px
      line-height: 24px
      margin-top: 7px

      &-info
        color: black
        font-size: 14px
        letter-spacing: 0.25px
        line-height: 18px

    &-country
      font-size: 14px
      letter-spacing: 0.25px
      margin-top: 30px

    &-sample
      color: #9B9B9B
      font-size: 14px
      letter-spacing: 0.25px
      line-height: 18px
      text-transform: uppercase
      margin-top: 23px

    &-upcoming
      color: #9B9B9B
      font-size: 14px
      letter-spacing: 0.25px
      margin-top: 23px

  &__buttons
    margin: 0 auto
    margin-top: 20px
    margin-bottom: 5px

  &__data-sheet-link
    text-decoration: underline
    color: #161616
    font-size: 14px
    display: inline-block

  &__image
    display: flex
    justify-content: center
    align-items: center
    width: 591px
    height: 591px
    margin-top: -30px

    video
      max-height: 100%
      max-width: 100%

    img
      max-height: 100%
      max-width: 100%

.tag-button
  cursor: default
  background-color: #dbdbdb !important
  color: #494949 !important
  height: 32px

.brand-product-item__dropdown
  position: absolute
  width: 128px
  height: 92px
  top: 10px
  right: 10px
  z-index: 100
  background-color: #ffffff
  border: 1px solid #DADADA

.brand-product-item__dropdown-item
  height: 45px
  display: flex
  align-items: center
  padding-left: 10px
  cursor: pointer

.brand-product-item__dropdown-icon
  padding-right: 15px

.brand-product-item__dropdown-item:hover
  background-color: #F5F6F8

.brand-product-item__remove-button
  color: #BF0711

.brand-product-item__circles
  position: absolute
  top: 15px
  right: 0px
  z-index: 1
  cursor: pointer
  height: 30px
  width: 30px
