
.retailer-shipping-window
  &__edit-form
    text-decoration: underline
    color: #161616
    font-size: 14px
    font-weight: 300
    letter-spacing: 0.16px
    line-height: 18px
    margin-left: 11px

    a
      color: #161616

  &__title
    color: #000000
    font-size: 18px
    letter-spacing: 0.25px
    margin-bottom: 25px

  &__date-name
    color: #888888
    font-size: 12px
    text-transform: uppercase
    margin-bottom: 16px
    font-weight: 500

.shipping-modal
  &__content
    position: relative
    background-color: #FFFFFF
    width: 660px
    height: 520px
    overflow: scroll

  &__close-button
    position: absolute
    top: 24px
    right: 24px
    cursor: pointer
    height: 12.55px
    width: 12.74px

  &__title--centered
    display: flex
    justify-content: center
    font-size: 20px
    font-weight: 600
    padding-top: 70px
    letter-spacing: 0.28px
    padding-bottom: 120px

  &__body--extended
    height: 670px

  &__input-group
    padding-left: 160px
    padding-right: 180px
    padding-bottom: 20px

  &__datepicker-input
    padding-left: 20px

  &__input
    height: 40px
    color: #000000
    font-size: 14px
    font-weight: bold

  &__input::placeholder
    color: #000000 !important
    font-size: 14px
    font-weight: bold

  &__input:focus
    outline: none !important
    border: 2px solid #858585
    box-shadow: none !important

  &__input--selected
    border: 2px solid #858585

  &__calendar-icon
    cursor: pointer
    position: absolute
    right: 15px
    top: 10px

  &__checkbox
    width: 22px
    height: 22px
    border-radius: 50%
    border: 1px solid #979797
    margin-top: 10px
    margin-right: 10px

  &__checkbox-dot
    width: 10px
    height: 10px
    border-radius: 50%
    background-color: #000000
    margin-top: 5px
    margin-left: 5px

  &__save-button
    margin-top: 80px
    height: 36px
    width: 83px
    font-size: 12px
    font-weight: bold

.datepicker-wrapper
   margin-left: -160px
   width: 600px
   z-index: 10
   position: absolute
   background: white
   box-shadow: 0 2px 6px 0 rgba(0,0,0,0.3)
   max-height: 1000px
