
.retailer_card {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 240px;
  // height: 350px;
  border: 1px solid #e8e8e8;
  border-radius: 25px;
  box-shadow: 1px 1px 4px 1px #c9c9c9;
  margin-right: 27px;
  margin-bottom: 32px;
  // box-sizing: border-box;
  padding: 17px 0 24px 0;
  &:hover &_c {
    color: #166ed9;
  }
  &_a {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    .card-tag {
      font-family: SF Pro Display;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 22px;
      color: #4a8a5d;
      border: 1px solid #4a8a5d;
      padding: 0 8px;
    }
  }
  &_b {
    margin: 17px 0 8px;
  }
  &_c {
    font-family: SF Pro Display;
    font-weight: 600;
    font-size: 16px;
    color: #262626;
  }
  &_d {
    height: 66px;
    padding: 0;
    margin-top: 8px;
    margin-bottom: 16px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    white-space: pre-wrap;
    padding: 0 17px;
    text-align: center;
    font-family: SF Pro Display;
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    color: #595959;
  }
  &_e {
    display: flex;
    width: 80%;
    margin: 0 auto;
    position: relative;
    justify-content: center;
    overflow: hidden;
    margin-bottom: 4px;
  }
  &_f {
    font-family: SF Pro Display;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #262626;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &_g {
    margin-top: 16px;
    width: 114px;
    height: 40px;
    //padding: 0 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fafafa;
    border-radius: 2px;
    font-size: 16px;
    background-color: #4a8a5d;
    .svg-icon-style {
      margin-right: 5px;
    }
  }
}
.retailer_card:nth-of-type(2n) {
  margin-right: 0;
}
