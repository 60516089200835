
.ant-btn:last-child
  background: #4A8A5D
  border: 1px solid transparent

.modal-body
  width: 100%
  height: 105px
  padding: 0 24px

  .modal-header
    padding: 24px 0 0 0

  .modal-filter
    margin-bottom: 20px

  .modal-date-select
    border-bottom: 1px solid #e8e8e8

  .modal-body-footer
    padding-top: 24px

  .modal-date-font
    margin-right: 60px
    font-family: 'Poppins-Bold'
    font-size: 15px
    font-weight: 500

.modal-footer
  position: relative

  button:first-child
    position: absolute
    left: 0


.modal-title
  height: 27px

.admin-orders
  padding: 20px 20px 0 30px
  font-family: "Proxima"
  .progress-download-tips
    width: 400px
    height: 100px
    padding: 20px 25px
    text-align: center
    position: fixed
    top: 60px
    left: 35%
    background-color: white
    p
      margin: 0 auto
  .upload-csv-progress-style
    width: 300px
    position: fixed
    top: 100px
    left: 40%

  &__filter
    padding-right: 70px
    padding-top: 10px

  &__table-name
    color: #000000
    font-size: 20px
    font-weight: 700
    letter-spacing: 0.25px
    padding-left: 15px
    margin: 0px !important

  &__table-head
    color: #9E9E9E !important
    font-size: 12px
    font-weight: 500
    border-bottom: 1px solid #E0E0E0 !important
    cursor: pointer

  &__table-cell
    color: #333333 !important
    font-size: 14px
    font-weight: 500
    border-bottom: 1px solid #E0E0E0 !important
    margin-top: 10px
    position: relative
    a
      color: #333333
  .tacking-number-style
    display: inline-block
    width: 200px
    text-overflow: ellipsis
    overflow: hidden
    white-space: nowrap

  &__table-row
    cursor: pointer

  &__filter-column-name
    color: #292929
    font-size: 16px
    font-weight: 300
    margin-bottom: 15px

  &__border
    border-bottom: 1px solid #E0E0E0

  .admin-orders-title
    display: flex
    justify-content: space-between
    align-items: center

    .admin-orders__download
      width: 142px
      height: 42px
      border-radius: 5px
      background: #4A8A5D
      text-align: center
      font-family: Proxima Nova
      font-size: 18px
      font-weight: 400
      line-height: 42px
      color: #ffffff
      cursor: pointer

    .click-btn
      background: rgba(0, 0, 0, .3)
      width: 142px
      height: 42px
      border-radius: 5px
      text-align: center
      font-family: Proxima Nova
      font-size: 18px
      font-weight: 400
      line-height: 42px
      color: #000000
      cursor: not-allowed

.upload-modal
  &__body
    margin-top: 16px

  &__action
    margin-top: 16px
    display: flex

  &__btn
    font-family: "Proxima Nova"
    font-weight: normal
    font-size: 14px
    margin: 0 auto
    padding: 4px 20px
    color: white !important

  &__error-icon
    color: red
    margin-right: 10px

  &__action-btn
    padding: 4px 20px
    border-radius: 2px
    background-color: #4A8A5D
    font-size: 24px
    color: white
    cursor: pointer
    margin-right: 20px
.order-processing-admin-tabs
  margin-bottom: 3px
  margin-top: 100px
  display: flex
  justify-content: space-between
  .admin-tabs-style
    padding-top: 22px
  span
    padding: 4px 18px
    border: 1px solid white
    cursor: pointer
  .tab-active
    border: 1px solid gray
    border-bottom: none

.ant-upload-text
  font-family: "Proxima Nova"

.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon
  color: #4A8A5D

//.ant-upload-list-item-name
  margin-left: 10px !important
  width: 70% !important
.admin-order-top-button
  display: flex
  justify-content: space-between
  text-align: center
  p
    margin-bottom: 0
  .order-upload-admin
    width: 140px
    height: 30px
    cursor: pointer
    line-height: 30px
    background: #FFFAF9
    border: 1px solid #FF6132
    color: #FF6132
    span
      margin-right: 5px
  .shipping-upload-style
    width: 180px
    margin-left: 10px
  .search-order
    margin-left: 10px
  .top-right-button
    display: flex
    justify-content: flex-start
  .csv-style
    width: 150px
    height: 30px
    line-height: 30px
    text-align: center
    border: 1px solid #FF6132
    background-color: #FFFAF9
    color: #FF6132
    cursor: pointer
    position: relative
    margin-right: 10px
    .csv-select
      position: absolute
      top: 30px
      left: -1px
      z-index: 1000
      border: 1px solid #D9D9D9
      background-color: white
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15)
      .download-csv-select-one
        display: block
        color: #000000
        width: 150px
        cursor: pointer

      .download-csv-select-one:hover
        background-color: #FFFAF9
      .download-csv-select-two
        display: block
        color: #000000
        width: 150px
        cursor: pointer

      .download-csv-select-two:hover
        background-color: #FFFAF9
  .top-left-button
    display: flex
    justify-content: flex-start
  .button-icon-style
    display: flex
    align-items: center
    justify-content: center
  .clear-search-admin
    color: #FF6132
    margin-left: 18px
  //.admin-orders__table-name
  //  color: #FF6132
  //  font-size: 14px
  //  width: 172px
  //  height: 30px
  //  line-height: 30px
  //  text-align: center
  //  cursor: pointer
  //  .search-brand-order
  //    border: 1px solid #FF6132
  //    border-radius: 2px
  //    background-color: #FFFAF9
  .can-not-click
    cursor: not-allowed
    background-color: #D9D9D9
    color: white
    border-color: #d9d9d9
  .do-not-click
    cursor: not-allowed
.spin-style-admin
  text-align: center
  margin: 40px 0
//display: flex
//justify-content: center
//align-items: center
//.ant-spin
//  color: #ff6132 !important
//.ant-spin-dot-item
//  background-color: #ff6132 !important
.old-order-table
  position: relative
  .old-order-icon
    position: absolute
    left: -4px
    top: 19px
    color: #1c7430
.table-pagination-style
  text-align: right
  margin-bottom: 30px

.clear-brand-order
  text-decoration: underline
  cursor: pointer
