
.nav-container .menu-items li .ant-badge .ant-badge-multiple-words {
  padding: 0 2px;
}
.nav-container .menu-items li .ant-badge .ant-badge-count {
  background: #ff6132;
  height: 18px;
  min-width: 18px;
  padding: 0 2px;
  line-height: 18px;
}

.menu-items {
  a {
    color: rgb(74, 74, 74);
    svg {
      color: rgb(74, 74, 74);
    }

    .fill {
      svg {
        path {
          fill: rgb(74, 74, 74);
        }
      }
    }
    :not(.fill) {
      svg {
        path {
          stroke: rgb(74, 74, 74);
        }
      }
    }

    .svg-menu-icon {
      display: flex;
      flex-direction: column;
      align-items: center;

      &.fill {
        svg {
          path {
            fill: rgb(74, 74, 74);
          }
        }
      }
    }

    &.router-link-active {
      color: rgb(74, 138, 93);

      svg {
        color: rgb(74, 138, 93);
        path {
          //stroke: rgb(74, 138, 93);
        }
      }

      .fill {
        svg {
          path {
            fill: rgb(74, 138, 93) !important;
          }
        }
      }

      :not(.fill) {
        svg {
          path {
            stroke: rgb(74, 138, 93);
          }
        }
      }
    }

    &[disabled="disabled"] {
      color: rgba(0, 0, 0, 0.25) !important;
      svg {
        color: rgba(0, 0, 0, 0.25) !important;
        path {
          fill: rgba(0, 0, 0, 0.25) !important;
          stroke: rgba(0, 0, 0, 0.25) !important;
        }
      }
    }
  }
}
