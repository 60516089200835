
.admin-training-brand-product {
  font-style: normal;
  font-weight: normal;
  &__hero-product-position {
    padding: 3px 8px;
    color: #ffffff;
    font-size: 12px;
    background-color: #f3ca50;
    border-radius: 20px;
  }
  &__draft-product {
    margin-right: 20px;
    padding: 3px 8px;
    color: #ffffff;
    font-size: 12px;
    background-color: #8c8c8c;
    border-radius: 20px;
    float: right;
  }
  &__title {
    text-align: center;
    color: black;
    font-size: 16px;
  }
  &__action {
    cursor: pointer;
    color: #4a8a5d;
  }
}
.admin-training-brand-product__main {
  .can-not-create-training {
    padding: 15px;
    text-align: left;
    font-size: 15px;
    .title-error-text {
      font-weight: bold;
    }
    .button-style {
      text-align: right;
    }
    .btn-style {
      background: #9fd19a;
      border: 1px solid #9fd19a;
      color: #ffffff;
    }
    .btn-style:hover {
      background: #4a8a5d;
      border: 1px solid #4a8a5d;
      color: #ffffff;
    }
  }
}
