
.chip-panel {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  border-radius: 2px;
  display: flex;
}
.chip-panel > * {
  padding: 6px 8px;
}

.chip {
  background-color: #fff;
  border: 1px solid #4a8a5d;
  white-space: nowrap;
  color: #4a8a5d;
  > * {
    margin-right: 4px;
  }
  > i {
    font-size: 12px;
  }
  &.red {
    border: 1px solid #ff6132;
    color: #ff6132;
  }
  &.green {
    border: 1px solid #4a8a5d;
    color: #4a8a5d;
  }
}

.chip-edit {
  background-color: #fff;
  color: #bfbfbf;
  &.red {
    border: 1px solid #ff6132;
  }
  &.green {
    border: 1px solid #4a8a5d;
  }
}
