@font-face
  font-family: 'Montserrat'
  src: url('~@/assets/fonts/Montserrat-Regular.ttf') format('font-truetype')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'Proxima'
  src: url('~@/assets/fonts/Proxima-Nova-Light.otf') format('opentype')
  font-weight: 300
  font-style: normal
  
@font-face
  font-family: 'Proxima Nova Light'
  src: url('~@/assets/fonts/Proxima-Nova-Light.otf') format('opentype')
  font-weight: 300
  font-style: normal

@font-face
  font-family: 'Proxima-Bold'
  src: url('~@/assets/fonts/Proxima-Bold.otf') format('opentype')

@font-face
  font-family: 'Proxima'
  src: url('~@/assets/fonts/Proxima-Nova-Regular.otf') format('opentype')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'Proxima Nova'
  src: url('~@/assets/fonts/Proxima-Nova-Regular.otf') format('opentype')
  font-weight: normal
  font-style: normal
  
@font-face
  font-family: 'Proxima-Nova-Regular'
  src: url('~@/assets/fonts/Proxima-Nova-Regular.otf') format('opentype')
  font-weight: normal
  font-style: normal
  
@font-face
  font-family: 'Proxima Nova Regular'
  src: url('~@/assets/fonts/Proxima-Nova-Regular.otf') format('opentype')
  font-weight: normal
  font-style: normal
  
@font-face
  font-family: 'ProximaNova Bold'
  src: url('~@/assets/fonts/ProximaNova-Bold.ttf') format('opentype')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'Proxima'
  src: url('~@/assets/fonts/Proxima-Nova-Semibold.otf') format('opentype')
  font-weight: 600
  font-style: normal

@font-face
  font-family: 'Proxima-Nova-Semibold'
  src: url('~@/assets/fonts/Proxima-Nova-Semibold.otf') format('opentype')
  font-weight: 600
  font-style: normal

@font-face
  font-family: 'Poppins'
  src: url('~@/assets/fonts/Poppins-Regular.ttf') format('truetype')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'Poppins'
  src: url('~@/assets/fonts/Poppins-Medium.ttf') format('truetype')
  font-weight: 600
  font-style: normal

@font-face
  font-family: 'Poppins'
  src: url('~@/assets/fonts/Poppins-SemiBold.ttf') format('truetype')
  font-weight: 700
  font-style: normal
  
@font-face
  font-family: 'Poppins SemiBold'
  src: url('~@/assets/fonts/Poppins-SemiBold.ttf') format('truetype')
  font-weight: 700
  font-style: normal

@font-face
  font-family: 'Poppins'
  src: url('~@/assets/fonts/Poppins-ExtraBold.ttf') format('truetype')
  font-weight: 900
  font-style: normal

@font-face
  font-family: 'Playfair Display'
  src: url('~@/assets/fonts/PlayfairDisplay-Regular.ttf') format('truetype')
  font-weight: normal
  font-style: normal
  
@font-face
  font-family: 'Playfair Display Regular'
  src: url('~@/assets/fonts/PlayfairDisplay-Regular.ttf') format('truetype')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'Playfair Display'
  src: url('~@/assets/fonts/PlayfairDisplay-Bold.ttf') format('truetype')
  font-weight: bold
  font-style: normal
  
@font-face
  font-family: 'Playfair Display Bold'
  src: url('~@/assets/fonts/PlayfairDisplay-Bold.ttf') format('truetype')
  font-weight: bold
  font-style: normal
  
@font-face
  font-family: 'Eczar Regular'
  src: url('~@/assets/fonts/Eczar-Regular.ttf') format('truetype')
  font-weight: 300
  font-style: normal
  
@font-face
  font-family: 'Eczar Bold'
  src: url('~@/assets/fonts/Eczar-Bold.ttf') format('truetype')
  font-weight: bold
  font-style: normal
  
@font-face
  font-family: 'Eczar Medium'
  src: url('~@/assets/fonts/Eczar-Medium.ttf') format('truetype')
  font-weight: 300
  font-style: normal

$family-primary: 'Proxima'
$caption: 'Montserrat', sans-serif

@mixin caption
  font-family: 'Montserrat', sans-serif
  text-transform: uppercase
