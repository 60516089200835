
.retailer-order-print
  height: auto
  //padding-top: 40px
  //padding-bottom: 80px
  //padding: 40px 122px 80px 122px

  .retailer-order-status-banner__block
    display: flex
    //justify-content: space-between
    align-items: center
    padding-right: 20px

    .retailer-order-status-banner__left-block
      margin-bottom: 0px

    .retailer-order-status-banner__button-block
      margin-left: auto
      margin-top: 10px
      display: flex
      align-items: center
      flex-wrap: wrap-reverse

      .button
        margin-bottom: 10px

      .button.button
        margin-left: 10px

  .retailer-order-table__container
    .retailer-order-table
      padding: 20px 0px

      .reretailer-order-table__header-container
        .button[disabled]
          cursor: default
        display: flex
        justify-content: space-between

        .add-charge-button
          margin-right: 10px

      .retailer-order-table__cell
        color: #333
        font-size: 14px
        font-family: "Proxima Nova", "Roboto", sans-serif

        .additional-charge
          width: 100%

          select
            width: 100%
            height: 36px

        .amount-input-container
          display: flex
          margin-bottom: 7px

          .input
            width: 70px
            margin-right: 4px

          .additional-charge-dropdown
            .select
              height: 36px

              select
                height: 100%

        .additional-charge-buttons
          width: 129px
          display: flex
          justify-content: flex-end
          padding: 5px 0

          .button[disabled]
            cursor: default

          .save-additional-charge
            margin-right: 10px
            font-size: 14px
            height: 25px
            width: 50px

          .cancel-additional-charge
            font-size: 14px
            height: 25px
            width: 50px
            text-decoration: underline
            color: #4250C7
            border: none

        .retailer-order-quantity-input
          text-align: right !important
          width: 70px

          .retailer-order-input
            width: 74px
            height: 41px
            position: relative
            text-align: center

          .retailer-order-input-plus
            position: absolute
            top: 0px
            right: 0px
            border-left: 1px solid #E5E5E5
            border-bottom: 1px solid #E5E5E5
            height: 21px
            width: 21px
            display: flex
            align-items: center
            justify-content: center
            cursor: pointer

          .retailer-order-input-minus
            position: absolute
            top: 20px
            right: 0px
            border-left: 1px solid #E5E5E5
            height: 21px
            width: 21px
            display: flex
            align-items: center
            justify-content: center
            cursor: pointer

        .product-edit-buttons
          display: flex
          justify-content: flex-start
          padding: 25px 0 0 0

          .button[disabled]
            cursor: default

          .save-additional-charge
            margin-right: 10px
            font-size: 14px
            height: 25px
            width: 50px

          .cancel-additional-charge
            font-size: 14px
            height: 25px
            width: 50px
            text-decoration: underline
            color: #4250C7
            border: none

      .table-border-b-none
        border-bottom: none !important

      .retailer-order-table__cell.subtotal
        font-weight: 700

      .brand-order-table__cell.subtotal
        font-weight: 700

      .total
        padding-top: 30px

        td
          padding: 30px 16px 16px 16px

    .retailer-order-table__cell.table-border-b-none
      border-bottom: none

    .brand-order-table__cell.table-border-b-none.text-right
      text-align: right

    .brand-order-table__cell.table-border-b-none
      border-bottom: none

    .retailer-order-table__cell.table-border-b-none.text-right
      text-align: right

    .retailer-order-table.table-border-t-none
      padding-top: 46px
      margin-top: 10px
  //border-top: none


  &-navigation
    font-size: 14px
    color: #4A90E2

    span:first-child
      padding-right: 20px

  &-id
    padding-top: 10px
    padding-bottom: 10px
    font-size: 28px

  &-table
    border: 1px solid #E0E0E0
    padding: 20px

    &__top
      display: flex

    &__name
      color: #333333
      font-size: 24px
      font-weight: 500
      letter-spacing: 0.25px
      margin-bottom: 30px
      margin-left: 10px

    &__edit
      color: #262626
      margin-left: 30px
      font-size: 14px
      line-height: 35px
      text-decoration: underline
      cursor: pointer

    &__head
      color: #888888 !important
      font-size: 14px
      font-weight: 500
      border-bottom: 1px solid #E0E0E0 !important

    &__cell
      color: #333333 !important
      font-size: 14px
      font-weight: 500
      border-bottom: 1px solid #E0E0E0 !important
      margin-top: 10px
      position: relative

    &__container
      margin-right: 20px

  &-information
    border: 1px solid #E0E0E0

    &__title
      color: #333333
      font-size: 16px
      padding-bottom: 20px

    &__container
      padding: 20px
      border-bottom: 1px solid #E0E0E0

    &__link
      color: #006FBB
      font-weight: 600
      font-size: 14px

    &__header
      text-transform: uppercase
      color: #888888
      font-size: 12px
      padding-bottom: 10px

    &__logo
      width: 30px
      height: 30px

    &__terms
      margin-top: 20px

  &-status-banner
    background-color: rgba(74, 138, 93, 0.11)
    //border: 1px solid #E0E0E0
    border-radius: 0 0 3px 3px
    //border-top: 3px solid #9A8A8A
    min-height: 112px
    width: 100%
    margin-bottom: 20px
    padding-left: 20px

    &__title
      padding-top: 4px
      font-size: 16px
      color: #212B36

    &__details
      font-size: 14px
      color: #212B36

    &__icon
      display: flex
      padding-right: 10px

  &-calculations

    &__subtotal
      display: flex
      justify-content: flex-end
      padding-bottom: 20px

      &-amount
        padding-left: 45px

    &__full-subtotal
      display: flex
      justify-content: flex-end
      border-top: 1px solid #E0E0E0
      padding-top: 15px

      &-amount
        padding-left: 45px
        border-top: 1px solid #E0E0E0
        padding-top: 15px

    &__full-subtotal-title
      font-weight: bold
      color: #333333

  .retailer-order-information__container
    .shipping-first-block
      width: 100%

      .hipping-first-block__heading
        display: flex
        align-items: center

        .retailer-order-information__title
          padding-bottom: 0px

        .img-block
          height: 30px
          margin-left: auto

          img
            height: 30px

    .retailer-order-information__header
      color: #888

      .adresss
        padding-top: 16px

        p
          color: #333333
          font_size: 14px

  .order-product-item__dropdown
    position: absolute
    width: 128px
    top: 10px
    right: 10px
    z-index: 100
    background-color: #ffffff
    border: 1px solid #DADADA

  .order-product-item__dropdown-item
    height: 45px
    display: flex
    align-items: center
    padding-left: 10px
    cursor: pointer

  .order-product-item__dropdown-icon
    padding-right: 15px

  .order-product-item__dropdown-item:hover
    background-color: #F5F6F8

  .order-product-item__remove-button
    color: #BF0711

  .order-product-item__circles
    position: absolute
    top: 15px
    right: 0px
    z-index: 1
    cursor: pointer
    height: 30px
    width: 30px

  .alignTextCenter
    text-align: center !important

  .edit-shipment-info
    margin-left: auto
    text-decoration: underline
    color: #333333 !important
    font-size: 14px

  .shipment-provider
    font-size: 14px
    margin-bottom: 17px

  .order-information-subheading
    display: flex

    span
      color: #000000
      font-size: 16px

  .button-new-color
    width: 153px
    background-color: #4a8a5d
    color: white

  .icon-tips-style
    font-size: 44px
    color: #7ACE93

  .total-amount-number
    padding: 20px 25px
    margin-top: 10px
    border: 1px solid #D9D9D9
    font-size: 14px
    font-weight: 700
    font-family: "Proxima Nova", "Roboto", sans-serif
    color: #000000
    text-align: right

    span
      margin-left: 40px

  .edit-params-product
    color: #7ACE93
    font-size: 14px
    margin-bottom: 0

  .edit-params-product-color
    color: #7ACE93

  .additional-charge-tips-text
    margin: 9px 0
    display: flex
    justify-content: flex-start
    align-items: center

  .download-estimated-payment
    font-size: 14px
    text-decoration: underline
    font-weight: normal

  .download-estimated-payment:hover
    color: white
    background-color: #4a8a5d
    padding: 5px 7px
    font-size: 14px
    text-decoration: none
    border-radius: 2px
    font-weight: normal

.delete-dialog-tips-admin
  .ant-btn-primary
    background-color: #ff6132 !important
    border-color: #ff6132 !important

.delete-dialog-tips-brand
  .ant-btn-primary
    background-color: #4a8a5d !important
    border-color: #4a8a5d !important

.delete-dialog-tips-retailer
  .ant-btn-primary
    background-color: #FEC206 !important
    border-color: #FEC206 !important

.sku-style
  width: 160px
.print-button
  text-align: right
  //.retailer-order-print
  //  overfolw-x: hidden
  //  width: 1000px
.retailer-order-status-bar__creation-date
    min-height: 40px
    //padding-top: 20px
    margin-top: -24px
    color: #000000
    font-size: 20px
    font-weight: 300
    //margin-bottom: 30px
    font-family: "Proxima Nova", "Roboto", sans-serif
