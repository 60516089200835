
.admin-message-custom-filters {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  &__dropdown {
    line-height: 32px;
    color: #262626;
    border: 1px solid #4a8a5d;
    border-top: none;
    box-sizing: border-box;
    border-radius: 2px;
    box-shadow: none;
  }
  &__desc {
    line-height: 22px;
    color: #595959;
    padding-top: 6px;
  }
  &__marginTop {
    margin-top: 20px;
  }
  &__date-range-icon {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 40px;
    align-items: center;
    text-align: center;
    color: #000000;
  }
}
.admin-message-custom-filters .ant-select-selection--single {
  height: 36px;
}
.admin-message-custom-filters .ant-select-selection {
  border: 1px solid #4a8a5d;
  box-sizing: border-box;
  border-radius: 2px;
}
.admin-message-custom-filters .admin-message-custom-filters__noTopBorder .ant-select-selection {
  border-top: none;
}
.admin-message-custom-filters .ant-select-selection-selected-value {
  color: #4a8a5d;
}
.admin-message-custom-filters .ant-select-selection:active,
.admin-message-custom-filters .ant-select-open .ant-select-selection,
.admin-message-custom-filters .ant-select-focused .ant-select-selection,
.admin-message-custom-filters .ant-select-selection:hover {
  border-color: #4a8a5d;
  box-shadow: none;
}
.admin-message-custom-filters__dropdown .ant-select-dropdown-menu-item-active,
.admin-message-custom-filters__dropdown
  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  color: #262626;
  background-color: rgba(74, 138, 93, 0.15);
}
.admin-message-custom-filters__marginTop .ant-input {
  border-radius: 2px;
}
.admin-message-custom-filters__marginTop .ant-input:focus,
.admin-message-custom-filters__marginTop .ant-input:hover {
  border-color: #4a8a5d;
  box-shadow: none;
}
.admin-message-custom-filters__marginTop .ant-calendar-picker:hover,
.admin-message-custom-filters__marginTop .ant-calendar-picker:focus {
  border-color: #4a8a5d;
  box-shadow: none;
}
.admin-message-custom-filters__dropdown.ant-select-dropdown.ant-select-dropdown--multiple
  .ant-select-dropdown-menu-item
  .ant-select-selected-icon,
.admin-message-custom-filters__dropdown.ant-select-dropdown.ant-select-dropdown--multiple
  .ant-select-dropdown-menu-item:hover
  .ant-select-selected-icon {
  display: none;
}
