
.edit-product-all-info-modal__main {
  p {
    margin-bottom: 0;
  }
  .is-transparent {
    width: 100%;
    height: 12px;
    opacity: 0;
  }
  .modal-main-edit-product {
    background-color: white;
    padding: 24px;
  }
  .ant-modal-content {
    background-color: rgba(255, 255, 255, 0);
    -webkit-box-shadow: none;
  }
  .title-flex-style {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .date-content-style {
    margin-left: 345px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .date-Flexible {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
  }
  .position-style {
    position: relative;
    .button-style {
      padding: 5px 16px;
      color: white;
      cursor: pointer;
      border-radius: 2px;
      position: absolute;
      top: 0;
      right: 0;
    }
    .button-style-top {
      font-size: 24px;
      padding: 5px 16px;
      color: white;
      cursor: pointer;
      border-radius: 2px;
      position: absolute;
      top: -40px;
      right: -18px;
    }
  }
  .additional-charge-content {
    margin-left: 345px;
  }
}
