
.comment-item {
  width: 100%;
  margin-bottom: 29px;
  &__header {
    height: 100%;
    display: inline-block;
  }
  &__header-avarta {
    position: absolute;
    bottom: 30px;
  }
  &__float-left {
    float: right;
  }
  &__float-right {
    float: left;
  }
  &__message {
    min-height: 80px;
    padding: 20px;
  }
  &__message-left {
    width: 100%;
    border-radius: 12px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }
  &__message-right {
    width: 100%;
    border-radius: 12px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }
  &__message-date {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 22px;
    letter-spacing: 0.13125px;
    color: #c4c4c4;
  }
}
