
.card-number
  &__icon-visa
    background: url("../../../assets/img/card_type.png") no-repeat
    height: 26px
    width: 40px
  &__icon-master
    background: url("../../../assets/img/card_type.png") no-repeat -42px 0px
    height: 26px
    width: 40px
  &__icon-american
    background: url("../../../assets/img/card_type.png") no-repeat -82px 0px
    height: 26px
    width: 40px
  &__icon-discovery
    background: url("../../../assets/img/card_type.png") no-repeat -118px 0px
    height: 26px
    width: 41px
  &__method-card-num
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 22px
    color: #000000
