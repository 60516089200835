
.admin-console-add-header {
  &__title {
    font-family: Proxima Nova;
    font-style: normal;
    font-size: 22px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #262626;
    text-transform: uppercase;
  }
  &__btn {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    background: #4a8a5d;
    color: #fff;
    padding: 0px 8px !important;
    border: 1px solid #4a8a5d !important;
    border-radius: 2px !important;
    margin-right: 12px;
    height: auto !important;
  }
  &__drop-btn {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    background: #4a8a5d;
    color: #fff;
    padding: 0px 20px !important;
    border: 1px solid #4a8a5d !important;
    border-radius: 2px !important;
    margin-right: 12px;
    height: auto !important;
  }
  &__btn-icon {
    vertical-align: middle;
    line-height: normal;
    margin-bottom: 4px;
  }
}
.admin-console-add-header__btn:hover,
.admin-console-add-header__btn:focus,
.admin-console-add-header__btn:active,
.admin-console-add-header__btn.active,
.admin-console-add-header__drop-btn:hover,
.admin-console-add-header__drop-btn:focus,
.admin-console-add-header__drop-btn:active,
.admin-console-add-header__drop-btn.active {
  background-color: #e2eeec;
}
.admin-console-add-header__btn:hover,
.admin-console-add-header__btn:focus,
.admin-console-add-header__drop-btn:hover,
.admin-console-add-header__drop-btn:focus {
  color: #4a8a5d;
}
