
.markAsIcon
    position: absolute !important

.retailer-order-status-bar
    .retailer-order-status-bar__block
        display: flex
        padding-bottom: 45px
        padding-top: 30px

        .order-status-bar
            display: flex
            flex-direction: column
            align-items: self-start
            img
              height: 32px

            span
                width: 100%
                font-size: 14px
                color: #ffffff
                //padding-top: 16px
                text-align: center
                margin-top: -27px

        .order-status-bar.completed
            svg
                .order-status-bar-order-status-bar-inactive
                    fill: #FFFBFA !important

    &__statuses
        max-width: 1180px
        padding-top: 5px
        padding-bottom: 40px
        margin: 0 auto

    &__icon
        img
            margin-bottom: -5px
            margin-right: 3px
            cursor: pointer

    &__creation-date
      min-height: 40px
      //padding-top: 20px
      margin-top: -38px
      color: #000000
      font-size: 20px
      font-weight: 300
      //margin-bottom: 30px
      font-family: "Proxima Nova", "Roboto", sans-serif

    &__line-container
        max-width: 176px

    .disabled
        opacity: 0.6
        pointer-events: none

.order-status-print-and-download
  min-width: 230px
  //float: right
  margin-top: 20px
  display: flex
  justify-content: space-between
  p
    margin-bottom: 0
  //align-items: center
  .retailer-order-status-bar__item
    color: #595959
    font-size: 14px
    padding-left: 4px
    padding-right: 4px
    line-height: 24px
    cursor: pointer
    position: relative
    vertical-align: text-top
    //display: flex
    //align-items: center
    .print-tabs
      background-color: #ffffff
      border: 1px solid #efefef
      display: block
      width: 116px
      position: absolute
      top: 25px
      left: -28px
      z-index: 4
      span
        display: block
        padding-left: 12px
        font-size: 13px
        color: #000000
        font-family: "Proxima Nova", "Roboto", sans-serif
      span:hover
        background-color: #E2EEEC
    .download-tabs-width
      width: 180px
.dropIcon
    position: absolute !important

.add-tracking
    margin-left: 100px
.download-box
  display: flex
  justify-content: flex-end
  .download-content
    margin: 0 8px
    line-height: 24px
    display: flex
    justify-content: flex-start
    align-items: center
    .retailer-order-status-bar__item
      display: flex
      align-items: center
  //.download-tab-style
  //  display: flex
  //  align-items: center
.mask-as-style
  width: 100px
.disalbe-action-text
  color: #e8e8e8
.can-not-click-mark-as
  cursor: not-allowed
  background-color: #D9D9D9
  color: white
.can-not-click-mark-as:hover
  background-color: #595959
