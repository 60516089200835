
.add-retailer-terms-modal__main {
}
.add-new-terms {
  text-decoration: underline;
  cursor: pointer;
}
.apply-button-add-terms {
  text-align: right;
  span {
    padding: 5px 16px;
    color: white;
    cursor: pointer;
  }
}
