
.admin-console-cascade-add {
  &__btn {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    background: #e2eeec !important;
    color: #4a8a5d !important;
    border: 1px solid #4a8a5d !important;
    border-radius: 2px !important;
    margin-right: 12px;
  }
  &__item-btn {
    background: #fff;
    border: 1px solid #4a8a5d;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 6px 8px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #4a8a5d;
    display: inline-block;
  }
  &__disabled-btn {
    background: #f5f5f5 !important;
    border: 1px solid #bfbfbf !important;
    box-sizing: border-box;
    border-radius: 2px !important;
    color: #bfbfbf !important;
  }
  &__second-item-btn {
    background: #fff1f0 !important;
    border: 1px solid #ffa39e !important;
    box-sizing: border-box;
    border-radius: 2px !important;
    padding: 6px 8px !important;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #f5222d;
    display: inline-block;
    margin-bottom: 8px;
  }
  &__third-item-btn {
    background: #fff7e6 !important;
    border: 1px solid #ffd591 !important;
    box-sizing: border-box;
    border-radius: 2px !important;
    padding: 6px 8px !important;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #fa8c16 !important;
    display: inline-block;
    margin-right: 14px;
    margin-bottom: 14px;
  }
  &__divider {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 50px;
  }
  &__second-div {
    background: #fff;
    // box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    border: 1px solid #4a8a5d;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding-top: 12px;
    padding-left: 26px;
    // border-radius: 2px;
    padding-bottom: 8px;
  }
  &__third-div {
    background: #e2eeec;
    // box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    // border-radius: 2px;
    padding: 12px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding-bottom: 10px;
    // margin-top: 11px;
  }
  &__add-new-input {
    background: #ffffff;
    border: 1px solid #4a8a5d !important;
    box-sizing: border-box;
    border-radius: 2px !important;
    padding: 6px 8px !important;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    margin-top: 2px;
    width: 100px !important;
    height: 32px;
  }
  &__edit-input {
    padding: 6px 8px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #bfbfbf;
    border: 1px solid #ff6132;
    box-sizing: border-box;
    border-radius: 2px;
    display: inline-block;
  }
  &__close-icon {
    color: #ff4d4f !important;
    float: right;
    margin-top: -10px;
    margin-left: -10px;
    font-size: 18px;
    z-index: 199;
    position: relative;
  }
  &__undo-icon {
    float: right;
    margin-top: -10px;
    margin-left: -10px;
    font-size: 18px;
    z-index: 199;
    text-align: center;
    position: relative;
  }
}
.admin-console-cascade-add__collapse .ant-collapse-borderless {
  background-color: transparent;
}
.admin-console-cascade-add__collapse .ant-collapse-borderless > .ant-collapse-item {
  border: none;
}
.admin-console-cascade-add__collapse
  .ant-collapse-borderless
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box {
  padding-top: 12px;
}
.admin-console-cascade-add__collapse
  .ant-collapse
  > .ant-collapse-item.ant-collapse-no-arrow
  > .ant-collapse-header {
  padding-left: 30px;
}
.admin-console-cascade-add__collapse .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0px;
}
.admin-console-cascade-add__item-btn[disabled] {
  background: #f5f5f5 !important;
  border: 1px solid #bfbfbf !important;
  box-sizing: border-box;
  border-radius: 2px !important;
  color: #bfbfbf !important;
}
