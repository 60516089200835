
.banner-or-placeholder
    width: 100%
    padding-bottom: 38%
    overflow: hidden
    height: 0px
    background-position: center center
    background-repeat: no-repeat
    background-size: 100% 100%
    background-color: var(--background-color)

    img
        width: 100%
        padding-bottom: 38%
        overflow: hidden
        border-radius: 0 !important

.brand-profile__logo img
    border-radius: 0 !important
    border: 1px #e5e5e5 solid
    webkit-box-shadow: none
    box-shadow: none
