
.brand-message-page {
  width: 100%;
  min-height: 750px !important;
  overflow: hidden;
  display: flex;
  justify-content: center;
  &__outer {
    margin: 0 auto;
  }
  &__search-panel {
    float: left;
    margin-top: 26px;
  }
}
.brand-message-page::after {
  clear: both;
  content: " ";
  display: block;
  width: 0;
  height: 0;
  visibility: hidden;
}
.brand-message-page__main {
  width: 952px;
  margin: 0 auto;
  float: left;
  padding-top: 10px;
  .brand-message-header-box {
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .message-title {
      font-family: "Playfair Display Bold", "Playfair Display";
      font-size: 48px;
      color: #000000;
    }
    .message-title-button {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      p {
        width: 87px;
        height: 30px;
        line-height: 30px;
        border: 1px solid #e3e3e3;
        background-color: #f5f5f5;
        margin-right: 11px;
        text-align: center;
        font-size: 12px;
        margin-bottom: 0;
        cursor: pointer;
      }
    }
  }
  .brand-message-table-box {
    margin-top: 11px;
    .message-table-title {
      display: flex;
      p {
        width: 477px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background-color: #f5f5f5;
        cursor: pointer;
        font-size: 14px;
        color: #000000;
        font-family: "Proxima Nova", "Roboto", sans-serif;
        margin-bottom: 0;
        border-top-right-radius: 2px;
        border-top-left-radius: 2px;
      }
      p:not(:last-child) {
        margin-right: 2px;
      }
      .active-table-title {
        background-color: #e2eeec;
      }
    }
  }
}
