
.hero-product-form__main {
  font-family: "Proxima Nova", "Roboto", sans-serif;

  .ant-form-item {
    margin-bottom: 0;
  }
  .hero-product-form-first {
    display: flex;
    justify-content: space-between;
    .item-category {
      width: 100px;
    }
  }
  .icon-from-tips {
    display: inline-block;
    width: 125px;
    padding-right: 16px;
    font-size: 15px;
    i {
      font-size: 12px;
      color: #bfbfbf;
    }
  }
  .special-tips {
    width: 146px;
    text-align: right;
    color: #000000;
    padding-right: 25px;
    margin-bottom: 5px;
  }
  .category-text {
    display: inline-block;
    width: 379px;
    height: 24px;
    line-height: 24px;
    padding-left: 8px;
    background-color: #fafafa;
  }
  .upload-icon-tip {
    padding-right: 6px;
    i {
      font-size: 12px;
      color: #bfbfbf;
    }
  }
  .ant-switch-checked {
    background-color: #4a8a5d;
  }

  .upload-content {
    width: 100%;
    text-align: right;
    margin: 10px 0;
  }

  .upload-video-style {
    width: 215px;
    border: 1px solid #4a8a5d;
    color: #4a8a5d;
    background-color: #e2eeec;
    text-align: center;
  }

  .ant-form-horizontal .ant-form-item .ant-form-item label {
    //padding-right: 16px !important;
  }

  .ant-select-selection:hover {
    border-color: #4a8a5d;
  }

  .ant-select-open .ant-select-selection {
    border-color: #4a8a5d;
  }

  .ant-btn:hover,
  .ant-btn:focus {
    color: #4a8a5d;
    border-color: #4a8a5d;
  }
}
