
.categories-filter
  padding-top: 10px
  padding-bottom: 10px

.categories-filter__item--title
  cursor: pointer
  font-size: 14px
  font-weight: 300
  &:hover
    font-weight: 700
