
.heading1 {
  font-weight: 800;
}
.fragrance-header {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}
.fragrance-sub-header {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
.ant-form-item-label > label {
  color: rgba(0, 0, 0, 0.65) !important;
  font-weight: 800;
}

.subheader-padding {
  padding-bottom: 8px;
}
.padding-bottom {
  padding-top: 8px;
  padding-bottom: 15px;
}
.proTipTxtArea {
  min-height: 80px !important;
  height: 50px !important;
}

.product-training-edit-add-dialog__main {
  .adminProductTrainingDialog .ant-modal-footer .ant-btn:hover {
    color: #4a8a5d;
    border-color: #4a8a5d;
  }
  .adminProductTrainingDialog .ant-modal-footer .ant-btn-primary {
    background-color: #4a8a5d;
    border-color: #4a8a5d;
  }
  .adminProductTrainingDialog .ant-modal-footer .ant-btn-primary:hover {
    color: white;
  }
  .adminProductTrainingDialog .ant-select-selection:hover {
    border-color: #4a8a5d;
  }
  .adminProductTrainingDialog .ant-input:hover {
    border-color: #4a8a5d;
  }
  // .ant-switch-checked {
  //   background-color: #4a8a5d !important;
  // }
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    color: #4a8a5d;
  }
  // .ant-tabs-nav .ant-tabs-tab:hover {
  //   color: #4a8a5d !important;
  // }
  .product-training-button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    text-align: center;
    margin-top: 20px;
    .training-save-draft-button {
      width: 160px;
      height: 30px;
      line-height: 30px;
      border-radius: 7px;
      background-color: #8c8c8c;
      color: white;
      cursor: pointer;
    }
    .training-cancel {
      width: 160px;
      height: 30px;
      line-height: 30px;
      border-radius: 7px;
      background-color: #f27c59;
      color: white;
      margin: 0 13px;
      cursor: pointer;
    }
    .training-public-button {
      width: 160px;
      height: 30px;
      line-height: 30px;
      border-radius: 7px;
      background-color: #a0d29b;
      color: white;
      cursor: pointer;
    }
  }
  .training-product-edit {
    &__number-text {
      // float: right;
      display: flex;
      justify-content: flex-end;
      font-size: 10px;
      color: #262626;
      line-height: 1;
      padding-right: 2px;
    }
    &__row-margin {
      margin-bottom: 10px;
    }
  }
  .detail-video-info {
    display: inline-block;
    display: flex;
    justify-content: start;
    align-items: center;
    .video-style {
      width: 69px;
      height: 65px;
      margin-top: 16px;
      text-align: center;
      border: 1px solid #262626;
      cursor: pointer;
      position: relative;
      .video-img {
        //width: 100%;
        height: 100%;
      }
      .public-start {
        position: absolute;
        top: 17px;
        right: 14px;
        border-radius: 50%;
      }
      .background-style {
        width: 43px;
        height: 43px;
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 50%;
        top: 34px;
        right: 83px;
      }
    }
    .add-second-video {
      width: 69px;
      height: 65px;
      //border: 1px solid #d9d9d9;
    }
  }
  .upload-video-style {
    width: 140px;
    border: 1px solid #4a8a5d;
    color: #4a8a5d;
    background-color: #e2eeec;
    text-align: center;
  }
  .upload-success {
    width: 140px;
    border: 1px solid #4a8a5d;
    color: #ffffff;
    background-color: #4a8a5d;
    text-align: center;
  }
  .key-benefits-info-style {
    margin-top: 46px;
    // height: 202px;
  }
  #benefits {
    list-style: disc;
    list-style-position: outside;
    padding: 10px 20px;
    max-height: 300px;
    overflow-y: auto;
  }
  #benefits li {
    padding: 10px 0;
  }
  //#benefits li {
  //  float: left;
  //}
  .ant-switch-checked {
    background-color: rgba(243, 202, 80, 1);
  }
  .vide-image-content-two {
    width: 100%;
    display: flex;
    justify-content: start;
    //align-items: center;
    .video-content-left {
      width: 49%;
    }
    .image-content-right {
      margin-left: 2%;
    }
    .content-two-style {
      display: flex;
    }
    .video-style-main {
      width: 69px;
      height: 65px;
      border-radius: 7px;
      border: 1px solid #d9d9d9;
      margin-right: 15px;
      cursor: pointer;
    }
    .no-image-style {
      border-color: red;
      color: red;
    }
    .no-video-style {
      text-align: center;
      line-height: 56px;
    }
    .has-video-style {
      position: relative;
    }
    .retailer-icon-style {
      font-size: 36px;
      color: #d9d9d9;
    }
    .public-start {
      position: absolute;
      top: 22px;
      right: 22px;
      border-radius: 50%;
    }
    .close-icon {
      font-size: 10px;
      position: absolute;
      top: -17px;
      right: 0;
      color: #8c8c8c;
      z-index: 999;
    }
    .ant-select-selection::v-deep .anticon-loading {
      color: #0592f2;
    }
    :root .ant-select-arrow {
      color: #0592f2;
    }
  }
}
