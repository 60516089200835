
.cancel-subscription {
  font-family: Proxima Nova;
  font-style: normal;
  &__title {
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #595959;
    margin-top: 27px;
    margin-bottom: 10px;
  }
  &__content {
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    color: #8c8c8c;
    margin-top: 20px;
    margin-bottom: 22px;
  }
  &__action {
    margin-bottom: 8px;
    margin-top: 10px;
  }
  &__confirm_action {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #4a8a5d;
    padding: 6px 12px;
    border: 1px solid #4a8a5d;
    cursor: pointer;
  }
  &__cancel_action {
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ff6132;
    text-align: right;
    cursor: pointer;
  }
}
