
.retailer-checkout
  padding-bottom: 40px
  padding-left: 122px
  padding-right: 122px

  &__title
    color: #212B36
    font-family: "Proxima"
    font-size: 28px
    font-weight: 300
    margin: 16px 0

  .order-details
    color: #000000
    font-size: 14px
    letter-spacing: 0.25px
    padding: 25px
    border: 1px solid #E5E5E5
    border-radius: 4px

    &__dates-container
      margin-top: 10px

    &__title
      color: #000000
      font-size: 18px
      letter-spacing: 0.25px
      margin-bottom: 24px

    &__subtitle
      font-size: 18px
      font-weight: 500 !important

    &__tip
      color: #8D8D8D
      padding-top: 5px

    &__shipping-address
      color: #000000
      font-size: 14px
      letter-spacing: 0.25px

    &__key-dates
      &--cancel
        padding-top: 20px

    &__terms-title
      color: #000000
      font-size: 18px
      letter-spacing: 0.25px
      margin-bottom: 15px

    &__terms-subtitle
      font-size: 12px
      color: #888888
      text-transform: uppercase
      margin-bottom: 15px

    &__reference
      max-width: 250px

    &__square-box
      box-sizing: border-box
      height: 222px
      width: 224px
      border: 1px solid #9B9B9B
      border-radius: 4px
      margin-right: 16px
      margin-top: 25px
      margin-bottom: 23px
      display: flex
      justify-content: center
      align-items: center

    &_plus
      font-size: 70px
      text-align: center
      margin-bottom: -30px
      margin-top: -30px

    &__dates
      margin-right: 26px

.right-padding
  padding-right: 5px

.retailer-terms-edit
  margin-left: 52px
  font-size: 14px
  color: #262626
  text-decoration: underline
  cursor: pointer

.left-padding
  padding-left: 10px

.order-summary
  border: 1px solid #E5E5E5
  margin-left: 25px

  &__title
    color: #000000
    font-size: 18px
    letter-spacing: 0.25px
    line-height: 24px
    padding: 24px 23px

  &__brand-order
    display: flex
    justify-content: space-between
    height: 44px
    align-items: center
    margin: 0px 19px
    border-top: 1px solid #E5E5E5
    margin: 0px 16px

  &__submit-button-container
    margin-left: 25px
    margin-top: 15px

  &__submit-button
    height: 46px
    font-size: 12px

.order-items
  &__container
    border: 1px solid #E6E6E6

  &__banner
    height: 80px
    background-color: #F4F6F8
    position: relative
    border-top: 3px solid rgba(145,158,171,0.54)

  &__brand-name
    margin: 30px 10px 30px 25px
    color: #333333
    font-size: 16px

  &__brand-amount
    margin: 30px 0px 30px
    color: #AAAAAA
    font-size: 16px

  &__title
    font-size: 18px
    padding-left: 20px
    padding-bottom: 20px
    font-weight: 500
    color: #000000

  &__header
    padding-top: 20px

    &--margin
      margin-left: 30px

  &__body
    min-height: 110px
    padding-top: 20px
    padding-bottom: 20px
    border-top: 1px solid #E6E6E6

  &__image
    margin-left: 10px
    display: flex
    justify-content: center

    img
      max-width: 74px
      max-height: 74px

  &__name
    font-size: 14px

  &__details
    font-size: 14px
    color: #9B9B9B
    margin-top: 8px

  &__quantity
    margin-left: 10px

  &__quantity-type
    margin-bottom: 20px

.additional-charges
  margin-top: 10px
  border: 1px solid #E6E6E6
  font-size: 14px

  &__title
    padding: 20px
    font-size: 16px
    color: #333333

  &__row
    border-top: 1px solid #E6E6E6
    padding: 15px

  &__header
    padding: 15px

  &__header-cell
    color: #888888 !important

  &__subtotal
    border-top: 1px solid #E6E6E6
    padding: 60px 0px 10px

  &__subtotal-item
    margin-left: 55px

  &__subtotal-item--bold
    font-weight: bold
    color: #000000
.retailer-terms-total-style
  font-size: 16px
  color: #333333
