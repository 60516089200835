
.ant-modal-body {
  .edit-video-content {
    text-align: center;
    margin: 44px 166px 24px 193px;
    .video-title-style {
      display: inline-block;
      width: 80px;
      font-size: 16px;
      font-weight: bold;
      color: #262626;
      font-family: "Proxima Nova", "Roboto", sans-serif;
      margin-right: 24px;
    }
    .upload-status {
      width: 182px;
      height: 40px;
      line-height: 37px;
      border: 1px solid #4a8a5d;
      border-radius: 4px;
      color: #4a8a5d;
      background: #e2eeec;
      //margin: 44px 228px 24px 298px;
      margin: 0 0 24px 105px;
      .status-icon-style {
        color: #4a8a5d;
      }
    }
    .text-content {
      margin-left: -29px;
      p {
        width: 100%;
        margin-bottom: 0;
      }
      //margin: 24px 218px 24px 194px;
    }
    .upload-message {
      display: flex;
      //justify-content: space-between;
      font-size: 10px;
      //transition: .625;
      transform: scale(0.83);
      .message-title-style {
        display: inline-block;
        width: 100px;
        margin-right: 24px;
        text-align: right;
      }
      .message-content-style {
        display: inline-block;
        width: 233px;
        text-align: left;
      }
    }
    .upload-btn-bottom {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 18px;
    }
    .replace-btn {
      width: 106px;
      text-align: center;
      height: 22px;
      line-height: 18px;
      color: #4a8a5d;
      border: 1px solid #4a8a5d;
      border-radius: 2px;
      margin-left: 105px;
      font-size: 14px;
    }
    .replace-btn:hover {
      background-color: #4a8a5d;
      color: white;
      cursor: pointer;
    }
    .save-btn {
      width: 47px;
      text-align: center;
      height: 22px;
      line-height: 22px;
      background-color: #4a8a5d;
      color: white;
      border-radius: 2px;
      margin-left: 86px;
      font-size: 14px;
    }
    .save-btn:hover {
      color: #4a8a5d;
      border: 1px solid #4a8a5d;
      background-color: #ffffff;
      cursor: pointer;
    }
    input {
      width: 244px;
    }
  }
  .upload-button {
    width: 243px;
    margin-left: 4px;
    height: 156px;
    span {
      position: relative;
      color: #b21f2d;
      cursor: pointer;
      background-color: white;
      .close-icon-btn {
        position: absolute;
        right: -126px;
        top: -164px;
        font-size: 16px;
      }
    }
  }
  .upload-box-style {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 5px;
  }
  .upload-style-box {
    width: 227px;
    height: 130px;
    text-align: center;
    padding-top: 42px;
  }
  .upload-img-style-box {
    width: 227px;
    height: 130px;
    text-align: center;
    img {
      //width: 100%;
      height: 100%;
    }
  }
  .ant-upload-picture-card-wrapper {
    width: auto;
  }
  .ant-upload .ant-upload-select-picture-card {
    width: 244px;
    height: 138px;
    margin: 0;
    padding-top: 42px;
    display: none;
  }
  .error-text-style {
    font-size: 10px;
    width: 100%;
    height: 20px;
    color: #ff6132;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .error-text-icon {
    width: 20px;
    height: 20px;
    line-height: 20px;
    color: #ff6132;
    margin-right: 24px;
  }
}
