
.admin-retailer-type-dialog {
  &__save-btn {
    background-color: #000 !important;
    border-color: transparent;
    color: #fff !important;
    justify-content: center;
    padding-bottom: calc(0.375em - 1px);
    padding-left: 0.75em;
    padding-right: 0.75em;
    padding-top: calc(0.375em - 1px);
    text-align: center;
    white-space: nowrap;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 0;
    -webkit-appearance: none;
    -webkit-box-align: center;
    display: inline-flex;
    font-size: 1rem;
    height: 2.25em;
  }
}
.admin-retailer-type-dialog__save-btn.ant-btn:hover,
.admin-retailer-type-dialog__save-btn.ant-btn:active,
.admin-retailer-type-dialog__save-btn.ant-btn:focus {
  background: #000;
  color: #fff;
  border-color: transparent;
}
