
.productStatus
  position: absolute
  top: 14px
  left: 12px
  border: 1px solid rgba(0,0,0,0.38)
  padding: 4px
  p
    color: rgba(0,0,0,0.38)
    font-size: 12px
    letter-spacing: 0.09px
    line-height: 14px
    text-align: center
.flexButton
  display: flex!important
.product-item
  position: relative
  margin-bottom: 50px

  &__thumb-container
    display: flex
    justify-content: center
    align-items: center

  &.best-seller:before
    content: "*BEST SELLER*"
    background: #71cfb3

  &.new:before
    content: "*NEW*"
    background: #fcc9c5

  a
    color: #787474
    text-decoration: none

  &__add-to-cart-button
    height: 33px
    width: 265px
    font-size: 12px
    line-height: 20px

    &--not-allowed-cursor
      cursor: not-allowed

    &--disabled
      color: #8D8D8D !important
      border-color: #8D8D8D !important
      cursor: not-allowed !important
      pointer-events: none

  &__samples-request-button
    height: 33px
    width: 147px
    font-size: 12px
    line-height: 20px

  .product-item__thumb
    background-color: #fff
    padding: 30px
    margin-bottom: 15px
    border-radius: 4px
    overflow: hidden
    height: 264px
    display: flex
    justify-content: center
    align-items: center

    img
      max-width: 100%
      max-height: 100%

  .product-item__video
    display: flex
    background-color: #fff
    padding: 30px
    margin-bottom: 15px
    border-radius: 4px
    overflow: hidden
    height: 264px

  .product-item__body
    color: #888888
    letter-spacing: 0.01em
    font-size: 13px

    &-title
      font-size: 14px
      letter-spacing: 0.2px
      min-height: 42px
      overflow: hidden
      text-overflow: ellipsis
      display: -webkit-box
      -webkit-line-clamp: 2
      -webkit-box-orient: vertical

      a
        color: black

    &-price
      font-weight: 300
      color: #292929
      font-size: 16px
      letter-spacing: 0.5px
      margin-bottom: 9px

    &-brand
      font-weight: 600
      font-size: 14px
      letter-spacing: 0.2px
      text-transform: uppercase
      color: #292929

    &-status
      color: #757575
      font-size: 12px
      letter-spacing: 0.17px
