
.admin-console-add {
  &__btn {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    background: #e2eeec;
    color: #4a8a5d;
    border: 1px solid #4a8a5d !important;
    border-radius: 2px !important;
    margin-right: 12px;
  }
  &__item-btn {
    background: #fff !important;
    border: 1px solid #4a8a5d !important;
    box-sizing: border-box;
    border-radius: 2px !important;
    padding: 0px 8px !important;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #4a8a5d !important;
  }
  &__item-edit-icon {
    color: #4a8a5d;
    font-size: 14px;
    margin-bottom: 4px;
  }
  &__add-new-input {
    background: #ffffff;
    border: 1px solid #4a8a5d;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 6px 8px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    margin-top: 2px;
    width: 100px;
    height: 32px;
  }
  &__close-icon {
    color: #ff4d4f;
    float: right;
    margin-top: -10px;
    margin-left: -10px;
    font-size: 18px;
    z-index: 199;
    position: relative;
  }
  &__undo-icon {
    float: right;
    margin-top: -10px;
    margin-left: -10px;
    font-size: 18px;
    z-index: 199;
    text-align: center;
    position: relative;
  }
  &__edit-input {
    padding: 6px 8px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #bfbfbf;
    border: 1px solid #ff6132;
    box-sizing: border-box;
    border-radius: 2px;
  }
  &__divider {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 50px;
  }
  &__confirm-btn {
    background: #4a8a5d;
    border: 1px solid #4a8a5d;
    box-sizing: border-box;
    border-radius: 2px;
  }
}
.ant-btn-admin-console-add__confirm-btn {
  background: #4a8a5d;
  border: 1px solid #4a8a5d;
  box-sizing: border-box;
  border-radius: 2px;
}
.admin-console-add__item-btn[disabled] {
  background: #f5f5f5 !important;
  border: 1px solid #bfbfbf !important;
  box-sizing: border-box;
  border-radius: 2px !important;
  color: #bfbfbf !important;
}
.admin-console-add__item-edit-icon[disabled] {
  color: #e8e8e8;
}
