
.ant-btn-primary {
  background-color: #4a8a5d;
  border-color: #4a8a5d;
  border-radius: 2px !important;
}
.ant-modal .message-list-modal {
  background: #ffffff;
  border: 1px solid rgba(74, 138, 93, 0.46);
  box-sizing: border-box;
  border-radius: 4px;
  padding-bottom: 0px;
}
.message-list-modal .ant-input {
  border: none;
  background: inherit;
  border-bottom: 1px solid rgba(74, 138, 93, 0.46);
}
.message-list-modal .ant-input:hover,
.message-list-modal .ant-input:focus {
  border-color: rgba(74, 138, 93, 0.46);
  box-shadow: none;
}
.message-list-modal .ant-btn-primary:active,
.message-list-modal .ant-btn-primary:hover,
.message-list-modal .ant-btn-primary:focus {
  color: #fff;
  background: #4a8a5d;
  border-color: #4a8a5d;
  box-shadow: 2px 2px 2px 2px rgb(24 144 255 / 20%);
}
.message-list-modal
  .admin-message-brand-to-error.admin-message-brand-create-new-modal__toTarget.ant-select
  .ant-select-selection-selected-value {
  color: #ff4d4f;
}
