
//@font-face {
//  font-family: "Proxima Nova";
//  src: url("@/assets/fonts/Proxima-Nova-Light.otf")
//  format('truetype')
//}

.share-link-dialog__main {
}
.share-link-content {
  padding: 150px 212px;
  //width: 80%;
  //margin: 0 auto;
  text-align: center;
  .title-img {
    width: 136px;
    height: 136px;
    line-height: 126px;
    margin: 0 auto;
    border-radius: 50%;
    font-size: 42px;
    color: #4a8a5d;
    background-color: #e2eeec;
    border: 4px solid #4a8a5d;
  }
  .title-text {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    font-family: "Proxima Nova", "Roboto", sans-serif;
    margin: 22px 0;
  }
  .link-text {
    width: 100%;
    padding: 5px 0;
    //height: 18px;
    font-size: 13px;
    line-height: 15px;
    color: #595959;
    border: 1px solid #bfbfbf;
    background-color: #f5f5f5;
    margin-bottom: 22px;
  }
  .link-btn-text {
    width: 94px;
    height: 30px;
    border: 1px solid #4a8a5d;
    color: #4a8a5d;
    background-color: #e2eeec;
  }
}
