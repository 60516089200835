
.content {
    display: flex;
    flex-direction: column;

    .top {
        display: flex;
        justify-content: right;
        padding-bottom: 10px;
    }
}

.card {
    display: flex;
    flex-direction: column;

    .content {
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        div {
            display: flex;
            flex-direction: row;
            width: 100%;

            label {
                padding-left: 40px;
                font-weight: bold;
                flex: 1;
            }

            div {
                flex: 1;
            }
        }
    }

    .subscription-footer {
        text-align: center;
    }
}
