// it is public sass
.landing_container
  width: 1190px
  margin: 0 auto
.clearfix:after,
.clearfix:before
  content: "."
  display: block
  height: 0
  clear: both
  visibility: hidden

body
  .ant-input-affix-wrapper::before
    display: none


// components/svgIcon base css
.svg-icon-base
  font-size: 14px
.svg-icon-font16
  font-size: 16px
.svg-icon-font18
  font-size: 18px
.svg-icon-font20
  font-size: 20px
.svg-icon-font22
  font-size: 22px
.svg-icon-font24
  font-size: 24px

// input placeholader css
input::-webkit-input-placeholder
  color: #D9D9D9

input::-moz-placeholder
  color: #D9D9D9

input:-moz-placeholder
  color: #D9D9D9

input:-ms-input-placeholder
  color: #D9D9D9

