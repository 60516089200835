
.brand-pending
  display: flex
  min-height: 80vh
  justify-content: center
  align-items: center

  &__contact-us
    color: #008489

  &__panel
    padding: 24px
    padding-bottom: 20px
    min-height: 335px
    width: 362px
    border: 1px solid #E5E5E5
    border-radius: 2px
    background-color: #FFFFFF

  &__title
    color: #000000
    font-family: "Proxima"
    font-size: 16px
    letter-spacing: 0.44px

  &__paragraph
    margin-top: 16px
    color: #8D8D8D
    font-size: 14px
    letter-spacing: 0.25px

  &__divider
    border-bottom: 1px solid #e5e5e5
    margin: 40px 0px 25px 0px

  &__verification-sent-divider
    border-bottom: 1px solid #e5e5e5
    margin: 140px 0px 25px 0px

  &__link
    color: #008489
    cursor: pointer

  img
    float: left

  &__check
    color: #8D8D8D
    font-family: "Proxima"
    font-size: 14px
    letter-spacing: 0.25px
    font-weight: 500
    height: 20px
    line-height: 20px
    margin-left: 30px

    &--passed
      color: #008489
  .pending-email
    color: #4A90E2
  .welcome-subheading
    color: #000000
    margin: 0
  .first-subheading
    margin-top: 16px
  .sub-section
    margin-left: 30px
    margin-top: 10px
