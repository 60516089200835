
.common-overview
  &__card-shadowed
      -webkit-box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%)
      box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%)
      border-radius: 2px
      padding: 24px 20px 16px 17px
  &__outlined
      border: 1px solid rgba(151, 151, 151, 0.234149)
      border-radius: 3px
      padding: 23px 13px 16px 24px
  &__title
      font-size: 24px !important
      font-weight: normal !important
      line-height: 24px !important
      color: #000000 !important
      margin-bottom: 8px !important
  &__border
      margin: 4px 16px 13px 0px !important
      max-width: 342px !important
      border-bottom: 1px solid #E8E8E8
  &__brand-about
      font-family: Proxima Nova
      font-style: normal
      font-weight: normal
      font-size: 14px
      line-height: 20px
      color: #000000
      margin-top: 16px
      margin-bottom: 10px
  &__retailer-about
      font-family: Proxima Nova
      font-style: normal
      font-weight: normal
      font-size: 14px
      line-height: 20px
      color: #595959
  &__content
    vertical-align: top
    position: relative
    padding: 24px 0px 16px 0px
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    display: flex
    color: #262626
  &__padding-left
      padding-left: 20px !important
  &__col_content
      margin-top: 16px !important
  &__detail_margin
    margin-top: 6px !important
  &__detail
      font-family: Proxima Nova
      font-style: normal
      font-weight: normal
      font-size: 14px
      line-height: 20px
      display: flex
      align-items: center
      color: #8C8C8C
  &__border_detail
      background: #F5F5F5
      padding: 1px 6px
      border: 1px solid #D9D9D9
      box-sizing: border-box
      border-radius: 2px
      font-family: Proxima Nova
      font-style: normal
      font-weight: normal
      font-size: 12px
      line-height: 22px
      color: #595959
      text-align: center
      display: inline-block
      margin-bottom: 4px !important
  &__link
     max-width: 123px
     overflow: hidden
     text-overflow: ellipsis
     font-family: Proxima Nova
     font-style: normal
     font-weight: normal
     font-size: 14px
     line-height: 20px
     display: block
     white-space: nowrap
     align-items: center
     color: #1D4BA1
  &__gray
      color: #8C8C8C !important
  &__img_margin
    margin-left: 4px
.col
    padding: 12px 8px !important
.v-sheet.v-card
    border-radius: 2px !important
.brand-profile__pdf-text
  word-wrap: break-word
  word-break: break-all
  overflow: hidden
  width: 90%
  text-overflow: ellipsis
  display: -webkit-box
  -webkit-line-clamp: 1
  -webkit-box-orient: vertical
