
.brand-training-retailer-add-dialog__main {
  p {
    margin-bottom: 0;
  }
  .modal-title-location {
    width: 100%;
    text-align: center;
  }
  .step-content-text {
    margin-bottom: 20px;
  }
  .button-step-style {
    text-align: right;
    margin-top: 35px;
    .cancel-button-style {
      width: 160px;
      color: white;
      background-color: rgba(242, 124, 89, 1);
    }
    .next-button-style {
      width: 160px;
      color: white;
      background-color: rgba(145, 211, 149, 1);
    }
  }
  .ant-table-thead > tr > th {
    background-color: #ffffff;
  }
  .ant-radio-wrapper {
    height: 16px;
  }
  .ant-radio {
    top: 0;
  }
  .ant-radio-wrapper::after {
    contain: none;
    display: none;
  }
}
