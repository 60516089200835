
    $regular_size_text : 14px
    $large_size_text : 16px

.retailer-card__main
  .card-thumbnail
    background-color: #fff
    text-align: center
    display: flex
    justify-content: center
    align-items: center
    // height: 100px
    margin: 0 auto

  .card-body-retailer
  /*pointer-events: none*/
  // margin-top: -10px
    padding-top: 8px
  .text

    &__name
      font-size: $large_size_text
      font-weight: 700
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      padding-top: 15px


    &__desc
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      padding-top: 15px
      color: #595959
      font-size: $regular_size_text
      margin-bottom: 1rem



      &__website
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
        padding-top: 25px
        font-size: $regular_size_text


    &__locations
      color: #595959
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      padding-top: 5px
      padding-bottom: 15px
      font-size: $regular_size_text
      font-size: 14px !important


      &__message
        color: #595959
        font-size: 16px
        font-weight: 500

  .card-tag
      background-color: rgba(229, 247, 255, 0.25)
      border-color: transparent
      color: #4A8A5D
      height: 22px
      line-height: 22px
      border-radius: 2px
      border: solid #4A8A5D 1px
      padding: 1px 5px 0px 8px
      font-size: 12px
      box-sizing: border-box

  .card-icon
      padding: 0 !important
      margin-right: 0 !important

  .card-header
      justify-content: space-between !important
      margin-bottom: 16px
      box-shadow: none
  .message-button
    margin-top: 15px
    border-radius: 2px
    padding: 5px
    position: relative
    display: flex
    justify-content: center
    align-items: center
    margin: 0 auto
    width: 100px
    background-color: #4a8a5d

  .message-text
    color: white
    position: relative
    display: inline-block
    float: left

  .icon
    position: relative
    display: inline-block
    float: left
    width: 25px
    height: 25px
    margin-left: 25px
    margin-right: 10px
    padding-top: 10px

  .icon-message
    -webkit-box-align: center
    -ms-flex-align: center
    align-items: center
    display: -webkit-box
    display: -ms-inline-flexbox
    display: inline-flex
    -webkit-box-pack: center
    -ms-flex-pack: center
    justify-content: center
    height: 1.5rem
    width: 1.5rem


  a
    color: #262626
    cursor: pointer
    text-decoration: none

  .text-website__anchor
    color: #166ED9
  .card-content-retailer
    padding-top: 17px
    padding-bottom: 24px
    .text__name
      padding-top: 0
      line-height: 24px
    .text__desc
      max-height: 66px
      height: 66px
      padding: 0
      margin-top: 8px
      margin-bottom: 16px
      display: -webkit-box
      -webkit-box-orient: vertical
      -webkit-line-clamp: 3
      overflow: hidden
      word-wrap: break-word
      white-space: pre-wrap
      text-overflow: none
