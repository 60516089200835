
.image-cropper__zoom-slider--wrapper
  position: relative
  height: 26px
  margin-right: 30px
  width: 135%
.image-cropper__change-slider--wrapper
  position: relative
  height: 26px
  margin-left: 30px
.image-cropper__slider--scale
  height: 6px
  border-radius: 2px
  width: 100%
  background: #E5E5E5
  position: absolute
  left: 0
  top: calc(50% - 3px)
.image-cropper__slider--scale_active
  height: 6px
  border-radius: 2px
  background: #000
  width: 100%
  left: 0
  top: 0
  position: absolute
.image-cropper__slider--input
  position: absolute
  left: 0
  width: 100%
  pointer-events: none
  background: inherit
  -webkit-appearance: none
  height: 26px
  &:focus
    outline: none
  &::-webkit-slider-thumb
    pointer-events: auto
    background: #292929
    width: 12px
    height: 12px
    border: none
    border-radius: 50%
    -webkit-appearance: none
  &::-moz-range-thumb
    pointer-events: auto
    background: #292929
    width: 12px
    height: 12px
    border: none
    border-radius: 50%
  &::-ms-thumb
    pointer-events: auto
    background: #292929
    width: 12px
    height: 12px
    border: none
    border-radius: 50%
.image-cropper
#image-upload-main, #image-upload-banner
  display: none
.image-upload__profile--main
  max-height: 64px
  max-width: 64px
  cursor: pointer
.image-cropper__modal
  display: flex
  justifyContent: center
  alignItems: center
.image-cropper__header
  font-size: 20px
  font-weight: bold
  letter-spacing: 0.28px
  color: #000000
  padding-left: 25px
.image-cropper__close-icon
  cursor: pointer
.image-cropper__footer
  display: flex
  justify-content: center
.image-cropper__text
  color: #ffffff
  font-size: 20px
  font-weight: bold
  letter-spacing: 0.28px
  padding-top: 20px
.image-cropper__button-save
  font-size: 14px
  font-weight: 500
  letter-spacing: 0.7px
  margin-top: 30px
  margin-bottom: 20px
.image-upload__profile-logo-container
  position: relative
  display: flex
  justify-content: center
  vertical-align: center
.image-upload__profile-logo
  height: 64px
  width: 64px
  background-color: transparent
.image-upload__profile--main_icon
  position: absolute
  background-color: rgba(74,74,74,0.47)
  border-radius: 50%
  padding: 5px
  left: calc(50% - 20px)
  top: calc(50% - 20px)
  cursor: pointer
.image-upload__profile--banner_icon
  position: absolute
  background-color: rgba(74,74,74,0.47)
  border-radius: 50%
  padding: 5px
  right: 20px
  top: 40px
  cursor: pointer
.image-cropper__button-change
  font-size: 14px
  font-weight: 500
  letter-spacing: 0.7px
  float: right
  margin-top: 30px
  margin-bottom: 20px
.image-cropper__modal--header
  font-size: 20px
  font-weight: bold
  letter-spacing: 0.28px
.image-cropper__modal--straighten-header
  font-size: 16px
  font-weight: normal
  letter-spacing: 0.28px
  margin-left: 30px
.image-container
  display: inline-block
.crop-container
  background-color: black
  padding: 40px 0px 20px
  overflow: scroll
.clipperContainer
  overflow: scroll
.image-preview
  display: block
  max-height: 229px
