
.edit-reference-dialog {
  &__edit-text {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #bfbfbf;
    padding: 6px 8px;
    border: 1px solid #4a8a5d;
    background: #ffffff;
    border-radius: 2px;
    display: inline-block;
    margin-right: 12px;
    margin-bottom: 12px;
  }
}
.edit-reference-dialog__edit-text:focus {
  border: 1px solid #ff6132;
}
