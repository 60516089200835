

.max-width
  width: 334px !important

.request-invite-modal
  .modal-card-body
    display: flex

  .request-term-details-info
    color: #000
    font-size: 14px
    font-weight: 400
    margin: 0px !important

  .infoText
    margin-bottom: 20px

  &__header
    color: #212B36
    font-size: 20px
    border-bottom: 1px solid #E1E4E9
    padding-top: 15px
    padding-left: 20px
    padding-bottom: 15px
    .request-invite-modal__close-button
      top: 16px
      width: 14px
      height: 14px

  &__content
    position: relative
    background-color: #FFFFFF
    width: 510px

  .request-invite-modal__buttons
    padding: 10px 91px 10px 90px
    .button
      height: 30px
      font-size: 14px
      padding: 16px 20px
      width: 100%
      background: black
      color: white

  &__body
    padding: 30px 90px 30px 91px

  &__buttons
    padding: 10px 20px
    display: flex
    justify-content: flex-end

  &__close-button
    position: absolute
    top: 20px
    right: 20px
    cursor: pointer
    height: 20px
    width: 20px

  &__details-container
    font-size: 14px
    padding-bottom: 60px

  &__details
    margin: 18px
