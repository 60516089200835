
.modal-search-order-content {
  p {
    text-align: center;
    font-family: "Proxima Nova", "Roboto", sans-serif;
    color: #000000;
    margin-bottom: 9px;
    margin-top: 20px;
  }
  .search-first-param {
    margin-top: 0;
  }
  .data-label-style {
    text-align: left;
  }
  .modal-button-style {
    margin-top: 60px;
  }
  .apply-button {
    padding: 5px 16px;
    border-radius: 2px;
    background-color: #4a8a5d;
    color: white;
    font-size: 14px;
    cursor: pointer;
    font-family: "Proxima Nova", "Roboto", sans-serif;
  }
  .error-params {
    color: #b21f2d;
  }
}
