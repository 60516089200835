
.agency-retailer {
  .ant-carousel {
    width: 100%;
    height: 443px;

    .slick-list {
      height: 443px;
    }

    .slick-dots {
      bottom: 20px !important;
    }

    .slick-slide {
      height: 443px;
      overflow: hidden;
    }
  }
}
