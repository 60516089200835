
.request-terms-modal-retailer
  .modal-card-body
    display: flex
    .view-pending-request__wrap
      margin: 0px 0px 50px 0px
      .request-terms-modal-retailer__details-container
        padding-bottom: 50px
        display: flex
        .payment-block
          .request-terms-modal-retailer__subtitles
            color: #000
            font-weight: 500
          .request-terms-modal-retailer__details
            color: #000
            margin: 18px
        .payment-block + .payment-block
            margin-left: 30px
      :last-child
        padding-bottom: 0px

  .request-term-details-info
    color: #000
    font-size: 14px
    font-weight: 400
    margin: 0px !important

  &__header
    color: #212B36
    font-size: 20px
    border-bottom: 1px solid #E1E4E9
    padding-top: 15px
    padding-left: 20px
    padding-bottom: 15px
    .request-terms-modal-retailer__close-button
      top: 20px
      width: 14px
      height: 14px

  &__content
    position: relative
    background-color: #FFFFFF
    width: 601px

  .button
      height: 30px
      font-size: 14px
      padding: 16px 20px

  &__body
    padding: 30px 20px
    width: 601px

  &__buttons
    padding: 10px 20px
    display: flex
    justify-content: flex-end

  &__close-button
    position: absolute
    top: 20px
    right: 20px
    cursor: pointer
    height: 20px
    width: 20px

  &__details-container
    font-size: 14px
    padding-bottom: 60px

  &__details
    margin: 18px

.bottom-margin
  margin: 0px !important
