

.retailer-settings
  padding-top: 50px
  padding-bottom: 300px

  &__eyeIcon
    cursor: pointer
    position: absolute
    right: 14px
    top: 35px

  &__title
    color: #000000
    font-size: 20px
    letter-spacing: 0.32px
    line-height: 18px
    margin-bottom: 30px
    margin-top: 19px

  &__input-password
    box-sizing: border-box
    height: 40px
    width: 100%
    border: 1px solid #E5E5E5
    border-radius: 2px
    position: relative

  &__account-information

    &_label
      color: #363636
      display: block
      font-size: 1rem
      font-weight: 400
      position: relative

  &__password

    &_form-section
      margin-top: 34px

    &_button
      height: 36px
      width: 135px
      font-size: 14px
      top: 6px
