
u {
  text-decoration: underline;
}
.toolbar-button-style {
  display: flex;
  justify-content: flex-start;
  //border-top: 1px solid #4a8a5d;
  padding-top: 8px;
  padding-left: 5px;
  padding-bottom: 8px;
  //border-bottom: 1px solid #4a8a5d;
}
.toolbart-style {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
}
.w-e-bar-item button {
  padding: 0 3px;
}
.w-e-bar-divider {
  display: none;
}
.w-e-bar-item-group {
  display: none;
}
.w-e-bar-item-group .w-e-bar-item-menus-container .w-e-bar-item:nth-child(1) {
  display: none;
}
.w-e-bar-item-group .w-e-bar-item-menus-container .w-e-bar-item button {
  font-size: 14px;
}
.w-e-bar-item-group .w-e-bar-item-menus-container .w-e-bar-item span {
  font-size: 14px;
}
.w-e-modal {
  z-index: 666;
}
.message-input-content {
  background: #f5f5f5;
  border-radius: 16px;
  padding: 20px;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  &__from {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    margin-bottom: 4px;
  }
  &__to {
    color: #000000;
    margin-bottom: 10px;
  }
  &__divider {
    border-bottom: 1px solid #d9d9d9;
    margin-right: 60px;
    margin-bottom: 20px;
  }
  &__content {
    color: #262626;
  }
  &__text-container {
    border-top: 1px solid rgba(74, 138, 93, 0.46);
    border-bottom: 1px solid rgba(74, 138, 93, 0.46);
    margin-top: -1px;
    min-height: 111px;
  }
  &__text-contailer-admin {
    border: 1px solid rgba(74, 138, 93, 0.46);
    margin-top: -1px;
    min-height: 217px;
  }
}
.editor-style {
  position: relative;
  .draft-save-info-style {
    padding: 2px 10px;
    height: 26px;
    line-height: 26px;
    background: #e8e8e8;
    font-family: ProximaNova;
    border-radius: 2px;
    color: #000000;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 300;
  }
  .file-list-editor {
    position: absolute;
    left: 12px;
    bottom: 8px;
    color: #262626;
    padding: 2px 4px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    display: flex;
    justify-content: start;
    align-items: center;
    z-index: 300;
  }
  .delete-file-info {
    padding: 0 5px;
    cursor: pointer;
  }
  p {
    padding: 0;
    margin: 0;
  }
}
