
@font-face
  font-family: "Proxima Nova"
  src: url("../../../assets/fonts/Proxima-Nova-Light.otf") format("truetype")

.retailer-az
  width: 100%

.name
  display: flex
  // background-color: green

.letter-bar
  padding-bottom: 18px
  min-width: 597px
  border-bottom: 1px solid #ddd
  display: flex
  align-items: center
  flex-wrap: wrap
.filter-item
  font-family: Proxima
  font-size: 14px
  cursor: pointer

  a
    font-size: 12px
    margin: 6px
    color: #8C8C8C
    position: relative
    font-weight: 600
    text-transform: uppercase
    &:hover
      text-decoration: underline
.filter-item:nth-child(1) a
  display: inline-block
  width: 30px !important
  height: 30px !important
  border-radius: 50%
.directory
  flex-wrap: wrap
  display: flex
  align-items: flex-start
  width: 100%
  height: 100%
  padding: 20px 0px
  min-width: 597px
  // background-color: yellow

  ul
    list-style: none
    padding: 0
    margin: 0 33px 30px
    flex-basis: 15%

    li
      color: #000000
      a
        font-size: 12px
        color: #000
        font-weight: 300
        &:hover
          text-decoration: underline
  ul:nth-child(4n)
    margin-right: 0
  ul:nth-child(4n-3)
    margin-left: 0

.text
  &__title
    font-size: 48px
    font-weight: 650

.dot
  color: white !important
  height: 40px !important
  width: 40px !important
  padding: 5px !important
  box-sizing: border-box
  background-color: #4a8a5d !important
  border-radius: 25px !important
.loading_wrap
  width: 100%
  display: flex
  justify-content: center
.text__title
  font-family: Playfair Display
  font-weight: bold
  font-size: 48px
  color: #262626
.retailer-item
  a
    font-family: "Proxima Nova"
    color: #000000
    font-weight: 400
    color: #000000
