
.terms
  margin: 100px 80px

.terms__main-header
  color: #333333
  font-size: 16px
  font-weight: bold
  letter-spacing: 0.44px

.terms__small-header
  color: #4a4a4a
  font-size: 14px
  font-weight: bold
  letter-spacing: 0.39px

.terms__paragraph
  color: #4a4a4a
  font-size: 14px
  letter-spacing: 0.39px
