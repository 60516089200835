
.f-spacer {
  flex: 1 1 auto;
}
.flex-r {
  display: flex;
  flex-direction: row;
}
.flex-c {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.root {
  margin-bottom: 43px;
}
.header-border {
  border-bottom: 1px solid #d9d9d9;
}
.header {
  margin-bottom: 12px;
  .title {
    font-weight: normal;
    font-family: Proxima Nova;
    font-style: normal;
    font-size: 22px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #262626;
    text-transform: uppercase;
  }
  .btn {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    background: #4a8a5d;
    color: #fff;
    padding: 0px 8px;
    border: 1px solid #4a8a5d;
    border-radius: 2px;
    &:not(:last-child) {
      margin-right: 12px;
    }
  }

  .bold {
    font-weight: bold;
  }
  .ant-dropdown-menu-item {
    > div {
      > * {
        margin-right: 4px;
      }
    }
  }
  .ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active,
  .ant-btn.active {
    color: #4a8a5d;
    background-color: #e2eeec;
  }
}
