
.input-with-prefix
  position: relative

  &__input
    font-size: 14px
    height: 40px
    line-height: 40px
    &::placeholder
      font-size: 500
      line-height: 28px
      padding-left: 0

  &__prefix
    position: absolute
    left: 10px
    z-index: 1
    display: inline
    height: 40px
    line-height: 40px
    color: #000000
    font-family: "Proxima"
    font-size: 14px
    letter-spacing: 0.2px
    pointer-events: none
