
.logo-or-placeholder
  width: 100%
  height: 100%
  display: flex
  justify-content: center
  align-items: center

  img
      max-width: 100%
      max-height: 100%
      border-radius: 100%
      box-shadow: 0px 0px 0px 3px white, 2px 8px 5px 1px rgb(82 77 77 / 55%)
