
.retailer-item-product__main {
  p {
    margin-bottom: 0;
  }

  .item-title-retailer-pro {
    // width: 100%;
    text-align: center;
  }

  .training-page-table {
    // width: 840px;

    //height: 500px;
    //position: relative;
    .training-table-style {
      overflow: visible;
      // width: 100%;
      //max-height: 500px;
      overflow-y: auto;
      color: #262626;
      //border: #e8e8e8 solid 1px;
      border-collapse: collapse;
    }

    .training-table-style tr {
      width: 100%;
      position: relative;
    }

    .training-table-style th {
      //border-top: #e8e8e8 solid 1px;
      border-bottom: #e8e8e8 solid 1px;
      padding: 10px;
      //background-color: #fafafa;
      font-size: 14px;
      font-weight: normal;
    }

    .training-table-style td {
      height: 56px;
      //border-top: #e8e8e8 solid 1px;
      border-bottom: #e8e8e8 solid 1px;
      padding: 17px 10px;
      background-color: #ffffff;
      color: #595959;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .training-table-style tr,
    .training-table-style td.move:hover {
      //cursor: pointer;
    }

    .training-table-style td.click-pro-action:hover {
      cursor: pointer;
    }

    .name-tab-style {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .data-tab-style {
      //position: relative;
    }
  }

  .hero-product-position {
    padding: 3px 8px;
    color: #ffffff;
    font-size: 12px;
    background-color: #f3ca50;
    border: 1px solid #f3ca50;
    border-radius: 100px;
  }

  .retailer-product-item-button {
    // width: 100%;
    text-align: right;
    padding: 20px;

    .close-button-product-item {
      width: 160px;
      height: 30px;
      border-radius: 7px;
      background-color: #f27c59;
      color: white;
    }
  }

  .item-content-retailer-pro {
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  .icon-sort-style {
    color: #8c8c8c;
  }
}

.ant-switch {
  background-color: #f27c59;
  background-image: none !important;
}

.ant-switch-checked {
  background-color: #9fd19a;
}
