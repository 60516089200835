
.retailer-settings
  padding-left: 80px !important
  padding-bottom: 300px
  .margin-label-wrapper
   display: flex
   width: 100%
   border-top: 1px solid #ebeff2
   flex-direction: row
   height: auto
   min-height: 50px
   .margin-label
    font-size: 14px
    color: #333333
    margin-left: 70px
    margin-right: 50px
    width: 150px
    font-weight: 500
    margin-top: 15px
    white-space: normal
    word-break: break-all
   .margin-label-data
    font-size: 14px
    color: #333333
    width: 228px
    font-weight: 500
    padding-right: 85px
    margin-top: 15px
    white-space: normal
    word-break: break-all
  .margin-label-header-wrapper
   display: flex
   flex-direction: row
   height: 50px
   .margin-label
    font-size: 14px
    color: #333333
    margin-left: 70px
    min-width: 150px
    font-weight: 500
    margin-top: 15px
   .margin-label-data
    font-size: 14px
    color: #333333
    min-width: 228px
    font-weight: 500
    padding-right: 85px
    margin-top: 15px
  .padding-style-header
   margin-top: 37px
   margin-bottom: 17px
   &__title
    color: #000000
    font-size: 18px
    letter-spacing: 0.32px
    line-height: 18px
    margin-bottom: 24px
