
.brand-settings__account-information
  width: 100%
  padding-top: 68px
.brand-line-margin
  border-top: 1px solid #ebeff2
.retailer-settings__wrap
  height: 100%
.retailer-settings__box-shadow
  box-shadow: 2px 2px 10px rgb(163,177,198,0.6)
.retailer-settings
  padding-left: 80px !important
  padding-bottom: 68px
  &__dialog-close-btn
    width: 200px
    height: 33px
    line-height: 33px
    border-radius: 2px
    font-size: 14px
    margin-top: 27px
    background-color: #12433d
    color: white
    text-align: center
    margin-left: auto
    margin-right: auto
    cursor: pointer
  &__dialog-close-btn:hover
    background-color: #4a8a5d
    color: #ffffff
  &__dialog-info-before-submit
    text-align: center
    color: #12433d
    font-size: 13px
    margin-top: 13px
    font-family: "Proxima Nova", "Roboto", sans-serif
  &__dialog-info-title
    text-align: center
    font-size: 16px
    margin-bottom: 23px

  .brand-line
   display: flex
  .table, th, td
   border-bottom: 1px solid #ebeff2
   height: 50px
   padding-left: 41px
   padding-top: 20px
  table
   border: 1px solid #ebeff2 !important
  td
   color: #000000
  .text-align
   text-align: center
  .vertical-margin
   padding-top: 25px
   color: #888888 !important
   font-weight: 100
   padding-bottom: 15px
  .align-value
   text-align: right
   padding-right: 10px
  .shipping-block
   padding-top: 41px
  .header-text
   color: #888888
   font-size: 14px
   weight: 200
  .footer-grid
   border-bottom: 1px solid transparent !important
  .grid-style
   color: #333333
   font-size: 14px
  .footer-grid-style
   color: #333333
   font-size: 16px
   font-weight: 700
  .footer-grid-subtitle
   color: #212B36
   font-size: 14px
   font-weight: 300
   padding-top: 1px
  .sub-menuItem
   margin-left : 21px
  .is-selected
   color: #000000
   font-size: 16px

  &__title-style
    font-weight: bold
    font-size: 14px
    color: #333333

  &__border-line
    border-top: 1px solid #ebeff2

  &__status-align
    margin-left: -1px

  &__sub-menuItem-link
    margin-left : 21px
    color: #7F7F7F
    font-size: 16px
    letter-spacing: 0.25px
    line-height: 18px
    cursor: pointer
    margin-bottom: 16px

  &__title
    color: #000000
    font-size: 18px
    letter-spacing: 0.32px
    line-height: 18px
    margin-bottom: 24px

  &__links
    color: #7f7f7f
    font-size: 14px
    padding-top: 70px
    border-right: 1px solid #ddd
    min-height: 70vh!important
    margin-right: 50px

  &__link
    color: #7f7f7f
    font-size: 16px
    letter-spacing: 0.25px
    line-height: 18px
    cursor: pointer
    margin-bottom: 16px

  &__account-information
    margin-top: 41px
    .margin-style
      margin-right: 97px

  &__retailer-account
    padding: 18px
    margin-left: 20px

  &__shipping-addresses
    height: 100%
    overflow: auto

  &__saved-addresses-button
    height: 36px
    width: 198px
    color: white
    font-size: 14px
    font-weight: 600
    letter-spacing: 0.7px
    line-height: 18px
    margin-bottom: 35px
  .is-black
    background-color: #292929

  &__modify-buttons
    text-align: right
    font-size: 14px
    color: #442B2D
    font-weight: 600

  &__saved-addresses_boxes
    margin-right: 40px

  &__default-address
    color: #000000

  &__saved-addresses_box
    box-sizing: border-box
    height: 154px
    border: 1px solid #B3B3B3
    padding: 25px
    font-size: 15px
    &_title
      margin-bottom: 5px
    &_subtitle
      color: black

  &__saved-addresses_box_default
    box-sizing: border-box
    height: 154px
    border: 2px solid #000000
    padding: 25px
    &_title
      color: #292929
      width: 300px
      font-size: 14px
      font-weight: 200
      letter-spacing: 0.25px
      line-height: 18px
      margin-bottom: 10px

    &_subtitle
      color: #292929
      width: 300px
      font-size: 14px
      font-weight: 500
      color: black
      letter-spacing: 0.25px
      line-height: 18px
      margin-bottom: 0px
    &_subtitle-second
      color: #292929
      width: 300px
      font-size: 14px
      font-weight: 500
      color: black
      letter-spacing: 0.25px
      line-height: 18px
      margin-bottom: 57px

    &__buttons
      margin-top: 63px
.memberss__wrap
  .members-details-table
    border: 1px solid #ddd

    .members-details-table-header
      padding: 11px 0px
      border-bottom: 1px solid #ddd
      display: flex

      .members__title
        padding-left: 18px
      .invite_member
        justify-content: flex-end
        display: flex
        padding-right: 20px
        .button
          background-color: black
          color: white
          font-size: 14px

    .members-details-table-body
      .table-header
        padding: 11px 35px
        border-bottom: 1px solid #ddd
        display: flex

        .brand-settings__account-information_text
          font-size: 14px

      .table-body
        padding: 6px 35px
        border-bottom: 1px solid #ddd
        display: flex
        align-items: center
        flex-direction: column

    .members-details-table-footer1
      .banner-status1
        width: 100%
        margin-top: 0px
        margin-left: 0px
        border: none
        display: flex
        box-shadow: 0 -10px 10px -5px rgba(63, 63, 68, 0.05), 0 1px 2px -1px rgba(63, 63, 68, 0.15)
        flex-direction: column
        justify-content: center
        .title-style1
          font-weight: bold

        .title-boxbanners1
          margin-top: 32px
          line-height: 2px
          font-size: 16px
          letter-spacing: 0px
        .title-block1
          padding : 21px 35px
          .title-boxbanners1
            margin-top: 0px
          .title-boxbanners1 + .title-boxbanners1
            margin-top: 25px
            font-size: 13px

        .button-block1
          margin-top: 15px
          margin-left: 35px
          .box-button1
            min-width: 124px
            width: auto
            min-height: 27px
            font-size: 14px
.retailer-settings-cpc
  &__edit-modal--form_terms
    position: sticky
    top: 33px
  &__edit-modal--form_container
    padding-bottom: 5px
  &__ingredients-dropdown-outer
    border: 1px solid #BFBFBF
    padding: 8px 0px
    overflow: hidden
    z-index: 999
    position: relative
    background-color: white
  &__alpha-area
    padding: 0px 4px
    width: 100%
    display: block
  &__alpha-filter
    float: left
    width: 18px
    height: 18px
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 18px
    align-items: center
    text-align: center
    margin-right: 1px

  //padding: 2px 3px
  &__alpha-invalid
    color: #D9D9D9
  &__alpha-valid
    color: #262626
    cursor: pointer
  &__alpha-selected
    background-color: #595959
    border-radius: 2px
    color: #ffffff
    cursor: pointer
    text-align: center
  &__add-ingredient-placeholder
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 17px
    color: #262626
  &__added-ingredient-item
    display: flex
    width: 100%
    background-color: #FFFFFF
    border-radius: 2px
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 22px
    color: #262626
  &__add-ingredients-item-close
    text-align: center
  &__dropdown-list-container
    overflow: hidden
    width: 100%
    padding: 0px !important
  &__expand
    background-color: #4C4C4C
  &__expand-icon
    color: white !important
  &__dropwon-list-loading-container
    width: 100%
    text-align: center
    margin: 0 auto
  &__add-ingredients
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 17px
    align-items: center
    color: #262626
  &__select-ingredients-tag-text-outer
    width: 90%
    display: inline-block
    vertical-align: middle
  &__select-ingredients-tag
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 12px
    line-height: 22px
    align-items: center
    color: #262626
    background: #F5F5F5
    border: 1px solid #E8E8E8
    border-radius: 2px
    padding: 1px 8px
    margin-bottom: 2px !important
  &__select-ingredients-individual-tag
    width: 48%
    display: inline-block !important
  &__select-ingredients-category-tag
    width: 100%
  &__text-ellipsis
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    width: 96%
    display: block
  &__category-text-ellipsis
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    width: 90%
    display: inline-block
  &__added-text-ellipsis
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    width: 430px
  &__tooltip-text
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 12px
    line-height: 15px
    color: #FFFFFF
  &__btn-clicked
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 17px
    color: #FFFFFF !important
    border-radius: 2px !important
    background: #595959 !important
    height: 25px
  &__btn-clicked:hover
    border: none !important
  &__btn-clicked:focus
    border: none !important
  &__btn-unclicked
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 17px
    color: #262626 !important
    border-radius: 2px !important
    background: #F5F5F5 !important
    height: 25px

  &__btn-unclicked:hover
    border: none !important
  &__btn-unclicked:focus
    border: none !important
  &__btn-save
    border: 1px solid #595959
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 17px
    background: #FEF2E2 !important
    border-radius: 2px !important
    color: #595959 !important
    width: 88px
    height: 25px
  &__btn-save:hover
    border: none !important
  &__btn-save:focus
    border: none !important
  &__add-load-more
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 17px
    color: #12433D
  &__add-ingredient-result
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 12px
    line-height: 15px
    text-align: right
    padding-right: 16px !important
    margin-right: 10px
    margin-bottom: 10px
    color: #8C8C8C
  &__added-layer
    width: 100%
    margin-top: 20px
    position: absolute
    top: 40px
    height: 100%
  &__already-added
    background: #D9D9D9 !important
    color: darkgray !important
  &__option-selected
    background: #E8E8E8 !important
  &__dropdown-list-item
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 17px
    color: #262626
    width: 100%
    border-bottom: none !important
  &__dropdown-list-item:hover
    background-color: #E8E8E8
  &__dropdown-category-header
    background-color: #FFFFFF
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 17px
    color: #262626
    padding: 1px 4px
  &__dropdown-category-header:focus
    background-color: #8C8C8C
  &__search-input
    background-color: #f5f5f5
    border-radius: 0px
    padding-left: 6px
    padding-right: 4px
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 17px
    color: #595959
    width: 100%
    overflow: hidden
    text-overflow: ellipsis
    margin-left: 8px
    margin-right: 8px
  &__margin-top
    margin-top: 22px
  &__margin-left
    margin-left: 20px
  &__header
    border-bottom: 1px solid #D8D8D8
    padding-bottom: 25px
  &__added-dirty-filter-number
    font-family: Proxima Nova
    font-style: normal
    font-weight: bold
    font-size: 14px
    line-height: 15px
    align-items: center
    text-align: left
    color: #8C8C8C
  &__list-empty-layer
    display: table-cell
    vertical-align: middle
    height: 200px
  &__list-empty-text
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 20px
    align-items: center
    text-align: center
    color: #BFBFBF
  &__list-empty-border
    border: 1px solid #BFBFBF
    padding: 2px 4px
  &__added-ingredients-item
    padding-left: 10px
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 22px
    color: #262626
    border-radius: 2px
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
  &__added-item-delete
    padding-left: 20px !important
  //display: none !important
  &__delete
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
  &__category-ingredient-ck
    font-family: "Proxima Nova"
  &__panel-content
    //margin-bottom: -16px
    //margin-left: -16px
    //margin-right: -16px
    background-color: #F5F5F5
  //padding: 6px 6px 6px 16px
  &__panel-header
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 17px
    color: #262626
    padding-left: 2px
  &__panel-content
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 17px
    color: #262626
#retailerAddedIngredients .ant-list-split .ant-list-item
  border-bottom: 4px solid transparent !important
#retailerAddedIngredients .ant-list-item
  padding: 0px !important
  margin: 0px !important
.retailer-settings-cpc__delete .ant-tooltip-arrow::before
  background-color: #E2EEEC !important
.retailer-settings-cpc__delete .ant-tooltip-inner
  background-color: #E2EEEC !important
  padding: 2px !important
.retailer-settings-cpc__tooltip-text .ant-tooltip-arrow::before
  background-color: #8C8C8C !important
.retailer-settings-cpc__tooltip-text .ant-tooltip-inner
  background-color: #8C8C8C !important
  padding: 2px !important
.retailer-settings-cpc__dropdown-list-container .ant-collapse-borderless > .ant-collapse-item
  border-bottom: none !important
  padding-bottom: 1px
  padding-top: 1px
.retailer-settings-cpc__dropdown-list-container.retailer-settings-cpc__already-added .ant-collapse-borderless .ant-collapse-item .ant-collapse-header
  background-color: #D9D9D9 !important
.retailer-settings-cpc__dropdown-list-container.retailer-settings-cpc__already-added .ant-collapse-borderless .ant-collapse-item .ant-collapse-header .retailer-settings-cpc__panel-header
  color: darkgray
.retailer-settings-cpc__edit-modal--form_container .multiselect__option--highlight .retailer-settings-cpc__dropdown-list-container .ant-collapse-borderless
  background: #e8e8e8
.retailer-settings-cpc__dropdown-list-container .ant-collapse-borderless
  background-color: #FFFFFF
.retailer-settings-cpc__dropdown-list-container .ant-collapse-borderless > .ant-collapse-item-active
  background: #8C8C8C !important
.retailer-settings-cpc__dropdown-list-container .ant-collapse-icon-position-right > .ant-collapse-item-active.ant-collapse-item > .ant-collapse-header .retailer-settings-cpc__panel-header
  color: #595959
.retailer-settings-cpc__dropdown-list-container .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header
  padding: 0px !important
.retailer-settings-cpc__dropdown-list-container .ant-collapse-icon-position-right > .ant-collapse-item-active.ant-collapse-item > .ant-collapse-header
  padding: 0px !important
  background: #8C8C8C !important
#retailerAddedIngredients .ant-collapse-borderless > .ant-collapse-item
  border-bottom: none !important
#retailerAddedIngredients .ant-collapse-borderless
  background-color: #FFFFFF
#retailerAddedIngredients .ant-collapse-borderless > .ant-collapse-item-active
  background: #8C8C8C !important
#retailerAddedIngredients .ant-collapse-icon-position-right > .ant-collapse-item-active.ant-collapse-item > .ant-collapse-header .retailer-settings-cpc__panel-header
  color: #FFFFFF
  padding: 2px
  margin-top: 2px
  margin-bottom: 2px
#retailerAddedIngredients .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header
  padding: 0px !important
#retailerAddedIngredients .ant-collapse-icon-position-right > .ant-collapse-item-active.ant-collapse-item > .ant-collapse-header
  padding: 0px !important
  background: #8C8C8C !important
.retailer-settings-cpc__panel .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow
  margin-top: 2px
#addedDirtyList .ant-list-empty-text
  display: table !important
  margin: 0 auto !important
.retailer-settings-cpc__edit-modal--form_container .multiselect__option
  padding: 0px !important
  min-height: 17px !important
.retailer-settings-cpc__edit-modal--form_container .multiselect__option--highlight
  background: #E8E8E8
.retailer-settings-cpc__edit-modal--form_container .multiselect__tags-wrap
  width: 100%
  display: block
.retailer-settings-cpc__select-ingredients-tag:hover
  background: #FEF2E2
.retailer-settings-cpc__edit-modal--form_container .multiselect__content >.multiselect__element .multiselect__option--selected
  background: #E8E8E8 !important
  color: #262626 !important
  font-weight: normal !important
.retailer-settings-cpc__edit-modal--form_container .multiselect__content >.multiselect__element >.multiselect__option >.retailer-settings-cpc__dropdown-list-container.retailer-settings-cpc__option-selected .ant-collapse-borderless
  background: #E8E8E8 !important
.retailer-settings-cpc__edit-modal--form_container .multiselect__content >.multiselect__element >.multiselect__option >.retailer-settings-cpc__dropdown-list-container.retailer-settings-cpc__option-selected .ant-collapse-borderless .ant-collapse-item-active .ant-collapse-header span
  color: #FFFFFF
.retailer-settings-cpc__edit-modal--form_container .multiselect__content >.multiselect__element >.multiselect__option >.retailer-settings-cpc__dropdown-list-container.retailer-settings-cpc__option-selected .ant-collapse-borderless .ant-collapse-content > .ant-collapse-content-box
  padding: 0px
.retailer-settings-cpc__edit-modal--form_container  .multiselect__content >.multiselect__element >.multiselect__option .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content
  background: #F5F5F5
  border-radius: 0px
.retailer-settings-cpc__edit-modal--form_container .multiselect__content >.multiselect__element >.multiselect__option >.retailer-settings-cpc__dropdown-list-container.retailer-settings-cpc__option-selected .ant-collapse-borderless .ant-collapse-content > .ant-collapse-content-box .retailer-settings-cpc__category-ingredient-ck
  margin-left: 16px !important
#modifyCategory .ant-collapse-item .ant-collapse-header
  padding: 12px 0px
  padding-right: 16px
//background: #E8E8E8 !important
#modifyCategory .ant-collapse-item .ant-collapse-content
  background: #F5F5F5
#modifyCategory .ant-collapse-borderless .ant-collapse-item-active .ant-collapse-header span
  color: #FFFFFF
#modifyCategory .ant-collapse-borderless .ant-collapse-content > .ant-collapse-content-box
  padding: 0px
#modifyCategory .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content
  background: #F5F5F5
  border-radius: 0px
#modifyCategory .ant-collapse-borderless .ant-collapse-content > .ant-collapse-content-box .retailer-settings-cpc__panel-content
  margin-left: 16px !important
.retailer-settings-cpc__added-item-delete:hover
  display: inline-block !important
.retailer-settings-cpc__delete .ant-popover-inner-content
  padding: 2px
  img
    margin: 0px
.retailer-settings-cpc__panel-header .ant-checkbox-checked .ant-checkbox-inner
  background-color: #D9D9D9 !important
  border: 1px solid #FFFFFF !important
.retailer-settings-cpc__panel-header .ant-checkbox-checked .ant-checkbox-inner:after
  border-color: #595959 !important
.retailer-settings-cpc__category-ingredient-ck .ant-checkbox-checked .ant-checkbox-inner
  background-color: #FFFFFF !important
  border: 1px solid #D9D9D9 !important
.retailer-settings-cpc__category-ingredient-ck .ant-checkbox-checked .ant-checkbox-inner:after
  border-color: #595959 !important
.retailer-settings-cpc__dropdown-list-container .retailer-settings-cpc__panel-header .ant-checkbox-checked .ant-checkbox-inner
  background-color: #D9D9D9 !important
  border: 1px solid #FFFFFF !important
.retailer-settings-cpc__dropdown-list-container .retailer-settings-cpc__panel-header .ant-checkbox-checked .ant-checkbox-inner:after
  border-color: #595959 !important
.retailer-settings-cpc__dropdown-list-container .retailer-settings-cpc__category-ingredient-ck .ant-checkbox-checked .ant-checkbox-inner
  background-color: #FFFFFF !important
  border: 1px solid #D9D9D9 !important
.retailer-settings-cpc__dropdown-list-container .retailer-settings-cpc__category-ingredient-ck .ant-checkbox-checked .ant-checkbox-inner:after
  border-color: #595959 !important
.retailer-settings-cpc__added-ingredient-item:hover
  background-color: #d9d9d9
#retailerDirtyIngredientSearch .ant-input-affix-wrapper .ant-input:not(:last-child)
  background: #f5f5f5
#addedDirtyList .ant-pagination-item-active
  background: #595959
  border-color: #595959
#addedDirtyList .ant-pagination-item
  border-radius: 2px
#addedDirtyList .ant-pagination-item-active a
  color: #FFFFFF
#addBannedIngredient .multiselect__tags
  border-radius: 2px
  padding: 8px
  padding-bottom: 0px
#addBannedIngredient .multiselect__content-wrapper
  border-bottom-left-radius: 2px
  border-bottom-right-radius: 2px
#countBannedIngredient .ant-tag
  background: #FEF2E2
  border: 1px solid #FEC206
  border-radius: 2px
  font-family: Proxima Nova
  font-style: normal
  font-weight: normal
  font-size: 14px
  line-height: 17px
  color: #FEC206
.retailer-settings-cpc__search-input > input.ant-input
  background: #f5f5f5
.categoryTooltip1.ant-tooltip
  max-width: 560px
.categoryTooltip1 .ant-tooltip-inner
  font-size: 10px !important
  -webkit-text-size-adjust: none
.categoryTooltip.ant-tooltip
  max-width: 690px
.categoryTooltip .ant-tooltip-inner
  font-size: 10px !important
  -webkit-text-size-adjust: none
