
.subcategories-filter
  .dropdown-item
    cursor: pointer
    height: 27px
    padding: 0px
    padding-left: 14px
    font-size: 14px
    color: rgba(0, 0, 0, 0.65)
    font-weight: 300

  &__subcategories--visible
    display: block
    padding-bottom: 15px

  &__header
    position: relative
    display: block
    cursor: pointer
    font-family: Poppins
    font-size: 14px
    color: #000000
    font-weight: 600
    padding-top: 10px
