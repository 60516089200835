
.subscribe-success__btn.ant-btn > span {
  text-transform: uppercase;
}
.subscribe-success__btn.ant-btn:hover,
.subscribe-success__btn.ant-btn:focus,
.subscribe-success__btn.ant-btn:active,
.subscribe-success__btn.ant-btn.active {
  background: #f15a42;
  color: white;
  border: none;
}
