<template>
  <div class="privacy">
    <h1 class="privacy__main-header">
      Privacy Policy
    </h1>
    <div class="privacy__content">
      <p>
        Last Updated: February 20, 2020
        <br />
        <br />
        Landing understands you care about how your personal information is used and shared, and we
        take your privacy seriously. Please read the following to learn more about our privacy
        practices. By using or accessing the Services in any manner, you acknowledge that you accept
        the practices and policies outlined in this Privacy Policy, and you hereby consent that we
        will collect, use, and share your information in the following ways.
        <br />
        <br />
        Remember that your use of Landing&rsquo;s Services is subject to the Terms of Use, which
        incorporates this Privacy Policy. Any terms we use in this Policy without defining them have
        the definitions given to them in the Terms of Use.
        <br />
        <br />
        Scope of the Privacy Policy
        <br />
        Our platform integrates every step of the product retail lifecycle into one easy-to-use
        system. From discovery features that help buyers find new brands to training tools that help
        brands educate in-store staff, our platform provides the infrastructure that brands need to
        simplify and streamline their internal processes.
        <br />
        <br />
        Information Collected
        <br />
        We gather various types of personal information from our users, as explained in more detail
        below. We use this personal information internally in connection with our Services,
        including to: personalize, provide, and improve our Services; allow you to set up a user
        account and profile; contact you and allow other users to contact you; fulfill your requests
        for certain products and services; and analyze how you use the Services. In certain cases,
        we may also share some personal information with third parties, but only as described below.
        <br />
        <br />
        Information You Provide to Landing
        <br />
        We receive and store any information you knowingly provide to us. For example, if you create
        an account as a Retailer or Brand or place an order with us, we may collect personal
        information such as:
        <br />
        <br />
        Retailers:
        <br />
        When you register as a Retailer with Landing, we will collect your first and last name,
        email address, business address, retailer name, retailer address(es), years you have been in
        business, website, desired business terms (e.g. margin, payment terms, etc.) and parent
        company name, if applicable.
        <br />
        If you place an order with Landing, we will collect information to facilitate that order,
        such as your shipping address.
        <br />
        <br />
        Brands:
        <br />
        When you register as a Brand with Landing, we will collect your first and last name, brand
        name, website, address, phone number, and information about your brand such as the primary
        category of products you provide, number of stores you have, the countries you operate in,
        and your social media profiles.
        <br />
        <br />
        If you are accepted as a Brand for Landing, we may ask for additional information to build
        out your profile such as your profile photo, wholesale pricing and order requirements, and
        years you have been established.
        <br />
        <br />
        When you receive an order through Landing, we will collect information to facilitate that
        order, such as certain financial information (e.g. bank account information) so we can pay
        you.
        <br />
        <br />
        Communication with Landing
        <br />
        We collect personal information from you when you communicate with us, for example when you
        request information about our services, contact our Support team, apply for a job, or
        register for our newsletter.
        <br />
        <br />
        Information Collected Automatically
        <br />
        Whenever you interact with our Services, we automatically receive and record information on
        our server logs from your browser or device, which may include your IP address, geolocation
        data, device identification, the type of browser and/or device you&rsquo;re using to access
        our Services, and the page or feature you requested.
        <br />
        <br />
        Additionally, we, as well as third parties that provide content, advertising, or other
        functionality on our Services, may use cookies, pixel tags, local storage, and other
        technologies to collect information automatically through our Services.
        <br />
        <br />
        These technologies are small data files that we transfer to your browser or device that
        allows us to provide and enhance our Services. You may be able to change the preferences on
        your browser or device to prevent or limit your device&rsquo;s acceptance of these
        technologies, but this may prevent you from taking advantage of some of our features.
        <br />
        <br />
        Analytics
        <br />
        We may use analytics service providers, including Google Analytics, to collect information
        regarding visitors to our Services, such as their behavior on our Services or information
        about their demographic. For more information about Google Analytics, see
        <a
          target="_blank"
          class="privacy__link_color"
          href="https://www.google.com/policies/privacy/partners"
        >
          www.google.com/policies/privacy/partners
        </a>
        . To opt out of Google Analytics, visit tools.google.com/dlpage/gaoptout.
        <br />
        <br />
        Do-Not-Track
        <br />
        Your browser may offer you a &ldquo;Do Not Track&rdquo; option, which allows you to signal
        to operators of websites and web applications and services (including behavioral advertising
        services) that you do not wish such operators to track certain of your online activities
        over time and across different websites. At this time, we do not respond to nor honor
        Do-Not-Track signals from web browsers.
        <br />
        <br />
        Landing&rsquo;s Use of Your Personal Information
        <br />
        We use the information we collect about you for various business purposes as described
        below.
        <br />
        <br />
        Provide and improve our Services
        <br />
        Fulfill your requests for products and services
        <br />
        Communicate with you about your account and updates to our Services
        <br />
        Process payments
        <br />
        Provide advertising, analytics, and marketing
        <br />
        Process applications and transactions
        <br />
        Facilitate communications between you and other users
        <br />
        Other purposes you consent to or are notified of at the time you provide personal
        information
        <br />
        Detect security incidents; protect against malicious, deceptive, fraudulent, or illegal
        activity
        <br />
        Ensure quality control
        <br />
        Verify your identity and prevent fraud
        <br />
        Debug to identify and repair errors
        <br />
        Enforce our Terms and policies
        <br />
        Audit or other compliance activities
        <br />
        Comply with our legal obligations, protect your vital interest, or as may be required for
        the public good
        <br />
        <br />
        De-Identified and Aggregated Information
        <br />
        We may use personal information to create de-identified, aggregated information such as:
        information about demographics, de-identified location information, or information about
        devices used to access our Services.
        <br />
        <br />
        Automatic Collection Technologies
        <br />
        Our use of automatic collection technologies such as cookies, pixel tags, and local storage
        objects fall into the following general categories:
        <br />
        <br />
        Strictly Necessary: Technologies that allow you to access our Services and use our features,
        and tools that help us identify irregular site behavior and prevent fraudulent activity or
        improve security.
        <br />
        Personalization: Technologies that help us personalize advertising and content for you, such
        as by making inferences about your interests based on the content you interact with on our
        Services, which informs future selections of content or advertising.
        <br />
        Measurement: Technologies that collect information about your use of our Services, including
        measuring, understanding, and reporting on your usage of the Services.
        <br />
        Information Storage and Access: Technologies that store information or provide access to
        information that is already stored on your device, such as your shopping cart.
        <br />
        Content Selection, Delivery, and Reporting: Technologies that select and deliver content for
        you, and measure the effectiveness of that content, including information about what content
        was shown, how often or how long it was shown, when and where it was shown, and what
        actions, if any, you took on the content.
        <br />
        Ad Selection, Delivery, and Reporting: Technologies that select and deliver advertisements
        for you, and measure the effectiveness of those advertisements, including information about
        what advertisements were shown, how often or how long they were shown, when and where they
        were shown, and what actions, if any, you took on the advertisements.
        <br />
        <br />
        Third-Party Websites
        <br />
        We may offer links to third-party websites or Services, which are not controlled by us and
        not subject to the protections laid out in this Privacy Policy. We do not endorse nor
        approve any third-party website and we encourage our users to read the Privacy Policy of
        each service they interact with.
        <br />
        <br />
        Cross-Device Tracking
        <br />
        Your browsing activity may be tracked across different devices or Landing Services. For
        instance, we may match your activity on our website using your mobile device with your
        browsing activity on your laptop. To do this, we may match information such as your browsing
        patterns, geolocation, and device identifiers where it appears to be related to the same
        user.
        <br />
        <br />
        APIs and SDKs
        <br />
        We may use third-party APIs and software development kits (&ldquo;SDKs&rdquo;), which may
        allow third parties to collect personal information about you for various purposes, such as
        to conduct analytics, verify your business when you register with Landing, or provide
        customized content. For more information about our use of APIs and SDKs, contact us as
        described below.
        <br />
        <br />
        Landing Policy on Sharing Personal Information
        <br />
        We do not rent nor sell your personal information in personally identifiable form to anyone.
        <br />
        <br />
        Children&rsquo;s Information
        <br />
        As noted in the Terms of Use, we do not knowingly collect nor solicit personal information
        from anyone under the age of 18. If you are under 18, please do not attempt to register for
        the Services or send any personal information about yourself to us. If we learn that we have
        collected personal information from a child under age 18, we will delete that information as
        quickly as possible. If you believe that a child under 18 may have provided us with personal
        information, please contact us as described below.
        <br />
        <br />
        Security of Personal Information
        <br />
        You must prevent unauthorized access to your account and personal information by selecting
        and protecting your password appropriately and limiting access to your computer or device
        and browser by signing off after you have finished accessing your account.
        <br />
        <br />
        We endeavor to protect the privacy of your account and other personal information we hold in
        our records, but unfortunately, we cannot guarantee complete security. Unauthorized entry or
        use, hardware or software failure, and other factors, may compromise the security of user
        information at any time.
        <br />
        <br />
        Your Privacy Rights and Choices
        <br />
        You can always opt not to disclose information to us, but keep in mind some information may
        be needed to register with us or to take advantage of some of our features.
        <br />
        <br />
        Opting Out of Communications: If you no longer wish to receive communications from us, click
        the unsubscribe link at the bottom of the email or follow opt-out instructions provided in
        other communication channels. Note you may still receive transactional emails from us.
        <br />
        <br />
        Opting Out of Cookies and Advertising: You may stop or restrict the placement of cookies on
        your device or remove them as your browser or device permits. Visit
        <a
          target="_blank"
          class="privacy__link_color"
          href="https://www.networkadvertising.org/managing/opt_out.asp"
        >
          www.networkadvertising.org/managing/opt_out.asp,
        </a>
        <a target="_blank" class="privacy__link_color" href="https://www.youronlinechoices.eu">
          www.youronlinechoices.eu, www.youradchoices.ca/choices,
        </a>
        and
        <a target="_blank" class="privacy__link_color" href="https://www.aboutads.info/choices">
          www.aboutads.info/choices
        </a>
        to learn more about the choices available to opt out of cookies and advertising.
        <br />
        <br />
        Updating Your Account Information: Through your account settings, you may access, and, in
        some cases, edit or delete the following information you&rsquo;ve provided to us:
        <br />
        name
        <br />
        email address
        <br />
        location
        <br />
        payment information
        <br />
        <br />
        The information you can view, update, and delete may change as the Services change. If you
        have any questions about viewing or updating information we have on file about you, please
        contact us as described below.
        <br />
        <br />
        You may also have privacy rights including:
        <br />
        <br />
        Access Data: You may have the right to receive a copy of data we have about you.
        <br />
        Request Correction of Data: You may request that we update information we have about you.
        <br />
        Request Deletion of Data: You may request that we delete information we have about you.
        <br />
        Request Restriction of or Object to Processing: You may have the right to opt in or opt out
        of certain uses of your data.
        <br />
        If you would like to exercise any of these rights, please contact us as described below. We
        will process your request in the time and manner required by law. To protect your privacy,
        we may take steps to verify your identity before fulfilling your request.
        <br />
        <br />
        Data Retention
        <br />
        We store personal information for as long as you use our Services or as may be necessary to
        fulfill the purposes for which the information was collected, provide our Services, resolve
        disputes or establish legal defenses, enforce our Terms or other agreements, engage in
        audits, protect our Services, prevent fraud, comply with the law, or for legitimate business
        purposes.
        <br />
        <br />
        Security
        <br />
        We take steps to treat your information securely and make sure it is treated in accordance
        with this Privacy Policy. No system is 100% secure, and we cannot ensure nor warrant the
        security of any information you provide us.
        <br />
        <br />
        Updates to the Privacy Policy
        <br />
        We&rsquo;re constantly trying to improve our Services, so we may need to change this Privacy
        Policy from time to time as well, but we will alert you to material changes by placing a
        notice on the www.landinginternational.com website, by sending you an email, and/or by some
        other means.
        <br />
        <br />
        By using the Services after any changes to the Privacy Policy have been posted, you agree to
        the new Privacy Policy.
        <br />
        <br />
        Inquiries regarding this Policy
        <br />
        If you have any questions or concerns regarding our privacy policies, please contact us at:
        <br />
        <br />
        Landing International, Inc..
        <br />
        1330 W. 12th Street, Suite A
        <br />
        Los Angeles, CA 90015
        <br />
        <a href="url" class="privacy__link_black">privacy@landingintl.com</a>
      </p>
    </div>
  </div>
</template>

<style>
.privacy {
  max-width: 1200px;
  width: 85%;
  margin: 0 auto;
}

.privacy__main-header {
  color: #12433d;
  font-size: 45px;
  font-weight: bold;
  font-family: "Playfair Display Bold";
  letter-spacing: 0.44px;
  margin-bottom: 40px;
  text-align: left;
}

.privacy__content {
  margin-bottom: 20px;
  margin-left: 20px;
  font-family: "ProximaNova-Regular", "ProximaNova", "Roboto", sans-serif;
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
}
.privacy__link_color {
  color: #008489;
}
.privacy__link_black {
  color: #000000;
}
</style>
