
.retailer-products
  padding: 0 20px 0 30px
.retailer-products__not-found
  font-size: 12px
  font-weight: 600
  padding-top: 15px
  padding-left: 10px
.filter-style
  min-height:90vh

.retailer-categories
  padding-top: 10px
  padding-bottom: 10px

.filter-tags-results
  padding-left: 10px
  padding-top: 5px

.retailer-categories__header
  font-size: 14px
  font-weight: 600
  margin-top: 16px

.retailer-categories__back-to
  cursor: pointer
  font-size: 14px
  font-weight: 300
  padding-top: 10px

.retailer-products
  padding-top: 10px
  padding-bottom: 50px


  &__view-name-select
    margin-left: 11px

  &__view-no-background
    background: none !important

  &__results
    display: flex
    justify-content: flex-end
    font-size: 12px
    margin-top: 8px
    padding: 0px

  .retailer-product-wrapper
    max-width: 250px
    margin: 0 auto

  .retailer-products-page__title
    font-size: 14px
    color: #000
    border-bottom: 1px solid EBEFF2
    padding: 15px

  .retailer-products-page__header
    font-size: 14px
    font-weight: 600
    text-transform: uppercase

  .retailer-products-page__filter
    cursor: pointer
    margin: 0px 48px 0px 0px

  .title__navigationalTag
    margin: -4px 0px 22px 0px
    font-size: 28px
    font-weight: 300
    line-height: 32px

  .filter__control
    margin-top: 30px

  select
    border-radius: 0px !important
    border-color: #e8e8e8 !important
    color: #000
    font-size: 12px
    letter-spacing: 0.17px
    line-height: 20px
    height: 39px
    width: 141px
    padding: 10px 0px 9px 14px
