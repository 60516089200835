
.retailer-search
  font-family: Proxima Nova
  display: flex
  position: relative !important
  width: 80%
  height: 100%
  align-items: top
  margin: auto
  padding-top: 50px
.container
  &__query
    position: absolute
    display: inline-block !important
    border: 1px solid gray
    border-radius: 14px
    border-color: #e6e6e6
    box-shadow: 1px 1px 4px 1px #d9d9d9
    width: 34%
    margin-top: 8px
    height: auto !important
    padding-bottom: 5px
    &-search
      display: flex
      align-items: center
      height: 60px
      padding:0 18px
      .ant-input
        border: none
      .inp
        flex:1
      .icon
        margin-right: 8px
        cursor: pointer
  &__display
    position: relative
    display: inline-block !important
    left: 35%
    width: 55%
    mid-width: 670px
    // top: 0
    height: auto
    min-height: 700px
    margin-left: 5px

.section-text
  display: flex
  width: 100%
  font-weight: 620
  font-size: 16px
  padding-top: 10px
  padding-bottom: 10px
  margin-left: 15px

.location-text
  font-size: 16px
  color:#262626
  margin-bottom: 9px

  &__icon
    display: flex
    width: 100%
    font-weight: 620
    font-size: 16px
    padding-top: 10px
    padding-bottom: 10px
    margin-left: 40px
    margin-top: 10px

.search-field
  display: block
  border-bottom: 1px solid #ffffff
  padding-top: 10px
  padding-bottom: 10px

.location-tags
  display: flex
  flex-wrap: wrap
  width: 100%
  border-top: 1px solid #D9D9D9
  padding: 24px 18px
.tag-box
  width: 100%
  display: flex
  // margin-left: 25px
  // height: 70px
  overflow-y: auto
  .tags
    margin: 0.25em
    display: inline-block !important
    float: left

.retailer-directory-toggle
  display: block
  width: 100%
  border: 1px solid #d4d4d4

  & :hover
    cursor: pointer


.filters
  display: block
  position: relative
  bottom: 0

.search-item
  text-align: center
  //padding-left: 10px
  // background: blue
  // width: 100%

.search-box
  width: 95%
  padding: 20px 20px 20px 36px
  border: 1px solid #dbdbdb
  //border-radius: 5px
  height: 25px
  margin: 10px 0px 10px 0px



.retailer-items
  // background-color: orange
  // height: 100%
  // margin-left: 42px


.retailer-directory
  // background-color: pink
  margin-left: 42px

.pagination-footer
  padding-top: 30px
  // background-color: green
  width: 100%
  height: 80px
  margin: 0 auto

.section-title
  display: float
  float: left
  height:100%
  width: 70%
  font-size: 48px !important
  font-weight: 700
  margin-bottom: 33px

.section-results
  display: float
  float: right
  bottom: 0%
  height:100%
  width: 20%
  font-size: 15px
  font-weight: 500

.filter-tag-box
  display: inline-block
  position: relative
  width: 782px

.retailer-items-title
  // height: 100px
  // background-color: blue
