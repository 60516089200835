
.admin-search-items {
  &__header-title {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.13125px;
    text-transform: uppercase;
    display: inline-block;
  }
  &__top-border {
    background: #ffffff;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    padding: 12px 16px;
  }
}
.admin-console-search-item .ant-collapse {
  background-color: white;
  border-radius: 0px;
}
.admin-console-search-item .ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0px;
}
.admin-console-search-item .ant-collapse > .ant-collapse-item {
  border-bottom: none;
}
.admin-console-search-item .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px;
}
.admin-console-search-item .ant-collapse-borderless {
  background-color: white;
}
.admin-console-search-item .admin-console-search-item-child .ant-collapse:last-child {
  border-bottom: none;
}
.admin-console-search-item-child
  .ant-collapse-icon-position-right
  > .ant-collapse-item
  > .ant-collapse-header {
  padding: 2px 4px;
  padding-right: 0px;
}
.admin-console-search-item-child .ant-collapse-borderless > .ant-collapse-item {
  border: none;
}
.admin-console-search-item .ant-collapse:last-child {
  border-bottom: 1px solid #d9d9d9;
}
.admin-search-items__header-title .ant-checkbox + span {
  padding-left: 6px;
  padding-right: 4px;
}
