
@font-face {
  font-family: "Proxima Nova";
  src: url("../../assets/fonts/Proxima-Nova-Light.otf") format("truetype");
}
.training-product-card__main {
  width: 220px;
  height: 364px;
  border: 2px solid #ffffff;
  .prod-img {
    max-width: 220px;
    height: 264px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 90%;
      max-height: 90%;
    }
  }
  .prod-content {
    text-align: center;
    font-size: 13px;
    font-family: "Proxima Nova";
    .content-title {
      width: 80%;
      margin: 0 auto;
      text-align: center;
      height: 44px;
      word-wrap: break-word;
      word-break: normal;
      //display: block;
      //white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      //line-height: 44px;
    }
  }
}
.training-product-card__main:hover {
  border: 2px solid #8c8c8c;
  cursor: pointer;
}
.training-product-card-click__main {
  border: 2px solid #8c8c8c;
}
.ant-btn-primary {
  background-color: #12433d;
}
.ant-modal-confirm .ant-modal-confirm-btns {
  text-align: center;
  float: none;
}
.dialog-close-btn {
  width: 200px;
  height: 33px;
  line-height: 33px;
  border-radius: 2px;
  font-size: 14px;
  margin-top: 27px;
  background-color: #12433d;
  color: white;
  text-align: center;
  margin-left: 110px;
  cursor: pointer;
}
.dialog-close-btn:hover {
  background-color: #4a8a5d;
  color: #ffffff;
}
.ant-modal-confirm-title {
  text-align: center;
}
.info-dialog-main {
  text-align: center;
  background-color: yellow;
}
.dialog-info-before-submit {
  //width: 236px;
  text-align: center;
  color: #12433d;
  font-size: 13px;
  //margin-left: 77px;
  margin-top: 23px;
  font-family: "Proxima Nova", "Roboto", sans-serif;
}
.dialog-info-title {
  text-align: center;
  font-size: 16px;
  margin-bottom: 23px;
  //height: 16px;
}
.dialog-content-style {
  width: 70%;
  margin-left: 60px;
}
