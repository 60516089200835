
button.button {
  font-family: Niveau Grotesk;
  border-radius: 100px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 17px 35px;
  height: fit-content;
}

.button.is-medium {
  font-size: 14px;
  line-height: 16px;
}

.button.is-large {
  font-size: 18px;
  line-height: 21px;
}

.button.is-primary {
  color: white !important;
  background-color: #f15a42 !important;
}
