
.payment-method {
  font-family: Proxima Nova;
  .wire-transfer-content {
    font-size: 9px;
    line-height: 20px;
    color: #000000;
  }
  .action {
    display: flex;
    justify-content: center;
    padding-top: 24px;
    .ant-btn.green-btn {
      display: inline-flex;
      align-items: center;

      color: #4a8a5d;
      font-size: 16px;
      font-family: Proxima Nova;
      border: 1px solid #4a8a5d;
      .anticon {
        font-size: 20px;
        svg {
          color: #4a8a5d;
        }
      }
    }
  }
  .ant-radio-group {
    display: flex;
    flex-direction: row;
    gap: 24px;
    padding-bottom: 24px;
  }
  .ant-radio-button-wrapper {
    padding: 0 24px;
    border-left: 1px solid #d9d9d9;
    span {
      font-family: Proxima Nova;
      font-size: 12px;
      color: #595959;
    }
    .anticon {
      font-size: 18px;
      padding-right: 5px;
      color: rgba(0, 0, 0, 0.85);
    }
  }

  label.ant-radio-button-wrapper-checked {
    background-color: #e2eeec;
    border: 1px solid transparent !important;
  }
}
.payment-card {
  h3 {
    padding-left: 8px;
  }
  width: 375px;
  .ant-form-item {
    margin-bottom: 18px;
  }

  .f-col {
    display: flex;
    flex-direction: row;
    gap: 24px;
  }

  .anticon {
    svg {
      color: rgba(0, 0, 0, 0.65);
    }
  }
}
