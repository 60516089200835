
.cart-product-group
  min-height: 220px

.cart-product-group__link
  color: #161616
  font-size: 14px
  font-weight: 300
  letter-spacing: 0.16px
  line-height: 18px
  text-decoration: underline !important
  text-align: center

.retailer-cart
  margin-bottom: 32px
  min-height: 80vh
  margin-left: 124px
  margin-right: 122px

  &__navbar
    margin-top: 20px
    padding-bottom: 10px
    border-bottom: 1px solid #DFE3E8

  &__tabs
    margin-top: 25px
    border-bottom: 1px solid #DFE3E8
    height: 40px
    box-sizing: border-box

  &__container
    border: 1px solid #E5E5E5
    margin-bottom: 25px

  &__header
    padding-top: 30px
    width: 200px
    color: #212B36
    font-size: 28px
    font-weight: 300

  &__tab
    font-size: 14px
    color: #292929
    font-weight: 600
    width: 115px
    cursor: pointer
    width: 100px
    text-align: center
    margin-right: 5px

  &__tab--active
    border-bottom: 2px solid black
    letter-spacing: 0.1px

  &__banner
    height: 80px
    border: 1px solid #E5E5E5
    background-color: #F4F6F8
    position: relative

    &-border
      height: 3px
      background-color: rgba(145,158,171,0.54)
      position: absolute
      top: 0px
      width: 100%

    &-title
      align-items: center
      display: flex
      justify-content: space-evenly
      justify-items: start
      height: 83px
      margin-left: 23px
      color: #333333
      font-size: 16px
      font-weight: 500
      line-height: 24px

    &-price
      align-items: center
      display: flex
      justify-content: space-evenly
      justify-items: start
      height: 83px
      margin-left: 40px
      color: #AAAAAA
      font-size: 16px
      line-height: 20px

    &-links
      display: flex
      height: 83px
      align-items: center
      justify-content: flex-end
      margin-right: 23px

      &--divider
        margin: 0px 16px 0px 16px

      &--link
        text-transform: capitalize
        color: #161616
        font-size: 14px
        font-weight: 300
        letter-spacing: 0.16px
        line-height: 18px
        position: relative

        &-underlined
          border-bottom: 1px solid #393939
          position: absolute
          top: 17px
          width: 100%

  &__input
    width: 74px
    height: 41px
    position: relative
    text-align: center

    &-plus
      position: absolute
      top: 0px
      right: 0px
      border-left: 1px solid #E5E5E5
      border-bottom: 1px solid #E5E5E5
      height: 21px
      width: 21px
      display: flex
      align-items: center
      justify-content: center
      cursor: pointer

    &-minus
      position: absolute
      top: 20px
      right: 0px
      border-left: 1px solid #E5E5E5
      height: 21px
      width: 21px
      display: flex
      align-items: center
      justify-content: center
      cursor: pointer

  &__input_right
    text-align: right !important

  &__title
    color: #292929 !important
    font-size: 14px
    line-height: 18px
    margin: 24px 0px 5px 0px

  &__subtitle_black
    color: #000000
    font-size: 16px
    font-weight: 600
    line-height: 22px
    margin-bottom: 3px

  &__subtitle-crossed-out
    text-decoration: line-through
    position: absolute
    top: 93px
    color: #8D8D8D
    font-size: 14px
    line-height: 18px
    left: 58px

  &__subtitle
    color: #9B9B9B
    font-size: 14px
    line-height: 20px
    margin-bottom: 24px

  &__subtitle-brown
    color: #442B2D
    font-size: 14px
    line-height: 22px

  &__type
    color: #000000
    font-size: 14px
    line-height: 22px

  &__product
    display: block

  &__product-border
    border-bottom: 1px solid #dbdbdb

  &__picture
    padding: 30px

  &__price
    padding: 7px
    text-align: justify
    position: relative

  &__price-input
    width: 60px
    margin-top: 5px

  &__footer
    padding: 0px 10px 20px
    font-size: 11px

  &__total
    padding: 0px 10px 20px
    font-size: 13px
    display: flex
    justify-content: flex-end

  &__type-price
    color: #000000
    font-size: 14px
    font-weight: 600

  &__type
    font-size: 14px

  &__product-info
    padding: 30px 0px 30px 0px

  &__image
    display: flex
    justify-content: center

    img
      max-height: 112px
      max-width: 112px

  &__table-row
    height: 90px
    margin-left: -2px

  &__table-border
    padding-top: 20px
    border-bottom: 1px solid #E5E5E5

  &__column-with-image
    display: grid !important
    justify-content: center
    padding: 24px 0px 20px 0px
    align-content: space-between

  &__column-with-table
    margin-top: 6px

  &__summary
    border: 1px solid #E5E5E5
    margin-left: 25px

    &--title
      color: #000000
      font-size: 18px
      letter-spacing: 0.25px
      line-height: 24px
      padding: 24px 23px

    &--brand-order
      display: flex
      justify-content: space-between
      height: 44px
      align-items: center
      margin: 0px 19px
      border-top: 1px solid #E5E5E5
      margin: 0px 16px

  &__button
    height: 46px
    font-size: 13px
    line-height: 20px
    margin-top: 8px

  &__button-position
    margin-left: 25px

  &__table
    width: 100%

  &__close-button
    cursor: pointer

  &__alert
    color: #D0021B
    margin-top: -25px

  &__prices
    padding-top: 30px

  &__subtitle--line-through
    color: #8D8D8D
    font-size: 14px
    text-decoration: line-through

  &__subtotals
    padding-top: 10px
    font-size: 14px
    letter-spacing: 0.25px
    color: #000000

  &__subtotals-item
     padding-bottom: 10px
     padding-left: 25px

     &--bold
       font-weight: bold

  &__subtotals-prices
    padding-left: 25px

  &__subtitle-row
    padding-top: 15px
    padding-bottom: 15px
    border-bottom: 1px solid #E5E5E5

  &__subtitle-row-column
    font-size: 14px
    color: #292929
    margin-left: 20px

    &--padding
     font-size: 14px
     color: #292929
     padding-left: 50px

.disabled
  pointer-events: none

.quantity-input
  max-width: 80px
.retailer-cart__banner-title >h1
  font-size: 16px
  color: #333
  line-height: 24px
  font-weight: normal
.retailer-cart__banner-price >h1
  font-size: 16px
  color: #333
  line-height: 20px
  font-weight: normal
