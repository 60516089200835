
.checkout-success
  padding-top: 70px
  padding-bottom: 200px

  &__title
    height: 60px
    color: #292929
    font-size: 40px
    font-weight: 700
    letter-spacing: 0.08px
    line-height: 60px
    margin-top: 41px

  &__tab
    font-weight: 500
    width: 115px !important
    letter-spacing: 0.1px

  &__subtitle
    margin-top: 80px

  &__subtitle__text
    width: 678px
    font-size: 16px
    font-weight: 600
    letter-spacing: 0.5px
    line-height: 22px
    margin-top: 20px
    color: #292929

  &__buttons
    margin-top: 40px
    display: flex
    justify-content: flex-start
    align-items: center
    div
      display: flex
      justify-content: flex-start
      margin-right: 15px
    .margin-rights-tyle
      margin-right: 15px

  &__buttons__button
    margin-top: 11px
    height: 46px
    width: 310px
    font-size: 14px
