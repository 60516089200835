
.border-line
    border-bottom: 1px solid #ddd
    width: 352px
    margin-bottom: 7px

.member-added
    color: #333
    font-size: 14px
    white-space: normal
    word-break: break-all

.margin-label
    margin-bottom: 0px !important

.margin-value
    margin-bottom: 20px

.done
    font-size: 14px
    width: 82px
    height: 38px

.invite-more
    height: 38px
    font-size: 14px
    width: 144px
    margin-right: 7px

.brand-invite-modal__input--fullwidth
  width: 327px

.added-member-modal
  .modal-card-body
    display: flex

  .request-term-details-info
    color: #000
    font-size: 14px
    font-weight: 400
    margin: 0px !important

  .infoText
    margin-bottom: 20px

  &__header
    color: #212B36
    font-size: 20px
    border-bottom: 1px solid #E1E4E9
    padding-top: 15px
    padding-left: 20px
    padding-bottom: 15px
    .added-member-modal__close-button
      top: 16px
      width: 14px
      height: 14px

  &__content
    position: relative
    background-color: #FFFFFF
    width: 510px

  &__body
    padding: 45px 38px 60px 72px

  &__buttons
    padding: 10px 20px
    display: flex
    justify-content: flex-end

  &__close-button
    position: absolute
    top: 20px
    right: 20px
    cursor: pointer
    height: 20px
    width: 20px

  &__details-container
    font-size: 14px
    padding-bottom: 60px

  &__details
    margin: 18px
