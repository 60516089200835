
@import "../../assets/styles/shared/_fonts";

// this is font and style variable
$contentWidth: 1440px;
$titleFont: "Poppins-Bold";
$titleColor: #12433d;
$contentFont: "Proxima-Nova";
$fontSize: 18px;
$lineHeight: 22px;

.wrap {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  // padding: 20px 0;

  .wrap-box {
    width: $contentWidth;
    font-family: $contentFont;
    line-height: $lineHeight;

    .page-description {
      max-width: 985px;
      width: 100%;
      padding-top: 94px;
      padding-bottom: 75px;
    }
  }
}
