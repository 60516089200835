
.nav-link
  color: #000000
  font-size: 14px
  font-weight: 500
  line-height: 17px
  margin-right: 55px
  white-space: nowrap
  @media screen and (max-width: 600px)
    margin-right: 5px

.nav-link-image
  margin-top: 11px
  margin-right: 10px

.footer__paddings
  padding: 10px 0px 10px 0px

.flexy-column
  display: flex
  justify-content: space-between

.columns:not(:last-child)
  margin-bottom: 0px

.justify-elements-end
  justify-content: flex-end
  align-items: center

.fixed-footer-container

.fixed-footer
  &__border
    border-top: 1px solid #e5e5e5

  &__column
    display: flex
    align-items: center
