
.brand-registraion
  padding: 50px
  width: 100%

  &__buttons
    display: flex
    margin-top: 25px
    flex-direction: column
    align-items: center

.brand-personal-info
  &__header
    display: flex
    flex-direction: column
    align-items: center
    margin-bottom: 25px
    color: #212B36

    &--title
      font-size: 28px
      color: #333333

    &--subtitle
      font-size: 16px
      color: #333333

  &__form-field
    padding-right: 16px
    padding-left: 16px

  &__field
    margin-bottom: 24px !important

  &__form
    &--label
      font-size: 14px
      color: #212B36
    &--text-area
      height: 204px

    .registraion-code-message
      font-size: 10px

  &__terms-checkbox
    position: relative

  &__terms-checkbox-icon
    position: absolute
    left: 0
    cursor: pointer

  &__terms-checkbox-icon--checked
    position: absolute
    right: 3px
    top: 3px
    width: 18px
    height: 18px

  &__terms-checkbox-text
    margin-left: 25px
    font-size: 14px
    color: #212B36

  &__select-placeholder
    position: relative
    padding-left: 20px
    bottom: 30px
    color: #b4b4b4
    width: 0px
    font-size: 14px

  &__dot-container
    display: flex
    margin-bottom: 12px

    &--dot-filled
      height: 8px
      width: 8px
      background-color: #000000
      margin: 0 4px
      border-radius: 50px

    &--dot-unfilled
      height: 8px
      width: 8px
      margin: 0 4px
      background-color: #DBDBDB
      border-radius: 50px

.next-button
  background-color: #FF6132
  width: 335px
  height: 55px
  border-radius: 30px
  color: #FFFFFF
  font-size: 16px

.back-button
  margin-top: 20px
  color: #161616
  font-size: 14px
  font-weight: 300
  text-decoration: underline

.percentage-icon
  color: #000000 !important

.agree-terms
  display: flex
  align-items: center
  font-size: 10px
  color: #000000
  margin-bottom: 5px

  &__icon
    margin-right: 10px
    height: 16px
    width: 16px
    cursor: pointer

  &__icon-checked
    margin-right: 10px
    height: 16px
    width: 16px
    cursor: pointer
  &__text-link
    text-decoration: underline
    cursor: pointer
    margin: 0 3px
.disabled-button
  opacity: 0.5
