
@import "@/assets/styles/shared/_fonts";

// this is font and style variable
$titleFont: "Poppins-Bold";
$titleColor: #12433d;
$titleSize: 48px;
$subheadSize: 31px;
$subheadHeight: 52px;
$contentFont: "Proxima-Nova";
$color: #000000;
$fontSize: 18px;
$lineHeight: 52px;

.carousel {
  width: 100%;
  height: auto;
  text-align: center;
  font-family: "Playfair Display";

  &.ant-carousel .slick-dots-bottom {
    top: 420px;
  }

  .slick-initialized .slick-slide {
    height: auto;
  }

  h3 {
    font-size: 50px;
  }

  .img-box {
    width: 100%;
    height: 443px;
    // height: 400px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% auto;

    @media screen and (max-width: 1440px) {
      background-size: auto 100%;
    }
  }

  .arrow-area {
    display: flex;
    right: 35px;
    position: absolute;
    top: 380px;

    .arrow-prev {
      margin-right: 24px;
    }

    button {
      width: 40px;
    }
  }

  .carousel-item {
    position: relative;

    .text-wrap {
      position: absolute;
      top: 120px;

      &.left {
        left: 10%;
        text-align: left;
      }

      &.right {
        right: 10%;
        text-align: right;
      }

      .title {
        font-size: 55px;
        color: $titleColor;
        font-weight: 700;
        line-height: 52px;
        font-family: "Playfair Display";
        white-space: pre-line;
      }

      .subhead {
        font-family: "Proxima Nova";
        font-size: 25px;
        line-height: 28px;
        color: #2c2e30;
        white-space: pre-line;
      }
    }
  }
}

.carousel.ant-carousel .slick-slider .slick-track {
  height: auto !important;
}
