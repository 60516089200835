
.message-item-new {
  width: 100%;
  margin-bottom: 29px;
  p {
    margin-bottom: 0px;
  }
  &__header {
    display: inline-block;
  }
  &__header-avarta {
    position: absolute;
  }
  &__float-left {
    float: right;
  }
  &__float-right {
    float: left;
  }
  &__message {
    min-height: 80px;
    padding: 10px 20px;
  }
  &__message-divider {
    border-bottom: 1px solid #d9d9d9;
    width: 100%;
    margin: 10px 0px;
  }
  &__message-title {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
  }
  &__message-left {
    width: 100%;
    border-radius: 14px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #262626;
    background: #f5f5f5;
  }
  &__message-right {
    width: 100%;
    border-radius: 14px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #262626;
    background-color: rgba(74, 138, 93, 0.11);
  }
  &__message-date {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 0.13125px;
    color: #bfbfbf;
    text-align: right;
  }
  &__message-menu {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 40px;
    color: #8c8c8c;
    background: #fafafa;
  }
  &__message-product-title {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 38px;
    color: #000000;
  }
  &__message-product-detail {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }
  &__message-product-outer {
    width: 123px;
    height: 185px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 2px;
    margin-right: 18px;
    display: inline-block;
    position: relative;
    margin-bottom: 4px;
  }
  &__intro-product-list-item-text {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    line-height: 12px;
    position: absolute;
    bottom: 7px;
    width: 119px;
    padding: 0px 2px;
    text-align: center;
  }
}
.message-item-new__message-product-outer:hover {
  cursor: pointer;
}
.message-item-new__intro-product-list-item-text:hover {
  cursor: pointer;
  text-decoration: underline;
}
.file-list-editor-message-item {
  //position: absolute;
  //left: 12px;
  //bottom: 8px;
  margin-top: 20px;
  color: #262626;
  padding: 2px 4px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  display: flex;
  justify-content: start;
  align-items: center;
  //z-index: 300;
}
