
.filter_btn {
  display: inline-block;
  margin: 0 7px 7px 0;

  .choosed {
    background-color: #4a8a5d !important;
    color: #fff !important;
    outline: none;
  }

  .ant-btn {
    font-size: 12px;
  }
}
