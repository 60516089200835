
.retailer-clean-pre-check {
  width: 952px;
  margin: 0 auto;
  &__dialog-close-btn {
    width: 200px;
    height: 33px;
    line-height: 33px;
    border-radius: 2px;
    font-size: 14px;
    margin-top: 27px;
    background-color: #12433d;
    color: white;
    text-align: center;
    margin-left: 110px;
    cursor: pointer;
  }
  &__dialog-close-btn:hover {
    background-color: #4a8a5d;
    color: #ffffff;
  }
  &__dialog-info-before-submit {
    //width: 236px;
    text-align: center;
    color: #12433d;
    font-size: 13px;
    //margin-left: 77px;
    margin-top: 13px;
    font-family: "Proxima Nova", "Roboto", sans-serif;
  }
  &__dialog-info-title {
    text-align: center;
    font-size: 16px;
    margin-bottom: 23px;
  }
}
