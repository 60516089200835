
.add-new-dialog {
  &__error-txt {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #f5222d;
  }
  &__input-tag {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #262626;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 2px;
  }
  &__tags {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 18px !important;
    padding: 6px 8px !important;
    background: #ffffff !important;
    border: 1px solid #4a8a5d !important;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 2px !important;
    margin-bottom: 12px !important;
    color: #4a8a5d !important;
  }
  &__error-tag {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 18px !important;
    padding: 6px 8px !important;
    background: #ffffff !important;
    box-sizing: border-box;
    color: #f5222d !important;
    border: 1px solid #f5222d !important;
    border-radius: 2px !important;
    margin-bottom: 12px !important;
  }
}
.add-new-dialog__tags.ant-tag .anticon-close {
  color: inherit;
  vertical-align: middle;
}
.add-new-dialog__error-tag.ant-tag .anticon-close {
  color: inherit;
  vertical-align: middle;
}
.admin-console-edit-dialog .ant-modal-footer .ant-btn-primary {
  border-color: #4a8a5d;
  background: #4a8a5d;
}
