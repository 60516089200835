
.is-disabled
  pointer-events: none
.old-edit-address-information-base-form
  width: 100%
.old-edit-address-information-modal
  background-color: #ffffff
  width: 100%
  &__body
    overflow-x: hidden
  &__container
    min-height: 0px
  &__container-images
    min-height: 600px !important
  &__container-documents
    min-height: 500px !important
  &__form
    padding: 20px 40px 20px 40px
  &__form-buttons
    padding-right: 20px
    height: 75px
    border-top: 1px solid #E0E4E9
    display: flow-root
  &__header
    font-size: 18px !important
    font-weight: 500
    letter-spacing: 0.25px
    color: #212B36
    padding-left: 20px
    border-bottom: 1px solid #E0E4E9
  &__header-close-icon
    cursor: pointer
  &__menu
    padding-left: 40px
    padding-top: 30px
    background-color: #F4F4F4
    font-size: 18px
    width: 240px
    li
      margin-top: 20px
    a
      color: #000000 !important
  &__menu--sticky
    position: sticky
    top: 30px
  &__save-button, &__cancel-button
    height: 36px
    width: 69px
    font-size: 12px
    font-weight: bold
    margin-left: 10px
  &__error-message
    color: #BF0711
    font-size: 18px
  &__form-messages
    display: flex
    align-items: center
.old-edit-address-information
  &__label
    font-size: 14px
    font-weight: 500
  &__msrp-input
    width: 260px
  &__size-select
    width: 90px
  &__size-input
    width: 205px
  &__select, &__input
    width: 295px
    &--fullwidth
      width: 100%
