
.sign-up-title {
    font-family: "Niveau Grotesk";
    font-size: 25px;
    color: #1a1818;
}

.bf-sign-up {
    .sign-up-link {
        text-decoration: underline;
    }

    .ant-form-item {
        margin-bottom: 22px;

        &.center {
            display: flex;
            justify-content: center;
        }



        .ant-form-explain {
            position: absolute;
            bottom: -20px;
            left: 24px;
        }

        input {
            padding: 6px 24px 10px 24px;
            border-radius: 50px;
            font-size: 20px;
            color: #000;
            height: fit-content;
        }

    }
}
