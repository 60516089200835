
.reset--section--wrapper {
  padding: 180px 0px;
  overflow: hidden;
}

.reset--section--inner {
  position: relative;
}

.reset--section--container {
  max-width: 590px;
  margin: 0 auto;
  padding: 0 20px;
}

.reset--section--header {
  font-family: Niveau Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 65px;
  line-height: 60px;
  text-align: center;
  margin-bottom: 27px;
}

.reset--section--header__red {
  color: #f15a42;
  margin-right: 8px;
  padding-left: 8px;
  padding-right: 8px;
  background-color: rgba(241, 90, 66, 0.1);
}

.reset--section--content {
  font-family: Mr Eaves Mod OT;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 33px;
  text-align: center;
  color: #000000;
  margin-bottom: 40px;
}

.reset--section--wrapper .left--dots--group {
  position: absolute;
  left: 0;
  top: 10px;
}

.reset--section--wrapper .ellipse {
  position: absolute;
  right: 0;
  top: 80px;
}

.reset--section--wrapper .right--dots--group {
  position: absolute;
  right: 0;
  top: 80px;
}

.reset--footer--content {
  text-align: center;
}

.reset--footer--content a {
  font-family: Niveau Grotesk;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #f15a42;
  margin-left: 20px;
}

@media screen and (max-width: 767px) {
  .reset--section--wrapper {
    padding: 40px 0;
  }

  .reset--section--header {
    font-weight: 400;
    font-size: 40px;
    line-height: 47px;
    margin-bottom: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .reset--section--header span:nth-child(1) {
    margin-right: 0;
  }

  .reset--section--content {
    font-weight: 400;
    font-size: 25px;
    line-height: 28px;
  }

  .reset--section--wrapper .left--dots--group {
    width: 70px;
    height: 120px;
    object-fit: cover;
    top: -21px;
  }

  .reset--section--wrapper .ellipse {
    width: 25px;
    top: -16px;
  }

  .reset--section--wrapper .right--dots--group {
    width: 130px;
    height: 155px;
    right: -60px;
    top: -70px;
    object-fit: cover;
  }

  .reset--footer--content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .reset--footer--content a {
    margin-top: 20px;
    margin-left: 0;
  }
}
