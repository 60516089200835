
.dates-filter
  .d-wrapper
    width: 600px
    z-index: 10
    position: absolute
    background: white
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.3)
    max-height: 1000px

  &__items
    display: none
    overflow: hidden
    margin-top: 10px

  &__icon
    cursor: pointer
    position: absolute
    right: 15px
    top: 7px

  &__subtitle
    font-weight: 600
    font-size: 14px

  &__items--visible
    display: block

  &__header-body
    position: relative
    display: block
    cursor: pointer
    padding: 16px 0px 16px 0px
    border-top: 1px solid #EBEFF2
    color: #292929
    font-size: 12px
    font-weight: 300
    line-height: 18px

    &--extended
      height: 420px

  &__header--icon
    float: right
    padding-right: 10px

  &__header--reset
    float: right
    color: #B3B3B3

  &__input
    width: 200px
