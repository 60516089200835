
.dropdown-list__search-input .ant-input {
  height: 24px;
  border-radius: 30px;
  border: none;
}
.dropdown-list__search-input .ant-input:hover {
  border: none;
}
.dropdown-list__search-input.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border: none;
}
.dropdown-list__search-input.ant-input-affix-wrapper {
  border: none;
}
.dropdown-list__search-input .ant-input {
  border-radius: 0px;
}
.dropdown-list .ant-btn:hover,
.dropdown-list .ant-btn:focus,
.dropdown-list .ant-btn:active {
  color: #fff;
  background: #4a8a5d;
  border-radius: 2px;
  border-color: #4a8a5d;
}
