
.order-payment-count__main {
  min-height: 100px;
  p {
    margin-bottom: 0;
  }
  .retailer-payment-title {
    color: #8c8c8c;
    margin-bottom: 10px;
  }
  .payment-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    span {
      color: #262626;
      font-size: 14px;
    }
  }
  .special-total {
    font-weight: 700;
  }
}
