
.price-filter
  &__items
    display: none
    overflow: hidden
    margin-top: 10px

  &__items--visible
    display: block

  &__icon
    position: absolute
    left: 0
    margin-right: 20px

  &__header-body
    position: relative
    display: block
    cursor: pointer
    padding: 16px 0px 16px 0px
    border-top: 1px solid #EBEFF2
    color: #292929
    font-size: 12px
    font-weight: 300
    line-height: 18px

  &__header--icon
    float: right
    padding-right: 10px

  &__header--reset
    float: right
    color: #B3B3B3

  &__input
    width: 110px

  &__input-divider
    margin: 8px
