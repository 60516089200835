
input[type=number]
  width: auto !important
.verify
  &__box
    width: 360px
    border: 1px solid #E5E5E5
    border-radius: 2px
    padding: 25px
  &__divider
    border-bottom: 1px solid #e5e5e5
    margin: 120px 0px 25px 0px

.register
  display: flex
  justify-content: center
  align-items: center
  min-height: 80vh
  font-family: Proxima
  &__box
    max-width: 410px
    min-height: 637px
    border: 1px solid #E5E5E5
    border-radius: 2px
    margin: 40px 0px 100px 0px
    padding: 30px 45px 45px 45px !important

  &__form
    text-align: center
    margin-bottom: 28px

  &__name
    color: #000000
    font-size: 14px
    font-weight: 500
    letter-spacing: 0.25px
    line-height: 24px

  &__input
    height: 40px
    border-radius: 2px
    margin-bottom: 5px

  &__select
    posiition: absolute

  &__select-placeholder
    position: relative
    padding-left: 20px
    bottom: 30px
    color: #b4b4b4
    width: 0px
    font-size: 14px

  &__role
    text-transform: capitalize
    font-size: 14px
    font-weight: bold
    letter-spacing: 0.25px
    color: #000000
    text-align: center
    padding-top: 30px
    padding-bottom: 25px

  &__button
    height: 46px
    margin: 24px 0px 24px 0px
    border-radius: 2px

  &__add-location-button
    color: #161616
    font-size: 14px
    font-weight: 300
    letter-spacing: 0.16px
    text-decoration: underline
    cursor: pointer
    margin-top: -10px

  &__border
    border-bottom: 1px solid #e5e5e5
    margin: 0px 0px 25px 0px

  &__login
    display: flex
    justify-content: space-between

    &-question
      font-size: 14px
      font-weight: 600
      letter-spacing: 0.25px
      line-height: 21px

    &-sign-in
      font-size: 14px
      font-weight: 600
      letter-spacing: 0.25px
      color: #008489

  &__terms-checkbox
    position: relative

  &__terms-checkbox:last-child
    margin-bottom: 15px

  &__parent-company-checkbox
    position: relative
    margin-top: 20px
    margin-bottom: 20px

  &__parent-company-checkbox-icon, &__terms-checkbox-icon
    position: absolute
    left: 0
    cursor: pointer

  &__parent-company-checkbox-icon--checked, &__terms-checkbox-icon--checked
    position: absolute
    right: 3px
    top: 3px
    width: 18px
    height: 18px

  &__terms-checkbox-text
    margin-left: 25px
    font-size: 14px

  &__parent-company-checkbox-text
    margin-left: 35px

  &__account-type
    padding-bottom: 20px

  .retailer-terms
    margin-top: 20px

  &__header
    font-family: Proxima
    text-align: center !important
    font-size: 14px
    font-weight: bold
    letter-spacing: 0.25px
    padding-bottom: 25px
    padding-top: 15px
    color: #000000

  &__field-header
    font-size: 14px
    color: #000000
    padding-top: 10px
    padding-right: 5px

  &__radio-field
    padding-bottom: 15px

  &__radio + &__radio
    margin-left: 3em !important

  &__radio-container
    position: relative
    padding-left: 25px
    margin-top: 10px
    cursor: pointer
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none

    input
      position: absolute
      opacity: 0
      cursor: pointer

  &__radio-label
    font-size: 14px
    color: #000000

  .checkmark-box
    position: absolute
    top: 3px
    left: 0
    height: 14px
    width: 14px
    background: #FFFFFF
    border-radius: 2px
    border: 1px solid #E5E5E5

  &__radio-checkmark
    position: absolute
    top: 3px
    left: 0
    height: 14px
    width: 14px
    background: #FFFFFF
    border-radius: 50%
    border: 1px solid #E5E5E5

  &__radio-container input:checked ~ &__radio-checkmark, .checkmark-box
    background-color: #FFFFFF

  &__radio-checkmark:after
    content: ""
    position: absolute
    display: none

  .checkmark-box:after
    content: ""
    position: absolute
    display: none

  &__radio-container input:checked ~ &__radio-checkmark:after
    display: block

  &__radio-container input:checked ~ .checkmark-box:after
    display: block

  &__radio-container &__radio-checkmark:after
    top: 3px
    left: 3px
    width: 6px
    height: 6px
    border-radius: 50%
    background: #000000

  &__radio-container .checkmark-box:after
    top: 3px
    left: 3px
    width: 6px
    height: 6px
    border-radius: 2px
    background: #000000
    padding-left: 20px

  &__textarea
    min-height: 84px !important

  &__textarea::placeholder
    padding-left: 10px

  &__input::placeholder
    font-weight: 500
    letter-spacing: 0.25px
    color: #B6B6B6
    text-transform: capitalize
    padding-left: 10px

  .percent-icon
    color: #000000 !important
    position: relative!important
    right:35px!important

  .account-headquarters
    &__columns
      margin-bottom: 10px !important
