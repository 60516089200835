
.admin-console-delete-reference-dialog {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  &__deleted-tag {
    font-size: 18px;
    line-height: 22px;
    color: #f5222d;
    padding: 6px 8px;
    border: 1px solid #f5222d;
    background: #ffffff;
    border-radius: 2px;
    //display: inline-block;
    margin-right: 12px;
    margin-bottom: 12px;
    float: left;
    cursor: pointer;
  }
  &__delete-text {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin-bottom: 14px;
    margin-top: 12px;
  }
}
