
.cancel-subscription-dialog-new__main {
  .cancel-subscription-new {
    text-align: center;
    padding: 48px 0;
    position: relative;
    overflow: hidden;

    p {
      font-size: 18px;
      font-weight: 400;
      color: #000000;
      font-family: "Mr Eaves Mod OT";
    }
  }
}

.continue-cancel-style {
  color: #000000;
  cursor: pointer;
}

.close-dialog-style {
  padding: 17px 35px;
  color: white;
  background: #f15a42;
  font-size: 14px;
  font-weight: bold;
  border-radius: 100px;
  margin-left: 30px;
  cursor: pointer;
}

.cancel-dialog-btn-style {
  width: 100%;
  margin-top: 50px;
  text-align: center;
}
