
.terms-edit
  padding-bottom: 10px

.pending-changes-approval
  position: absolute
  top: 415px
  right: 20px

.remaining-characters
  font-size: 12px
  justify-content: flex-end
  display: flex
.retailer-profile-container
  color: #000000
  width: 953px
  margin: 0px auto !important

.header-content
  margin-bottom: 26px
.retailer-profile
  .modal-card-body
    overflow-y: auto
    overflow-x: hidden

  &__breadcrumb
    margin-bottom: 1rem !important

  &__edit-modal
    background-color: #ffffff
    width: 875px

    &-error-message
      color: #BF0711
      font-size: 18px

    &-form-messages
      display: flex
      align-items: center

    input::placeholder
      text-transform: none

    .radio + .radio
      margin-left: 3em !important

    .container
      position: relative
      width: auto !important
      padding: 0px
      padding-left: 25px
      margin-top: 10px
      cursor: pointer
      -webkit-user-select: none
      -moz-user-select: none
      -ms-user-select: none
      user-select: none

      input
        position: absolute
        opacity: 0
        font-size: 14px
        cursor: pointer

    .modal-card-head
      border-bottom: 1px solid #DFE3E8
    .checkmark-box
      position: absolute
      top: 3px
      left: 0
      height: 14px
      width: 14px
      background: #FFFFFF
      border-radius: 2px
      border: 1px solid #E5E5E5

    .checkmark-radio
      position: absolute
      top: 3px
      left: 0
      height: 14px
      width: 14px
      background: #FFFFFF
      border-radius: 50%
      border: 1px solid #E5E5E5

    .container input:checked ~ .checkmark-radio, .checkmark-box
      background-color: #FFFFFF

    .checkmark-radio:after
      content: ""
      position: absolute
      display: none

    .checkmark-box:after
      content: ""
      position: absolute
      display: none

    .container input:checked ~ .checkmark-radio:after
      display: block

    .container input:checked ~ .checkmark-box:after
      display: block

    .container .checkmark-radio:after
      top: 3px
      left: 3px
      width: 6px
      height: 6px
      border-radius: 50%
      background: #000000

    .container .checkmark-box:after
      top: 3px
      left: 3px
      width: 6px
      height: 6px
      border-radius: 2px
      background: #000000

    .percent-icon
      color: #000000 !important

    select
      font-size: 14px
      height: 40px
      margin-top: 8px
      padding-left: 18px

    .select
      display: block
      margin-top: 8px

    &--container
      min-height: 1149px

    &--change-warning
      padding-bottom: 5px
      margin-bottom: 24px
      font-size: 14px
      height: 47px
      overflow: auto
    &--clean-title
      padding-bottom: 5px
      margin-bottom: 24px
      font-size: 14px
      height: 47px
      font-weight: 600
      overflow: auto

    &--add-interested-product-categories
      padding-bottom: 5px
      font-size: 14px
      height: 47px
      overflow: auto

    &--form
      padding: 0px 66px 44px 44px

    &--form_label
      color: #000000
      font-size: 14px
      font-weight: 500
      letter-spacing: 0.2px
      padding-bottom: 20px
      padding-right: 5px

    &--clean_label
      color: #000000
      font-size: 14px
      font-weight: 400
      letter-spacing: 0.2px
      padding-bottom: 20px
      padding-right: 5px

    &--clean_label
      color: #000000
      font-size: 14px
      font-weight: 400
      letter-spacing: 0.2px
      padding-bottom: 20px
      padding-right: 5px

    &--form-compliance
      padding-bottom: 5px
      font-size: 14px
      font-weight: 500

    &--form_input
      height: 40px
      font-size: 14px
      padding-left: 18px
      border-radius: 2px

    &--form_link
      float: right
      font-size: 14px
      text-decoration: underline
      color: #000000

    &--form_textarea
      height: 175px

    &--form_control
      margin-top: 8px

    &--form_radio-label
      font-size: 14px

    &--form_prefix
      padding-left: 15px
      padding-top: 12px
      display: flex
      align-items: center

    &--form_subtitle
      font-size: 16px
      font-weight: bold
      padding: 15px 0px 20px 0px

    &--form-locations-title
      margin-top: 40px
      font-size: 16px
      font-weight: bold

    &--form_container
      padding-bottom: 5px

    &--form_address
      padding-bottom: 10px

      .column:first-child
        padding: 0px 4px 0px 0px

      .column
        padding: 0px 5px 0px 5px

      .column:last-child
        padding: 0px 0px 0px 4px

    &--form_save-button
      padding-top: 30px

    &--form_add-location
      color: #161616
      font-size: 14px
      font-weight: 300
      letter-spacing: 0.16px
      text-decoration: underline
      margin-top: 17px
      cursor: pointer

    &--form_terms
      position: sticky
      top: 33px

    &--form_button
      color: #FFFFFF
      font-size: 12px
      font-weight: bold
      height: 36px
      width: 83px
      background-color: #323232
      box-shadow: inset 0 1px 0 1px rgba(255,255,255,0.06), 0 1px 0 0 rgba(22,29,37,0.1)

    .retailer-profile__edit-modal--form_photos-overview
      margin-top: 30px

    &--header
      font-size: 20px
      font-weight: 500
      letter-spacing: 0.25px
      color: #212B36
      padding-left: 15px

    &--header_close-icon
      cursor: pointer

    &--menu
      padding-left: 35px
      padding-top: 50px
      background-color: #F4F4F4
      font-size: 18px
      width: 241px !important

      li
        margin-top: 15px

      a
        color: #000000

  &__categories
    padding-top: 60px
    padding-bottom: 10px

    &--header
      font-size: 14px
      font-weight: 600

    &--back-to
      cursor: pointer
      font-size: 14px
      font-weight: 300
      padding-top: 10px

  &__filter-header
    font-size: 14px
    font-weight: 600
    text-transform: uppercase

  &__filter
    cursor: pointer
    margin: 10px 48px 0px 0px

  &__about
    padding: 55px 100px 0px 100px

  &__gallery-container
    padding-top: 55px

  &__gallery
    max-width: 920px
    margin: 0 auto

  &__overview
    margin-right: 40px
    font-size: 14px

  &__details


    &--container
      margin-top: 30px

    &--terms-title
      font-size: 18px
      letter-spacing: 0.25px
      margin-bottom: -15px

    &--title
      font-size: 18px
      letter-spacing: 0.25px

      &_text
        margin-top: 20px
        font-weight: 300

    &--subtitle


      &_link
        text-decoration: underline

    &--edit-icon
      float: right
      cursor: pointer

    &--info-container
      padding-top: 25px
      font-size: 14px
      letter-spacing: 0.13px

  &__banner
    height: 280px
    margin-bottom: -35px
    background-color: #ffffff

  &__logo
    display: flex
    justify-content: center
    margin-inline-end: 10px
    margin-left: 30px
    height: 140px
    width: 140px
    border: 1px solid #E5E5E5
    background-color: #ffffff

  &__name
    font-size: 18px
    font-weight: bold
    letter-spacing: 0.25px
    padding-top: 50px

  &__description
    font-size: 14px
    font-weight: 300
    letter-spacing: 0.25px
    padding-top: 10px

    &--info
      padding-top: 15px

  &__website
    text-decoration: underline

    a
      color: #161616

  &__edit
    cursor: pointer
    color: #161616
    font-size: 14px
    letter-spacing: 0.16px
    text-decoration: underline
    float: right

  &__country
    font-size: 14px

  &__tabs
    padding-top: 40px

  &__tab
    font-size: 14px
    color: #9B9B9B
    font-weight: 500
    width: 115px
    letter-spacing: 0.1px

  &__product
    max-width: 250px
    margin: 0 auto

  &__products
    padding-top: 30px

  &__icons
    margin-top: 50px

  &__add-category-button
    margin-top: 10px

.retailer-profile__edit-modal-sticky-menu
  position: sticky
  top: 40px
  font-size: 18px
  color: #212B36
  padding-left: 30px

.is-selected
  font-weight: bold
  color: #000
.enable-line-break
  white-space: pre-wrap

.admin-view
  top: 375px
  right: 32px

.disable-save
  pointer-events: none
.card-content
  width: 100%
  display: flex
  padding: 0px !important
  margin-top: 20px
.card-left-content
  width: 600px
.card-right-content
  width: 327px
  margin-left: 25px
.card-bottom-action
  width: 100%
  float: left
  margin-bottom: 20px
  .link-left
    float: left
    text-decoration: underline
    font-size: 14px
    color: #595959
  .link-right
    float: right
    text-decoration: underline
    font-size: 14px
    color: #595959
