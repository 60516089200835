
.admin-retailer-edit {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  &__title {
    font-family: Playfair Display;
    font-size: 23px;
    font-weight: bold;
    color: #000000;
    margin-bottom: 83px;
  }
}
.admin-retailer-edit .ant-anchor-ink {
  height: 0%;
}
.admin-retailer-edit .ant-anchor-ink-ball.visible {
  display: none;
}
.admin-retailer-edit .ant-anchor-link {
  padding: 12px 24px;
  font-size: 18px;
  line-height: 22px;
  // border: 1px solid #d9d9d9;
  letter-spacing: 0.13125px;
  color: #262626;
  border-bottom: none;
  text-transform: uppercase;
}
.admin-retailer-edit .ant-anchor-link-title {
  text-transform: uppercase;
}
.admin-retailer-edit .ant-anchor-link-active > .ant-anchor-link-title {
  color: #ff6132;
  text-transform: uppercase;
}
