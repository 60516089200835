
.address-button
    border: none
    justify-content: center !important

.address-modal__new
  position: absolute
  bottom: 43px
  width: 220px
  height: 46px
  left: calc(50% - 110px)

.retailer-address
  &__title
    color: #000000
    font-size: 18px
    letter-spacing: 0.25px
    margin-bottom: 25px


  &__edit-form
    text-decoration: underline
    color: #161616
    font-size: 14px
    font-weight: 300
    letter-spacing: 0.16px
    line-height: 18px
    margin-left: 11px

  &__paragraph
    color: #000000
    font-size: 14px
    letter-spacing: 0.25px
    line-height: 26px

  a
    color: #161616

.address-modal
  &__content
    position: relative
    background-color: #FFFFFF
    padding: 24px 24px 0px 24px
    width: 660px
    height: 600px
    overflow: auto

  &__close-button
    position: absolute
    top: 24px
    right: 24px
    cursor: pointer
    height: 12.55px
    width: 12.74px

  &__title
    font-size: 20px
    font-weight: 600
    padding-top: 44px
    margin-left: 133px
    letter-spacing: 0.28px
    line-height: 24px
    margin-bottom: 28px

    &--centered
      display: flex
      justify-content: center
      font-size: 20px
      font-weight: 600
      padding-top: 44px
      letter-spacing: 0.28px
      line-height: 24px
      margin-bottom: 28px

  &__back-button
    position: absolute
    top: 24px
    left: 24px
    cursor: pointer
    height: 16px
    width: 15px

  &__address:first-child
    border-top: 1px solid #e5e5e5
    border-bottom: 1px solid #e5e5e5
    margin-left: 28px
    margin-right: 28px
  // &__address:last-child
  //     border: none

  &__address
    border-bottom: 1px solid #e5e5e5
    margin-left: 28px
    margin-right: 28px

  &__paragraphs
    margin-top: 14px
    margin-bottom: 14px

  &__text-end
    text-align: end

  &__action-button
    cursor: pointer
