
.payment-detail
  &__title
    font-family: Playfair Display
    font-style: normal
    font-weight: bold
    font-size: 48px
    line-height: 38px
    display: flex
    align-items: center
    color: #000000
    margin-bottom: 35px
  &__subtitle
    font-family: SF Pro Display
    font-style: normal
    font-weight: normal
    font-size: 16px
    line-height: 24px
    display: flex
    align-items: center
    text-align: center
    color: #4A8A5D
    margin-bottom: 6px
    cursor: pointer
    width: fit-content
    width: -moz-fit-content
