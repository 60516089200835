

.owner
  font-family: Proxima
  color: black
  font-weight: bold

.membersList__table-cell
  color: #333333 !important
  font-size: 15px
  font-size: 14px
  font-weight: 500
  padding-top: 1px
  text-align: center
  padding-bottom: 10px
  position: relative

.membersList__dropdown
  position: absolute
  width: 135px
  height: auto
  top: 2px
  right: 20px
  z-index: 100
  background-color: #ffffff
  border: 1px solid #DADADA


.table-body
  padding: 6px 35px
  border-bottom: 1px solid #ddd
  display: block !important
  align-items: center
  .membersList
    /*flex: 1!important*/
    margin-bottom: 15px
    width: 100%

  .user-name__block
    display: flex
    align-items: center
    font-family: "Proxima"

    .user-name-status
      width: 31px
      height: 31px
      background-color: #e3d7d7
      border: 1px solid #979797
      color: #535050
      padding: 2px
      border-radius: 100%
      font-size: 16px
      font-weight: 300
      margin-right: 5px
      display: flex
      align-items: center
      justify-content: center

    .brand-settings__account-information_name
      color: #333
      font-size: 14px
      white-space: normal
      word-break: break-all
      margin-bottom: 0px !important
.table-item-information-name
  font-family: Proxima
  color: #333
  font-size: 14px
  white-space: normal
  direction: ltr
  word-break: break-all
  margin-right: 4px
  margin-bottom: 0px !important
.col-phone
  text-align: left
.text-padding-left
  padding-left: 10px
