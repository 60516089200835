
//.retailer-cards
//  position: relative
//  // background-color: red
//  height: auto
//  width: 100%
//  padding-top: 30px


.retailer-card
    height: 350px
    width: 240px
    min-width: 200px
    float: left
    margin: 0 13px 32px
    text-align: center
    border: 1px solid #e8e8e8
    border-radius: 27px
    box-shadow: 1px 1px 4px 1px #c9c9c9
    font-size: 14px
    font-family: SF Pro Display
