
.assets-form
  width: 580px

  &__header
    font-size: 16px
    padding: 5px 0px 20px 0px

  &__banner
    border: 1px solid #FFDBCF
    border-left: 11px solid #FFDBCF
    height: 100px
    margin-bottom: 20px
    font-size: 10px
    padding-left: 20px
    display: flex
    align-items: center
    margin-top: 10px
    padding-top: 10px

  &__banner-list
    list-style-type: disc

.image-assets-form
  width: 580px

  &__header
    font-size: 16px
    padding: 5px 0px 20px 0px

  &__banner
    border: 1px solid #FFDBCF
    border-left: 11px solid #FFDBCF
    height: 125px
    margin-bottom: 20px
    font-size: 10px
    padding-left: 20px
    display: flex
    align-items: center
    margin-top: 10px

  &__banner-list
    list-style-type: disc

.file-input
  height: 1px
  opacity: 0

.product-asset__new:hover
  border-color: #4C4C4C

.product-asset__new:hover .product-asset__new-plus-icon
  color: #4C4C4C

.product-asset__new:hover .product-asset__new-link
  color: #4C4C4C !important

.product-asset
  &__card
    height: 173px
    width: 173px
    display: flex
    align-items: center
    justify-content: center
    background-color: white
    border: 1px solid #979797

  &__image
    max-width: 169px
    max-height: 169px

  &__image--main
    border: 2px solid #979797

  &__new
     width: 173px !important
     height: 173px
     font-size: 14px
     border-radius: 5px
     cursor: pointer
     border: 2px dashed #DFE3E8

     &-plus-icon
       font-size: 45px
       font-weight: 600
       color: rgba(0,0,0,0.38)
       display: flex
       justify-content: center
       align-items: center
       padding-top: 20px

     &-link
       color: rgba(0,0,0,0.38)
       display: flex
       justify-content: center
       align-items: center

  &__set-main-button
    color: #4A90E2
    font-size: 12px
    margin-top: 5px
    margin-left: 5px

  &__delete-button
    font-size: 12px
    margin-top: 5px
    margin-right: 5px
    color: #F9375D

    &--disabled
      display: none

  &__input--disabled
    display: none
