
.aboutpage
  width: 678px
  margin: 0 auto
  margin-bottom: 80px

  &__image-container
    display: flex
    justify-content: center
    align-items: center

  &__image
    margin-top: 80px
    height: auto
    width: 180px

  &__title
    font-weight: bold
    margin-bottom: 25px

  &__text
    margin-top: 80px
    color: #4A4A4A
    font-size: 16px
    letter-spacing: 0.44px
    line-height: 28px
    text-align: center

  &__subtitle
    margin-bottom: 128px
