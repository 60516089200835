
.banner-img
  width: 100%
  height: 100%
  display: none
.brand
  min-height: 80vh
.retailer-brand-profile
  margin: 0 auto
  padding-bottom: 250px
  color: #000000
  padding-left: 30px
  padding-right: 20px
  height: 100%
  .profile-style
    margin-bottom: 70px !important

  &__banner
    height: 280px
    margin-bottom: -35px
    background-color: #ffffff

  &__categories
    padding-top: 60px
    padding-bottom: 10px

    &--header
      font-size: 14px
      font-weight: 600

    &--back-to
      cursor: pointer
      font-size: 14px
      font-weight: 300
      padding-top: 10px

  &__filter-header
    font-size: 14px
    font-weight: 600
    text-transform: uppercase

  &__filter
    cursor: pointer
    margin: 10px 48px 0px 0px

  &__about
    padding: 55px 100px 0px 100px !important

  &__gallery-container
    padding-top: 55px

  &__gallery
    max-width: 920px
    margin: 0 auto

  &__overview
    margin-right: 40px
    font-size: 14px

  &__details-value
    font-size: 16px

  &__details
    border: 1px solid rgba(151,151,151,0.23)
    padding: 25px !important
    margin-bottom: 25px

    &--container
      margin-top: 30px

    &--title
      font-size: 18px
      letter-spacing: 0.25px
      padding-bottom: 20px

      &_text
        font-weight: 400

    &--subtitle
       font-size: 14px
       font-weight: bold

       &_link
         text-decoration: underline

  &__logo
    display: flex
    justify-content: center
    margin-inline-end: 10px
    margin-left: 35px
    height: 140px
    width: 140px
    border: 1px solid #E5E5E5
    background-color: #ffffff

  &__name
    font-size: 18px
    font-weight: bold
    letter-spacing: 0.25px
    padding-top: 50px

  &__description
    font-size: 14px
    font-weight: 300
    letter-spacing: 0.25px

  &__website
    padding-top: 30px
    text-decoration: underline
    display: flex
    align-items: center
    a
      color: #161616

  &__message-button
    margin-left: auto
    height: 30px
    width: 120px

  &__edit
    cursor: pointer
    display: flex
    justify-content: flex-end
    margin-bottom: -25px
    color: #161616
    font-size: 14px
    letter-spacing: 0.16px
    text-decoration: underline
    position: relative

  &__country
    font-size: 14px

  &__tabs
    padding-top: 40px

  &__tab
    font-size: 14px
    color: #9B9B9B
    font-weight: 500
    width: 115px
    letter-spacing: 0.1px

  &__product
    max-width: 250px
    margin: 0 auto

  &__products
    padding-top: 30px

  &__icons
    margin-top: 50px
