
.disabled
  pointer-events: none
  background-color: white !important
  color: black !important
  border-color: #8D8D8D !important
  opacity: 0.6

.documents-form
  width: 600px

  &__header
    font-size: 16px
    padding: 20px 0px 5px 0px

  .upload-file__area-info
    margin-top: 5px
    p
      color: #333
      font-size:10px
      font-weight: 300

  .upload-file__block
    margin-top: 26px
    display: flex
    align-items: center
    justify-content: space-between

    .left-block
      display: flex
      align-items: center

      .image-block
        width: 30px

      .file-details
        margin-left: 15px

        .top-block
          display: flex
          p
            font-size: 10px
            color: #737781
          span
            font-size: 10px
            color: #737781
            margin-left: 5px

        .bottom-block
          span
            font-size: 13px
            color: #737781

    .right-block
      display: flex
      align-items: center
      flex-direction: column

      .download-link
        color: #000
        font-size: 12px

      .delete-link
        color: #D0021B
        font-size: 12px
        margin-top: 12px


.file-input
  height: 1px
  opacity: 0

.wait
  cursor: wait

.product-documents
  &__card
    height: 250px
    width: 250px
    display: flex
    align-items: center
    justify-content: center
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06)
    border-radius: 4px
    background-color: white

  &__icon
    margin-top: 45px

  &__image
    max-width: 250px
    max-height: 250px
    font-size: 12px

  &__info
    text-align: center
    padding-top: 10px

    &-name
      font-weight: 500
      font-size: 13px
      letter-spacing: 0.01em
      color: #60ceb3 !important

  &__new
     width: 580px !important
     height: 222px
     font-size: 20px
     border-radius: 5px
     cursor: pointer
     border: 2px dashed #DFE3E8
     display: flex
     align-items: center
     justify-content: center

     .upload-block
      a
        color: #007ACE

     &-button
       color: #333333
       height: 36px
       width: 136px
       font-size: 12px

     &-recommendations
       color: #637381
       font-size: 14px
       padding-top: 10px
