
.ant-modal-confirm-info .ant-modal-confirm-body > .anticon
  color: #4A8A5D !important
.table-title
  font-weight: 600
  font-size: 14px
  color: #333
  line-height: 18px
  letter-spacing: 0.25px
.table-content
  font-weight: 300
  font-size: 14px
  color: #333
  line-height: 18px
  letter-spacing: 0.25px
.brand-line__main-block
  width: 100%
  display: flex
  padding: 10px 0px 8px 35px
  border-bottom: 1px solid #ddd
  min-height: 50px
.brand-settings__account-information_text
  margin-bottom: 0px !important
  text-align: left

.brand-line__left-block
  padding: 0px !important
  display: flex
  align-items: center

  .brand-settings__account-information_text
    font-size:14px
    font-weight: 600
    color: #333

.brand-line__right-block
  padding: 0px !important
  display: flex
  align-items: center

  .brand-settings__account-information_text
    font-size: 14px
    font-weight: 300
    color: #333


.brand-settings__account-information
  width: 100%
  padding-top: 68px

  .brand-line
    :last-child
      border-bottom: none

.brand-settings__account-information
  width: 100%
  padding-top: 68px

  .brand-line
    :last-child
      border-bottom: none
.upgrade-button
  background-color: #000 !important
  border-color: transparent
  color: white !important
  min-width: 124px
  height: 30px
  font-size: 14px
  font-weight: 600
