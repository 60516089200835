
.validation-message
  margin-top: -0.75rem !important
.reset-password
  display: flex
  min-height: 80vh
  justify-content: center
  align-items: center


.reset-password-success
  &__link
    color: #008489
    font-size: 14px

  &__panel
    padding: 24px
    padding-bottom: 20px
    min-height: 335px
    width: 362px
    border: 1px solid #E5E5E5
    border-radius: 2px
    background-color: #FFFFFF

  &__title
    color: #000000
    font-family: "Proxima"
    font-size: 16px
    letter-spacing: 0.44px

  &__paragraph
    margin-top: 16px
    color: #8D8D8D
    font-size: 14px
    letter-spacing: 0.25px

  &__divider
    border-bottom: 1px solid #e5e5e5
    margin: 200px 0px 25px 0px


.reset-password
  .change-password
    &__box
      width: 360px
      border: 1px solid #E5E5E5
      border-radius: 2px
      padding: 25px

  .reset-form
    width: 170px
    text-align: center

  .input_margin-top
    margin-top: 20px

  .button_margin-top
    margin-top: 10px

  .page-title
    font-family: "Montserrat", sans-serif
    font-size: 20px
    font-weight: 300
