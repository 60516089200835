
@import "../../assets/styles/shared/_fonts";

// this is font and style variable
$contentWidth: 1106px;
$titleFont: "Poppins-Bold";
$titleColor: #12433d;
$contentFont: "Proxima Nova";
$fontSize: 18px;
$lineHeight: 22px;

.agency-wrap {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;

  &__wrap-box {
    width: $contentWidth;
    line-height: $lineHeight;
    margin: 0 auto;

    &__page-description {
      font-family: $contentFont;
      color: black;
      max-width: 984px;
      width: 100%;
      padding-top: 94px;
      padding-bottom: 75px;
      font-size: 18px;
      line-height: 22px;

      p {
        margin-bottom: 0;
      }

      p:first-child {
        margin-bottom: 37px;
      }

      p:first-child {
        margin-bottom: 37px;
      }
    }
  }
  .agency-wrap-bold-style {
    font-weight: bold;
  }
}
