
.microcategories-filter
  .li-height
    height: 27px
    padding: 0px
    font-weight: 300
  .dropdown-item
    cursor: pointer

  &__checkbox
    position: absolute
    left: 0
    margin-right: 10px

  &__checked
    margin-left: -17px
    margin-top: -16px

  &__name
    font-size: 14px
    color: rgba(0, 0, 0, 0.65)
    margin-left: 24px

  &__visible
    display: block
    padding-bottom: 10px

  &__header
    position: relative
    display: block
    cursor: pointer
    font-family: Poppins
    font-size: 14px
    color: #000000
    font-weight: 600
    padding-top: 10px
