
.retailer-order-table__header-container
  display: flex
  justify-content: flex-start
  align-items: center
  p
    margin-bottom: 10px
  .add-charge-button
    margin-left: 30px
    text-decoration: underline
    cursor: pointer

.additional-charge
  width: 100%
  select
    width: 100%
    height: 36px
.amount-input-container
  display: flex
  margin-bottom: 7px
  .input
    width: 70px
    margin-right: 4px
  .additional-charge-dropdown
    .select
      height: 36px
      select
        height: 100%
.additional-charge-buttons
  width: 129px
  display: flex
  justify-content: flex-end
  padding: 5px 0
  .button[disabled]
    cursor: default
  .save-additional-charge
    margin-right: 10px
    font-size: 14px
    height: 25px
    width: 50px
  .cancel-additional-charge
    font-size: 14px
    height: 25px
    width: 50px
    text-decoration: underline
    color: #4250C7
    border: none

.adjust-width
  width: 120px
.total-amount-style
  background-color: #595959
