
.v-avatar
  background: white !important
  border-radius: 10%
.order-activity
  padding-top: 40px

  &__logo
    width: 36px
    height: auto

  &__tabs
    padding-bottom: 54px
    padding-top: 0
    border-top: 1px solid #DFE3E8
    height: 18px
    box-sizing: border-box

  &__tab
    cursor: pointer
    width: 30px
    height: 26px
    text-align: center
    margin-right: 5px

  &__tab--active
    border-top: 2px solid black

  &__time-tab
    position: absolute
    left: 35px
    padding-right: 8px
    padding-left: 8px
    cursor: pointer

  &__title
    font-size: 16px
    color: #212B36
    padding-bottom: 20px
    padding-left: 20px

  &__timeline-container
    border-left: 2px solid #DFE3E8
    margin-left: 20px

  &__timeline-logo-icon
    margin-left: -16px
    padding: 1px
    border: 1px solid #aaaaaa
    height: 37px
    width: 37px
    background: white

  &__timeline-clock-icon
    margin-left: -9px
    z-index: 999
    img
      background: white

  &__account-name
    margin-left: 10px
    color: #4A90E2
    font-size: 14px
    font-weight: bold

  &__date
    margin-left: 10px
    color: #AAAAAA
    font-size: 14px
    font-weight: 500
    text-transform: lowercase

  &__comment
    margin-left: 10px
    font-size: 14px
    color: #333333

  &__comment-input-group
    margin-top: 20px
    position: relative
    margin-bottom: 20px

  &__comment-input
    height: 44px
    font-size: 16px

  &__comment-input::placeholder
    font-size: 16px !important

  &__coment-input-button
    width: 89px
    position: absolute
    right: 10px
    top: 70px
    //margin-left: -60px
    margin-top: 5px
    color: #ffffff
    border: none
    font-size: 15px
    border-radius: 2px

  &__event:last-child
    background: white
    height: 100%

.order-activity__timeline-container
  .time-line__wrap
    height: 100%
    margin-top: 0px
    display: flex

    .time-line-list
      margin: 0
      padding: 0
      list-style: none
      width: 100%
      position: relative
      overflow: auto
      display: flex
      flex-direction: column

      .time-line
        position: relative

      .time-line:after
        display: inline-block
        width: 2px
        height: 100%
        background-color: #D7D9DE
        position: absolute
        top: 27px
        left: 17px
        z-index: 1

        .img-block
          position: absolute
          top: 0
          left: 0

        .order-activity__block
          padding: 14px 0px 11px 36px
.commentDate
  color: #AAAAAA
  font-size: 14px
  margin-right: 6px
.v-application--wrap
  min-height: 0px!important
