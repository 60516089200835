
.payment-history
  &__title
    font-family: Playfair Display
    font-style: normal
    font-weight: bold
    font-size: 48px
    line-height: 38px
    display: flex
    align-items: center
    color: #000000
    margin-bottom: 30px
  &__subtitle-desc
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 16px
    line-height: 24px
    display: flex
    align-items: center
    color: #595959
    margin-bottom: 26px
  &__table-title
    font-family: SF Pro Display
    font-style: normal
    font-weight: 600
    font-size: 14px
    line-height: 22px
    display: flex
    align-items: center
    color: #262626
  &__margin-left
    margin-left: 14px
  &__table-content
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 22px
    display: flex
    align-items: center
    //margin-top: 8px
    color: #595959
  &__table-operation
    font-family: Proxima Nova
    font-style: normal
    font-weight: normal
    font-size: 14px !important
    line-height: 22px
    display: flex
    align-items: center
    color: #4A8A5D !important
    cursor: pointer
    background: none !important
    border: none !important
.payment-history__main
  .can-not-create-training
    padding: 15px
    text-align: center
    font-size: 15px
    .title-error-text
      font-weight: bold
    .button-style
      text-align: right
    .btn-style
      background: #9fd19a
      border: 1px solid #9fd19a
      color: #ffffff
    .btn-style:hover
      background: #4a8a5d
      border: 1px solid #4a8a5d
      color: #ffffff
  .refund-content
    width: 300px
    margin: 20px 70px
    text-align: center
  .refunded-style
    color: red !important
