
.old-tracking-information-modal
  .modal-card-body
    display: flex
  &__header
    color: #212B36
    font-size: 20px
    border-bottom: 1px solid #E1E4E9
    padding-top: 15px
    padding-left: 20px
    padding-bottom: 15px
    .old-tracking-information-modal__close-button
      top: 20px
      width: 14px
      height: 14px
  &__content
    position: relative
    background-color: #FFFFFF
    width: 525px
  .button
    height: 30px
    font-size: 14px
    padding: 16px 20px
  &__body
    padding: 30px
    width: 525px
    display: flex
    justify-content: center
  &__buttons
    padding: 15px 20px
    display: flex
    justify-content: flex-end
  &__close-button
    position: absolute
    top: 20px
    right: 20px
    cursor: pointer
    height: 20px
    width: 20px
  &__select-placeholder
    position: relative
    padding-left: 20px
    bottom: 29px
    color: #b4b4b4
    font-size: 14px
    width: 165px
  &__label
    font-size: 14px
    font-weight: 500
  &__form-container
    width: 300px
    min-height: 220px
