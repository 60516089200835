
.admin-message-brand-create-new-modal {
  &__outer {
    padding-top: 40px;
    padding-bottom: 47px;
    background-color: #fff;
    height: 100%;
  }
  &__title {
    font-family: Playfair Display;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 22px;
    color: #262626;
  }
  &__large-icon {
    color: #595959;
    font-size: 14px;
  }
  &__box-content {
    background: #ffffff;
    border: 1px solid rgba(74, 138, 93, 0.46);
    box-sizing: border-box;
    border-radius: 4px;
  }
  &__choose-model-layer {
    border-bottom: 1px solid rgba(74, 138, 93, 0.46);
    vertical-align: middle;
  }
  &__subject {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #262626;
    padding-right: 4px;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-top: 4px;
  }
  &__intro-brand {
    width: 600px;
    min-height: 300px;
    background: #f5f5f5;
    border-radius: 16px;
    padding: 15px 20px;
  }
  &__intro-brand-title {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
  }
  &__intro-brand-subtitle {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #000000;
  }
  &__intro-brand-divider {
    border-bottom: 1px solid #d9d9d9;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  &__intro-brand-img {
    border-radius: 4px;
    width: 680px;
    height: 151px;
    margin-bottom: 20px;
  }
  &__intro-brand-content {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    width: 561px;
  }
  &__intro-product {
    background: #f5f5f5;
    border-radius: 16px;
    padding: 13px 20px;
  }
  &__intro-product-title {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 38px;
    color: #262626;
  }
  &__intro-product-desc {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #262626;
    margin-bottom: 14px;
  }
  &__intro-product-list-item-outer {
    width: 141px;
    height: 195px;
    position: relative;
    background-color: inherit;
    // display: inline-block;
    float: left;
  }
  &__intro-product-list-item-icon {
    font-size: 15px;
    color: #4a8a5d;
    position: absolute;
    top: 2px;
    right: 12px;
    // margin-top: 2px;
    // float: right;
    // margin-right: 12px;
  }
  &__intro-product-list-item {
    width: 123px;
    height: 185px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 2px;
    margin-right: 18px;
    display: inline-block;
    margin-top: 8px;
  }
  &__intro-product-list-item-no-product {
    width: 123px;
    height: 185px;
    background: #ffffff;
    border: 1px dashed #999999;
    box-sizing: border-box;
    border-radius: 2px;
    margin-right: 18px;
    display: inline-block;
    margin-top: 8px;
  }
  &__intro-product-list-item-text {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    line-height: 12px;
    position: absolute;
    bottom: 7px;
    width: 119px;
    padding: 0px 2px;
    text-align: center;
  }
  &__add-product {
    background: #4a8a5d;
    border-radius: 2px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #ffffff;
  }
  &__sign-txt {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #262626;
    margin-top: 14px;
  }
}
