
.v-expansion-panel--active>.v-expansion-panel-header
    min-height: 50px !important
.v-expansion-panel-content__wrap
    padding: 0 0px 10px !important
.row
    margin-left: 0px !important
    margin-right: 0px !important
.admin-retailer-margin
    padding: 0 10%
    margin-top: 5%
    margin-left: 10%
    margin-right: 10%
    margin-bottom: 100px
    &__margin-top
        margin: 4px 0px !important

    &__table-name
        color: #000000
        font-size: 20px
        font-weight: 700
        letter-spacing: 0.25px
        padding-left: 15px

    &__table-sub-name
        color: #9E9E9E !important
        font-size: 12px
        font-weight: 500
        padding-top: 10px
        padding-left: 15px

    &__add-retailer
        width: 130px
        height: 30px
        float: right
        text-align: center
        background: #716B68
        font-size: 14px
        font-weight: bold
        color: #eeeeee

    &__expansion-head
        font-weight: bold
        font-size: 12px

    &__expansion-head>div
        margin-left: auto
    &__text-percent
        text-align: center
        font-size: 14px !important
        width: 100%
    &__text-center
        text-align: left
        font-size: 14px !important
    &__text-right
        text-align: right
        font-size: 14px !important
        margin-bottom: 0px !important
        width: 100%
        padding-right: 4px

    &__col-cell
        display: flex
        text-align: left
        color: #333333 !important
        padding: 4px !important
        align-items: center
    &__col-col-cell
        display: flex
        align-items: center
    &__compobox-cell
        font-size: 14px !important

    &__filter-column-name
        color: #292929
        font-size: 16px
        font-weight: 300
        margin-bottom: 15px

    &__border
        border-bottom: 1px solid #E0E0E0

    &__no-margin-bottom
        margin: 0px !important


    &__compbox-left-block
        float: left
        display: block
        padding: 0.4em 1em
        vertical-align: middle
        margin: 10px !important


    &__btn-right
        float: right
        display: flex
        justify-content: end
        justify-content: flex-end
        font-size: 12px
        padding: 10px
        margin: 10px

    &__no-padding-bottom
        padding-bottom: 0px !important

    &__no-padding-top
        padding-top: 0px !important

    &__no-padding-top-bottom
        padding: 0px 0px !important

    &__margin-right
        margin-right: 10px

    &__btn_border-grad
        margin-right: 10px
        width: 70px
        height: 30px
        border: 1px solid #D9D9D9
        box-sizing: border-box
        border-radius: 2px
        font-size: 12px
        font-weight: bold
        color: #595959

    &__btn_border-black
        margin-right: 10px
        width: 70px
        height: 30px
        border: 1px solid #D9D9D9
        box-sizing: border-box
        border-radius: 2px
        font-size: 12px
        font-weight: bold
        color: #000000
        align-items: center

    &__btn_black-white
        color: #ffffff
        margin-right: 10px
        width: 70px
        height: 30px
        background: #000000
        align-items: center
        font-size: 12px
        font-weight: bold
    &__btn_black-white:hover
        color: #ffffff !important

    &__btn_black-white:focus
        background: #000000
        color: #ffffff
    &__padding
        padding: 0px 0px !important

    &__margin-left
        margin-left: 16px !important
    &__margin-left-eight
        margin-left: 8px !important
    &__ctn-width
        width: 40%
        text-align: right
    &__new-retailer-select
        width: 60% !important

    &__padding-left
        padding-left: 42px !important
.v-messages__message
    color: #ff3860 !important
    font-size: 0.75rem !important
    line-height: 14px !important
    padding: 0 !important
    margin: 0 !important
.v-text-field__details
    padding: 4px 0px 0px 0px !important
    margin-bottom: 0px !important
.v-input__slot
    margin: 10px 0px 0px 0px!important
.v-btn--fab.v-size--small.v-btn--absolute.v-btn--bottom
    bottom: -15px !important
.v-btn--fab.v-size--small
    height: 25px !important
    width: 25px !important
.v-text-field--outlined.v-input--dense.v-text-field--outlined>.v-input__control>.v-input__slot
    min-height: 20px !important
.v-icon
    height: 20px !important
    width: 20px !important
    font-size: 20px !important
hr
    margin: 0 0 !important
@media (min-width: 1264px)
.col-1
    -webkit-box-flex: 0
    -ms-flex: 0 0 10.3333333333% !important
    flex: 0 0 10.3333333333% !important
    max-width: 10.3333333333% !important
.ant-btn-primary
    color: #ffffff !important
    background-color: #4a8a5d !important
    border-color: #4a8a5d !important
