
  .guest-header__container
    @media only screen and (max-width: 991px)
      padding: 0px 40px 0px 40px !important

    @media only screen and (max-width: 991px)
      padding: 0px 20px 0px 20px !important

    .guest-header__navbar
      .guest-header__actions
        .guest-header__item
          margin-right:0px
        .guest-header__item.guest-header__item
          margin-left: 12px

.categoryTitle
  position: absolute
  width: 111px
  top: 2px
  color: #4A4A4A
  font-size: 12px
  letter-spacing: 0
  line-height: 15px
.unfiltered
  &__main-section
    position: relative
    height: 440px
    background-color: #E9CFCF
    .unfiltered__main-section-image-block
      width: 100%
      height: 100%
      img
        width: 100%
        height: 100%

    .unfiltered__main-section__top-block
      height: 100%
      width: 100%
      display: flex
      flex-direction: row
      position: absolute
      top: 0
      left: 0
      padding: 0px 80px
      @media only screen and (max-width: 991px)
        padding: 0px 40px

      .unfiltered__main-section__top-left
        width: 100%
        display: flex
        flex-direction: column
        align-items: flex-start
        justify-content: center

        .left-text
          text-align: left
          margin-bottom: 30px

        .unfiltered__main-section__buttons
          justify-content: center
          flex-wrap: nowrap
          @media only screen and (max-width: 600px)
          flex-wrap: wrap

        @media only screen and (max-width: 1200px)
          .unfiltered__main-section__button
            width: 210px
          .unfiltered__main-section__button-brand
            width: 210px

        @media only screen and (max-width: 600px)
          .unfiltered__main-section__button
            width: 256px
            margin-right:0px
          .unfiltered__main-section__button-brand
            width: 256px

      .unfiltered__main-section__top-right
        width: 100%
        display: flex
        flex-direction: column
        align-items: flex-end
        padding-top: 52px

        @media only screen and (max-width: 991px)
          display: none

      @media only screen and (max-width: 1200px)
        .unfiltered__main-section__paragraph-2
          font-size: 25px

        .unfiltered__main-section__paragraph-2__small-text
          font-size: 25px

        .unfiltered__main-section__paragraph-2-text
          font-size: 25px


    &__button
      height: 46px
      width: 256px
      border-radius: 2px
      font-size: 14px
      font-weight: 500
      letter-spacing: 0.75px
      line-height: 17px
      margin-right: 20px

    &__button-brand
      height: 46px
      width: 256px
      border-radius: 2px
      font-size: 14px
      font-weight: 500
      letter-spacing: 0.75px
      line-height: 17px
      background: transparent
      border: 1px solid black

    &__paragraph
      //position: absolute
      //top: 137px
      //left: 80px
      height: 84px
      width: 532px
      color: #4A4A4A
      font-size: 14px
      font-weight: 600
      letter-spacing: 0.39px
      line-height: 28px

    &__paragraph-2
      //position: absolute
      //top: 137px
      color: #12433D
      font-size: 62px
      font-weight: bold
      font-family: Playfair Display
      font-style: normal
      letter-spacing: -1.6px
      line-height: 83px
      width: 1283px
      height: 91px
      text-align: right
      right: 112px

      &__small-text
        //position: absolute
        //top: 230px
        color: #414141
        font-style: normal
        font-weight: normal
        font-size: 39px
        line-height: 48px
        text-align: right
        right: 80px

      &-text
        //position: absolute
        //top: 260px
        margin-top: 50px
        color: #414141
        font-size: 24px
        font-weight: 400
        letter-spacing: -0.6px
        line-height: 33px
        text-align: right
        right: 80px
        width: 673px

    &__paragraph-3
      margin-top: 65px
      color: #414141
      font-size: 18px
      text-align: right
      line-height: 22px
      &--link
        color: #FF6132
        font-weight: bolder
        cursor: pointer

    &__link
      //position: absolute
      color: #161616
      font-size: 14px
      letter-spacing: 0.16px
      line-height: 18px
      bottom: 130px
      left: 89px
      text-decoration: underline

    &__image
      height: 100%
      position: absolute
      background-repeat: repeat-x
      width: 100%

  &__form-section
    position: relative
    height: 1050px
    .unfiltered__form-section__top-block
      height: 100%
      width: 100%
      display: flex
      flex-direction: row
      position: absolute
      top: 0
      left: 0
      padding: 0px 80px
      @media only screen and (max-width: 991px)
        padding: 0px 40px

      .unfiltered__form-section__top-left
        width: 100%
        display: flex
        flex-direction: column
        align-items: flex-start

        .left-text
          text-align: left
          margin-bottom: 30px

        .unfiltered__form-section__buttons
          justify-content: center
          flex-wrap: nowrap
          @media only screen and (max-width: 600px)
          flex-wrap: wrap

        @media only screen and (max-width: 1200px)
          .unfiltered__form-section__button
            width: 210px
          .unfiltered__form-section__button-brand
            width: 210px

        @media only screen and (max-width: 600px)
          .unfiltered__form-section__button
            width: 256px
            margin-right:0px
          .unfiltered__form-section__button-brand
            width: 256px

      .unfiltered__form-section__top-right
        width: 100%
        display: flex
        flex-direction: column
        align-items: flex-end
        padding-top: 52px

        @media only screen and (max-width: 991px)
          display: none

      @media only screen and (max-width: 1200px)
        .unfiltered__form-section__paragraph-2
          font-size: 25px

        .unfiltered__form-section__paragraph-2__small-text
          font-size: 25px

        .unfiltered__form-section__paragraph-2-text
          font-size: 25px



    &__button
      height: 46px
      width: 256px
      border-radius: 2px
      font-size: 14px
      font-weight: 500
      letter-spacing: 0.75px
      line-height: 17px
      margin-right: 20px

    &__button-brand
      height: 46px
      width: 256px
      border-radius: 2px
      font-size: 14px
      font-weight: 500
      letter-spacing: 0.75px
      line-height: 17px
      background: transparent
      border: 1px solid black
    &_item-1
      font-size: 14px
    &_title-1
      font-family: Playfair Display
      font-style: normal
      font-weight: bold
      font-size: 28px
      line-height: 37px
      color: #12433D
      margin-top: 81px
      margin-bottom: 24px

    &_title-2
      font-family: Playfair Display
      font-style: normal
      font-weight: bold
      font-size: 28px
      line-height: 37px
      color: #12433D
      margin-top: 48px
      margin-bottom: 24px

    &_title-3
      font-family: Playfair Display
      font-style: normal
      font-weight: bold
      font-size: 48px
      line-height: 52px
      color: #12433D
      margin-bottom: 18px
      align-self: center

    &_title-4
      font-style: normal
      font-weight: normal
      font-size: 22px
      line-height: 27px
      margin-top: 0px
      margin-bottom: 0px
      align-self: center
      text-align: center
      padding-left: 63px
      padding-right: 63px

    &__paragraph-2
      //position: absolute
      //top: 137px
      color: #12433D
      font-size: 62px
      font-weight: bold
      font-family: Playfair Display
      font-style: normal
      letter-spacing: -1.6px
      line-height: 83px
      width: 1283px
      height: 91px
      text-align: right
      right: 112px

      &__small-text
        //position: absolute
        //top: 230px
        color: #414141
        font-style: normal
        font-weight: normal
        font-size: 39px
        line-height: 48px
        text-align: right
        right: 80px

      &-text
        //position: absolute
        //top: 260px
        margin-top: 50px
        color: #414141
        font-size: 24px
        font-weight: 400
        letter-spacing: -0.6px
        line-height: 33px
        text-align: right
        right: 80px
        width: 673px

    &__link
      //position: absolute
      color: #161616
      font-size: 14px
      letter-spacing: 0.16px
      line-height: 18px
      bottom: 130px
      left: 89px
      text-decoration: underline

    &__image
      height: 100%
      position: absolute
      background-repeat: repeat-x
      width: 100%

  &__categories-section
    height: 454px
    padding: 80px 60px 95px 60px

  .unfiltered-border__block
    margin-top: 80px


    &__column
      position: relative
      display: flex
      justify-content: center
      align-items: center

    &__picture-title
      position: absolute
      top: 10px
      color: #4A4A4A
      font-size: 12px
      line-height: 15px
      height: 46px
      width: 82px
      left: 22%

  &__title
    font-family: Didot
    font-size: 28px
    letter-spacing: 0.39px
    line-height: 35px
    color: #333333
    text-align: center
    margin-bottom: 28px
    text-transform: capitalize

  &__subtitle
    font-size: 16px
    letter-spacing: 0.44px
    line-height: 28px
    text-align: center

  &__border
    border: 0.5px solid #E5E5E5
    text-align: center

  &__carousel
    position: relative
    display: flex
    justify-content: space-between
    //margin-top: 50px

  &__arrow
    //margin: 142px 24px
    margin: 60px 24px

  &__retailers-section
    height: 563px
    padding: 102px 32px 80px 32px
    //margin-bottom: 30px

    &__image
      //height: 168px
      //width: 168px
      padding-right: 24px
      //margin-top: 100px

      &-1
        margin-top: 122px

      &-2
        margin-top: 78px

      &-3
        margin-top: 138px
        margin-left: -10px

      &-4
        margin-top: 139px

      &-5
        margin-top: 138px

      &-6
        margin-top: 117px

  &__brands-section
    height: 542px
    padding: 80px 32px 80px 32px

    &__image
      //height: 168px
      width: 168px
      padding-right: 24px
      margin-top: 40px

      &-1
        //margin-top: 78px

      &-2
        //margin-top: 114px

      &-3
        //margin-top: 140px

      &-4
        //margin-top: 136px
        //margin-left: 9px

      &-5
        //margin-top: 79px
        //margin-left: 5px

      &-6
        //margin-top: 134px

  &__sign-up-section
    height: 270px
    background: rgba(228,217,216,0.53)
    padding: 65px 0px 23px 0px
    margin-top: 30px

    @media only screen and (max-width: 600px)
      margin-top: 100px

    .signup__text-block
      width: 680px
      margin: 0 auto

      @media only screen and (max-width: 991px)
        width: auto

    &__text
      font-family: Didot
      font-size: 16px
      font-style: italic
      letter-spacing: 0.44px
      line-height: 28px
      text-align: center

    &__link
      color: #161616
      font-size: 14px
      font-weight: 500
      letter-spacing: 0.16px
      line-height: 18px
      text-decoration: underline
      display: flex
      justify-content: center
      margin-top: 55px

  &__publications-section
    padding: 80px 32px 80px 32px
    height: 442px

    &__image
      //height: 168px
      width: 168px
      padding-right: 24px
      height: 144px
      padding-right: 24px
      display: flex
      align-items: center

      &-1
        //margin-top: 125px

      &-2
        //margin-top: 122px

      &-3
        //margin-top: 137px

      &-4
        //margin-top: 127px

      &-5
        //margin-top: 132px

      &-6
        //margin-top: 141px

  &__instagram-section
    height: 579px
    padding: 80px 32px 80px 32px

    &__image
      height: 168px
      width: 168px
      padding-right: 24px
      margin-top: 40px

  .unfiltered__categories-section
    .categories__image-block
      .slider__block
        .VueCarousel-wrapper

          .VueCarousel-inner
            .VueCarousel-slide
              display: flex
              align-items: center
              justify-content: center

  .slider__block
    width: 100%
    .VueCarousel-wrapper

      .VueCarousel-inner
        .VueCarousel-slide
          margin: 0px 6px
          display: flex
          align-items: center

    .VueCarousel-pagination
      .VueCarousel-dot-container
        margin-top:0px
        .VueCarousel-dot
          outline: none

  .unfiltered-retailer-section
    margin-top: 81px
    &__title-1
      font-family: Playfair Display
      font-style: normal
      font-weight: bold
      font-size: 48px
      line-height: 52px
      color: #12433D
      margin-bottom: 24px
    &__paragraph-1
      font-style: normal
      font-weight: normal
      font-size: 22px
      line-height: 27px
      color: #000000
      margin-bottom: 24px
