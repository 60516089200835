
.landing-terms-main {
  .logistic-style {
    font-size: 28px;
    color: #000000;
    font-weight: 600;
    font-family: "Proxima Nova", "Roboto", sans-serif;
  }
  .active-button {
    float: right;
    margin-top: -63px;
    padding: 5px 16px;
    border: 1px solid #4a8a5d;
    background-color: #e2eeec;
    color: #4a8a5d;
    font-size: 14px;
    cursor: pointer;
    font-family: "Proxima Nova", "Roboto", sans-serif;
  }
  .active-button:hover {
    background-color: #4a8a5d;
    color: white;
  }
  .activated-button {
    float: right;
    margin-top: -62px;
    color: #4a8a5d;
    font-size: 14px;
    display: flex;
    align-items: center;
  }
}
.logistic-modal-title {
  text-align: center;
  margin-bottom: 0;
}
.logistic-text-content {
  width: 525px;
  font-size: 14px;
  font-family: "Proxima Nova", "Roboto", sans-serif;
  font-weight: 400;
  color: #000000;
  //text-align: center;
  margin-left: 30px;
}
.logistic-text-content-child {
  width: 425px;
  font-size: 14px;
  font-family: "Proxima Nova", "Roboto", sans-serif;
  font-weight: 400;
  color: #000000;
  //text-align: center;
  margin-left: 70px;
}
.logistic-modal-checkInfo {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 20px;
  color: #000000;
  margin-top: 70px;
  p {
    width: 488px;
    margin-left: 14px;
  }
}
.logistic-modal-button {
  text-align: center;
  margin-top: 20px;
  span {
    padding: 5px 16px;
    font-size: 14px;
    cursor: pointer;
    font-family: "Proxima Nova", "Roboto", sans-serif;
  }
  .decline-text {
    border: 1px solid #d9d9d9;
    color: #595959;
  }
  .accept-text {
    color: white;
    border: 1px solid #4a8a5d;
    background-color: #4a8a5d;
    margin-left: 8px;
  }
}
