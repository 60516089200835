
.clients-filter__item--checked
  position: absolute
  right: 3px
  top: 3px
  width: 18px
  height: 18px

.clients-filter
  ::-webkit-scrollbar
    width: 10px

  ::-webkit-scrollbar-track
    border-radius: 10px

  ::-webkit-scrollbar-thumb
    background: #E5E5E5
    border-radius: 10px

  ::-webkit-scrollbar-thumb:hover
    background: #B3B3B3

  &__item
    height: 27px
    font-size: 12px
    font-weight: 300
    position: relative

  &__items
    display: none
    overflow: hidden
    margin-top: 10px

  &__see-all
    color: #B3B3B3
    font-size: 12px
    font-weight: 300

  &__items--visible
    display: block

  &__icon
    position: absolute
    left: 0
    margin-right: 20px

  &__header-name
    position: relative
    display: block
    cursor: pointer
    border-bottom: 1px solid #EBEFF2
    padding: 16px 0px 16px 0px
    color: #292929
    font-size: 12px
    font-weight: 300
    line-height: 18px

  &__header--icon
    float: right
    padding-right: 10px

  &__header--reset
    float: right
    color: #B3B3B3

  &__item-name
    margin-left: 24px
    font-size: 14px
