
@import "../assets/styles/shared/_fonts";
.subscription{
  margin: 0 auto;
  padding-bottom: 74px;
  width: 100%;
}
.subscription__wrapper {
  margin: 0 auto;
  width: 100%;
  //max-width: 1440px
}

.subscription__cover {
  position: relative;
  width: 100%;
  height: 885px;
  img {
    width: 100%;
    height: 100%;
  }
  .subscription-logo {
    position: absolute;
    left: 66px;
    top: 59px;
    width: 173px;
    height: 54px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.subscription__title {
  margin: 76px auto 0 auto;
  width: 100%;
  text-align: center;
  font-family: 'Playfair Display';
  color: #12433D;
  font-weight: 600;
  font-size: 48px;
  line-height: 51px;
}

.subscription__subtitle {
  margin: 38px auto 0 auto;
  text-align: center;
  font-family: 'Proxima Nova';
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #595959;
}
.subscription__subtitle__highlight {
  color: #4a8a5d;
}
.subscription__description-flag {
  position: relative;
  margin: 114px auto 0 auto;
  height: 0;
  width: 1028px;
  &::before {
    position: absolute;
    top: -24px;
    left: -38px;
    width: 129px;
    height: 48px;
    line-height: 51px;
    content: attr(data-before);
    font-size: 33px;
    font-family: 'Playfair Display';
    font-weight: bold;
    color: #659A71;
  }
  &::after {
    position: absolute;
    right: -78px;
    top: -24px;
    width: 209px;
    height: 48px;
    content: attr(data-after);
    text-align: right;
    font-family: 'Playfair Display';
    font-weight: bold;
    font-size: 33px;
    line-height: 51px;
    color: #FF6132;
  }
}
.subscription__description{
  position: relative;
  display: flex;
  margin: 0 auto;
  width: 1028px;
  height: 1110px;
  border-radius: 250px;
  border: 5px solid #FF6132;
  overflow: hidden;
  &>section:first-child {
    width: 533px;
  }
  &>section:last-child {
    width: 485px;
  }
  &>section {
    margin-top: -5px;
    margin-bottom: -5px;
    height: 1110px;
    &>div {
      margin-bottom: 50px;
    }
    &>div:first-child {
      margin-top: 92px;
    }
    &>div:nth-child(2) {
      margin-bottom: 33px;
    }
    .feature-effective__date {
      margin: -30px 0 0 124px;
      font-family: 'Proxima Nova';
      font-style: italic;
      font-size: 14px;
      line-height: 14px;
      font-weight: 400;
    }
  }
}
.subscription__description__basic {
  border-right: 5px solid #4A8a5d;
  border-top: 5px solid #4A8a5d;
  border-bottom: 5px solid #4A8a5d;
  border-radius: 250px;
  transform: translateX(-3px);
}

.subscription__description__title {
  position: relative;
  margin-left: 124px;
  height: 23px;
  line-height: 23px;
  font-family: 'Playfair Display';
  font-weight: bold;
  font-size: 17px;
  color: #12433D;
  &::before {
    position: absolute;
    content: '.';
    top: -7px;
    left: -20px;
    font-size: 26px;
    font-weight: bold;
  }
}

.subscription__description__content {
  margin: 16px 0 0 124px;
  width: 246px;
  min-height: 51px;
  font-family: 'Proxima Nova';
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.subscription__comparison {
  margin: 76px auto 0 auto;
  width: 720px;
  font-family: 'Proxima Nova';
  thead {
    line-height: 21px;
    font-size: 17px;
    font-weight: bold;
    border-bottom: 1px solid rgba(51, 62, 76, .2);
    th {
      color: #888888;
    }
    &>th:first-child {
      width: 290px;
      text-indent: 26px;
    }
    &>th:nth-child(2) {
      width: 210px;
      text-align: center;
    }
    &>th:nth-child(3) {
      width: 220px;
      text-align: center;
    }
  }
  tbody {
    tr {
      height: 65px;
      font-size: 17px;
      line-height: 25px;
      font-weight: bold;
      color: #12433D;
      border-bottom: 1px solid rgba(51, 62, 76, .2);
      td {
        vertical-align: bottom;
      }
      &>td:first-child {
        text-indent: 34px;
        font-weight: 500;
      }
      &>td:not(:first-child) {
        text-align: center;
      }
    }
  }
}
.subscription__copyright {
  margin: 240px auto 0 auto;
  font-family: 'Proxima';
  font-weight: 400;
  font-size: 24px;
  line-height: 37px;
  text-align: center;
  color: #000;
}

