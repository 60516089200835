
.no-brands
  font-size: 14px
  color: #000
  text-align: center
  margin-left: 274px

.dot
  height: 19px
  padding: 3px
  background-color: #bbb
  border-radius: 29%

.brand-directory-page-placeholder
  min-height: calc(100vh - 160px)

  .hero-body
    padding-top: 0px !important
    padding-bottom: 0px !important
    justify-content: center
    align-items: flex-start !important
    display: flex

    .container
      .all-users-view__wrap
        padding-top: 20px
        max-width: 684px
        margin: 0 auto
        .all-brand
          font-size: 30px
          color: #000
          margin-bottom: 20px
          text-align: center

        .user-index__block
          padding-bottom: 18px
          min-width: 684px
          border-bottom: 1px solid #ddd
          display: flex
          align-items: center
          justify-content: center
          flex-wrap: wrap

          .index-box
            cursor: pointer
            // margin: 0px 8px
            a
              font-size: 12px
              margin: 8px
              color: #000
              font-weight: 600
              text-transform: uppercase
              &:hover
                text-decoration: underline

        .all-details__wrap
          padding: 20px 0px
          min-width: 860px
          flex-wrap: wrap
          display: flex
          align-items: flex-start

          ul
            list-style: none
            padding: 0
            margin: 0 30px 30px
            flex-basis: 15%
            li
              a
                font-size: 12px
                color: #000
                font-weight: 300
                &:hover
                  text-decoration: underline
