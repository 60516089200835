
.admin-training-retailer-location__main {
  //.ant-radio {
  //  top: 0;
  //}
  .ant-checkbox-wrapper::after {
    contain: none;
    display: none;
  }
}
.admin-training-retailer-location-upload .upload-modal__body {
  margin-top: 20px;
}
.admin-training-retailer-location-upload .ant-upload-drag-icon {
  i {
    color: #91d395 !important;
  }
}
.admin-training-retailer-location-upload .retailer-location-upload-modal__action {
  padding: 20px 0;
  text-align: center;
}

// .admin-training-retailer-location-upload .upload-modal__btn {
//   background: #ffffff;
//   border: 1px solid #e1644c ;
//   border-radius: 7px;
//   font-family: "Proxima Nova";
//   font-style: normal;
//   font-weight: 400;
//   font-size: 12px;
//   line-height: 20px;
//   letter-spacing: 0.04em;
//   color: #e1644c ;
// }
