
@font-face {
  font-family: "Playfair Display";
  src: url("../../../assets/fonts/PlayfairDisplay-Bold.ttf") format("truetype");
}

// @font-face {
//   font-family: "Proxima Nova";
//   src: url("../../../assets/fonts/Proxima-Nova-Light.otf") format("truetype");
// }
.brand-banner__main {
  width: 100%;

  //margin-top: 2px;
  //height: 544px;
  .banner-content {
    position: relative;
    max-height: 80vmin;

    //    margin-bottom: -200px;
    img {
      width: 100%;
      height: auto;
    }

    .content-text {
      max-width: 40%;
      background-color: white;
      text-align: left;
      margin: 0.5rem;
      position: absolute;
      top: 5%;
      border-radius: 22px;
      left: 5%;
      z-index: 3;
      letter-spacing: 0.6px;
    }

    .banner-title {
      margin: 5%;
      font-weight: 700;
      font-size: 2rem;
      font-family: "Playfair Display";
      color: #12433d;
      //      letter-spacing: 0.6;
    }

    .banner-content-text {
      margin: 5%;
      font-size: 1.5rem;
      font-family: "DM Sans";
      line-height: 40px;
      margin-top: 5%;
      margin-bottom: 38px;
      color: #000000;
      font-weight: 600;
    }

    .banner-button-yellow {
      width: 268px;
      height: 43px;
      background-color: #fec206;
      text-align: center;
      border-radius: 22px;
      line-height: 40px;
      font-size: 18px;
      color: #ffffff;
      font-family: "DM Sans";
      font-weight: 600;
      cursor: pointer;
    }

    .banner-button {
      margin: 5%;
      width: 268px;
      height: 43px;
      border: 2px solid #27468e;
      background-color: #27468e;
      text-align: center;
      border-radius: 22px;
      line-height: 40px;
      font-size: 16px;
      color: #ffffff;
      font-family: "DM Sans";
      cursor: pointer;
    }

    .banner-button:hover {
      background: #ff6132;
      color: white;
    }

    .left-content-text {
      max-width: 40%;
      background-color: white;
      position: absolute;
      top: 5%;
      right: 4%;
      z-index: 3;
      border-radius: 22px;
      margin: .5rem;

      .banner-content-text {
        text-align: left;
        //width: 500px;
      }

      .two-text {
        //margin-bottom: 56px;
      }

      .banner-button-yellow {
        float: right;
      }
    }
  }

  .banner-one {
    //width: 510px;
    //margin-top: -67px;
    //margin-bottom: -1em;
    line-height: 60px;
    //height: 20px;
  }

  .banner-three {
    height: 0;
  }
}

.ant-carousel .slick-dots li.slick-active {
  //width: 16px !important;
}

//.ant-carousel .slick-dots li {
//  margin: 0 7px !important;
//}
.ant-carousel .slick-dots li.slick-active .costom-pag-radius {
  background: #f5f4f4;
  margin-right: 0;
  width: 24px;
  height: 24px;
  margin-top: -19px;
}

.costom-pag-radius {
  height: 21px;
  width: 21px;
  border-radius: 50%;
  background: white;
  margin-top: -16px;
}

@media screen and (min-width: 1500px) {
  .slick-track {
    height: 544px !important;
  }

  .brand-banner__main .banner-content .left-content-text {
    //right: 190px;
  }

  .brand-banner__main {
    .banner-content {
      background-color: #ffffff;

      .content-text {
        //left: 183px;
      }
    }
  }
}
