
.admin-system-promocode__table-content .ant-table-thead > tr > th,
.admin-system-promocode__table-content .ant-table-tbody > tr > td {
  padding: 5px 5px;
  text-align: center;
}
.admin-system-promocode__table-content .ant-switch-checked {
  background: #4a8a5d;
}
.admin-system-promocode .ant-btn:hover,
.admin-system-promocode .ant-btn:focus {
  background: #4a8a5d;
  color: white;
}
