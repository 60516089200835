
.admin-brand-inventory {
  &__content {
    margin-top: 10px;
  }
  &__search-input {
    background: #dee5ef !important;
    border-radius: 1px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    line-height: 17px !important;
    color: #595959 !important;
    border: none !important;
    height: 27px !important;
    width: 100%;
  }
  &__back-btn {
    background: #dee5ef !important;
    border-radius: 2px !important;
    border: none !important;
    width: 88px;
    height: 30px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #000000 !important;
    float: right;
    margin-left: 34px;
  }
  &__back-img {
    width: 17px;
    height: 17px;
    margin-left: 6px;
  }
  &__name-value {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 98%;
    display: block;
  }
}
.admin-brand-inventory__search-input input.ant-input {
  background-color: #dee5ef;
  padding-left: 10px;
}
.admin-brand-inventory__search-input .ant-input {
  height: 27px;
}
.admin-brand-inventory__search-input .ant-select-selection--single {
  height: 28px;
  min-height: 28px;
  background-color: transparent;
  border: none;
}
.admin-brand-inventory__search-input
  .ant-select-selection--multiple
  .ant-select-selection__choice__remove {
  display: none;
}
.admin-brand-inventory__search-input .ant-select-selection__rendered {
  line-height: 27px;
}

.admin-brand-inventory__search-input input.ant-input {
  background-color: #dee5ef;
  padding-left: 10px;
}
.admin-brand-inventory__search-input .ant-input {
  height: 27px;
}
.admin-brand-inventory__search-input .ant-select-selection__clear {
  background-color: transparent;
  right: 24px;
}
.admin-brand-inventory__search-input.ant-select-focus {
  border: none;
}
.admin-brand-inventory__search-input .ant-select-selection--multiple .ant-select-selection__choice {
  padding: 2px 2px 0 2px;
  background-color: transparent;
  border: none;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #595959;
}
.admin-brand-inventory__search-input .ant-select-selection__placeholder {
  color: #595959 !important;
}
.admin-brand-inventory__content .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ffffff !important;
  border: 1px solid #bfbfbf !important;
}
.admin-brand-inventory__content .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #595959;
}
