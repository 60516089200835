
.brands-items
  padding-top: 20px

  .brand-item
    text-align: center
    width: 200px
    font-size: 12px
    font-weight: 300
    position: relative

    &__thumb
      background-color: #fff
      text-align: center
      display: flex
      justify-content: center
      align-items: center
      height: 200px

    &__body--title
      color: #000000
      padding-bottom: 30px
      padding-top: 20px
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
