
.retailer-location-tab__main {
  p {
    margin-bottom: 0;
  }
  .search-title-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .create-new-location {
      width: 350px;
      display: flex;
      p {
        width: 150px;
        height: 30px;
        line-height: 30px;
        background-color: #ffffff;
        border-radius: 5px;
        border: 1px solid #e19387;
        color: #e19387;
        text-align: center;
        cursor: pointer;
      }
      p:nth-child(1) {
        margin-right: 20px;
      }
      .add-location-style {
        background: #e19387;
        color: #ffffff;
      }
    }
  }
  .retailer-training-search {
    display: flex;
    .search-style {
      display: flex;
      flex-direction: column;
    }
    .search-input {
      width: 350px;
      margin-right: 15px;
      p {
        margin-bottom: 10px;
      }
    }
    .search-resent {
      width: 270px;
      p {
        margin-bottom: 10px;
      }
    }
  }
  .retailer-brand-training-table {
    //width: 952px;
    margin-top: 30px;
    //position: relative;
    .training-table-style {
      overflow: visible;
      width: 100%;
      color: #262626;
      //border: #e8e8e8 solid 1px;
      border-collapse: collapse;
    }
    .training-table-style tr {
      width: 100%;
      position: relative;
    }
    .training-table-style th {
      border-top: #e8e8e8 solid 1px;
      border-bottom: #e8e8e8 solid 1px;
      padding: 16px;
      //background-color: #fafafa;
      font-size: 14px;
      font-weight: normal;
    }
    .training-table-style tr td {
      max-width: 250px;
      height: 56px;
      border-bottom: #e8e8e8 solid 1px;
      padding: 17px 16px;
      background-color: #ffffff;
      color: #595959;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .training-table-style tr,
    .training-table-style td.move:hover {
      //cursor: pointer;
    }
    .training-table-style td.click-pro-action:hover {
      cursor: pointer;
    }
    .name-tab-style {
      position: relative;
      span {
        position: absolute;
        right: 16px;
        top: 15px;
      }
    }
    .editable-row-operations-share {
      text-align: center;
      padding: 3px 4px;
      background-color: #4a8a5d;
      color: white;
      border-radius: 5px;
    }
    .editable-row-operations {
      span:hover {
        color: #4a8a5d;
        text-decoration: underline;
      }
    }
  }
  .upload-bulk-csv-style {
    position: relative;
    .template-csv-style {
      position: absolute;
      bottom: -25px;
      right: 0;
      color: rgba(0, 0, 0, 0.45);
      font-size: 10px;
    }
    .template-csv-style:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .no-table-data {
    width: 100%;
    height: 270px;
    text-align: center;
    color: #bfbfbf;
    font-size: 16px;
    padding-top: 80px;
    position: relative;
    left: 350px;
  }
  .ant-tag {
    margin-bottom: 3px;
  }
  .upload-modal__body {
    margin-top: 20px;
  }
  .ant-upload-drag-icon {
    i {
      color: #4a8a5d !important;
    }
  }
  .retailer-location-upload-modal__action {
    padding: 20px 0;
    text-align: center;
  }
  .data-tab-style {
    //position: relative;
    //overflow: hidden;
    //text-overflow: ellipsis;
    //white-space: nowrap;
  }
  .location-circular-active {
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
  }
  .green-circular {
    background: #9fd19a;
  }
  .red-circular {
    background: #f27c59;
  }
  .icon-sort-style {
    color: #8c8c8c;
  }
  .table-pagination-style {
    text-align: right;
    margin-bottom: 30px;
  }
  .brand-orders__table-cell {
    .ant-checkbox-wrapper::after {
      contain: none;
      display: none;
    }
  }
}
