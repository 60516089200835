
.admin-orders {
  font-family: "Proxima";
  padding: 20px;

  .csv-style {
    min-width: 150px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border: 1px solid #ff6132;
    background-color: #fffaf9;
    color: #ff6132;
    cursor: pointer;
    position: relative;
    margin-right: 10px;

    .csv-select {
      position: absolute;
      top: 30px;
      // left: -1px;
      z-index: 1000;
      border: 1px solid #d9d9d9;
      background-color: white;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
      right: 0;
      width: 280px;
      padding-left: 30px;
      text-align: left;
    }

    .download-csv-select-one {
      display: block;
      color: #000000;
      // width: 150px;
      cursor: pointer;
    }

    .download-csv-select-one:hover {
      background-color: #fffaf9;
    }

    .download-csv-select-two {
      display: block;
      color: #000000;
      // width: 150px;
      cursor: pointer;
    }

    .download-csv-select-child {
      display: block;
      color: #000000;
      // width: 150px;
      cursor: pointer;
    }

    .download-csv-select-two:hover {
      background-color: #fffaf9;
    }
  }
}

.admin-order-top-button {
  display: flex;
  flex-direction: row;

  .top-left-button,
  .top-right-button {
    display: flex;
    flex-direction: row;

    .button-icon-style {
      >span {
        padding-right: 3px;
      }
    }
  }

  .fx-spacer {
    flex: 1 1 auto;
  }

  .clear-search-admin {
    cursor: pointer;
    text-decoration: underline;
    color: #ff6132;
    padding: 3px 15px;
    font-size: 16px;
  }

  .order-upload-admin {
    cursor: pointer;
    background: #fffaf9;
    border: 1px solid #ff6132;
    color: #ff6132;
    padding: 3px 15px;
    margin-right: 10px;
    font-size: 16px;
  }

  .select-text-right {
    position: absolute;
    right: 10px;
  }

  p {
    margin-bottom: 0;
  }
}

.ant-tabs {
  margin-top: 30px;
}

.ant-tabs-top {
  >.ant-tabs-nav {
    margin: 0;
  }

  .select-text-right {
    position: absolute;
    right: 10px;
  }
}

.ant-tabs-card {
  >.ant-tabs-nav {
    .ant-tabs-tab {
      background: #fff;
      border: 1px none white;
    }

    .ant-tabs-tab-active {
      background: #fafafa;
      color: grey;
      border: 1px solid grey;
    }
  }
}

.upload-modal {
  &__body {
    margin-top: 16px;
  }

  &__action {
    margin-top: 16px;
    display: flex;
  }

  &__btn {
    font-family: "Proxima Nova";
    font-weight: normal;
    font-size: 14px;
    margin: 0 auto;
    padding: 4px 20px;
    color: white !important;
  }

  &__error-icon {
    color: red;
    margin-right: 10px;
  }

  &__action-btn {
    padding: 4px 20px;
    border-radius: 2px;
    background-color: #4a8a5d;
    font-size: 24px;
    color: white;
    cursor: pointer;
    margin-right: 20px;
  }
}

.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: #4a8a5d;
}

.ant-btn:last-child {
  background: #4a8a5d;
  border: 1px solid transparent;
}
