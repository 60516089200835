
.productStatus
  position: absolute
  top: 14px
  left: 12px
  border: 1px solid rgba(0,0,0,0.38)
  padding: 4px
  p
    color: rgba(0,0,0,0.38)
    font-size: 12px
    letter-spacing: 0.09px
    line-height: 14px
    text-align: center
.brand-product-item
  position: relative
  margin-bottom: 50px

  .unpublished-tooltip
    display: none
    font-size: 12px
    min-width: 260px
    color: #4A4A4A
    background: #EAEAEA
    text-align: center
    position: absolute
    top: -55px
    left: -20px
    z-index: 100
    padding: 5px

    &:before
      content: ''
      display: block
      position: absolute
      left: 22px
      bottom: -7px
      border-left: 7px solid transparent
      border-right: 7px solid transparent
      border-top: 7px solid #EAEAEA

  &--unpublished
    .brand-product-item__thumb, .brand-product-item__body
      opacity: 0.5

  &.best-seller:before
    content: "*BEST SELLER*"
    background: #71cfb3

  &.new:before
    content: "*NEW*"
    background: #fcc9c5

  a
    color: #787474
    text-decoration: none

  .brand-product-item__thumb
    background-color: #fff
    border-radius: 4px
    overflow: hidden
    height: 264px
    max-width: 250px
    display: flex
    justify-content: center
    align-items: center
    margin-bottom: 10px

    img
      max-width: 90%
      max-height: 90%

  .brand-product-item__dropdown
    position: absolute
    width: 128px
    height: 92px
    top: 20px
    right: 5px
    z-index: 100
    background-color: #ffffff
    border: 1px solid #DADADA

  .brand-product-item__dropdown-item
    height: 45px
    display: flex
    align-items: center
    padding-left: 10px
    cursor: pointer

  .brand-product-item__dropdown-icon
    padding-right: 15px

  .brand-product-item__dropdown-item:hover
    background-color: #F5F6F8

  .brand-product-item__remove-button
    color: #BF0711

  .brand-product-item__unpublished-icon
    position: absolute
    top: 15px
    right: 25px
    z-index: 1
    height: 20px
    width: 20px
    display: flex
    justify-content: center
    align-items: center

    &:hover
      .unpublished-tooltip
        display: block


  .brand-product-item__circles
    position: absolute
    top: 10px
    right: 0px
    z-index: 1
    cursor: pointer
    height: 30px
    width: 30px
    display: flex
    justify-content: center
    align-items: center

  .brand-product-item__video
    display: flex
    background-color: #fff
    padding: 30px
    margin-bottom: 15px
    border-radius: 4px
    overflow: hidden
    height: 264px

  .brand-product-item__body
    color: #888888
    letter-spacing: 0.01em
    font-size: 13px

    &-title
      font-size: 14px
      letter-spacing: 0.2px
      overflow: hidden
      min-height: 42px
      text-overflow: ellipsis
      display: -webkit-box
      -webkit-line-clamp: 2
      -webkit-box-orient: vertical

      a
        color: black

    &-price
      font-weight: 300
      color: #292929
      font-size: 16px
      letter-spacing: 0.5px
      line-height: 22px
      margin-bottom: 9px

    &-brand
      font-size: 14px
      letter-spacing: 0.2px
      text-transform: uppercase
      color: #292929

    &-status
      color: #757575
      font-size: 12px
      letter-spacing: 0.17px
