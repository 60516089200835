
#dirtyCategoryIngredientPanel
  .ant-collapse-icon-position-right
  > .ant-collapse-item
  > .ant-collapse-header {
  display: block;
  padding-left: 30px;
}

.button {
  padding: 5px;
  text-transform: none !important;
}
.fx-r {
  display: flex;
  flex-direction: row;

  .button {
    margin-left: 10px;
  }

  > div {
    display: inline-flex;
  }

  &.center {
    justify-content: center;
  }

  &.pad-t {
    padding-top: 10px;
  }
}
.spacer {
  flex: 1 1 auto;
}
.fx-c {
  display: flex;
  flex-direction: column;
  width: 100%;

  .fx-r:not(:first-child) {
    &.label {
      padding-top: 12px;
    }
  }

  .label {
    font-size: 13px;
  }
}
.full {
  .ant-select-selection {
    width: 100%;
  }
}
a {
  &.upload {
    display: flex;
    align-items: center;
    font-family: "Niveau Grotesk";
    font-size: 14px;
    text-decoration: underline;
    color: #4a8a5d;
    margin-right: 16px;
    font-weight: bold;
  }
}
.hightlight {
  .ant-collapse-header {
    background: #e2eeec;
    border-bottom: 1px solid #d9d9d9;
  }
}
.ant-modal-content .ant-btn-primary,
.ant-modal-content .upload-modal__btn {
  background-color: #4a8a5d !important;
  color: white !important;
}

.title-panel {
  background-color: #ffff66;
  margin-right: 10%;
  padding: 20px;
}

.upload-csv-link {
  align-items: center;
  white-space: nowrap;
  height: 46px;
}
