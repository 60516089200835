
.approve-modal
  .modal-card-body
    display: flex
    justify-content: center
    align-items: center

  &__header
    color: #212B36
    font-size: 20px
    border-bottom: 1px solid #E1E4E9
    padding-top: 15px
    padding-left: 20px
    padding-bottom: 15px

  &__content
    position: relative
    background-color: #FFFFFF
    width: 510px
    height: 474px

  &__body
    width: 325px

  &__input-label
    padding-bottom: 5px
    font-size: 14px
    color: #000

  &__buttons
    padding: 10px 20px

  &__close-button
    position: absolute
    top: 20px
    right: 20px
    cursor: pointer
    height: 20px
    width: 20px

  &__save-button
    font-size: 14px
    width: 325px
    margin: 0 auto

  .is-red
    color: #ff3860
    border-color: #ff3860
