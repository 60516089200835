
.brand-profile-right-item
    &__outlined
      border: 1px solid rgba(151, 151, 151, 0.234149)
      border-radius: 2px
      box-sizing: border-box
      padding: 24px
    &__title
        font-size: 24px !important
        font-weight: normal !important
        line-height: 24px !important
        color: #000000 !important
        margin-bottom: 24px !important
        letter-spacing: 0.25px
    &__content
        font-family: Proxima Nova
        font-style: normal
        font-weight: normal
        font-size: 14px
        line-height: 22px
        letter-spacing: 0.13125px
        color: #000000
