
.markAsIcon
  position: absolute !important
.old-retailer-order-status-bar
  .old-retailer-order-status-bar__block
    display: flex
    padding-bottom: 45px
    padding-top: 8px
    .order-status-bar
      display: flex
      flex-direction: column
      align-items: self-start
      span
        width: 100%
        font-size: 14px
        color: #333
        padding-top: 16px
        text-align: center
    .order-status-bar.completed
      svg
        .order-status-bar-order-status-bar-inactive
          fill: #FFFBFA !important
  &__statuses
    max-width: 1180px
    padding-top: 5px
    padding-bottom: 40px
    margin: 0 auto
  &__item
    padding-right: 15px
    cursor: pointer
  &__icon
    img
      margin-bottom: -5px
      margin-right: 3px
      cursor: pointer
  &__creation-date
    padding-top: 52px
    color: #212B36
    font-size: 14px
    font-weight: 300
    display: block
  &__line-container
    max-width: 176px
  .disabled
    opacity: 0.6
    pointer-events: none
.dropIcon
  position: absolute !important
.add-tracking
  margin-left: 100px
