
@font-face {
  font-family: "Roboto";
  src: url("~@/assets/fonts/Roboto-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("~@/assets/fonts/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("~@/assets/fonts/Poppins-Medium.ttf") format("truetype");
}

.team_container {
  max-width: 1140px;
  margin: auto;
}

.team_page {
  margin: 0 auto;
}

.team_title {
  font-family: Playfair Display, sans-serif;
  font-style: normal;
  font-size: 48px;
  font-weight: 700;
  line-height: 52px;
  color: #12433d;
  margin-bottom: 100px;
}

.team_list {
  display: flex;
  flex-wrap: wrap;
}

.modal-body {
  display: flex;
  flex-wrap: wrap;
  margin-top: 55px;
  margin-bottom: 30px;
}

.team_role {
  writing-mode: vertical-rl;
  transform: rotate(-180deg);
  font-family: Poppins, sans-serif;
  font-size: 12px;
  position: absolute;
  left: 33px;
  letter-spacing: 3px;
  color: #1e252c;
}

.team_content {
  padding: 30px 49px;
  position: relative;
  flex: 33.3%;
}

.team_contain {
  margin-top: 119px;
  margin-bottom: 647px;
  text-align: center;
}

.team_head {
  font-family: "Poppins-Bold", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 38px;
  margin-top: 8px;
  letter-spacing: 0.5px;
  color: #1e252c;
  text-align: left;
  padding-left: 16px;
}

.modal_left {
  flex: 0 0 41.666667%;
  padding: 8px 28px 8px 45px;
}

.modal_right {
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 54px;
  flex: 0 0 58.33333%;
}

.detail {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
}

.modal-title {
  height: 27px;
}
