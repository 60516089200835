
.admin-message-recipeint-box .ant-tag {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  background: #e2eeec;
  border: 1px solid #12433d;
  box-sizing: border-box;
  border-radius: 2px;
  color: #12433d;
  font-size: 14px;
  margin-right: 4px;
}
.admin-message-recipeint-box .admin-message-recipeint-box__deleted-item.ant-tag {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  background: #d9d9d9;
  border: 1px solid #12433d;
  box-sizing: border-box;
  border-radius: 2px;
  color: #12433d;
  font-size: 14px;
  margin-right: 4px;
}
.admin-message-recipeint-box .ant-tag .anticon {
  color: #12433d;
  font-size: 10px;
}
