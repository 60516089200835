
.message-customer-condition__menu .ant-tooltip-arrow {
  display: none;
}
.message-customer-condition__menu .ant-tooltip-inner {
  padding: 0px;
  border-radius: 2px;
  background: #fafafa;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
  color: #8c8c8c;
  width: 104px;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
.message-customer-condition__menu .message-customer-condition__menu-item:hover {
  background-color: rgba(74, 138, 93, 0.15);
}
.admin-message-compose__custom-group .ant-modal-mask,
.admin-message-compose__custom-group .ant-modal-wrap {
  z-index: 1050;
}
