
.admin-landing-terms__main {
  p {
    margin-bottom: 0;
  }
  .landing-terms-title {
    font-size: 28px;
    color: #000000;
    font-weight: 700;
    font-family: "Proxima Nova", "Roboto", sans-serif;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .add-new-terms-button {
      width: 212px;
      height: 32px;
      color: white;
      text-align: center;
      line-height: 32px;
      font-size: 14px;
      border-radius: 2px;
      cursor: pointer;
      background-color: #ff6132;
    }
  }
  .landing-terms-content {
    .check-standard-style {
      margin-bottom: 80px;
      min-height: 40px;
      span {
        margin-right: 20px;
      }
    }
  }
  .title-second-style {
    font-size: 24px;
    color: #000000;
    font-family: "Proxima Nova", "Roboto", sans-serif;
  }
  .table-terms-standard {
    height: 46px;
    line-height: 46px;
    margin-bottom: 0;
    border: 1px solid #595959;
    border-left: none;
    border-right: none;
    span {
      display: inline-block;
      width: 280px;
    }
  }
  .table-terms-standard:nth-child(2) {
    border-top: none;
  }
  //.no-border {
  //  border: none;
  //  height: 25px;
  //}
  .special-span-style {
    display: block;
  }
  //.header-collapse-title__name {
  //  margin-top: 10px;
  //  margin-bottom: 10px;
  //  span {
  //    margin-right: 20px;
  //  }
  //}
  .add-button-style {
    width: 50px;
    cursor: pointer;
    text-decoration: underline;
    color: #ff6132;
  }
  .admin-other-terms-content {
    border: 1px solid #595959;
    border-left: none;
    border-right: none;
    padding: 12px 10px;
    .admin-other-name {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .name-left {
        span {
          margin-right: 20px;
        }
      }
      .name-right {
        span {
          display: inline-block;
          width: 60px;
          height: 24px;
          line-height: 24px;
          text-align: center;
          color: #595959;
          background-color: #f5f5f5;
          border: 1px solid #595959;
          border-radius: 2px;
          cursor: pointer;
          margin-right: 20px;
        }
      }
    }
    .admin-other-number {
      padding: 0 20px;
      .other-number-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 13px;
        .number-name {
          width: 280px;
        }
      }
    }
    p {
      margin-bottom: 0;
    }
  }
  .admin-other-terms-content:nth-child(n + 1) {
    border-top: none;
  }
  .admin-other-terms-content:nth-child(1) {
    border-top: 1px solid #595959;
  }
  .ant-switch-checked {
    background-color: #ff6132;
  }
  .standard-title-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .standard-edit {
    //display: inline-block;
    //width: 60px;
    //height: 24px;
    //line-height: 20px;
    //text-align: center;
    color: #595959;
    //background-color: #f5f5f5;
    //border: 1px solid #595959;
    //border-radius: 2px;
    cursor: pointer;
    margin-right: 20px;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: #ff6132 !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: white !important;
    border-color: #ff6132 !important;
  }
  .ant-checkbox-wrapper {
    margin-left: -27px !important;
    color: #262626 !important;
    font-weight: 600 !important;
    font-family: "Proxima Nova", "Roboto", sans-serif;
  }
}
.modal-terms-content__main {
  padding-left: 200px;
}
.accept-text-terms {
  background-color: #ff6132;
  color: white;
}
.add-landing-terms-number {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  p {
    margin-bottom: 0;
  }
}
.add-landing-terms-two {
  margin-left: 80px;
}
.other-terms-item {
  margin-bottom: 20px;
}
.add-other-info {
  margin-bottom: 15px;
  font-size: 14px;
  color: #000000;
}
.spin-style-admin {
  text-align: center;
  margin: 40px 0;
}
//.ant-checkbox-wrapper {
//  margin-left: -27px !important;
//  color: #262626 !important;
//  font-weight: 600 !important;
//  font-family: "Proxima Nova", "Roboto", sans-serif;
//}
.dialog-text-style {
  font-size: 14px;
  font-family: "Proxima Nova", "Roboto", sans-serif;
  font-weight: 400;
  margin-top: 14px;
}
